import React from "react";
// Customizable Area Start
import {
    Box,
    Radio,
    withStyles,
    styled,
    MenuItem,
    Select
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmenttestController, {
    Props,
} from "./AssessmenttestController";
import MainHeader from "../../../components/src/MainHeader.web";
// Customizable Area End

export default class RetirementCalc extends AssessmenttestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customSelBox = (input: any) => {
        return (
            <div style={webStyle.customBox}>
                {input}
            </div>
        )
    }

    customSelect = (heading: string, name: string, onChange: any, value: string, subHeading?:string) => {
        return this.customSelBox(
            <>
                <h3 style={webStyle.heading}>{heading}
               { subHeading &&<span style={webStyle.subHeading}>{subHeading}</span> }
               </h3>
                <StyledSelect
                    IconComponent={arrowDownIcon}
                    MenuProps={{disableScrollLock:true}}
                    style={{ color: 'grey', }}
                    data-test-id="assessment"
                    disableUnderline
                    displayEmpty
                    value={value}
                    name={name}
                    onChange={onChange}
                    renderValue={value !== "" ? undefined : () => "Select"}
                >
                    <MenuItem className='menuItems' value={"Yes"}>Yes</MenuItem>
                    <MenuItem className='menuItems' value={"No"}>No</MenuItem>
                </StyledSelect>
            </>
        )
    }


    render() {
        return (
            <>
                <div style={webStyle.mainDiv}>
                    <MainHeader handleLogout={this.handleLogout} handlemodalopen={this.handlemodalopen} data-aos='zoom-in' navigation={undefined} handleHeaderRoute={this.handleHeaderRoute} handledashboard={this.navigateToDashBoard} handledashprofile={this.handledashprofile} handleToLandingPage={this.handleToLandingPageTo} handleLogin={this.handleLoginBtn} type={""} />
                </div>

                <Box>
                    <Box style={webStyle.mainBox} >

                        <div style={webStyle.div2}>
                            <div style={webStyle.div3} onClick={this.handlenavigation}>
                                <ArrowBackIosIcon style={webStyle.arrowColor} />
                                <h4 style={webStyle.backHome}>Back To Home</h4>
                            </div>
                            <h1 style={webStyle.headerColor}>Retirement Benefits Calculator</h1>
                        </div>
                        {this.customSelect("Were you in india for a period of 182 or more days?","assessmentYear",this.handleSelectChange,this.state.assessmentYear)}
                        {this.customSelect("Have you been a resident in india for 2 out 10 preceding years? ","taxPayer",this.handleSelectChange,this.state.taxPayer,"(Excluding FY for which filing is done)")}
                        {this.customSelect("Have you been a resident in india for 730 or more days i 7 preceding years? ","gender",this.handleSelectChange,this.state.gender,"( Provide calculator)")}
                    </Box>
                    <div style={webStyle.btnDiv} >
                        <button style={webStyle.btnStyle} data-test-id="calculateBtn" onClick={this.handleCalculate}>Calculate</button>

                        <button style={webStyle.btnReset}
                            onClick={this.handleReset}
                        >Reset</button>
                    </div>
                </Box>
            </>
        );
    }
}

const webStyle = {
    mainBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column' as "column",
        alignItems: 'center'
    },
    heading:{
        fontSize: '22px',
        color:"#000000",
    },
    subHeading:{
        fontSize: '18px',
        color:"#000000",
        fontWeight: "normal" as "normal"
    },
    mainDiv: {
        height: '100px',
        backgroundColor: '#347152',
        marginBottom: '50px'
    },
    div2: {
        marginBottom: '5px',
        width: '85%',
    },
    div3: {
        height: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    backHome: {
        color: "#347152",
        fontWeight: 'normal' as "normal",
    },
    headerColor: {
        color: "#36403B",
        fontSize: "32px",
        fontWeight: "bold" as "bold"

    },
    arrowColor: {
        color: "#347152"
    },
    btnDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        marginTop: '50px',
    },
    btnStyle: {
        height: '45px',
        width: '170px',
        color: 'white',
        backgroundColor: '#347152',
        fontSize: '16px',
        margin: '10px',
        fontWeight: "bold" as "bold",
        borderRadius: '4px',
        fontFamily: 'sans-serif'
    },
    btnReset:{
        backgroundColor: 'white',
        height: '45px', 
         width: '170px',
          color: '#347152',
        border: '2px solid #347152', 
        fontSize: '16px', 
        margin: '10px',
        fontWeight: 'bold' as "bold",
        borderRadius: '4px', 
        fontFamily: 'sans-serif'
    },
    customBox: {
        marginBottom: '10px', 
        border: '1px solid #d3d3d3', 
        padding: '2px 35px',
        width: '80%',        
        minHeight: "50px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
    }
}

const StyledRadio = styled(Radio)({
    '&.Mui-checked': {
        color: 'rgb(52, 113, 82)',
    },
});
const StyledSelect = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        width: '143px',
        padding: '5px',
        paddingLeft: '10px',
        border: '1px solid grey',
        borderRadius: '4px',
        height: '20px',
        paddingTop: '6px',
        '& .MuiSvgIcon-root': {
            color: "red !important",
            marginRight: '10px',
        },
    },
}))(Select);
const arrowDownIcon = styled(ExpandMoreIcon)({
    marginRight: "10px"
})

// Customizable Area End
