import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Dialog,
    styled,
    Theme,
    Button,
    IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons"
import { upload, deleteRed } from "../../blocks/multipageforms2/src/assets";

import {
    configJSON,
} from "../../blocks/multipageforms2/src/CryptoVirtualDigitalAssetsController.web";

interface MyComponentProps {
    docData: any;
    handleModalOpen: any;
    handledelete: any;
    handleFileDrop:any;
    fileError:any;
    handleFileChange:any;
    handleClose:any;
    openbar:any;
    handleBrowseClick:any;
    handledownload?:any;
}

const CustomDialogCrpto = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "924px",
        width: "924px",
        "&>div": {
            overflowY: "scroll",
            height: "800px",
            "&>div": {
                padding: "0px 30px"
            }
        }
    },
    "& .MuiDialog-paper": {
        borderRadius: "24px",
        padding: "30px 0px"
    },
    "& .IS_header--text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .IS_header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .btn": {
        width: "250px",
        height: "50px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#858585",
        marginRight: "35px"
    },
    "& .IS_header--icon": {
        transform: "scale(1.2)",
        color: "#337152"
    },
    "& .btn_group": {
        margin: "30px 0px"
    },
    "& .btn_active": {
        backgroundColor: "#337152",
        color: "#ffffff !important"
    },
    "& .MuiSvgIcon-root": {
        fontSize: "1.7rem"
    },
    "& .MuiFormGroup-root": {
        flexDirection: "row"
    },
    "& .IS_document--box": {
        width: "518px",
        height: "193px",
        borderRadius: "8px",
        border: " 1px dashed #2B664B",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20px"

    },
    "& .file_formate": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .IS_document": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        "&>p": {
            color: "#337152",
            fontFamily: "Nunito",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: "30px"
        },
        "&>button": {
            width: "171px",
            height: "46px",
            borderRadius: "8px",
            backgroundColor: "#FFF5DC"
        }
    },
    "& .upload_icon": {
        marginTop: "40px",
        marginBottom: "20px"
    },
    "& .need_help": {
        color: "#C7AA61",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .IS_dounload--button": {
        borderRadius: "8px",
        border: "1px solid #337152",
        width: "183px",
        height: "46px",
        color: "#337152",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .IS_line": {
        height: "2px",
        backgroundColor: "rgba(152, 152, 152, 0.50)",
        width: "100%",
        marginTop: "30px"
    },
    "& .btn-small": {
        width: "198px",
        height: "46px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        backgroundColor: "#337152",
        color: "#ffffff !important",
        marginTop: "50px"
    },

    "& .IS_dounload--text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .IS_dounload--box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "30px"
    },
    "& .fontstyle": {
        fontSize: "1.13rem",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginBottom: "5px"
    },
    "& .browse-data": {
        textDecoration: "underline",
        color: "#2B664B",
        cursor: "pointer"
    },
    "& .doc_list": {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "14.5px",
        padding: '0.8em 1em 0.5em 1em',
        border: "0.5px solid #979797",
        margin: '0.5em 0 0.5em 0',

    },
    "& .doc-style": {
        color: "rgba(54,64,59,0.7)",
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Nunito",
    },
    "& .image-logo": {
        cursor: "pointer"
    },
    "& .file-error": {
        color: "red",
        fontSize: "13px",
        fontWeight: 600,
        fontFamily: "Nunito",
    }

})

export class UploadDocDailog extends React.Component<MyComponentProps> {


    ImportDetailUsingStatement = () => {
        const {docData,handleModalOpen,handledelete,handleFileDrop,fileError,handleFileChange,handleClose,handleBrowseClick,handledownload}=this.props;
        return (
            <Box>
                <Box>
                    <Box className="IS_header">
                        <Typography className="IS_header--text" data-test-id="docData-item">{configJSON.Importdetailsusingstatements}</Typography>
                        <IconButton onClick={handleModalOpen} data-test-id="handle-id">
                            <Close className="IS_header--icon" />
                        </IconButton>
                    </Box>
                    {docData && docData?.metadata?.map((item:{file_id:string,file_name:string}) => {
                        return (
                            <Box className="doc_list" data-test-id={"present" +item.file_id} >
                                <Typography className="doc-style">{item.file_name}</Typography>
                                <img src={deleteRed} data-test-id={"delete" + item.file_id} onClick={()=>handledelete(item.file_id)} className="image-logo" />
                            </Box>
                        )
                    })}
                    <Box>
                        <Box className="IS_document"
                            onDrop={handleFileDrop}
                            onDragOver={(e) => e.preventDefault()}
                            data-test-id="img_drag"
                        >
                            <Typography className="file-error" variant="h6">{fileError}</Typography>
                            <Box className="IS_document--box">
                                <img className="upload_icon" src={upload} alt="Upload Icon" />
                                <Typography className="fontstyle">
                                    {configJSON.Dragdrop} <span className="browse-data" data-test-id="open_folder" onClick={handleBrowseClick}>{configJSON.BrowseText}</span>
                                </Typography>
                                <input
                                    id="fileInput"
                                    type="file"
                                    value=""
                                    accept=".csv, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileChange}
                                    data-test-id="img_upload"
                                    style={{ display: 'none' }}
                                />
                                <Typography className="file_formate">{configJSON.supportedFormat}</Typography>
                            </Box>
                            <Button variant="text" className="need_help" onClick={handleModalOpen}>{configJSON.NeedHelp}</Button>
                            <Box className="IS_line"></Box>
                        </Box>
                        <Box className="IS_dounload--box">
                            <Typography className="IS_dounload--text">{configJSON.Downloadstandardtemplate}</Typography>
                            <Button variant="text" className="IS_dounload--button" onClick={handledownload}>{configJSON.Download}</Button>
                        </Box>
                        <Button className="btn-small" onClick={handleClose}>{configJSON.Next}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }
    render() {
        const { openbar } = this.props;
        return (
            <CustomDialogCrpto open={openbar}>
                {this.ImportDetailUsingStatement()}
            </CustomDialogCrpto>
        )
    }

}

export default (UploadDocDailog);

// Customizable Area End