import {
  styled,
  Dialog,
  Box
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
export const SuccessDialog = styled(Dialog)({
  "& .capital_success_icon": {
    width: "60px",
    margin: "20px auto"
  },
  "& .MuiDialog-paperWidthSm": {
    width: "592px",
    maxWidth: "592px",
    display: "flex",
    alignItems: "center",
    "&>div": {
      height: "800px",
    }
  },
  "& .S_text-1": {
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: "30px"
  },
  "& .S_text-2": {
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .MuiDialog-paper": {
    borderRadius: "24px"
  },
  "& .S_dialogue_title--text": {
    color: "#337152",
    fontFamily: "Nunito",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "20px"
  },
  "& .S_button": {
    width: "198px",
    height: "46px",
    color: "#FFF",
    backgroundColor: "#337152",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "-0.529px",
    fontFamily: "Nunito",
    fontSize: "16px",
    borderRadius: "10px",
    marginBottom: "25px"
  }
})
export const CustomAutocomplete = styled(Autocomplete)({
  position: "relative",
  "& .MuiAutocomplete-popupIndicator>span": {
    visibility: "hidden"
  },
  "& .MuiIconButton-root": {
    position: "relative",
    zIndex: 1000,
  },
  "& .MuiAutocomplete-input:first-child": {
    padding: "6.5px 4px"
  },
})

export const StepperContainer = styled(Box)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "D1D1D1"
  },
  "& .MuiStepIcon-root": {
    color: "white"
  },
  "& .MuiStepIcon-text": {
    fill: "black",
    fontSize: "0.57rem"
  },
  "& .MuiSvgIcon-root": {
    fontSize: "3.3rem",
    color: ""
  },
  "& .MuiStepConnector-alternativeLabel": {
    top: "25px"
  },
  "& .MuiStepper-alternativeLabel": {
    backgroundColor: "#F4F6F5",
  },
  "& .MuiStepConnector-lineHorizontal": {
    borderTopStyle: "dashed",
    borderWidth: "2px",
    zIndex: 1
  },
  "& .MuiStepLabel-iconContainer": {
    zIndex: 2
  },
  "& .MuiTypography-root": {
    color: "black !important"
  },
  '& .MuiStepIcon-completed': {
    color: 'blue',
  },
  "& .steplabel>span>svg": {
    color: "#337152 !important"
  },
  "& .steplabel>span>span": {
    color: "#337152  !important",
    fontFamily: "Nunito",
    fontSize: "18px",
  },
  "& .steplabel>span>svg>text": {
    fill: "#ffffff !important"
  },
  "& .MuiStepLabel-label": {
    color: "#000000bf !important",
    fontFamily: "Nunito",
    fontSize: "18px",
  },
})
export const TableContainer = styled(Box)({
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
      height: "7.94px"
  },
  "&::-webkit-scrollbar-track": {
      background: "#D8D8D8"
  },
  "&::-webkit-scrollbar-thumb": {
      background: "#337152",
      borderRadius: "10px"
  },
  '& table': {
      borderSpacing: '0px 10px',
      width: "1200px",
  },
  '& thead th': {
      background: '#F4F6F5',
      border: '1px solid #DCDCDC',
      borderLeft: 'none',
      borderRight: 'none',
      height: "48px",
      fontSize: "20px",
      fontWeight: 400,
      color: "#36403B"
  },
  '& tbody td': {
      background: '#F4F6F5',
      border: '1px solid #DCDCDC',
      padding: 15,
      borderLeft: 'none',
      borderRight: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 400,
      color: "#36403B"
  },
  '& thead th:first-child': {
      borderTopLeftRadius: '15px',
      borderLeft: '1px solid #DCDCDC',
  },
  '& thead th:last-child': {
      borderTopRightRadius: '15px',
      borderRight: '1px solid #DCDCDC'
  },
  '& tbody td:first-child': {
      borderLeft: '1px solid #DCDCDC',
  },
  '& tbody td:last-child': {
      borderRight: '1px solid #DCDCDC'
  },
})