import React, { useEffect } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

export interface State extends SnackbarOrigin {
  open: boolean;
}
interface MyPropsType {
  type: 'success' | 'info' | 'warning' | 'error';
  open: boolean,
  message: string,
  handleTostClose: (e:React.SyntheticEvent)=>void
}
export default function Toster(props: MyPropsType) {
  const [state, setState] = React.useState<State>({
    open: props.open,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;
console.log(open,"state", props.open)
  const handleClose = (e:React.SyntheticEvent) => {
    setState({ ...state, open: false });
    props.handleTostClose(e)
  };

  useEffect(() => {
    setState({ ...state, open: props.open })
  }, [props.open])

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={4000}
      >
        <Alert onClose={handleClose} severity={props.type}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}