import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Theme,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { info,listedshare3,manualEntry } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from "../../../components/src/Header.web";
import UploadDocDailog from "../../../components/src/UploadDocDailog.web";
import CryptoVirtualDigitalAssetsController, {
    Props,
    configJSON,
} from "./CryptoVirtualDigitalAssetsController.web";
import CryptoVirtualDigitalManualAsset from "./CryptoVirtualDigitalManualAsset.web"
import CryptoListedData from "./CryptoListedData.web"
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";


// Customizable Area End

export class CryptoVirtualDigitalAssets extends CryptoVirtualDigitalAssetsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderCommonCardforCryptoAssets = (title: string,image:string) => {
        const { classes } = this.props;
        return (
            <Box className={classes.mainCard}  >
                <Box className={classes.cardBorder}>
                    <img src={image} className={classes.imageWidth} />
                </Box>
                <Box className={classes.textDiv}>
                    <Typography className={classes.heading11}>{title}</Typography>
                </Box>

            </Box>
        )
    }
    renderCryptoCards = () => {
        const { classes } = this.props;
        return (
            <Box className={classes.mainWrapperbox}>
                <Box className={classes.subWrapper}>
                    <Box onClick={this.handleManulform} data-test-id="manual-form">
                        {this.renderCommonCardforCryptoAssets(configJSON.entriesmanually,manualEntry)}
                    </Box>
                    <Box onClick={this.handleModalOpen} data-test-id="open-modal">
                        {this.renderCommonCardforCryptoAssets(configJSON.statementsAvailable,listedshare3)}
                    </Box>
                </Box>
            </Box>
        )
    }
    renderDynamicCards = () => {
        const { classes } = this.props;
        if (this.state.isLoading ) {
            return (
                <Box className={classes.loaderContainer} data-test-id="initial-render">
                    <CircularProgress size="4em" />
                </Box>
            )
        }
         if (this.state.dataPresent&& this.state.docData?.data?.length >= 1) {
            return (
                <>
                    <CryptoListedData
                        navigation={this.props.navigation}
                        id={""}
                        handleModalOpen={this.handleModalOpen}
                        docData={this.state.docData}
                        handledelete={this.handledelete}
                        handleFileDrop={this.handleFileDrop}
                        fileError={this.state.fileError}
                        handleBrowseClick={this.handleBrowseClick}
                        handleFileChange={this.handleFileChange}
                    />
                </>
            )
        }
         else if (Object.keys(this.state.docData).length ==0){
            let openbar = this.state.documentUpload
            return (
                !this.state.openManualForm ?
                    <Container className={classes.mainWrapper} maxWidth={false}>
                        <Box className={classes.conditionalheading} >
                            <p data-test-id="initial-render-arrow" onClick={()=>this.props.changeTab("Capital Gains")}>
                            <ArrowBackIosIcon fontSize="large"className={classes.arrowColor} />
                            </p>
                            <Typography className={classes.donationdeading} data-test-id="initial-render">{configJSON.CryptoorVirtual}</Typography>
                        </Box>
                        <Box className={classes.backgroundWrapper}>
                            {this.renderCryptoCards()}
                        </Box>
                        {openbar && <UploadDocDailog
                            handleModalOpen={this.handleModalOpen}
                            docData={this.state.docData}
                            handledelete={this.handledelete}
                            handleFileDrop={this.handleFileDrop}
                            fileError={this.state.fileError}
                            handleBrowseClick={this.handleBrowseClick}
                            handleFileChange={this.handleFileChange}
                            handleClose={this.handleModalOpen}
                            openbar={openbar}
                            handledownload={this.handleSampleCSV}

                        />}
                    </Container> : <CryptoVirtualDigitalManualAsset navigation={this.props.navigation} onClick={this.handleManulform} />
            )
        }
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
                <Box className={classes.header}>
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                </Box>

                {this.renderDynamicCards()}


            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

export const styles = (theme: Theme) => ({
    arrowColor: {
        color: "#337152"
    },
    header: {
        margin: '0 2em 0 2em'
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    subWrapper: {
        paddingBottom: "10em"
    },
    mainWrapperbox: {
        borderRadius: 16,
        background: '#F4F6F5'
    },
    heading11: {
        color: '#36403B',
        fontStyle: 'normal',
        fontSize: 18,
        fontFamily: 'Nunito',
        fontWeight: 700,
        marginBottom: '10px'
    },
    textDiv: {
        flexDirection: 'column' as const,
        width: '100%',
        justifyContent: 'center',
        paddingLeft: 50,
        display: 'flex',
    },
    cardBorder: {
        borderRadius: 12,
        background: '#F4F6F5',
        padding: '0px 45px'
    },
    imageWidth: {
        width: 160
    },
    mainCard: {
        display: 'flex',
        background: '#FFF',
        marginBottom: "10px",
        padding: 20,
        borderRadius: 16,
        position: 'relative' as const,
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
        cursor: "pointer"
    },
    imagewidth: {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "70%",
        height: "60%"
    },

    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '3em',
        padding: 0,
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },

})
export default withStyles(styles)(CryptoVirtualDigitalAssets);
// Customizable Area End