import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getNavigationMessage } from "../../../components/src/Navigation.web"
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
  navigation: any;
  id?: string;
  step: number;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeItems: any;
  drawer: boolean;
  formsPresent: boolean;
  formLoading:boolean;
  formNumber: number;
  hide: boolean;
  employers: any;
  step: number;
  activeId: {id:string, type:string};
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StaticSalaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  employerDetailsGet: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      activeItems: {},
      drawer: false,
      formsPresent: false,
      formLoading:false,
      formNumber: 1,
      hide: false,
      employers: [],
      activeId: {id:"", type:""},
      step: 0
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.employerDetailsGet === responseData) {
      this.handleUserEmployerCall(successMessage);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.employerDetails()
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigateToDoc = () => {
    const navigationMessage = getNavigationMessage('Menu', this.props);
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), configJSON.DocumentsEnd)
    this.send(navigationMessage);
    window.location.reload()
  }

  handleUpdateData = (id: string, type: string) => {
    this.setState({ activeId: {id: id, type: type}})
    this.handleNavigateToForm2()
  }


  handleChildChange = (updatedData: number) => {
    this.setState({
      step: updatedData
    })
  };
  

  ///companydetails functionality start///
  handleActiveDrop = (id: string) => {
    this.setState((prevState) => ({
      activeItems: {
        ...prevState.activeItems,
        [id]: !prevState.activeItems[id] || false,
      },
    }));
  };
  handleDrawer = (id:string,type:string) => {
    this.setState({ drawer: !this.state.drawer })
    this.setState({ activeId: {id: id, type: type}})
  }

  handleNavigateToForm2 = () => {
    this.setState({
      hide: !this.state.hide,
      formsPresent: false,
      formNumber: 3
    })
  }

  //company details get data start //
  handleUserEmployerCall = (apiResponse: any) => {
    if (apiResponse && apiResponse.salarystruct?.data?.length > 0 || apiResponse.employers?.data?.length > 0 ) {
      let array = [...apiResponse.salarystruct.data, ...apiResponse.employers.data];
      this.setState({ employers: array,formLoading:false, formsPresent: true })
    }else{
      this.setState({ formsPresent: false,formLoading:false })      
    }
  }

  employerDetails = async () => {
this.setState({formLoading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employerDetailsGet = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employerEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  
  //company details get data end //




  // Customizable Area End
}
