import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Theme,
    TextField,
    FormControl,
    MenuItem,
    FormHelperText,
    Select,
    FormGroup,
    Checkbox,
    FormControlLabel,
    styled
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PartnershipFirmFormController, {
    Props,
    configJSON,
} from "./PartnershipFirmFormController.web";
import Toster from "../../../components/src/Toster";
// Customizable Area End

export class PartnershipFirmForm extends PartnershipFirmFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

  
    renderCostumTextfiledforFutures = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <>
                <Box id="customeTextfield" className={classes.amountWrapper1}>
                    <Box className={classes.ruppeslogoWrapper}>
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    </Box>
                    <TextField
                        error={error}
                        className={classes.textfiledWrapper1}
                        variant="outlined"
                        name={name}
                        placeholder={configJSON.passplaceHolder}
                        value={value}
                        type="string"
                        onFocus={this.handleFocusFutures}
                        onChange={onChange}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                        data-test-id={name}
                        helperText={error && configJSON.requiredField}
                    />
                </Box>
                <Box id="customeTextfield2" className={classes.topmargin1}>
                    {this.props.type !== configJSON.futuresProps && type == "true" &&

                        <Typography  className={classes.underhovertext1} variant="caption" >
                            <Box className={classes.iconAlignment}>
                                <HelpOutlineIcon />{configJSON.helpermessageIntraday}
                            </Box>
                        </Typography>
                    }
                </Box>
            </>
        )
    }
 

    renderCommonTextFieldforFutures = (commonAttributes:
        {
            amountActive: boolean,
            selectActive: boolean,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            heading: string,
            textInputValues: { name: string, value: string|number|null, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
            isBoldHeading?:boolean
            isPlaceHolder123?:boolean
            isDisabled?:boolean
        }) => {
        const { classes } = this.props;
        const { amountValues, textInputValues } = commonAttributes
        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiledforFutures(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        }
        else if (commonAttributes.selectActive) {
            rendertxt = this.renderSelectInputFutures(classes.selectdropDown, amountValues.name, amountValues.value, amountValues.onChange, amountValues.error, "")
        }
        else {
            rendertxt = this.renderStandardInputforFutures(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error,commonAttributes.isPlaceHolder123,commonAttributes.isDisabled)
        }
        const marginTopHeading: any = {
            [configJSON.GrossProfit]: "-4rem",

        };
        const customStyleHeading = {
            marginTop: marginTopHeading[commonAttributes?.heading] || "0px",
        };
        return (
            <Box id="commontextfield">
                <Box className={classes.textAlignment}>
                    <Box  className={classes.boxwidth} style={customStyleHeading}>
                        <Typography className={commonAttributes.isBoldHeading ?classes.subheadingdonations: classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box className={classes.boxWidth}>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }

    renderStandardInputforFutures = (name: string, value: string|number|null, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined,isPlaceHolder123:boolean|undefined,isDisabled?:boolean) => {
        const { classes } = this.props;
        let placeholder = configJSON.emptyPlaceholder;
        if(isDisabled){
            placeholder="";
        }
        return (
            <TextField
                error={error}
                className={classes.selectdropDown}
                variant="outlined"
                type={type}
                placeholder={isPlaceHolder123 ? "1234" : placeholder}
                name={name}
                onFocus={this.handleFocusFutures}
                value={value}
                onChange={onChange}
                FormHelperTextProps={{
                    style: { margin: '-9px 0 0 0' },
                }}
                helperText={error && this.getErrorMessageByName(name)}
                data-test-id={name}
                disabled={isDisabled}
            />
        )
    }

    renderSelectInputFutures = (stylesprop: string, name: string, value: string, onChange: any, error: boolean | undefined, apiResponse: any) => {
        const { classes } = this.props;
        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="Enter"
                        value={value}
                        error={error}
                        name={name}
                        onChange={onChange}
                        onFocus={this.handleFocusFutures}
                        data-test-id={name}
                    >
                        <MenuItem className={classes.menustyle} value="enter"> <span className={classes.menuItemcolor}>Enter</span></MenuItem>

                       {this.state.businessCodes.map((item)=>(
                            <MenuItem value={Number(item)}>{item}</MenuItem>
                       ))}
                    </Select>
                    <FormHelperText className={classes.formhelpertext}>{error && configJSON.requiredField}</FormHelperText>

                </FormControl>
            </Box>
        )
    }

    renderTypes = ()=>{
        let typeReturn = <></>
        const { classes, type } = this.props;
        const { incomeFromFirm, name,pan, auditable_non_auditable, 
                closing_balance_of_capital_in_the_firm,
                amount_of_interest_income,
                amount_of_remuneration_income,
                profit_share_received, 
                pass_through_business_income,
                deemed_business_income,
                income_from_aop,name_of_business,
                business_code,registration_no_of_vechile,
                owned_leased_hired,total_profit_from_44ae,
                tonnage_capacity_in_mt,
                presumptive_income,number_of_months_for_which_vechile_was_owned_leased_or_hired,error } = this.state;
        switch(type){
            case configJSON.partnershipFirmProps:
              typeReturn = 
               <>  
                 <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.natureofBussiness}</Typography>
                </Box>
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.incomeFromFirm,
                    amountActive: false,
                    selectActive: false,
                    isPlaceHolder123:true,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "incomeFromFirm", value: incomeFromFirm, type: "string", onChange: this.handleOnchangeNumber, error: error["incomeFromFirm"] },
                })}
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.detailsOfFirm}</Typography>
                </Box>
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.nameOfPartnershipFirm,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "name", value: name, type: "string", onChange: this.handleOnchangeString, error: error["name"] },
                })}
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.panOfPartnershipFirm,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "pan", value: pan, type: "string", onChange: this.handleOnchangeFutures, error: error["pan"] },
                })}
               
                <Box id="commontextfield">
                    <Box className={classes.textAlignment}>
                        <Box  className={classes.boxwidth}>
                            <Typography className={classes.labelheading}>{configJSON.audOrNonAud}</Typography>
                        </Box>
                        <Box className={classes.boxWidth}>
                            <FormGroup className="checkbox-group">
                                <FormControlLabel style={{width:"106px"}}
                                    control={
                                    <StyledCheckbox
                                        name="auditable_non_auditable"
                                        className="checkbox"
                                        checked={this.state.auditable_non_auditable}
                                    />
                                    }
                                    label="Auditable"
                                    onChange={this.checkboxChange}
                                    data-test-id="auditable_non_auditable"
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                </Box>
                
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.closingBalance,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "closing_balance_of_capital_in_the_firm", value: closing_balance_of_capital_in_the_firm, type: "string", onChange: this.handleOnchangeNumber, error: error["closing_balance_of_capital_in_the_firm"] },
                })}
                 <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations} style={{marginTop:"30px"}}>{configJSON.incomeTitle}</Typography>
                </Box>
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.amtOfInterest,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "amount_of_interest_income", value: amount_of_interest_income, type: "string", onChange: this.handleOnchangeNumber, error: error["amount_of_interest_income"] },
                })}
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.amtOfRemuneration,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "amount_of_remuneration_income", value: amount_of_remuneration_income, type: "string", onChange: this.handleOnchangeNumber, error: error["amount_of_remuneration_income"] },
                })}
                 {this.renderCommonTextFieldforFutures({
                    heading: configJSON.profitShareReceived,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "profit_share_received", value: profit_share_received, type: "string", onChange: this.handleOnchangeNumber, error: error["profit_share_received"] },
                })}
                </>
             break;
            case configJSON.otherBusinessProps:
               typeReturn =
               <>
                  {this.renderCommonTextFieldforFutures({
                    heading: configJSON.passThroghBuisInc,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "pass_through_business_income", value: pass_through_business_income, type: "number", onChange: this.handleOnchangeNumber, error: error["pass_through_business_income"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                 {this.renderCommonTextFieldforFutures({
                    heading: configJSON.deemedBuisInc,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "deemed_business_income", value: deemed_business_income, type: "number", onChange: this.handleOnchangeNumber, error: error["deemed_business_income"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                 {this.renderCommonTextFieldforFutures({
                    heading: configJSON.incFromAOP,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "income_from_aop", value: income_from_aop, type: "number", onChange: this.handleOnchangeNumber, error: error["income_from_aop"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                
               </>
             break;
            case configJSON.plyingTruckProps:
                typeReturn = 
                <>  
                  <Box className={classes.headingwrapeer}>
                     <Typography className={classes.subheadingdonations}>{configJSON.natureofBussiness}</Typography>
                 </Box>
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.nameOfBuiseness,
                     amountActive: false,
                     selectActive: false,
                     isPlaceHolder123:true,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "name_of_business", value: name_of_business, type: "string", onChange: this.handleOnchangeString, error: error["name_of_business"] },
                 })}
                  {this.renderCommonTextFieldforFutures({
                        heading: configJSON.buisenessCode,
                        amountActive: false,
                        selectActive: true,
                        amountValues: { name: "business_code", value: business_code, type: "string", onChange: this.handleOnchangeFutures, error: error["business_code"] },
                        textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    })}
                 <Box className={classes.headingwrapeer}>
                     <Typography className={classes.subheadingdonations} style={{marginTop:"30px"}}>{configJSON.truckWiseDet}</Typography>
                 </Box>
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.regNoOfVeh,
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "registration_no_of_vechile", value: registration_no_of_vechile, type: "string", onChange: this.handleOnchangeVehicle, error: error["registration_no_of_vechile"] },
                 })}
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.ownLeaHired,
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "owned_leased_hired", value: owned_leased_hired, type: "string", onChange: this.handleOnchangeString, error: error["owned_leased_hired"] },
                 })}
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.tonnCapacityInMT,
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "tonnage_capacity_in_mt", value: tonnage_capacity_in_mt, type: "string", onChange: this.calculateProfit, error: error["tonnage_capacity_in_mt"] },
                 })}
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.numberOfMonths,
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "number_of_months_for_which_vechile_was_owned_leased_or_hired", value: number_of_months_for_which_vechile_was_owned_leased_or_hired, type: "string", onChange: this.calculateProfit, error: error["number_of_months_for_which_vechile_was_owned_leased_or_hired"] },
                 })}
                
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.totProf44AE,
                     isBoldHeading:true,   
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "total_profit_from_44ae", value: total_profit_from_44ae, type: "string", onChange: this.handleOnchangeNumber, error: error["total_profit_from_44ae"] },
                     isDisabled: true
                 })}
                 {this.renderCommonTextFieldforFutures({
                     heading: configJSON.presumbptiveIncome,
                     isBoldHeading:true, 
                     amountActive: false,
                     selectActive: false,
                     amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                     textInputValues: { name: "presumptive_income", value: presumptive_income, type: "string", onChange: this.handleOnchangeNumber, error: error["presumptive_income"] },
                 })}
                 
                 </>
             break;
            default:
             break;
        }
        return typeReturn
    }

    rendercommonComponetFutures = () => { 
        return (
            <>
                {this.renderTypes()}
            </>
        )
    }

    renderMainTitle = ()=>{
        const { type } = this.props;
        let title
        switch(type){
            case configJSON.partnershipFirmProps:
                title=configJSON.IncomePartnershipFirm
                break;
            case configJSON.otherBusinessProps:
                title=configJSON.OtherBuisenessIncome
                break;
            case configJSON.plyingTruckProps:
                title=configJSON.IncomePlyingTruck
                break;
            default:
                break;
        }
        return title
    }

    render() {
        const { classes, onClick } = this.props;
        return (
            <>
                <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
                <Container id="conatiner" className={classes.mainWrapper} maxWidth={false}>
                    <Box id="conatinerBox" className={classes.conditionalheading}>
                        <ArrowBackIosIcon onClick={() => onClick("")} fontSize="large" /><Typography className={classes.donationdheading}>{this.renderMainTitle()}</Typography>
                    </Box>
                    <Box id="mainredner" className={classes.backgroundWrapper}>
                        {this.rendercommonComponetFutures()}
                    </Box>
                    <Box >
                        <Button data-test-id="next-submit" className={classes.buttonComponent} onClick={this.handleSubmitFutures} >{configJSON.NextFutures}</Button>
                    </Box>
                </Container>
            </>
        )
    }
}
export const styles = (theme: Theme) => ({
  
    boxWidth: {
        width: "60%"
    },
    menustyle: {
        display: "none", color: '#ABABB0'
    },
    menuItemcolor: {
        color: "#ABABB0"
    },
    topmargin1: {
        marginTop: "3px"
    },
    iconAlignment: {
        display: "flex",
        gap: "10px"
    },
    underhovertext1: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },
    formhelpertext: { margin: '-9px 0 0 0' },
    donationdheading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    amountWrapper1: {
        display: "flex"
    },
    subheadingdonations: {
        fontWeight: 700,
        fontSize: "24px",
        fontFamily: "Nunito",
        color: "#36403b",
    },
    textfiledWrapper1: {

        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    ruppeslogoWrapper: {
        height: '42px',
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        display: "flex",
        alignItems: "center",
        borderRadius: "8px 0px 0px 8px",
    },
    mainWrapper: {
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },
        borderRadius: "16px",
        background: "#F4F6F5",
        margin: "4em 0 3em 1em",

    },
    backgroundWrapper: {
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
        borderRadius: "16px 10px 10px 16px",
        padding: '2em 2em 2em 0em',
        background: "#FFF",
        marginTop: '2em',
    },
    textAlignment: {
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
        alignItems: "center",
        display: "flex",
        padding: '2em 5em 0.5em 2em',
        gap: "50px",
        justifyContent: "flex-start",
    },

    boxwidth: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        width: '30%',
    },
    rupeessstyles: {
        fontSize: "14px",
        color: "rgba(54,64,59,0.9)",
        fontFamily: "Nunito",
        fontWeight: 400,
    },
    selectdropDown: {
        maxWidth: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        },
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiSelect-select:focus':{
            backgroundColor: 'unset !important' 
        }


    },

    labelheading: {
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
        fontSize: "17px",
        color: "rgba(54,64,59,0.7)",
        fontFamily: "Nunito",
        fontWeight: 600,
    },
    buttonComponent: {
        '&:hover': {
            background: "#337152",
        },
        borderRadius: "5px",
        background: "#337152",
        fontWeight: 600,
        color: "#ffff",
        margin: "2.5em 1em 4em 0",
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
    },
    conditionalheading: {
        alignItems: "center",
        padding: "2em 0em 0 0em",
        display: "flex",
        cursor: "pointer",
        gap: '4px',
    },
    headingwrapeer: {
        marginLeft: '2em'

    },
})

const StyledCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
        color: 'rgb(52, 113, 82)',
    },
});
export default withStyles(styles)(PartnershipFirmForm);
// Customizable Area End