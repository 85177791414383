import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
import { WithStyles } from "@material-ui/core";
interface RespData{
    data:{}
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    docData: [];
    handleModalOpen: ()=>void;
    handledelete: (id:string)=>void;
    handleFileDrop:(event: React.DragEvent<HTMLDivElement>)=>void;
    fileError:string;
    handleFileChange:(event: React.ChangeEvent<HTMLInputElement>)=>void;
    handleBrowseClick:()=>void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openManual: boolean;
    uploadfile: boolean;
    openModal: boolean;
    docData: any;
    id: { id: string, type: string },
    manualDocData:any;
    open:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class CryptoListedDataController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    deleteCallAPi: string = ""
    documentDetailsGet: string = ""
    documentManualDetailsGet:string=""
    deleteFileCallAPi:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            openManual: false,
            uploadfile: false,
            openModal: true,
            docData: {},
            id: { id: "", type: "" },
            open:false,
            manualDocData:{}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (this.deleteCallAPi === responseData) {
            this.documentGet()
            this.documentManualGet()
        }
        if (this.documentDetailsGet === responseData) {
            this.getData(successMessage)
        }
        if (this.documentManualDetailsGet === responseData) {
            this.getManualData(successMessage)

        }
        if (this.deleteFileCallAPi === responseData) {
            this.documentGet()

        }

        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.documentGet()
        this.documentManualGet()
        // Customizable Area End
    }

    // Customizable Area Start
 
    getData = (apiResponse:RespData) => {
        
        if (apiResponse) {
            if (apiResponse.data) {
                this.setState({ docData: apiResponse });
            } else{
                this.setState({ docData: [] });
            }
        }
    }
    
    getManualData = (apiResponse:RespData) => {
         
        if (apiResponse) {
            if (apiResponse.data) {
                this.setState({ manualDocData: apiResponse });
            } 
        }
    }
    
    
    handleNavigateManual = () => {
        this.setState({ openManual: !this.state.openManual })
        this.setState({ id: { id: "", type: "" } })

    }
    editdata = (id: string, type: string) => {
        this.setState({ id: { id: id, type: type },openManual: !this.state.openManual })
    }
    docDelete = async (id: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCallAPi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cryptoendPoint + "/" + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    fileDelete = async (id: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteFileCallAPi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.doDeleteById  + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    documentGet = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.documentDetailsGet = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAlldocs
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    documentManualGet = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.documentManualDetailsGet = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.manualGetDataEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleOpenModal=()=>{
        this.setState({open :!this.state.open})
    }
  
    // Customizable Area End
}