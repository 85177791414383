import React from "react";
// Customizable Area Start
import {
    Box,
    withStyles, Snackbar,
    Button, Slide,
    Input, TextField,
    Typography, Grid
}
    from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import OtherIncomesController, { Props, } from "./OtherIncomesController.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deleteRed } from "./assets";

export class OtherInterest extends OtherIncomesController {
    constructor(props: Props) {
        super(props);
    }

    commonGridFn(title: any, testID: any, value: any, errormessage: any) {

        const { classes } = this.props
        return (
            <Grid container spacing={2} className={classes.commonGrid}>
                <Grid sm={6} item>  <Typography className={classes.descField}>{title}</Typography>   </Grid>
                <Grid sm={6} item>
                    <Box className={classes.amount}>
                        <Box className={classes.ruppeslogo}>
                            <Typography className={classes.rupeessstyles11}>₹</Typography>
                        </Box>
                        <TextField
                            data-testid="fieldTest"
                            className={classes.textfiled} variant="outlined"
                            placeholder='00'
                            value={value} data-test-id={testID}
                            onChange={(e: any) => this.handleChange(testID, e.target.value)}
                        />
                    </Box>
                    {errormessage && <Box className={classes.error_message}>{errormessage}</Box>}
                </Grid>
            </Grid>
        )
    }

    render() {

        const { classes } = this.props


        return (
            <Box className={classes.mainBox}>
                {this.commonGridFn('Savings Bank Interest', 'intInterest', this.state.intInterest, this.state.otherIntIncomeError.intInterest)}
                {this.commonGridFn('Interest from Deposits', 'intDeposit', this.state.intDeposit, this.state.otherIntIncomeError.intDeposit)}
                {this.commonGridFn('Interest from Income Tax Refund', 'intIT', this.state.intIT, this.state.otherIntIncomeError.intIT)}
                {this.commonGridFn('Interest from Any Other Source', 'intOtherSrc', this.state.intOtherSrc, this.state.otherIntIncomeError.intOtherSrc)}

                <Typography className={classes.subHeading}>Expenses</Typography>
                {this.state.otherIntAddFields.map((item: any, index: any) => (
                    <div key={index}>
                        <Grid spacing={2} container className={classes.addnlGrid}>
                            <Grid sm={5} item>
                                <Typography className={classes.descField}>Description</Typography>
                                <Input data-testid="OtherInterestDes_test" value={item.description} disableUnderline className={classes.input} data-test-id={`description${index}`} style={{ height: '2.5rem' }}
                                    onChange={(e: any) => this.handleOtherInterestChangeDesc(index, e.target.value)} placeholder="Enter" />
                                {this.state.otherIntIncomeDescError.map((data: any) => {
                                    if (data.index === index) {
                                        return <Box className={classes.error_message}>{data.error}</Box>
                                    }
                                })
                                }

                            </Grid>
                            <Grid sm={5} item>
                                <Typography className={classes.descField}>Amount</Typography>

                                <Box className={classes.amount}>
                                    <Box className={classes.ruppeslogo}>
                                        <Typography className={classes.rupeessstyles11}>₹</Typography>
                                    </Box>
                                    <TextField
                                        data-testid="OtherInterestAmt_test"
                                        className={classes.textfiled} variant="outlined"
                                        placeholder='00'
                                        value={item.amount} data-test-id={`amount${index}`}
                                        onChange={(e: any) => this.handleOtherInterestAmt(index, e.target.value)}
                                    />
                                </Box>
                                {this.state.otherIntIncomeAmterror.map((data: any) => {
                                    if (data.index === index) {
                                        return <Box className={classes.error_message}>{data.error}</Box>
                                    }
                                })
                                }


                            </Grid>
                            <Grid sm={2} item className={classes.deleteGrid}>
                                <img src={deleteRed} onClick={() => this.deleteFieldInterest(index)} className={classes.deleteImg} />
                            </Grid>
                        </Grid>
                    </div>
                ))}
                <span onClick={() => this.addInterestFields()} className={classes.addFieldDiv}>

                    <AddCircleIcon className={classes.addIcon} />
                    <Typography className={classes.addMore}>Add More Items</Typography>
                </span>
                <Button onClick={() => this.saveOTherInt()} data-test-id='saveBtn'
                    style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
                >Save</Button>

                <Snackbar TransitionComponent={Slide} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} open={this.state.snack ? this.state.snack : this.state.snackError} autoHideDuration={2000} onClose={(event,reason)=>{
                    this.props.closeDialog()
                    this.handleMessageClose(event,reason);
                   
                    }} 
                    data-test-id="interestClose"
                    >
                    <Alert severity={"success"}>
                        Saved Succesfully
                    </Alert>
                </Snackbar>

            </Box>
        )
    }
}

const styles = {

    descField: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        marginBottom: "5px"
    },
    addIcon: {
        color: '#337152',
        margin: '0 5px 0 -10px'
    },
    addnlGrid: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8
    },
    addFieldDiv: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        margin: '1rem 0 2rem 0',
        paddingLeft: 12
    },
    deleteImg: {
        cursor: 'pointer',
        marginTop: '2.5rem',
        marginLeft: '0.5rem'
    },
    mainBox: {
        padding: '10px 20px'
    },
    deleteGrid: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    commonGrid: {
        padding: 10,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem'
    },
    subHeading: {
        color: '#242B28',
        fontFamily: 'Nunito',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '1rem 0rem'
    },
    addMore: {
        color: '#000',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },
    inputAmountHalf: {
        border: ' 0.5px solid #C3CAD9',
        borderRadius: '0px 8px 8px 0px',
        borderLeft: 'none',
    },
    input: {
        border: ' 0.5px solid #C3CAD9',
        borderRadius: 8,
        padding: 8
    },


    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 1.7em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    textfiled: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: "0px 8px 8px 0px",
                borderLeft: 'none',
            },
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },

        "& input": {
            border: "1px solid #grey",
            padding: "11px 0px 11px 12px",
        },
    },
    rupeessstyles11: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    amount: {
        display: "flex",

    },
    error_message: {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    },

}


export default withStyles(styles)(OtherInterest);
// Customizable Area End