import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Theme,
    TextField,
    FormControl,
    MenuItem,
    FormHelperText,
    Select,
    Snackbar,
    Card,
    IconButton,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { calenderIcon, DeleteRedIcon, edit, search, tooltip } from "./assets"

import CryptoVirtualDigitalAssetsController, {
    Props,
    configJSON,
} from "./CryptoVirtualDigitalAssetsController.web";

import { withStyles } from "@material-ui/core/styles";
import { TableBoxContainer } from "./CapitalGainBond.web";
import { CustomTextArea } from "../../../components/src/CustomTextfield.web";
import { TableContainer } from "./ListedShares.web";
import { AddCircle } from "@material-ui/icons";
import Toster from "../../../components/src/Toster";



// Customizable Area End

export class CryptoVirtualDigitalManualAsset extends CryptoVirtualDigitalAssetsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderComminDateforCryptoAssets = (heading: string, value: any, onChange: any, isDatePickerOpen: boolean, datePickerName: string, openCloseDatePicker: any) => {
        const { classes } = this.props;
        return (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box className={classes.headingwrapeer} >
                    <span className={classes.inputlableStyle}>{heading}</span>
                    <span className={classes.requiredDot}>*</span>
                    <Box className={classes.amountBond}>
                        <Box className={classes.ruppeslogo}>
                            <img src={calenderIcon}
                            data-test-id="calender1"
                             onClick={() => openCloseDatePicker(datePickerName, true)}
                                className={classes.rupeessstylesBonds} />
                        </Box>
                        <DatePicker
                            inputVariant="outlined"
                            disableToolbar
                            autoOk
                            variant="inline"
                            placeholder="DD/MM/YYYY"
                            className={classes.textfiledBond}
                            style={{
                                height: "3em", borderLeft: 'none',
                                borderRadius: "0px 8px 8px 0px",
                            }}
                            onChange={onChange}
                            open={isDatePickerOpen}
                            format="DD/MM/YYYY"
                            onOpen={() => openCloseDatePicker(datePickerName, true)}
                            onClose={() => openCloseDatePicker(datePickerName, !datePickerName)}
                            data-test-id={heading}
                            value={value}
                            maxDate={Date.now()}
                        />
                    </Box>
                </Box>
                </MuiPickersUtilsProvider>
            </>
        )
    }
    renderStandardInputforCryptoAssets = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <>
                <TextField
                    className={classes.selectdropDown}
                    variant="outlined"
                    placeholder={configJSON.emptyPlaceholder}
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleFocusCryptoAssets}
                    onBlur={this.handleBlurCryptoAssets}
                    data-test-id={name}
                    FormHelperTextProps={{
                        style: { margin: '-12px 0 0 0' },
                    }}

                />
                <Box className={classes.marginTypo}>

                    <Typography variant="caption" className={classes.helpertext}>
                        <HelpOutlineIcon />{configJSON.vdsSubtitle}
                    </Typography>
                </Box>
            </>
        )
    }
    renderCostumTextfiledforCryptoAssets = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.amountWrapper}>
                    <Box className={classes.ruppeslogoWrapper}>
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    </Box>
                    <TextField
                        className={classes.textfiledWrapper}
                        variant="outlined"
                        placeholder={configJSON.passplaceHolder}
                        name={name}
                        // type="number"
                        value={value}
                        onChange={onChange}
                        onFocus={this.handleFocusCryptoAssets}
                        onBlur={this.handleBlurCryptoAssets}
                        data-test-id={name}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                    />
                </Box>
            </>
        )
    }
    renderSelectInputCryptoAssets = (stylesprop: string, name: string, value: string, onChange: ((event: React.ChangeEvent<{
        name?: string;
        value: unknown;
    }>, child: React.ReactNode) => void) | any, error: boolean | undefined, apiResponse: any) => {
        const { classes } = this.props;
        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined">
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="select"
                        value={value}
                        name={name}
                        onChange={onChange}
                        onFocus={this.handleFocusCryptoAssets}
                        onBlur={this.handleBlurCryptoAssets}
                        data-test-id={name}
                    >
                        <MenuItem className={classes.menustyle} value=""> <span className={classes.menuItemcolor} >{configJSON.SelectInitial}</span></MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                    <Box>
                        <Typography variant="caption" className={classes.helpertext}>
                            <HelpOutlineIcon />{configJSON.SelectBusinessIncomePlaceholder}
                        </Typography>
                    </Box>
                </FormControl>
            </Box>
        )
    }
    renderCommonTextFieldforCryptoAssets = (commonAttributes:
        {
            amountActive: boolean,
            selectActive: boolean,
            heading: string,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
        }) => {
        const { amountValues, textInputValues } = commonAttributes
        const { classes } = this.props;

        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiledforCryptoAssets(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        }
        else if (commonAttributes.selectActive) {
            rendertxt = this.renderSelectInputCryptoAssets(classes.selectdropDown, amountValues.name, amountValues.value, amountValues.onChange, amountValues.error, "")
        }
        else {
            rendertxt = this.renderStandardInputforCryptoAssets(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)
        }
        const marginTopHeading: any = {
            [configJSON.EnterVDA]: "-3rem",
            [configJSON.IncomeType]: "-3rem"

        };
        const customStyleHeading = {
            marginTop: marginTopHeading[commonAttributes?.heading] || "0px",
        };
        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box style={customStyleHeading} className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box className={classes.boxWidth}>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }

    rendercommonComponetCryptoAssets = () => {
        const { classes } = this.props;
        const {
            assetName,
            totalSalePrice,
            totalPurchasePrice,
            incomeType,
            gains,
            error } = this.state;
        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.AddDetailsForassets}</Typography>
                </Box>

                {this.renderCommonTextFieldforCryptoAssets({
                    heading: configJSON.EnterVDA,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "assetName", value: assetName, type: "string", onChange: this.handleOnchangeCryptoAssets, error: error["assetName"] },
                })}
                {this.renderCommonTextFieldforCryptoAssets({
                    heading: configJSON.TotalSalePrice,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "totalSalePrice", value: totalSalePrice, type: "number", onChange: this.handleOnchangeCryptoOnlyNumber, error: error["totalSalePrice"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.renderCommonTextFieldforCryptoAssets({
                    heading: configJSON.TotalPurchasePrice,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "totalPurchasePrice", value: totalPurchasePrice, type: "true", onChange: this.handleOnchangeCryptoOnlyNumber, error: error["totalPurchasePrice"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.renderCommonTextFieldforCryptoAssets({
                    heading: configJSON.IncomeType,
                    amountActive: false,
                    selectActive: true,
                    amountValues: { name: "incomeType", value: incomeType, type: "true", onChange: this.handleOnchangeCryptoAssets, error: error["incomeType"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.renderCommonTextFieldforCryptoAssets({
                    heading: configJSON.gains,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: "gains", value: gains, type: "true", onChange: this.handleOnchangeCryptoOnlyNumber, error: error["gains"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
            </>
        )
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Container className={classes.mainWrapper} maxWidth={false}>
                    <Toster {...this.state.toast} data-test-id='handleTostClose' handleTostClose={this.handleTostClose} />                
                    <Box className={classes.conditionalheading}>
                        <ArrowBackIosIcon fontSize="large" data-test-id='backToPrevPage' onClick={this.backToPrevPage} className={classes.arrowColor}/>
                        <Box>
                        <Typography className={classes.donationdeading} data-test-id="initial-render">{this.state.isFormEnable ? configJSON.VirtualDigitalAssetHeading : configJSON.virtDigAssHeadWithoutAdd}</Typography>
                        {this.state.isFormEnable && <Typography className={classes.subdonationdeading} >{configJSON.VirtualDigitalAssetSubHeading}</Typography>}

                        </Box>
                        
                    </Box>

                    {
                        (this.state.manualDocData && this.state.manualDocData.length > 0  && !this.state.isFormEnable) ? <TableBoxContainer>
                            <Card className="hover_block">
                                <Box className="DL_header">
                                    <Box className="DL_Search">
                                        <CustomTextArea data-test-id='searchValueChange' variant="outlined" fullWidth placeholder="Search" />
                                        <img src={search} alt="" className="DL_Search-icon" />
                                    </Box>
                                    <Button className="btn" variant="outlined">Re - Import</Button>
                                </Box>
                                {
                                    <>
                                <TableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Asset Name</th>
                                                <th>Date of Purchase</th>
                                                <th>Date of Sale</th>
                                                <th>Total Sale Price</th>
                                                <th>Total Purchase Price</th>
                                                <th>Income Type</th>
                                                <th>Gains</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.manualDocData.map((data: any, index: number) => {
                                                let attributes = data && data.attributes;
                                                return (
                                                    <tr key={index}>
                                                        <td>{index > 8 ? index + 1 : `0${index + 1}`}</td>
                                                        <td>{this.checkValueEmpty(attributes.vda_asset_name)}</td>
                                                        <td>{this.checkIsDateNull(attributes.date_of_purchase)}</td>
                                                        <td>{this.checkIsDateNull(attributes.date_of_sale)}</td>
                                                        <td>{this.checkValueEmpty(attributes.total_sale_price)}</td>
                                                        <td>{this.checkValueEmpty(attributes.total_purchase_price)}</td>
                                                        <td>{this.checkValueEmpty(attributes.income_type)}</td>
                                                        <td>{this.checkValueEmpty(attributes.gains)}</td>
                                                        <td>
                                                            <Box className="action_button">
                                                                <img data-test-id="edit_test"  className="edit_icon" onClick={() => this.editManualDoc(data)} src={edit} alt="edit_icon" />
                                                                <img data-test-id="delete_test"  className="delete_icon" onClick={() => this.deleteManualDoc(data.id)} src={DeleteRedIcon} alt="delete_icom" />
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <Box className="add_item">
                                    <Box className="add_item_inner" data-test-id="add_more_data" onClick={this.addMore}>
                                        <IconButton style={{ padding: "7px" }} size="medium">
                                            <AddCircle className="add_icon" />
                                        </IconButton>
                                        <Typography>Add More items</Typography>
                                    </Box>
                                    <img src={tooltip} className="tooltip_icon" alt="tooltip" />
                                </Box>
                                </>
                                }
                            </Card>
                        </TableBoxContainer> 
                        : <>
                        
                    <Box className={classes.backgroundWrapper}>
                        <Box className={classes.dateWrapper}>
                            {this.renderComminDateforCryptoAssets(configJSON.dateofPurchasePlaceholder, this.state.dateofPurchase, this.handleChangePurchase, this.state.isPurchaseDateOpen, "isPurchaseDateOpen", this.openCloseDatePicker)}
                            {this.renderComminDateforCryptoAssets(configJSON.dateofSalePlaceholder, this.state.dataofSale, this.handleChangeSale, this.state.isSaleDateOpen, "isSaleDateOpen", this.openCloseDatePicker)}
                        </Box>

                    </Box>
                    <Box className={classes.backgroundWrapper}>
                        {this.rendercommonComponetCryptoAssets()}
                    </Box>
                    <Box >
                        <Button className={classes.buttonComponent} data-test-id="next-submit" onClick={this.handlepostmanual}>Save</Button>
                    </Box>

                    </>
                }
                </Container>
            </>
        )
    }
}
export const styles = (theme: Theme) => ({
    marginTypo: {
        marginTop: "-9px"
    },
    arrowColor: {
        color: "#337152"
    },
    dateWrapper: {
        display: "flex",
        flexDirection: "row" as const,
        marginTop: '0.5em',
        width: "50%"
    },
    inputlableStyle: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginBottom: '2em'
    },
    requiredDot: {
        color: '#C46F60',
        padding: '0 4px',
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    subdonationdeading:{
        color: "rgba(54, 64, 59, 0.50)",
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Nunito",
        marginTop:'0.3em'
    },
    amountWrapper: {
        display: "flex"
    },
    helpertext: {
        display: "flex",
        aliginItems: "center",
        gap: "5px",
        marginTop: '0.4em',
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 350,
        fontFamily: "Nunito",

    },
    boxWidth: {
        width: "60%"
    },
    formhelpertext: { margin: '-12px 0 0 0' },
    ruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    menuItemcolor: {
        color: "#ABABB0"
    },
    menustyle: {
        display: "none", color: '#ABABB0'
    },


    subheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },

    textfiledWrapper: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },

    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    labelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    textAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 5em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },

    boxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },

    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    },


    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },

    headingwrapeer: {
        marginLeft: '2em'

    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },
    amountBond: {
        display: "flex"
    },
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    rupeessstylesBonds: {
        color: "rgba(54,64,59,0.9)",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Nunito",
    },
    textfiledBond: {
        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey",
            height: '20px'
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },
    },
})
export default withStyles(styles)(CryptoVirtualDigitalManualAsset);
// Customizable Area End