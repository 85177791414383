import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Theme,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import CryptoListedDataController, {
    Props,
    configJSON,
} from "./CryptoListedDataController.web";
import { DeleteIcon, editIcon, search } from "./assets"
import SearchIcon from '@material-ui/icons/Search';
import CryptoVirtualDigitalManualAsset from "./CryptoVirtualDigitalManualAsset.web"
import UploadDocDailog from "../../../components/src/UploadDocDailog.web";

// Customizable Area End

export class CryptoListedData extends CryptoListedDataController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start


        // Customizable Area End
    }

    // Customizable Area Start
    constumRows = (name: string | number, rupee: boolean) => {
        const { classes } = this.props;
        return (
            <TableCell align="center" className={classes.makheaderStyle}>
                <Box className={classes.amountWrapper}>
                    {rupee &&
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    }

                    <span className={classes.headingText}>
                        {name == null ? configJSON.noDataCOntext : name}
                    </span>
                </Box>
            </TableCell>
        )
    }
    renderTable = () => {
        const { classes } = this.props;
        let serialNumber = 1;
        return (
            <>
                <TableContainer component={Paper} className={classes.tabelHead}>
                    <Table >
                        <TableHead >
                            <TableRow >
                                {configJSON.tableHeading.map((item: string) => {
                                    return (
                                        <TableCell align="center" className={classes.maxbodyStyle} key={item}>
                                            <span className={classes.headingText}>{item}</span>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <Box className={classes.headeralignment} />
                {
                    this.state.docData?.data && this.state.docData.data?.map((row: { data: [] }, rowIndex: number) => (
                        <div key={rowIndex}>
                            {row?.data?.map((item: {
                                id: string,
                                type: string,
                                total_sale_price: string,
                                attributes: {
                                    file_name: string,
                                    date_of_purchase: string,
                                    purchase_cost: string,
                                    date_of_sale: string,
                                    total_sale_price: string,
                                    capital_gains: string,


                                }
                            }, index: number) => (
                                <div key={item?.total_sale_price + index}>
                                    <TableContainer component={Paper} className={classes.tabelChild} key={item?.total_sale_price + index}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {this.constumRows(serialNumber++, false)}
                                                    {this.constumRows(item?.attributes?.file_name, false)}
                                                    {this.constumRows(item?.attributes?.date_of_purchase, false)}
                                                    {this.constumRows(item?.attributes?.purchase_cost, true)}
                                                    {this.constumRows(item?.attributes?.date_of_sale, false)}
                                                    {this.constumRows(item?.attributes?.total_sale_price, true)}
                                                    {this.constumRows(item?.attributes?.capital_gains, true)}
                                                    <TableCell align="center" className={classes.makheaderStyle}>
                                                        <Box className={classes.amountWrapper}>
                                                            <Box className={classes.ruppeslogoWrapper} onClick={this.editdata.bind(this, item?.id, item?.type)}>
                                                                <img className={classes.headingText} src={editIcon} alt="edit" />
                                                            </Box>
                                                            <Box onClick={this.docDelete.bind(this, item?.id)} data-test-id={"delete" + item?.id} className={classes.handleDeleteStyle}>
                                                                <img className={classes.headingText} src={DeleteIcon} alt="Delete" />
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                    <Box className={classes.headeralignment} />
                                </div>
                            ))}
                        </div>
                    ))
                }

            </>
        );
    }
    renderSearch = () => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.childwrapper}>
                    <TextField
                        placeholder="Search"
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><img src={search} alt="search" className={classes.searchSize} /></InputAdornment>,
                        }}
                        className={classes.searchtextfield}
                        data-test-id="inputchange"
                    />
                    <Box className={classes.serchbtn} >
                        <Typography className={classes.import} onClick={this.handleOpenModal} data-test-id="open_listed">{configJSON.reImports}</Typography>
                    </Box>
                </Box>
            </>
        )
    }


    renderforms = () => {
        const { classes } = this.props;
        if (!this.state.openManual) {
            return (
                <>
                    <Container className={classes.mainWrapper} maxWidth={false}>
                        <Box className={classes.conditionalheading} >
                            <ArrowBackIosIcon fontSize="large" className={classes.arrowColor} /><Typography className={classes.donationdeading} data-test-id="initial-render">{configJSON.CryptoorVirtual}</Typography>
                        </Box>
                        <Box className={classes.backgroundWrapper}>
                            {this.renderSearch()}
                            {this.renderTable()}
                            <Box className={classes.iconArrangmment} onClick={this.handleNavigateManual} data-test-id="add-more">
                                <AddCircleIcon />
                                <Typography className={classes.addMore1} >{configJSON.addMOreItems}</Typography>
                            </Box>
                        </Box>
                        <Button className={classes.buttonComponent} data-test-id="next-submit" >{configJSON.NextFutures}</Button>
                    </Container>
                </>
            )
        } else if (this.state.openManual) {
            return <CryptoVirtualDigitalManualAsset navigation={this.props.navigation} id={this.state.id} onClick={this.handleNavigateManual} />
        }

    }
    // Customizable Area End

    render() {
        const {
            handleFileDrop,
            fileError,
            handleFileChange,
            handleBrowseClick } = this.props;
        return (
            // Customizable Area Start
            <>
                {this.state.open && <UploadDocDailog
                    handleModalOpen={this.handleOpenModal}
                    docData={this.state.docData}
                    handledelete={this.fileDelete}
                    handleFileDrop={handleFileDrop}
                    fileError={fileError}
                    handleBrowseClick={handleBrowseClick}
                    handleFileChange={handleFileChange}
                    handleClose={this.handleOpenModal}
                    openbar={open}

                />}
                {this.renderforms()}
            </>

        )
    }
    // Customizable Area End
}
// Customizable Area Start

export const styles = (theme: Theme) => ({
    searchSize: {
        height: "26px",
        width: "26px",
        marginRight:"0.5em"
    },
    arrowColor: {
        color: "#337152"
    },
    handleDeleteStyle: {
        cursor: "pointer"
    },
    makheaderStyle: {
        maxWidth: "10px"
    },
    maxbodyStyle: {
        maxWidth: "20px"

    },
    serchbtn: {
        cursor: "pointer"
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },
    import: {
        padding: '0.6em 1em',
        color: "#36403b",
        fontFamily: "Nunito",
        fontSize: "19px",
        fontWeight: 500,
        border: "1px solid #36403b",
        borderRadius: "8px",
    },
    iconArrangmment: {
        marginTop: '2em',
        display: 'flex',
        gap: '10px',
        cursor: "pointer"
    },
    addMore1: {
        color: '#000',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },

    ruppeslogoWrapper: {
        gap: "25px",
        cursor: "pointer"
    },
    searchtextfield: {
        width: "55%",
        padding: "0.5em",
        borderRadius: "8px",
        border: "1px solid #C1C1C1",

        '& .MuiInput-underline:before': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },

        '& .MuiInputBase-input::placeholder': {
            "@media(max-width:486px)": {
                fontSize: '10px',
            },

        },
        "@media(max-width:690px)": {
            width: "80%",
        },
    },


    headeralignment: {
        marginTop: "0.7em"
    },
    amountWrapper: {
        display: "flex",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center"
    },
    childwrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: '1.5em',
        alignItems: "center",
        "@media(max-width:690px)": {
            flexDirection: "column"
        },
    },
    headingText: {
        color: "#36403b",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontWeight: 500,
        // wordBreak: "break-all" as const
    },
    rupeessstyles: {
        fontSize: "20px",
        fontWeight: 500,
        fontFamily: "Nunito",
        color: "#36403b"

    },
    tabelHead: {
        borderRadius: "8px 8px 0 0",
        border: "1px solid #dcdcdc",
        background: "#f4f6f5",
        overflow: 'auto'

    },
    tabelChild: {
        border: "1px solid #dcdcdc",
        background: "#f4f6f5",

    },
    table: {
        minWidth: 650,
    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '3em',
        padding: "2em",
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },

})
export default withStyles(styles)(CryptoListedData);
// Customizable Area End