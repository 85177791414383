import React from "react";
import LandingPageController, { Props, configJSON } from "./LandingPageController.web";
import { styled } from "@material-ui/styles";
// Customizable Area Start
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    Hidden,
    withStyles,
    TextField,
    InputAdornment
} from "@material-ui/core";
import MainHeader from "../../../components/src/MainHeader.web";
import { PigiBank, VideoBackground } from "./assets";
//@ts-ignore
import { ReactComponent as Twitter } from "../assets/Twitter.svg";
//@ts-ignore
import { ReactComponent as PocketLogo } from "../assets/PocketLogo.svg";
//@ts-ignore
import { ReactComponent as Instagram } from "../assets/Instagram.svg";
//@ts-ignore
import { ReactComponent as Facebook } from "../assets/Facebook.svg";
//@ts-ignore
import { ReactComponent as PlayIconBlack } from "../assets/play-circle-fill.svg";
import MakeaQuery from "./MakeaQuery.web";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VideoPlayer from "../../../components/src/VideoPlayer.web";

// Customizable Area End
export class KnowladgeBase extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start


    renderCards = (item: any, key: string) => {
        return (
            
            <Box key={item.id} sx={{ width: { xs: "70%", sm: "40%", md: "25.1%", xl: "25.1%" }, margin: "40px 0" }}>
                <Box style={{ position: "relative" }}>

               { item.attributes.image && <img src={item.attributes.image} data-test-id={`exampleimage${item.id}`}  className="all-img" onClick={() =>this.handlenewTab(item.attributes.link) } />}
                    {item.attributes?.video_url != "" ?
                        <svg width="64" height="64" data-test-id={`exampleimage1${item.id}`} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="play-icon" data-testid="your-button-id" onClick={() => this.handleOpenVideo(item.attributes?.video_url)} >
                            <g clip-path="url(#clip0_219_11962)">
                                <path d="M31.9987 58.6673C17.2707 58.6673 5.33203 46.7286 5.33203 32.0006C5.33203 17.2727 17.2707 5.33398 31.9987 5.33398C46.7267 5.33398 58.6654 17.2727 58.6654 32.0006C58.6654 46.7286 46.7267 58.6673 31.9987 58.6673ZM28.324 22.4406C28.1635 22.3336 27.977 22.272 27.7843 22.2626C27.5916 22.2531 27.4 22.2961 27.2298 22.3869C27.0595 22.4778 26.9172 22.6131 26.8178 22.7785C26.7184 22.9438 26.6657 23.1331 26.6654 23.326V40.6753C26.6657 40.8682 26.7184 41.0575 26.8178 41.2228C26.9172 41.3882 27.0595 41.5235 27.2298 41.6144C27.4 41.7052 27.5916 41.7482 27.7843 41.7387C27.977 41.7293 28.1635 41.6677 28.324 41.5606L41.3347 32.8886C41.481 32.7913 41.601 32.6593 41.684 32.5043C41.767 32.3494 41.8104 32.1764 41.8104 32.0006C41.8104 31.8249 41.767 31.6519 41.684 31.497C41.601 31.342 41.481 31.21 41.3347 31.1126L28.3214 22.4406H28.324Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_219_11962">
                                    <rect width="64" height="64" fill="white" />
                                </clipPath>
                            </defs>
                        </svg> : null
                    }


                </Box>
                <Box component="div" display="flex" className="authorContainer">
                    <Typography variant="subtitle1" className="author-all-name">{item.attributes.author}</Typography>
                    <Typography variant="subtitle1">{this.handleDateTime(item.attributes.created_at)}</Typography>
                </Box>
                <Typography variant="h6" className="post-title-all"><a href={item.attributes.link} target="_blank" className="post">{item.attributes.title}</a></Typography>
                <Typography variant="body2">{item.attributes.description}</Typography>
            </Box>
        )
    }
    rendermodelWrapper = () => {
        const { classes }: any = this.props;
        let mainStatus = this.state.filteredData.length == 0;
        let search = this.state.searchInput == ""

        return (
            <Box >
                <Box className={classes.parentwrapper} style={{ marginBottom: "2em", marginTop: '-1em' }}>

                    <Box className={classes.mainWrapper}>
                        <Box className={classes.closeicon} >
                            <ClearIcon onClick={this.handleCloseSearch} style={{ cursor: "pointer" }} />
                        </Box>
                        <Box className={classes.childwrapper}>
                            <TextField
                                placeholder={configJSON.Searchbasedontopic}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                }}
                                className={classes.searchtextfield}
                                onChange={this.handleInputChange}
                                data-test-id="inputchange"
                            />
                            <Box className={classes.serchbtn}>
                                <Button className={classes.searchbutton} disabled={!!search} onClick={this.handleSearchClick}>{configJSON.Searchtopic}</Button>
                            </Box>
                        </Box>
                    </Box>


                </Box>
                <Box className={classes.parentwrapper}>
                    <Typography variant="h5" style={{ color: "red" }}>{mainStatus ? configJSON.NoRecords : null}</Typography>
                </Box>
            </Box>

        )
    }
    renderRecent = () => {
        const { classes }: any = this.props;
        return (
            <Box component="section" className="recent-section">
                <Box className={classes.home} >
                    <span className={classes.fontNav}> <span onClick={this.handleToLandingPageTo} style={{ cursor: "pointer" }}>Home</span> <span className={classes.iconpadding}><ArrowForwardIosIcon className={classes.iconfont} /></span> <span className={classes.base}>Knowledge base</span></span>
                </Box>
                {this.state.searchknowledge ? this.rendermodelWrapper() : null}
                <Box className={classes.mainheader}>
                    <Typography variant="h4" className="recent-title">
                        {this.handleSearchStatus() ? configJSON.SearchResults : configJSON.recentArticles}
                    </Typography>
                    {!this.state.searchknowledge ? <SearchIcon onClick={this.handleOpenSearch} style={{ cursor: "pointer" }} /> : null}

                </Box>

                <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
                    {(this.handleSearchStatus() ? this.state.filteredData : this.state.responseRecentArticles).map((item, key) => {
                        return (
                            this.renderCards(item, item.id)
                        )

                    })}
                </Box>
            </Box>
        )
    }

    renderAll = () => {

        return (
            <Box component="section" className="all-section">
                <Typography variant="h4" className="title">
                    {configJSON.allArticle}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
                    {this.state.responseRecentArticlesAll.map((item, key) => {
                        if (key < this.state.loadMore) {
                            return (
                                this.renderCards(item, item.id)
                            )


                        }
                    })}
                </Box>
            </Box>
        )
    }

    renderInfo = () => {
        return (
            <InfoWrapper>
                <Box component="div" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <PocketLogo />
                    <Typography align="center" variant="body2" style={{ maxWidth: "700px", marginTop: "15px" }}>{configJSON.dummyTextknowledge + configJSON.dummyTextknowledge}</Typography>
                </Box>
                <Box component="div" display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ padding: "8rem 0 4rem 0" }}>
                    <Typography variant="subtitle1" align="center">{configJSON.followUs}</Typography>
                    <Box component="div" sx={{ marginTop: "2rem" }}>
                        <Facebook /><Instagram style={{ marginInline: "2rem" }} /><Twitter />
                    </Box>
                </Box>
            </InfoWrapper>
        )
    }

    renderVideoSection = () => {
        return (
            <Box component="section" className="video-section" display="flex" sx={{ width: { xs: "90%", md: "80%" }, justifyContent: "space-evenly", margin: "60px auto 100px auto", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "center", md: "unset" } }}>
                <Box component="div" sx={{ marginTop: "60px", color: "#fff" }}>
                    <Typography variant="h4" className="video-question" >{configJSON.Needhelpin}<Hidden smDown ><br /></Hidden> {configJSON.taxfiling}</Typography>
                    <Typography variant="subtitle1" align="center">{configJSON.pleasewatchthisvideoforassistance}</Typography>
                </Box>
                <Box component="div" sx={{
                    width: { xs: "90%", sm: "80%", md: "50%" }, height: { xs: "300px", md: "450px" },
                    margin: "60px 0",
                    borderRadius: "20px",
                    overflow: "hidden",
                    position: "relative"
                }}>
                    {!this.state.vedioImage ? this.state.knowladgeImage && <img src={this.state.knowladgeImage} className="fullWidth" /> : null}

                    {this.state.showVideoIcon && <PlayIconBlack className="play-icon" data-test-id="play-video" onClick={this.addControls} />}
                    <video data-test-id="video-play-pause" id="video-id" src={this.state.knowladgeVideo} className="video-player fullWidth" onPause={this.onVideoPause} onPlay={this.onVideoPlay} />
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (<>
            <VideoPlayer {...this.state.videoOpen} data-test-id="vedio-close" handleCloseVideo={this.handleCloseVideo} />
            <MainHeader handleLogin={this.handleLoginBtn} handleLogout={this.handleLogout} handleHeaderRoute={this.handleHeaderRoute} type="knowladge" navigation={undefined} handlemodalopen={this.handlemodalopen} handledashboard={this.navigateToDashBoard} handledashprofile={this.handledashprofile} handleToLandingPage={this.handleToLandingPageTo} />
            <MakeaQuery open={this.state.open} handleModelClose={this.handleModelClose} navigation={undefined} id={""} data-test-id="close_modal" history={undefined} />
            <MainWrapper disableGutters maxWidth={false}>
                {this.renderRecent()}
                {this.renderVideoSection()}
                {this.renderAll()}
                <Button variant="outlined" size="large" data-test-id="loadmore" className="load-btn" onClick={this.loadMore}>{configJSON.loadMoreArticles}</Button>
                {this.renderInfo()}
            </MainWrapper>
        </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Container)({
    "& .authorContainer": {
        textTransform: "uppercase",
        color: "#337152",
        marginTop: "12px",
    },
    "& .recent-img": {
        width: "-webkit-fill-available",
        borderRadius: "10px",
        height: "auto",
        "@media(min-width:900px)": {
            height: "250px",
        },
        "@media(min-width:1200px)": {
            height: "300px",
        },
        "@media(min-width:1536px)": {
            height: "350px",
        },
    },
    "& .all-img": {
        width: "-webkit-fill-available",
        borderRadius: "10px",
        position: "relative",
        cursor: "pointer",
        height: "auto",
        "@media(min-width:1536px)": {
            height: "400px",
        },
        "@media(min-width:1200px)": {
            height: "300px",
        },
        "@media(min-width:900px)": {
            height: "250px",
        },
    },
    "& .post-title-all": {
        margin: "12px 0",
        fontFamily: "Nunito Sans, sans-serif",
    },
    "& .post":{
        color: "#25241D",
        fontFamily: "Nunito",
        fontSize: "21px",
        fontWeight: 600,
        textDecoration:"none",
        cursor:"pointer"
    },
    "& .author-all-name": {
        marginRight: "25px"
    },
    "& .load-btn": {
        margin: "100px auto",
        display: "flex",
        borderColor: "#337152",
        color: "#337152"
    },
    "& .title": {
        marginLeft: "7%",
        "@media(max-width:600px)": {
            marginLeft: "15%"
        }
    },
    "& .recent-title": {
        marginLeft: "9%",
        "@media(min-width:600px)": {
            marginLeft: "5%"
        },
        "@media(min-width:1200px)": {
            marginLeft: "7%"
        },
    },
    "& .post-recent-title": {
        margin: "12px 0",
    },
    "& .author-recent-name": {
        marginRight: "30px"
    },
    "& .video-section": {
        backgroundImage: `url(${VideoBackground})`,
        backgroundSize: "100% 100%",
        borderRadius: "12px",
        "& .video-question": {
            marginBottom: "10px",
            fontFamily: "Gelasio, serif",
            "@media(max-width:600px)": {
                textAlign: "center"
            }
        },
        "& .video-player": {
            objectFit: "unset"
        }
    },
    "& .fullWidth": {
        height: "100%",
        width: "100%"
    },
    "& .play-icon": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        cursor: "pointer"
    },
});
const InfoWrapper = styled("footer")({
    color: "#fff",
    backgroundColor: "rgba(33, 38, 35, 1)",
    paddingTop: "3rem",
});
const styles = {
    mainheader: {
        display: "flex",
        marginRight: "9%",
        justifyContent: "space-between",
        alignItems: "center",
        "@media(min-width:600px)": {
            marginRight: "5%"
        },
        "@media(min-width:1200px)": {
            marginRight: "12%"
        },
    },
    parentwrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    mainWrapper: {
        width: "55%",
        margin: "2em 4em 2em 2em",
        padding: "0.7em 0.7em 2.5em 2.6em",
        borderRadius: "8px",
        border: "0.5px solid #C1C1C1",
        backgroundColor: "#FFFFFF",
        "@media(max-width:690px)": {
            padding: "0.7em 0.7em 2.5em 0.6em",
        },
    },
    searchbutton: {
        height: "48px",
        borderRadius: "8px",
        background: "#337152",
        padding: "0.556vw  2.222vw",
        color: " #FFF",
        '&:hover': {
            background: "#337152",
        },
        "@media(max-width:690px)": {
            padding: "8px 32px",
        },
        "@media(max-width:239px)": {
            padding: "0",
        },

    },
    searchtextfield: {
        borderRadius: "8px",
        width: "75%",
        padding: "0.5em",
        border: "1px solid #C1C1C1",
        '& .MuiInput-underline': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
            paddingLeft: "0.5em"
        },
        '& .MuiInputBase-input::placeholder': {
            "@media(max-width:486px)": {
                fontSize: '10px', // Set the font size for the placeholder
            },

        },
        "@media(max-width:690px)": {
            width: "80%",
        },
    },
    childwrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "@media(max-width:690px)": {
            flexDirection: "column"
        },
    },
    closeicon: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        padding: '0 0.8 0.6em 0'
    },
    serchbtn: {
        marginLeft: '1em',
        paddingRight: '2.5em',
        "@media(max-width:690px)": {
            marginTop: '1em',
            marginLeft: '0',
            paddingRight: '0',
        },
    },
    home: {
        margin: "1em 0 2em 9%",
        display: "flex",
        alignItems: "center",

        "@media(min-width:600px)": {
            marginLeft: "5%"
        },
        "@media(min-width:1200px)": {
            marginLeft: "6.7%"
        },
    },
    iconfont: {
        fontSize: '0.8rem'
    },
    fontNav: {
        fontFamily: "Nunito",
        fontSize: "14px",
        fontWeight: 400,
        color: "#000",
        display: "flex",
        alignItems: "center",
    },
    iconpadding: {
        margin: "0 0.4em 0 0.4em"
    }

}




export default withStyles(styles)(KnowladgeBase);

// Customizable Area End