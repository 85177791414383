import React from "react";
// Customizable Area Start
import {
    Container,
    Grid,
    MenuItem,
    Select,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import DashboardController, { Props } from "./DashboardController.web";
//@ts-ignore
import { ReactComponent as DownloadIcon } from "../assets/downloadIcon.svg";
import Header from "../../../components/src/Header.web";






export default class DashboardPanselector extends DashboardController {

    constructor(props: Props) {
        super(props);
    }
    comunSelector = (key: string, _option: object) => {
        return (
            <Select
                key={key}
                name={key}
                variant="outlined"
                value={this.state.selected[key]}
                onChange={this.handleSelector}
                IconComponent={DownloadIcon}
                displayEmpty
                className="selector"
                data-test-id={`${key}-id`}
                inputProps={{ "aria-label": "Without label" }}
            >
                <MenuItem value={"10"}>PAN : FRIPS8922R</MenuItem>
                <MenuItem value={"20"}>PAN : FRIPS8892R</MenuItem>
                <MenuItem value={"30"}>PAN : FRIPS6622R</MenuItem>
            </Select>
        );
    };

    render() {
        return (
            <MainWrapper >
                 <Header
            data-testid="header_test"
            active={true}
            panlist={this.state.userPancards}
            primaryPan={this.state.selectedValues}
            selectedYear={this.state.assismentYear}
            yearList={this.state.assismentYear}
            primaryAssismentYear={this.state.primaryAssismentYear}
            handleSetAssismentYear={this.handleSelectAssismentYear}
            handleSelector={this.handleSelector}
            handleNavigateToAddPan={this.handleNavigateToAddPan}
          />
            </MainWrapper>
        )
    }
}

const MainWrapper = styled(Container)({

    "& .selector": {
        border: "none",
        backgroundColor: "#F4F6F5",
        marginInline: "10px",
        width: "100%",
        margin: "13px 0",
        borderRadius: "8px",
        "& .MuiSelect-icon": {
            marginInline: "8px"
        },
        "@media (max-width:600px)": {
            width: "100%",
            marginInline: "0px"
        }
    },
    "& .left-bar": {
        width: "100%",
    },

});



// Customizable Area End