export const Shield = require("../assets/successshield.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const background = require("../assets/panBackground.png");
export const DocumentCopy = require("../assets/document copy.png");
export const Question = require("../assets/question.png");
export const CloudComputing = require("../assets/cloud-computing.png");
export const ExpandArrow = require("../assets/expandArrow.png");
export const TrashCan = require("../assets/trash-can.png");
export const BackArrow = require("../assets/backArrow.png");

