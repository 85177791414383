// Customizable Area Start
import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    withStyles,
    Input,
    Checkbox,
    Grid,
    TextField,
    Snackbar,
    styled
    // Customizable Area End
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HousePropertyEditController, { Props, configJSON,CoOwnerObj } from './HousePropertyEditController.web';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { deleteRed, LeftArrow,calanderIcon } from './assets';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-multi-date-picker';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export class HousePropertyEdit extends HousePropertyEditController {

    constructor(props: Props) {
        super(props);
    }



    steps = [
        <div className='stepNo'> <span>1</span>{configJSON.interestPaidOnHouseLoan}</div>,
        <div className='stepNo'> <span>2</span>{configJSON.interestPaidDuringPreConstrn}</div>,
        <div className='stepNo'> <span>3</span>{configJSON.houseAddress}</div>,
        <div className='stepNo'> <span>4</span>{configJSON.coOwnersDetails}</div>

    ];

    renderTextFiled = (inputprops: {
        name: string,
        value: string,
        type: string,
        inputType: string,
        onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
        error: boolean | undefined,
        message: string,
    }
    ) => {
        const { classes } = this.props;
        const {
            name,
            value,
            type,
            inputType,
            onChange,
            error,
            message,
        } = inputprops
        return (
            <Box className={classes.stringwrapper}>
                <TextField
                    className={classes.textfiledWrapperString}
                    error={error}
                    variant="outlined"
                    fullWidth
                    placeholder="Enter"
                    name={name}
                    type={inputType}
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleFocusHouseProperty}
                    onBlur={this.handleBlurHouseProperty}
                    data-test-id={name}
                    helperText={error && configJSON.requriedfield}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}
                />

                <Box className={classes.topmargin}>
                    {type == "true" &&

                        <Typography variant="caption" className={classes.underhovertext} >
                            <Box className={classes.iconAlign}>
                                <HelpOutlineIcon />{message}
                            </Box>
                        </Typography>
                    }
                </Box>
            </Box>
        )
    }

    renderCostumTextfiledforHouseProperty = (inputprops: {
        name: string,
        value: string,
        type: string,
        onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
        error: boolean | undefined,
        message: string,
    }
    ) => {
        const { classes } = this.props;
        const {
            name,
            value,
            type,
            onChange,
            error,
            message,
        } = inputprops
        return (
            <>
                <Box className={classes.amountWrapper}>
                    <Box className={classes.ruppeslogoWrapper}>
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    </Box>
                    <TextField
                        className={classes.textfiledWrapper}
                        error={error}
                        variant="outlined"
                        placeholder={configJSON.passplaceHolder}
                        name={name}
                        fullWidth
                        type="text"
                        value={value}
                        onChange={onChange}
                        onFocus={this.handleFocusHouseProperty}
                        onBlur={this.handleBlurHouseProperty}
                        data-test-id={name}
                        helperText={error && configJSON.requriedfield}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                    />
                </Box>
                <Box className={classes.topmargin}>
                    {type == "true" &&

                        <Typography variant="caption" className={classes.underhovertext} >
                            <Box className={classes.iconAlign}>
                                <HelpOutlineIcon />{message}
                            </Box>
                        </Typography>
                    }
                </Box>
            </>
        )
    }

    fourthForm() {
        const { classes } = this.props;
        return (
            <div>
                <Box className={`${classes.mainWrapper} tdsEditForm`}>
                    <h2 data-test-id="fourth-form"> {configJSON.coOwnersDetails} </h2>
                    <Typography className={classes.labelHeading}>{configJSON.fourthformHouseEditText}</Typography>
                    {this.state.coOwner.map((item:CoOwnerObj, index:number) => (
                        <Box key={index} >
                            <Grid container spacing={2} className={classes.marginHouse}>
                                <Grid sm={3} item>
                                    <Typography className={classes.labelHeading} >{configJSON.selfNameCoowner}</Typography>
                                    <Input type='text' disableUnderline value={item.name_of_coowner} placeholder='Enter' name='name_of_coowner' data-test-id={"coOwnerName" + index}
                                        onChange={this.handleMultipleChangeData.bind(this, item.id)}
                                    />
                                </Grid>
                                <Grid sm={3} item>
                                    <Typography className={classes.labelHeading}>{configJSON.selfPanCowner}</Typography>
                                    <Input type='text' disableUnderline value={item.pan_of_coowner} placeholder='Enter' name='pan_of_coowner' data-test-id={"coOwnerPAN" + index}
                                        onChange={this.handleMultipleChangeData.bind(this, item.id)}
                                    />
                                </Grid>
                                <Grid sm={3} item>
                                    <Typography className={classes.labelHeading}>{configJSON.selfPercentageShare}</Typography>
                                    <Input type='text' disableUnderline value={item.percentage_share} placeholder='Enter' name='percentage_share'  data-test-id={"coOwnerPercShare" + index}
                                        onChange={this.handleMultipleChangeData.bind(this, item.id)}
                                    />
                                </Grid>
                                <Grid sm={3} item className={classes.gridStyles}>
                                    <img src={deleteRed} data-test-id={"delete-icon"+index} style={{ cursor: 'pointer' }} onClick={this.handleDeleteMultipleItems.bind(this, item.id,item.dbId)} />
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                    <div data-test-id='addField' className={classes.addfiledtext} onClick={this.handleAddMultipleItems}>
                        <AddCircleIcon className={classes.circleIcon} />
                        <Typography className={classes.addMore}>{configJSON.addMOreItems}</Typography>
                    </div>
                    <p  className={classes.errorpancolour}>{this.state.error.coOwner?configJSON.errorPanmessage:null}
                    </p>
                    <p  className={classes.errorpancolour}>{this.state.error.duplicate?"Pan can't be same":null}
                    </p>
                   

                </Box>
            </div>
        )
    }

    first() {
        const { classes } = this.props
        return (
            <div>
                <Box className={`${classes.mainWrapper} tdsEditForm`}>
                    <Grid>
                        <h2 className='mt0 mb10 formHeading' data-test-id="first-form" > {configJSON.interestPaidOnHouseLoan} </h2>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <label className={classes.labelHeading1} htmlFor="fname">
                                    {configJSON.interestPaidOnHouseLoan}
                                </label>
                                <br />
                                <label className={classes.labelHeading1} htmlFor="fname">
                                    {'(for current financial year)'}
                                </label>
                            </Grid>
                            <Grid item sm={6}>
                                {this.renderCostumTextfiledforHouseProperty(
                                    {
                                        name: "firstInterest",
                                        value: this.state.firstInterest,
                                        type: "true",
                                        onChange: this.handleOnchangeHouseFirst,
                                        error: false,
                                        message: configJSON.intOnHouseLoanSub,

                                    }
                                )
                                }


                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }

    third() {
        const { classes } = this.props
        return (

            <div>
                <Box className={`${classes.mainWrapper} tdsEditForm`}>
                    <h2 data-test-id="third-form">{configJSON.houseAddress} </h2>
                    <div className={classes.aligindisplay}>
                        <Checkbox data-test-id="checkbox_id" checked={this.state.isChecked} onClick={this.handleCheckBox}/>
                        <Typography className={classes.labelHeading1}>{configJSON.selfSameAdress}</Typography>
                    </div>

                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.flatNum}
                            <span className="redSign">*</span>
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "flatNum",
                                    value: this.state.flatNum,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHouseAlpha,
                                    error: this.state.error["flatNum"],
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                    <br />
                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.premiseName}                           
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "premiseName",
                                    value: this.state.premiseName,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHouseAlpha,
                                    error: false,
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                    <br />
                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.roadName}
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "streetName",
                                    value: this.state.streetName,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHouseProperty,
                                    error: false,
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                    <br />
                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.pincode}
                            <span className="redSign">*</span>
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "pincode",
                                    value: this.state.pincode,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHousePin,
                                    error: this.state.error["pincode"],
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                    <br />
                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.state}
                            <span className="redSign">*</span>
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "state",
                                    value: this.state.state,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHouseProperty,
                                    error: this.state.error["state"],
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                    <br />
                    <Box className={classes.sampletext}>
                        <Typography className={classes.labelHeadingtext}>{configJSON.country}
                            <span className="redSign">*</span>
                        </Typography>
                        <Box className={classes.renderfield}>
                            {this.renderTextFiled(
                                {
                                    name: "country",
                                    value: this.state.country,
                                    type: "false",
                                    inputType: "text",
                                    onChange: this.handleOnchangeHouseProperty,
                                    error: this.state.error["country"],
                                    message: "",
                                }
                            )
                            }
                        </Box>
                    </Box>
                </Box>
            </div>
        )
    }

    second() {
        const { classes } = this.props
        return (
            <div>
                <Box className={`${classes.mainWrapper} tdsEditForm`}>
                    <Grid>
                        <h2 className='mt0 mb10 formHeading' data-test-id="second-form"> {configJSON.interestPaidDuringPreConstrn} </h2>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <label className={classes.labelHeading1} htmlFor="fname">
                                    {configJSON.financialYear}
                                </label>
                            </Grid>
                            <Grid item sm={6}>
                                <Box style={{ width: '80%' }}>
                                    <CustomCalander style={{ position: "relative" }}>
                                        <Box className="calander_icon">
                                        <img src={calanderIcon} />
                                        </Box>
                                        <Box className="rmdp-input--1">
                                            <DatePicker
                                                editable={false}
                                                placeholder="DD/MM/YYYY"
                                                data-test-id="secondFinYear"
                                                format="DD/MM/YYYY"
                                                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                                                name="secondFinYear"
                                                value={this.state.secondFinYear}
                                                onChange={(date) => {this.handleGetFinYear(date)}}
                                                maxDate={new Date()}              
                                            />
                                            {this.state.secondFinYear && 
                                            <CloseIcon className="close-icon" data-test-id="date-remove" onClick={this.emptySelectedDate.bind(this,"secondFinYear")}/>}
                                            

                                        </Box>
                                    </CustomCalander>
                                </Box>

                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <label className={classes.labelHeading1} htmlFor="fname">
                                    {configJSON.totalInterest}
                                </label>
                            </Grid>
                            <Grid item sm={6}>
                                {this.renderCostumTextfiledforHouseProperty(
                                    {
                                        name: "secondTotalInt",
                                        value: this.state.secondTotalInt,
                                        type: "true",
                                        onChange: this.handleOnchangeHouseFirst,
                                        error: false,
                                        message: configJSON.interestPaid,
                                    }
                                )
                                }

                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }

    renderLoaderSelfProperty = () => {
        const { classes } = this.props;

        if (this.state.isLoadingSelfProperty) {
            return (
                <Box className={classes.loaderContainer} data-test-id="loader">
                    <CircularProgress size='4em' />
                </Box>
            );
        } else {
            return this.renderSteps()

        }

    }

    renderSteps = () => {
        const { classes } = this.props
        const { activeStep } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Box className='commonFormBg' sx={{ width: '100%' }}>
                    <div data-test-id="initial-render">
                        <div className="step-bar">
                            <Box className={classes.stepbar} onClick={this.handleSteps}>
                                <img src={LeftArrow} />
                            </Box>
                            {this.steps.map((step, index) => {
                                index += 1
                                return (
                                    < div
                                        key={index}
                                        className={`step ${index < activeStep ? 'visited' : ''} ${index === activeStep ? 'active' : ''}`}
                                    >{step}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="step-content">

                            {(() => {
                                switch (activeStep) {
                                    case 1:
                                        return this.first()
                                    case 2:
                                        return this.second()
                                    case 3:
                                        return this.third()
                                    case 4:
                                        return this.fourthForm()
                                }
                            })()}
                        </div>
                    </div>

                    <button className='commonBtn' data-test-id="nextBtn" onClick={()=>this.handleValidationSubmit(true)}>{configJSON.btnSaveDraft}</button>
                    <button className='commonBtn ml' onClick={()=>this.handleValidationSubmit(false)} data-test-id="savethedraftBtn" >
                        {this.state.activeStep >= 4 ? 'Submit' : 'Save and Next'}
                    </button>
                </Box>
            </ThemeProvider>
        )
    }
    render() {
        return (
            <>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} open={this.state.snackbarOpenRental} autoHideDuration={4000} onClose={this.handleSnackbarself} >
                    <Alert severity="success">
                        {configJSON.datasucessMessage}
                    </Alert>
                </Snackbar>
                {this.renderLoaderSelfProperty()}

            </>
        )
    }



}

const styles = {
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    errorpancolour:{
        color:"red"
        },
    addfiledtext: {
        display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '1rem 0 2rem 0',width: 'max-content'
    },
    circleIcon: {
        color: '#337152', margin: '0 5px 0 -10px'
    },
    gridStyles: {
        display: 'flex', alignItems: 'flex-end'
    },
    marginHouse: {
        marginTop: "40"
    },
    stepbar: {
        display: "flex",
        marginTop: '1em',
        alignItems: "flex-start",
        cursor: "pointer"
    },
    aligindisplay: {
        display: 'flex', alignItems: 'center'
    },
    renderfield: {
        maxWidth: "100%",
        width: "70%"
    },
    sampletext: {
        padding: 12, display: 'flex', gap: "70px"
    },
    amount: {
        display: "flex",

    },
    stringwrapper: {
        maxWidth: '70%',
        "& input": {
            border: "none"
        },
        "& input:focus": {
            border: "none",
            borderRadius: "0px",
        }
    },
    amountWrapper: {
        display: "flex",
        maxWidth: '70%',
        "& input": {
            border: "none"
        },
        "& input:focus": {
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: "0px 8px 8px 0px",
        }
    },
    ruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    textfiledWrapperString: {
        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: "8px",
            },
        },
    },
    textfiledWrapper: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    topmargin: {
        marginTop: "3px",
        width: "65%",
        maxWidth: "100%"
    },
    underhovertext: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },
    iconAlign: {
        display: "flex",
        gap: "10px"
    },
    queTypo: {
        display: 'flex',
        alignItems: 'flex-start',
        color: 'rgba(54, 64, 59, 0.50)',
        fontFamily: 'Nunito',
        fontSize: 16,
        width: '60%',
        fontStyle: 'normal',
        fontWeight: 400,
        paddingTop: 10
    },
    mainWrapper: {
        background: '#FFF',
        padding: 10,
        borderRadius: 16,
    },
    labelHeading1: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontSize: 18,
        fontWeight: 600
    },
    labelHeadingtext: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontSize: 18,
        fontWeight: 600,
        width: "30%"
    },
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};

const CustomCalander = styled(Box)({
    "& .rmdp-day >span": {
      borderRadius: "20%",
    },
    "& .rmdp-day >span:hover": {
      backgroundColor: "#DEF5EA6B !important",
      color: "black !important"
    },
    "& .rmdp-day": {
      height: "40px",
      width: "30.5px !important"
    },
    "& .rmdp-today >span": {
      color: "black",
      backgroundColor: "transparent",
    },
    "& .rmdp-input": {
      zIndex: 1000,
      backgroundColor: "transparent",
      position: "relative",
      height: "48px",
      border: "none",
      paddingLeft: "70px",
      fontSize: "18px",
      width: "100%"
    },
    "& .rmdp-input--1": {
      border: "1px solid",
      maxWidth: "234px",
      borderRadius: "7px",  
      borderColor: '#c6c6c6',
      display: "flex",
      alignItems:"center"
    },
    "& .rmdp-input:focus": {
      outline: "none",      
      boxShadow: "none",      
      borderColor: "transparent",  
    },
    "& .rmdp-input::placeholder": {
      fontStyle: "normal",
      fontFamily: "Nunito",
      color: "rgba(54, 64, 59, 0.50)",
      fontSize: "18px",
      fontWeight: 600
    },
    "& .rmdp-header-values": {
      fontSize: "13.5px"
    },
    "& .calander_icon": {
      backgroundColor: "#ddfef7",
      position: "absolute",
      height: "100%",
      width: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "7px 0px 0px 7px"
    },
    "& .rmdp-week-day": {
      backgroundColor: "#DEF5EA6B !important",
      color: "black !important"
    },
    "& .rmdp-arrow": {
      borderColor: "black !important"
    },
    "& .close-icon":{
        left:"30px",
        position:"relative",
    }
  });

export default withStyles(styles)(HousePropertyEdit);

