import {
    styled,
    TextField
} from "@material-ui/core";
export const CustomTextfield = styled(TextField)({
    borderColor: "#c6c6c6",
    "& .MuiOutlinedInput-root": {
        borderRadius: "7px",
        height: "48px",
        '& fieldset': {
            borderColor: '#c6c6c6',
        },
        '&:hover fieldset': {
            borderColor: '#c6c6c6',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#c6c6c6',
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: "18.5px 24px"
    }
})
export const CustomTextArea = styled(TextField)({
    borderColor: "#c6c6c6",
    "& .MuiOutlinedInput-root": {
        borderRadius: "7px",
        '& fieldset': {
            borderColor: '#c6c6c6',
        },
        '&:hover fieldset': {
            borderColor: '#c6c6c6',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#c6c6c6',
        }
    }
})