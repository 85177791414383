Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.examplePatchAPiMethod = "PATCH";
exports.PUTAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "multipageforms2";
exports.labelBodyText = "multipageforms2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.dummyText = "Lorem Ipsum is simply dummy printing";
exports.dummySub = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
exports.UploadForm16 = "Upload Form 16";
exports.Addemployer = "Add employer";
exports.editGross = "I want to alter Gross Salary (Basic Salary, Allowances, Perquisites etc.)";
exports.editallowances = "I want to alter my deductible allowances (HRA, LTA etc.)";
exports.editdeductions = "I want to alter my deductions";
exports.allfields = "I want to check all fields";
exports.pathToForms = "/Menu/Documents";
exports.alterButton = "I want to alter the details"
exports.everythingfineButton = "Everything Looks Good - Confirm and Mark Complete"
exports.CompanyDetails = "Company Details"
exports.EmploymentTdstitle = "Employment and TDS details"
exports.Salarybreakdown = "Salary Breakdown"
exports.Employeraddress = "Employer Address"
exports.SuccessMessage = "Data submitted successfully!"
exports.DraftMessage = "Data draft successfully!"
exports.TDSDetailsApiCall = "bx_block_cfocritr17/employers";
exports.EmployerListApiCall = "bx_block_cfocritr17/fetch_breakdown";
exports.authToken = "authToken";
exports.employerEndpoint = "bx_block_cfocritr17/salary_structures";
exports.employerEndpointDashboard = "bx_block_cfocritr17";
exports.addHouseProperty = "Add House Property";
exports.housePropertyModalTitle = 'Select House Property Income';
exports.partnershipFirkModalTitle = 'Select Income from partnership firms, other business income, income from plying of trucks, etc';
exports.selfOccHouseProperty = 'Self Occupied House Property';
exports.rentalProperty = 'Rental Property';
exports.housePropertyIncome = 'House Property Income';
exports.housePropertyDetails = 'House Property Details';
exports.selfOccSubText = 'For property owned by you and currently being used by you - enter details such as interest paid on loan.';
exports.rentalSubText = 'For property owned by you and rented out to a tenant - enter details such as annual rental income, municipal taxes, interest paid on loan.'
exports.clickHereWarningText = " to complete the import of your prior year ITR and AIS for auto-completion of data such as address of property, tenant name and prior year value. This is useful if you have multiple house properties."
exports.DocumentsEnd = "Documents"
exports.natureemployer = "bx_block_cfocritr17/fetch_tds_details"
exports.prerequesites = "bx_block_cfocritr17/fetch_perquisites"


exports.lieuSalary = "bx_block_cfocritr17/fetch_profits_in_lieu_of_salary"
exports.stateList = "bx_block_cfocritr17/fetch_emp_states"
exports.exemptList = "bx_block_cfocritr17/fetch_exampt_allowances"
exports.savedraftbtn = "Save Draft"
exports.nextbtn = "Save and Next"

exports.interestPaidOnHouseLoan = 'Interest paid on housing loan for this house property'
exports.intOnHouseLoanSub = 'If you have a housing loan against a house you live in then you can claim a tax deduction of upto Rs. 2,00,000.'
exports.interestPaidDuringPreConstrn = 'Interest paid during the pre-construction period of house property'
exports.financialYear = 'Financial year previous to completion of construction'
exports.egForFinancialYear = 'E.g. If construction completed on 17th July’10, you need to specify 2009-10'
exports.totalInterest = 'Total interest amount paid during pre-construction period'
exports.interestPaid = 'Interest paid from the date of borrowing till 31st March of previous financial year'
exports.houseAddress = 'Your house address'
exports.coOwnersDetails = 'Co-Owners Details'
exports.flatNum = 'Flat/Door/Block No. '
exports.premiseName = 'Premise Name (optional)'
exports.roadName = 'Road/Street (optional)'
exports.pincode = 'Pincode '
exports.state = 'State '
exports.country = 'Country'
exports.section80C = "  For certain payments and investments"
exports.section80CDescription = " Life insurance premiums, provident fund contributions, tuition fees, home loan repayments etc."
exports.section80CMaxamount = "Max Limit: ₹ 1,50,000"
exports.Section80CCD = "  Contribution towards National Pension Scheme"
exports.Section80CCDDescription = "Voluntary Contribution made towards NPS"
exports.Section80CCDMaxAmont = "(Max Allowable Rs 50,000)"
exports.section80D = "Health Insurance Premium and Medical Expenses"
exports.section80DDescription = "Parents Age Group"
exports.section80DSwitchHeadingSelf = "Preventive Health Checkup for Self, Family or Parents"
exports.section80DSwitchsubHeadingSelf = "Max Limit is Rs. 5000 for Self, family including parents"
exports.section80DSwitchHeadingParent = "Health Insurance for Self, Family or Parents"
exports.section80DSwitchsubHeadingParent = "Medical Expenditure can be claimed for Senior Citizens (if there is no health insurance for such senior citizen)"
exports.Section80G = " Donations"
exports.Section80GDescription = "Donations made towards registered charitable institutions and funds"
exports.Section80TTASection80TTB = "Savings Bank and Fixed Deposit Interest"
exports.Section80TTASection80TTBDescription = "Deductible Savings Bank Interest and Fixed Deposit Interest (Max allowable 10,000 / 50,000 in case of senior citizen)"
exports.passplaceHolder = "00"
exports.deductionHeading = "Deductions against Taxable Income"
exports.addnewDOnationBUtton = "Add New Donation "
exports.addOthers = "Add other deduction"
exports.emptyString = ""
exports.Section80GGCSubHeading="Donations made to political parties and electoral trust"

exports.Common80GOrganizations = "Common 80G Organizations"
exports.Common80GOrganizationsMessage = "Once you select a donee from this list, ClearTax shall automatically populate the donee’s details for you. If your donee is not in the above list, please enter the details manually."

exports.Common80GGA2aOrganizations = "80GGA(2)(a) - Sum paid to Research Association or University, college or other institution for Scientific Research"
exports.Common80GGA2aaOrganizations = "80GGA(2)(aa) - Sum paid to Research Association or University, college or other institution for Social science or Statistical Research;"
exports.Common80GGA2bOrganizations = "80GGA(2)(b) - Sum paid to an association or institution for Rural Development;"
exports.Common80GGA2bbOrganizations = "80GGA(2)(bb) - Sum paid to PSU or Local Authority or an association or institution approved by the National Committee for carrying out any eligible project; "
exports.Common80GGA2cOrganizations = "80GGA(2)(c) - Sum paid to an association or institution for Conservation of Natural Resources or for afforestation; "
exports.Common80GGA2ccOrganizations = "80GGA(2)(cc) - Sum paid for Afforestation, to the funds, which are; notified by Central Govt.; "
exports.Common80GGA2dOrganizations = "80GGA(2)(d) - Sum paid for Rural Development to the funds, which are notified by Central Govt.; "
exports.Common80GGA2eOrganizations = "80GGA(2)(e) - Sum paid to National Urban Poverty Eradication Fund as setup and notified by Central Govt."

exports.NameoftheDonee = "Name of the Donee"
exports.DonationAmountCash = "Donation Amount (Cash)"
exports.DonationAmountNonCash = "Donation Amount (Non Cash)"
exports.PANofDonee = "PAN of Donee "
exports.PANofDoneeMessage = "Enter 'GGGGG0000G' if you have donated to Government Funds that don't have a PAN"

exports.AddressLine = "Address Line"
exports.PINCode = "PIN Code"
exports.CityStateCountry = " City/State/Country"
exports.emptyPlaceholder = "Enter"
exports.donationEndPoint = "bx_block_multipageforms2/donations"
exports.dropdownsDeductions = "bx_block_multipageforms2/deductions/section"
exports.donationsnameOne = "Section 80G - Donations to charitable organizations"
exports.donationsnameTwo = "Clause under which donation is made"

exports.Deduction80GGC = "Deduction80GGC"
exports.Deduction80GGA = "Deduction80GGA"

exports.helpertext = "Once you select a donee from this list, ClearTax shall automatically populate the donee’s details for you. If your donee is not in the above list, please enter the details manually."
exports.helpertextpan = "Enter 'GGGGG0000G' if you have donated to Government Funds that don't have a PAN"
exports.SaveDonationButton = "Save Donation"
exports.succesmessageSnackBar = "Data submitted sucessfully"
exports.AddresOfDonee = "Address of Donee"
exports.DonationDetails = "Donation Details"
exports.TownCity = "Town / City"
exports.StateSelect = "State"
exports.endpointdonations80g = "common_80g_organization"
exports.endpointdonationsclause = "clause_under_which_donation_is_made"
exports.addOther = "+  ADD OTHER "
exports.healthcheckupselfspouce = "Amount paid for Preventive Health Checkup"
exports.healthcheckupmessage = "If no health insurance then medical expenditure (only by cheque) MAX 50,000"
exports.healthcheckupselfparentOne = "Health/Medical Insurance Premium"
exports.healthcheckupselfparenttwo = "Medical Expenditure"

exports.addOther = "+  ADD OTHER "
exports.intIncome = 'Interest income';
exports.intIncomeSub = 'Income from savings bank, fixed deposits and any other source';
exports.dividend = 'Dividend';
exports.dividendSub = 'Received from companies';
exports.rent = 'Rent';
exports.rentSub = 'Rental income from machinery (including vehicles) or land';
exports.otherIncome = 'Other Income';
exports.otherIncomeSub = 'Pension from government schemes, gift income, family pension, race horses, pass through income etc';
exports.exemptIncome = 'Exempt Income';
exports.exemptincomeSub = 'Incomes that are not changeable to tax as per Income Tax law such as agricultural income, interest from PPF, maturitu of life insurance policies etc';
exports.otherIncEndPoint = 'bx_block_multipageforms2/other_incomes';
exports.rentEndPoint = 'bx_block_multipageforms2/rents';
exports.submitData = "Submit"
exports.deductionEndPoint = "bx_block_multipageforms2/deductions"
exports.interestEndPoint = 'bx_block_multipageforms2/interest_incomes';
exports.exemptEndPoint = 'bx_block_multipageforms2/exampt_incomes';
exports.exemptIncomeDropDownEndPoint = "bx_block_multipageforms2/exampt_incomes/exampt_income_dropdown";
exports.deleteMethod = 'DELETE'
exports.selfPropertyEndPoint = 'bx_block_multipageforms2/house_properties';
exports.saveDraftEndpoint= "bx_block_multipageforms2/general_informations";
exports.businessFormSubmmit="bx_block_multipageforms2/business_income/business_income_44ada";
exports.profIncome44adaGetEndpoint = "bx_block_multipageforms2/business_income/professional_income_44ada";
exports.profIncome44adGetEndpoint = "bx_block_multipageforms2/business_income/business_income_44ad";
exports.businessFormSubmmit="bx_block_multipageforms2/business_income/business_income_44ad"
exports.getDraftEndpoint = "bx_block_multipageforms2/general_informations";
exports.nameofBusiness = "Name of Business :"
exports.natureofBussiness = "Nature of Business"
exports.receiptsandprofit = "Receipts and Profit"
exports.recieptsAndProfits = "Receipts and Profit"
exports.grossReceipts = "Gross Receipts"
exports.grossReceiptscash = "Gross Receipts through Cash"
exports.grossReceiptscheques = "Gross Receipts through cheques/electronic mode/banking system"
exports.netProfits = "Net Profit"
exports.PresumptiveIncome = "Presumptive Income"
exports.addCapGainsInc = 'Add Capital Gains Income';
exports.saleofShares = 'Sale of Shares and Securities';
exports.saleOfSharesSub = 'Capital Gains on Sale of Domestic/Foreign Shares, Mutual Funds, ETFs, Bonds, Debentures, etc.'
exports.saleofLand = 'Sale of Land or Building';
exports.saleOfLandSub = 'Capital Gains on sale of plots and land, buildings,residential or commercial properties';
exports.cryptoAssets = 'Crypto/Virtual Digital Assets';
exports.cryptoAssetsSub = 'Capital Gains on sale of Virtual Digital Assets such as all typesof crypto assets, including NFTs, tokens, and cryptocurrencies'
exports.otherCapGains = 'Others (Reversal of 54 exemptions, any other assets such as jewellery, paintings, etc)'
exports.otherCapGainsSub = 'Gains on sale of capital assets such as Unit Linked Insurance Policy, Personal effects such as jewellery, gold or silver.'
exports.exemptionCapGains = 'Exemption on Capital Gains (on certain investments)';
exports.exemptionCapGainsSub = 'Exemptions available against capital gains on account of investment made in Residential Properties or Specified Bonds'
exports.dividendEndPoint = 'bx_block_multipageforms2/dividends'

exports.periodOfHolding = "Period of Holding"
exports.units = "Units"
exports.salesConsideration = "Sales Consideration"
exports.indexedCost = "Indexed Cost"
exports.salesAmount = "Sales Amount"
exports.purchaseAmount = "Purchase Amount"
exports.dateOfPurchase = "Date of Purchase"
exports.dateOfSale = "Date of Sale"
exports.bondsPlaceholder = "Example"
exports.typeOfSecurityText = "Type of Security"
exports.placeHolderDate = "11/22/3333"

exports.Professional44ADA = "Professional Income (44ADA)"
exports.Next = "Next"
exports.BusinessHeading = "Profession, Freelancing a Business Income "
exports.BusinessSubHeading = "For Doctors, Lawyers, CAs, Other Professionals, Freelancers, Small & Medium businesses, Tutors, Influencers etc."
exports.BusinessHeading1 = "Income from Profession/Freelancing - Professional Income"
exports.BusinessHeading2 = "Income from Business - trading business, e-commerce etc."
exports.BusinessHeading3 = "Income from Futures & Options or Intraday Trading"
exports.BusinessHeading4 = "Income from partnership firms, other business income, income from plying of trucks, etc"
exports.BusinessHeading5 = "Very small business (no accounts maintained)"
exports.BusinessHeading6 = "Regular Business Income"
exports.commonAmount = "10,000"
exports.incomeProfesionList = [
  "For freelancers, designers, photographers, consultants, lawyers, doctors etc.",
  "If total receipts are less than 50L",
  "Presumptive income can be declared at 50% of total receipts"
]
exports.incomeBusiness = [
  "For retail traders, e-commerce operators",
  "If total receipts are less than 2 crores",
  " Presumptive income can be declared at 8%/6% of receipts"
]
exports.incomeFeatures = [
  "3a. F&O",
  "3b. Intraday"
]
exports.incomePatnership = [
  "Remuneration, Interest from Partnership Firm",
  "Presumptive income for Goods Carriers (u/s 44AE)"
]
exports.smallBusiness = [
  " If turnover is less than 25L and income is less than 2.5L for businesses",
  "If turnover is less than 10L and income is less than 1.5L for professions"
]
exports.regularIncome = [
  "All the above cases not applicable",
  "Full books maintained"
]
exports.INTRADAY = "Intraday Share Trading"
exports.Futures = "Futures and Options"
exports.IncomePartnershipFirm = "Income from Partnership Firms"
exports.OtherBuisenessIncome = "Any Other Business Income"
exports.IncomePlyingTruck = "Income from plying of trucks 44AE"
exports.Receipts = "Receipts and Profit"
exports.totalPos = "Total of Positive Trades from F&O"
exports.totalnegv = "Total of Negative Trades from F&O"
exports.directExpense = "Direct Expenses"
exports.indirectExpense = "Indirect Expenses"
exports.turnover = "Turnover from Speculative Activity"
exports.GrossProfit = "Gross Profit"
exports.Expenditure = "Expenditure related to such activity"
exports.calculateTurnover = "How do I calculate my turnover"
exports.helpermessageIntraday = "if you don't have specific information regarding the expenditures, then they can provide the net profit and enter expenditure as zero"
exports.futuresHelperText = "You do not need to mandatorily fill a detailed P&L, but in case you have maintained full books of account for this business"
exports.futuresProps = "futures"
exports.intradayProps = "intraday"
exports.partnershipFirmProps = "partnershipfirmincome"
exports.otherBusinessProps = "otherbusinessincome"
exports.plyingTruckProps = "plyingtruckincome"
exports.incomeFromFirm = "Share of income from Firm"
exports.detailsOfFirm = "Details of Partnership Firm"
exports.nameOfPartnershipFirm = "Name"
exports.panOfPartnershipFirm = "Pan"
exports.audOrNonAud = "Auditable/Non-auditable"
exports.closingBalance = "Closing Balance of Capital in the firm"
exports.incomeTitle = "Incomes"
exports.amtOfInterest = "Amount of Interest Income"
exports.amtOfRemuneration = "Amount of Remuneration Income"
exports.profitShareReceived = "Profit Share Received (exempt)"
exports.passThroghBuisInc = "Pass through business income"
exports.deemedBuisInc = "Deemed business income"
exports.incFromAOP = "Income from AOP"
exports.nameOfBuiseness = "Name of Business"
exports.buisenessCode = "Business Code"
exports.truckWiseDet = "Truck-wise Details"
exports.regNoOfVeh = "Registration No. of Vehicle"
exports.ownLeaHired = "Owned/Leased/Hired"
exports.tonnCapacityInMT = "Tonnage Capacity in MT"
exports.totProf44AE = "Total profit from 44AE"
exports.presumbptiveIncome = "Presumptive Income"
exports.numberOfMonths = "Number of months for which vehicle was owned leased or hired"
exports.requriedfield = "required field"
exports.inCorrectPan = "Incorrect pan"
exports.requiredField = "Required field"
exports.otherExamptField = "All fields are required"
exports.rentFields = "Atleast one rent details should be added"
exports.deductionFields = "Atleast one deduction details should be added"
exports.atleatsOnlandDetails = "Atleast one land details should be added"
exports.SelectInitial = "Select"
exports.AddMoreDirectExpenses = "Add More Direct Expenses"
exports.AddMoreIndirectExpenses = "Add More Indirect Expenses"
exports.NextFutures = "Submit"
exports.capitalGainBondsEndPoint = "bx_block_multipageforms2/capital_gain_bonds"
exports.futuresAndOptionsApi = "bx_block_multipageforms2/futureoption_intradays/create_future_option"
exports.intradayApi = "bx_block_multipageforms2/futureoption_intradays/create_intraday"
exports.incomeFromPartnershipAPI = "bx_block_multipageforms2/income_from_partnership_firms"
exports.otherBusinessIncomeAPI = "bx_block_multipageforms2/any_other_business_incomes"
exports.incomeFromPlyingTruckAPI = "bx_block_multipageforms2/income_from_playing_of_trucks"
exports.getBusinessCodeListAPI = "bx_block_multipageforms2/regular_business_incomes/buissness_code"
exports.regularIncomeEndPoint = "bx_block_multipageforms2/regular_business_incomes";
exports.getBusinessIncomeAmountListAPI = "bx_block_multipageforms2/default_business_incomes"
exports.getIncomeFromPartnershipFirmAPIEndpoint = "bx_block_multipageforms2/income_from_partnership_firms"
exports.getOtherBusinessIncomeAPIEndpoint = "bx_block_multipageforms2/any_other_business_incomes"
exports.getIncomeFromPlyingTruckAPIEndpoint = "bx_block_multipageforms2/income_from_playing_of_trucks"
exports.Thirty = "Thirty"
exports.landGreenText = 'In case net agricultural income is above 5,00,000, details of Land need to be disclosed in the Income Tax Return '
exports.deductionGetCall = "bx_block_multipageforms2/deductions/find_deduction_pan"
exports.RegularBusinessIncomeHeading = "Regular Business Income"
exports.BusinessCode = "Business Code"
exports.PL = "P&L"
exports.NetProfitasperPL = "Net Profit as per P&L"
exports.Depreciationasperthebooks = "Depreciation as per the books"
exports.GiveLink = "Give Link to Detailed P&L"
exports.TaxDepreciation = "Give link to Income Tax Depreciation Schedule"
exports.CryptoorVirtual = "Crypto/Virtual Digital Assets"
exports.entriesmanually = "Add entries manually"
exports.statementsAvailable = "Import details using statements available with me"



exports.listSelfPropertiesEndPoint = 'bx_block_multipageforms2/house_properties/list_self_occupied'
exports.listRentalPropertiesEndPoint = 'bx_block_multipageforms2/house_properties/list_rental'

exports.Download = "Download"
exports.gains = "Gains"
exports.fourthformHouseEditText = "In case this property is jointly owned, please specify co-owners"
exports.addMOreItems = "Add More Items"
exports.Importdetailsusingstatements = "Import details using statements available with me"
exports.Dragdrop = "Drag & drop files or"
exports.BrowseText = "Browse"
exports.capitalCAMS = "Capital Gains documents from CAMS and KFIN can be imported here – this will cover all your mutual funds "
exports.NeedHelp = "Need Help?"
exports.supportedFormat = "Suppported format- CSV/XLXS"
exports.Downloadstandardtemplate = "Download our standard template"
exports.Download = "Download"
exports.VirtualDigitalAssetHeading = "Add gains from VDA (Virtual Digital Asset)"
exports.virtDigAssHeadWithoutAdd = "Gains from VDA (Virtual Digital Asset)"
exports.VirtualDigitalAssetSubHeading = "Add your VDA (Virtual Digital Asset) gains manually"
exports.dateofSalePlaceholder = "Date of Sale"
exports.dateofPurchasePlaceholder = "Date of Purchase"
exports.AddDetailsForassets = "Add Details of the asset"
exports.EnterVDA = "Enter VDA asset name"
exports.vdsSubtitle = "Bored Ape NFT , Nyan Cat gif NFT"
exports.TotalSalePrice = "Total Sale Price"
exports.TotalPurchasePrice = "Total Purchase Price"
exports.IncomeType = "Income Type"
exports.gains = "Gains"
exports.SelectBusinessIncomePlaceholder = "For most of the cases, select Capital Gains. Select Business Income for Crypto Mining income"
exports.cryptoendPoint = "bx_block_multipageforms2/capital_gain_asset_statements"
exports.samplecsvendpoint = "bx_block_multipageforms2/sample_document_vda"
exports.getAlldocs = "bx_block_multipageforms2/capital_gain_asset_statements"
exports.cryptomanalAsset = "bx_block_multipageforms2/"
exports.manualdocgetapi = "bx_block_multipageforms2/capital_gains_digital_assets"
exports.errormessageforfile = "The uploaded file format is unsupported. Please choose a different file to upload"
exports.capitalGainOtherEndPoint = "bx_block_multipageforms2/capital_gains_others"
exports.getCapitalGainOtherEndPoint = "bx_block_multipageforms2/capital_gains_others";
exports.tableHeading=["S.No","Name","DOP","Purchase Cost","DOS","Sale Price","Capital gains","Actions"]
exports.capitalGainLandBuildingsEndPoint = "bx_block_multipageforms2/capital_gain_land_buildings"
exports.costImprovementDeleteEndPoint = "bx_block_multipageforms2/cost_improvement_details"
exports.buyerDetailsDeleteEndPoint = "bx_block_multipageforms2/buyer_details"
exports.createManualEndPoint = "bx_block_multipageforms2/sale_of_share_and_securities/create_manual"
exports.SaleofShareAndSecuritiesEndPoint = "bx_block_multipageforms2/sale_of_share_and_securities"
exports.fourthformHouseEditText = "In case this property is jointly owned, please specify co-owners"
exports.addMOreItems = "Add More Items"
exports.deleteHouseProperty = "bx_block_multipageforms2/house_properties/"
exports.BusinessCodeEndPoint = "bx_block_multipageforms2/regular_business_incomes/buissness_code"
exports.SmallBusinessesEndPoint = "bx_block_multipageforms2/small_businesses"
exports.checkDraftStatus="bx_block_multipageforms2/house_properties/draft_data?property_type="
exports.landBuildingTableEndPoint = "bx_block_multipageforms2/capital_gain_land_buildings"
exports.errorPanmessage="All fields are required/Enter correct Pan/ Percentage must be between 1 to 100"
exports.datasucessMessage="Data saved successfully"
exports.IncomeDetailsText="Income Details"
exports.AnnualRentReceivedbyyou="Annual Rent Received by you"
exports.helperfirstRent="Please specify the portion of the rent received by you if the property is co-owned."
exports.MunicipalfirstRentTax="Municipal tax"
exports.helperfirstRentTax="Specifying House tax you paid reduces your tax liability."
exports.firstRentIntInterest="Interest paid on loan for this house property"
exports.selfNameCoowner="Name of Co-owner"
exports.selfPanCowner="PAN of Co-owner"
exports.selfPercentageShare="Percentage Share"
exports.selfSameAdress="Same as the address in Personal Info"
exports.btnSaveDraft="Save as Draft"
exports.manualGetDataEndPoint="bx_block_multipageforms2/capital_gains_digital_assets"
exports.noDataCOntext="No Data"
exports.reImports="Re - Import"
exports.editMessage="Edit"
exports.apiTypeUpdate="UPDATE"
exports.doDeleteById="bx_block_multipageforms2/capital_gain_asset_statements/delete_attachment/"
exports.finalEndPoint="capital_gain_asset_statement"
exports.finalAssetsEndpoint="capital_gain_asset_statements/"
exports.finalStatmentEndPoint="capital_gain_digital_asset"
exports.finalStatmentEndPointType="capital_gains_digital_assets/"
exports.newStatment="capital_gains_digital_assets"
exports.disiblity=["Disability Serious","Disability Not Serious"]
exports.listEndPoints="bx_block_multipageforms2/deductions/list_donation?donation_type="
exports.panUserProfile = "Exempt income already exists for this Pan User Profile";
exports.errorTypeForError = "error";
exports.savedSuccessMessage = "Saved Succesfully";
exports.errorTypeSuccess = "success";
exports.xyz="Testing purpose";
exports.salaryFinalAmountGreaterError = "Exemptions and Deductions added are more than Gross Salary"
exports.snackBarSubmitMsg = "Submitted Successfully";
exports.deleteTanentApi="bx_block_multipageforms2/house_properties";
exports.deleteCoOwnerApi="bx_block_multipageforms2/house_properties/delete_coowner_detail";
exports.getFNOApiEndPoint="bx_block_multipageforms2/futureoption_intradays/get_future_option"
exports.updateFNOApiEndPoint="bx_block_multipageforms2/futureoption_intradays/update_future_option/"
exports.deleteFNOApiEndPoint="bx_block_multipageforms2/futureoption_intradays/destroy_dropdown_item/"
exports.getIntradayApiEndPoint="bx_block_multipageforms2/futureoption_intradays/get_intraday"
exports.updateIntraApiEndPoint="bx_block_multipageforms2/futureoption_intradays/update_intraday/"
exports.getSmallBApiEndPoint="bx_block_multipageforms2/small_businesses/find_small_buissness"
exports.panCardEndpoint="account_block/pan_profiles"
// Customizable Area End