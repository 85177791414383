export const GirlCoin= require("../assets/Girlcoin.png");
export const GirlDashboard= require("../assets/GirlDashboard.png");
export const GirlStatistic= require("../assets/Girlstatistic.png");
export const Background= require("../assets/Background.png");
export const Laptop= require("../assets/Laptop.png");
export const leftInfoImage= require("../assets/leftInfoImage.png");
export const rightInfoImg= require("../assets/rightInfoImg.png");
export const PigiBank= require("../assets/pigi-bank.png");
export const VideoBackground=require("../assets/videoBackground.png");
export const KnowladgeVideoImage=require("../assets/knowladge_video_image.png");
export const rupeeIcon=require("../assets/rupeeIcon.png");
export const edit = require("../assets/edit.png");
export const downarrow = require("../assets/downarrow.svg");
