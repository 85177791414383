import React from "react";

import {
    Box,
    Button,
    Card,
    Container,
    styled,
    Typography,
    Grid
} from "@material-ui/core";
import { LeftArrow, downArrow } from "./assets"

import CapitalGainLangingPageController, {
    Props,
} from "./CapitalGainLangingPageController.web";
import { CustomTextfield } from "../../../components/src/CustomTextfield.web";
import { CustomAutocomplete } from "../../../components/src/StyledMuiComponent";


export const ReturnGrid: any = (props: any) => {
    const { title, name, value, handleGetShareAndScurity, errormessage, type, options } = props;
    console.log("typetypetypetype", type);

    return (
        <Grid container className="grid_container">
            <Grid className="align_flex" item lg={4} sm={12} md={4}>
                <Typography className="font_style-1">{title}</Typography>
            </Grid>
            <Grid item lg={8} sm={12} md={8}>
                {type === "text" ? <Box className="AMS_textfield_box">
                    <Box className="AMS_ruppee">
                        <span>₹</span>
                    </Box>
                    <CustomTextfield
                        data-testid="test_GridTextField"
                        className="form_textfield"
                        variant="outlined"
                        placeholder="00"
                        name={name}
                        value={value}
                        onChange={handleGetShareAndScurity}
                    />
                </Box> : <Box className="AMS_autocomplete_box">
                    <CustomAutocomplete
                        renderInput={(params: any) => <CustomTextfield
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder="Select"
                            name={name}
                        />}
                        value={value}
                        onChange={(e: any, value: any) => {
                            handleGetShareAndScurity(value, name, options)
                        }}
                        options={options}
                        fullWidth
                    />
                    <img className="AMS_downArrow_img" src={downArrow} />
                </Box>}
                {errormessage && <Box className="error_message">{errormessage}</Box>}
            </Grid>
        </Grid>
    )
}
export default class AddMoreSalesOfShare extends CapitalGainLangingPageController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const {
            handleGoToTable,
            rowId, shareAndScurity,
            handleGetShareAndScurity,
            shareAndScurityError,
            handleValidateShareAndScurityForm,
            hanleGetShareAndScurityTableData,
            handleGetShareAndScurityDropDown
        } = this.props;

        return (
            <MainWrapper maxWidth={false}>
                <Box className="ls_main">
                    <Box className="ls_main-title">
                        <img
                            data-testid="back_btn"
                            src={LeftArrow}
                            alt="back_btn"
                            onClick={() => {
                                handleGoToTable()
                                hanleGetShareAndScurityTableData()
                            }}
                        />
                        <Typography className="ls_body_title-text">Add gains form Sale of Shares & Securities</Typography>
                    </Box>
                    <Card className="card">
                        <Grid container className="grid_container">
                            <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                <Typography className="font_style-1">Security Name</Typography>
                            </Grid>
                            <Grid item lg={8} sm={12} md={8}>
                                <Box className="AMS_security_name-container">
                                    <CustomTextfield
                                        variant="outlined"
                                        placeholder="Enter"
                                        fullWidth
                                        name="security_name"
                                        value={shareAndScurity.security_name}
                                        onChange={handleGetShareAndScurity}
                                    />
                                    {shareAndScurityError.security_name && <Box className="error_message">{shareAndScurityError.security_name}</Box>}
                                </Box>
                            </Grid>
                        </Grid>
                        <ReturnGrid
                            title="Purchase Cost"
                            name="purchase_cost"
                            value={shareAndScurity.purchase_cost}
                            handleGetShareAndScurity={handleGetShareAndScurity}
                            errormessage={shareAndScurityError.purchase_cost}
                            type="text"
                        />
                        <ReturnGrid
                            title="FMV/Indexed Cost"
                            name="fmv_indexed_cost"
                            value={shareAndScurity.fmv_indexed_cost}
                            handleGetShareAndScurity={handleGetShareAndScurity}
                            errormessage={shareAndScurityError.fmv_indexed_cost}
                            type="text"
                        />
                        <ReturnGrid
                            title="Sale Consideration"
                            name="sale_consideration"
                            value={shareAndScurity.sale_consideration}
                            handleGetShareAndScurity={handleGetShareAndScurity}
                            errormessage={shareAndScurityError.sale_consideration}
                            type="text"
                        />
                        <ReturnGrid
                            title="Capital Gain"
                            name="capital_gain_data"
                            value={shareAndScurity.capital_gain_data}
                            handleGetShareAndScurity={handleGetShareAndScurity}
                            errormessage={shareAndScurityError.capital_gain_data}
                            type="text"
                        />
                        <ReturnGrid
                            title="Short/Long"
                            name="short_long"
                            value={shareAndScurity.short_long}
                            handleGetShareAndScurity={handleGetShareAndScurityDropDown}
                            errormessage={shareAndScurityError.short_long}
                            type="dropDown"
                            options={["Short", "Long"]}
                        />
                        <ReturnGrid
                            title="Debt/Equity"
                            name="debt_equity"
                            value={shareAndScurity.debt_equity}
                            handleGetShareAndScurity={handleGetShareAndScurityDropDown}
                            errormessage={shareAndScurityError.debt_equity}
                            type="dropDown"
                            options={["Debt", "Equity"]}
                        />
                        <ReturnGrid
                            title="Shares/Mutual Funds"
                            name="share_mutual_funds"
                            value={shareAndScurity.share_mutual_funds}
                            handleGetShareAndScurity={handleGetShareAndScurityDropDown}
                            errormessage={shareAndScurityError.share_mutual_funds}
                            type="dropDown"
                            options={["Shares", "Mutual Funds"]}
                        />
                        <Button
                            data-testid="save_form"
                            className="btn btn_active"
                            variant="outlined"
                            onClick={() => handleValidateShareAndScurityForm(rowId)}
                        >
                            Save
                        </Button>
                    </Card>
                </Box>
            </MainWrapper>
        );
    }
}

const MainWrapper = styled(Container)({
    width: "100%",
    "& .grid_container": {
        width: "90%",
        marginBottom: "35px"
    },

    "& .align_flex": {
        display: "flex",
        alignItems: "center",
        marginBottom: "0px"
    },

    "& .font_style-1": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600
    },

    "& .AMS_textfield_error_container": {
        display: "flex",
        maxWidth: "260px",
        position: "relative",
        flexDirection: "column"
    },
    "& .AMS_textfield_box": {
        display: "flex",
        position: "relative"
    },
    "& .AMS_ruppee": {
        width: "54px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#DEF5EA",
        zIndex: 1,
        marginRight: "-6px",
        flexShrink: 0
    },
    "& .error_message": {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    },
    "& .card": {
        padding: "35px",
        borderRadius: "16px"
    },
    "& .btn": {
        width: "200px",
        height: "50px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#858585",
        marginTop: "20px"
    },
    "& .btn_active": {
        backgroundColor: "#337152",
        color: "#ffffff !important"
    },
    "& .ls_main": {
        borderRadius: "16px",
        backgroundColor: "#F4F6F5",
        padding: "30px",
    },
    "& .ls_main-title": {
        display: "flex",
        marginBottom: "30px",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .ls_body_title-text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .AMS_autocomplete_box": {
        position: "relative",
        maxWidth: "310px"
    },
    "& .AMS_downArrow_img": {
        position: "absolute",
        right: "15px",
        bottom: "20px",
        zIndex: 1
    },
    "& .AMS_security_name-container": {
        maxWidth: "310px"
    }
});
