import React from "react";
import {
    // Customizable Area Start
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BusinessIncomeController, { Props, configJSON } from "./BusinessIncomeController.web";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/core/styles";
import { futuresandoptions, intraday } from "./assets";
// Customizable Area End

export class DailogFuturesIntraday extends BusinessIncomeController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props;
        return (
            <>
                <Dialog
                    data-test-id='dialog_Business'
                    maxWidth='md'
                    fullWidth
                    open={this.props.open}
                    onClose={this.props.handleopenfuturesOrIntraday}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                        }
                    }}
                    disableScrollLock={true}
                >
                    <DialogTitle className={classes.dialogTitle} >
                        {configJSON.housePropertyModalTitle}
                        <CloseIcon
                            data-test-id='closeDialog'
                            className={classes.closeBtn}
                            onClick={this.props.handleopenfuturesOrIntraday}
                        />
                    </DialogTitle>
                    <DialogContent className={classes.DailogBox}>
                        <Box className={classes.dialogImgBox} data-test-id='selfProperty' onClick={this.props.handleSendData.bind(this, configJSON.futuresProps)}>
                            <Box className={classes.imageStyle}>
                                <img src={futuresandoptions} className={classes.imagewidth} />
                            </Box>
                            <Typography className={classes.dialogText} style={{ textAlign: 'center' }} > {configJSON.Futures} </Typography>
                        </Box>
                        <Box className={classes.dialogImgBox} data-test-id='rentalProperty' onClick={this.props.handleSendData.bind(this, configJSON.intradayProps)} >
                            <Box className={classes.imageStyle} >
                                <img src={intraday} className={classes.imagewidth}/>
                            </Box>
                            <Typography className={classes.dialogText} style={{ textAlign: 'center' }}>{configJSON.INTRADAY} </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}
export const styles = () => ({
    imagewidth: {
        maxWidth: "100%",
        maxHeight:"100%",
        width:"70%",
        height:"60%"
    },
    DailogBox: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '15px 25px',
        width: '51rem',
        gap:'20px'
    },
    imageStyle: {
        padding: '20px 40px',
        borderRadius: 16,
        background: '#FFF',
    },
    closeBtn: {
        color: "#337152",
        position: "absolute" as const,
        top: 20,
        right: 15
    },
    dialogText: {
        width: '99%',
        paddingTop: 20,
        fontFamily: 'Nunito',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center'
    },
    dialogTitle: {
        position: 'relative' as const,
        background: '#DEF5EA',
        display: "inline-block",
    },
    dialogImgBox: {
        padding: 10,
        borderRadius: 16,
        border: '1px solid #E0E0E0',
        background: ' linear-gradient(0deg, #F3F5F4 0%, #F3F5F4 100%), #F3F5F4',
        '&:hover': {
            background: 'rgba(222, 245, 234, 0.50)',
            border: ' 1.5px solid #337152',
        },
        cursor:"pointer"
    }
})
export default withStyles(styles)(DailogFuturesIntraday);
// Customizable Area End
