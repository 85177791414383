import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData} from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    panDetails: {
        [key: string]: string;
    };
    panDetailsError: {
        [key: string]: string;
    };
    panOtp: string;
    panOtpError: string;
    isOtpVerified: boolean;
    panOtpOpen: boolean;
    second: number;
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DocumentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    timer: number | ReturnType<typeof setInterval> = 0;
    pancardpostprofile:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];
        this.state = {
            // Customizable Area Start
            panDetails: {
                name: "",
                panNo: ""
            },
            panDetailsError: {
                name: "",
                panNo: ""
            },
            panOtpOpen: false,
            panOtp: "",
            panOtpError: "",
            isOtpVerified: false,
            second: 59,
            toast: {
                type: "success",
                open: false,
                message: "",
              },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    componentWillUnmount(): Promise<void> {
        return new Promise((resolve) => {
            clearInterval(this.timer)
            resolve()
        })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
    
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (this.pancardpostprofile === responseData) {
            if (successMessage.message == "Pan already exists") {
                this.setState({
                    isOtpVerified: false,
                    panOtpOpen:false,
                    panOtp:"",
                    toast:{
                        message:"PAN already exists",
                        open:true,
                        type:"error"
                    }
                })
            }
            else(
                this.setState({isOtpVerified:true})
            )
        }
        // Customizable Area End
      }

    handlePanBackarrow = () => {
        this.props.navigation.navigate(configJSON.FileIncomeTax)
    }

    handlePanChange = (e: { target: { name: string, value: string } }) => {
        const { name, value } = e.target;
        if (name === "name") {
            let val = e.target.value.replace(/^\s+/, "");
            val = val.replace(/\s{2,}/g, ' ');
            if (!/^[a-zA-Z ]*$/.test(e.target.value) || e.target.value.length > 35) {
                return;
            }
            this.setState({
              panDetails: { ...this.state.panDetails, [e.target.name]: val },
              panDetailsError: { ...this.state.panDetailsError, [e.target.name]: "" }
            })
        } else {
            this.setState({
                panDetails: { ...this.state.panDetails, [name]: value },
                panDetailsError: { ...this.state.panDetailsError, [name]: "" }
            });
        }
    }
    
    

    handlePanOtpChange = (e: string) => {
        this.setState({ panOtp: e });
    }

    handleTostClose = () => {
        this.setState({
          toast: {
              open: false,
              message: this.state.toast.message,
              type: this.state.toast.type,
          },
          });
  }

    closePanModel = (_event?: {}, reason?: "escapeKeyDown" | "backdropClick") => {
        if (reason && reason === "backdropClick") {
            this.setState({panOtp:""})
        }
        else if (reason === "escapeKeyDown") {
            this.setState({
              panOtpOpen: false,
              panOtp:""
            });
            clearInterval(this.timer);
          }
        else{
            this.setState({
                panOtpOpen: false
            })
            clearInterval(this.timer)
            this.props.navigation.history.push("Menu/Dashboard")
        }
    }

    handleVerifyPanOtp = () => {
        this.PanCardAddPost()
    }

    handlePanResendOtp = () => {
        this.setState({ second: 60 })
    }
handlepanValid=(pan:string)=>{
    let regex = /^[A-Z]{5}\d{4}[A-Z]$/;
    if (regex.test(pan.toUpperCase())) {
        return true;
    } 
    else{
       return false
    }
}
    handlePanDetailsSubmit = () => {
    let main= this.handlepanValid(this.state.panDetails.panNo)
        const error = {
            panNo: main ? "" : "Incorrect PAN",
            name: this.state.panDetails.name.length >= 2 ? "" : "Enter valid name"
        };
        this.setState({ panDetailsError: error })
        let temp = [];
        for (const [key, value] of Object.entries(error)) {
            key && temp.push(value === "");
        }
        if (!temp.includes(false)) {
            this.setState({ second: 60, panOtpOpen: true })
            this.timer = setInterval(() => {
                if (this.state.second > 0) {
                    this.setState({ second: this.state.second - 1 });
                }
            }, 1000);
        }
    }

      ///pancard post details ///
  PanCardAddPost = async () => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardpostprofile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panCardEndpoint}?pan_number=${this.state.panDetails.panNo}&name=${this.state.panDetails.name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///pancard post details///
    // Customizable Area End
}
