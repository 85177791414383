import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    withStyles,
    Dialog, DialogTitle, DialogContent,
    Typography,
    styled
}
    from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import OtherIncomesController, { Props, configJSON } from "./OtherIncomesController.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import { dividend, exemptIncome, interest, otherIncome, rent, } from "./assets";
import OtherInterest from "./OtherInterest.web";
import OtherDividend from "./OtherDividend.web";
import OtherExemptIncomes from "./OtherExemptIncomes.web";
import OtherRent from "./OtherRent.web";
import OtherInc from "./OtherInc.web";



export class OtherIncomes extends OtherIncomesController {
    constructor(props: Props) {
        super(props);
    }

    commonCard(classes: any, title: any, subtitle: any, amount: any, banner: any) {
        return (
            <MainWrapper>
                <Box className={`${classes.mainCard} mainCard`} style={{ borderRadius: 16 }} >
                    <div style={{
                        borderRadius: 12,
                        background: '#F4F6F5',
                        padding: '0px 45px'

                    }}>
                        <img src={banner} style={{ width: 160 }} />
                    </div>
                    <Box className={classes.textDiv}>
                        <Typography className={classes.heading11}>{title}</Typography>
                        <Typography className={classes.subLine}> {subtitle} </Typography>
                    </Box>
                    <div className={`${classes.expandImg} expandImg`}>
                        <Typography>₹ {amount} </Typography>
                    </div>
                </Box>
            </MainWrapper>
        )
    }

    render() {

        const { classes } = this.props
        console.log('otherIncome')

        return (
            <>
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                <Container style={{ borderRadius: 16, background: '#F4F6F5' }}>
                    <Box style={{ paddingBottom: 20 }}>
                        <Box className={classes.headerBox1}>
                            <Typography className={classes.housePropertyHdr1}  data-test-id="otherIncomeId"> Other Source of Income </Typography>
                            <Typography>Fixed deposits, Savings Bank Interest, Agriculture, Online Gaming and any Other Income Sources</Typography>

                        </Box>
                        <div onClick={() => { this.setState({ intDialog: true }) }} data-test-id='int-pop'>
                            {this.commonCard(classes, configJSON.intIncome, configJSON.intIncomeSub, this.state.totalInterestIncome.toLocaleString(), interest)}
                        </div>
                        <div data-test-id='div-pop' onClick={() => { this.setState({ dividendDialog: true }) }}>
                            {this.commonCard(classes, configJSON.dividend, configJSON.dividendSub, this.state.totalDivident.toLocaleString(), dividend)}
                        </div>
                        <div data-test-id='rent-pop' onClick={() => { this.setState({ rentDialog: true }) }}>
                            {this.commonCard(classes, configJSON.rent, configJSON.rentSub, this.state.totalRentIncome.toLocaleString(), rent)}
                        </div>
                        <div data-test-id='inc-pop' onClick={() => this.setState({ otherDialog: true })}>
                            {this.commonCard(classes, configJSON.otherIncome, configJSON.otherIncomeSub,  this.state.totalOtherIncome.toLocaleString(), otherIncome)}
                        </div>
                        <div data-test-id='exe-pop' onClick={() => { this.setState({ exemptDialog: true }) }}>
                            {this.commonCard(classes, configJSON.exemptIncome, configJSON.exemptincomeSub, this.state.totalExemptIncome.toLocaleString(), exemptIncome)}
                        </div>





                    </Box>
                </Container>

                <Dialog
                    data-test-id='dialog'
                    maxWidth='md'
                    fullWidth
                    open={this.state.intDialog}
                    onClose={() => this.setState({ intDialog: false })}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                        }
                    }}
                    disableScrollLock={true}
                >
                    <DialogTitle className={classes.dialogTitle1} >
                        <Typography className={classes.dialogTitleText}>Interest income</Typography>
                        <CloseIcon
                            data-test-id='intClose'
                            className={classes.closeBtn1}
                            onClick={() => this.setState({ intDialog: false })}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: '40rem', padding: 10 }}
                    >
                        <OtherInterest navigation={this.props.navigation} id={""} addLandFn={this.addLandFn} closeDialog={this.closeIntDialog.bind(this)} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    data-test-id='dialog'
                    maxWidth='md'
                    fullWidth
                    open={this.state.dividendDialog}
                    onClose={() => this.setState({ dividendDialog: false })}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                        }
                    }}
                    disableScrollLock={true}
                >
                    <DialogTitle className={classes.dialogTitle1} >
                        <Typography className={classes.dialogTitleText}>Dividend</Typography>
                        <CloseIcon
                            data-test-id='divClose'
                            className={classes.closeBtn1}
                            onClick={() => this.setState({ dividendDialog: false })}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: '40rem', padding: 10 }}
                    >
                        <OtherDividend navigation={this.props.navigation} id={""} addLandFn={this.addLandFn} closeDialog={this.closeDevDialog.bind(this)}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    data-test-id='dialog'
                    maxWidth='md'
                    fullWidth
                    open={this.state.exemptDialog}
                    onClose={() => this.setState({ exemptDialog: false })}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px", position: 'relative'
                        }
                    }}
                    disableScrollLock={true}
                >


                    <DialogContent style={{ width: '40rem', padding: 10, overflowX: 'hidden' }}
                    >
                        <OtherExemptIncomes navigation={this.props.navigation} id={""} addLandFn={this.addLandFn}  closeDialog={this.closeExemptDialog.bind(this)} />
                    </DialogContent>
                </Dialog>




                <Dialog
                    data-test-id='dialog'
                    maxWidth='md'
                    fullWidth
                    open={this.state.rentDialog}
                    onClose={() => this.setState({ rentDialog: false })}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                        }
                    }}
                    disableScrollLock={true}
                >
                    <DialogTitle className={classes.dialogTitle1} >
                        <Typography className={classes.dialogTitleText}>Rent</Typography>
                        <CloseIcon
                            data-test-id='rentClose'
                            className={classes.closeBtn1}
                            onClick={() => this.setState({ rentDialog: false })}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: '40rem', padding: 10 }}
                    >
                        <OtherRent data-test-id='rentClick' navigation={this.props.navigation} id={""} addLandFn={()=>{this.getOtherRent();this.setState({rentDialog:false});}} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    data-test-id='dialog'
                    maxWidth='md'
                    fullWidth
                    open={this.state.otherDialog}
                    onClose={() => this.setState({ otherDialog: false })}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                        }
                    }}
                    disableScrollLock={true}
                >
                    <DialogTitle className={classes.dialogTitle1} >
                        <Typography className={classes.dialogTitleText}>Other Income</Typography>
                        <CloseIcon
                            data-test-id='otherClose'
                            className={classes.closeBtn1}
                            onClick={() => this.setState({ otherDialog: false })}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: '40rem', padding: 10 }}
                    >
                        <OtherInc navigation={this.props.navigation} id={""} addLandFn={this.addLandFn} closeDialog={this.closeOtherIncDialog.bind(this)}/>
                    </DialogContent>
                </Dialog>




            </>
        )
    }
}


const MainWrapper = styled(Container)({
"& .mainCard": {
    "@media(max-width: 768px)": {
        flexDirection: 'column'
    }
},
"& .expandImg": {
    "@media(max-width: 768px)": {
        position: 'unset'
    }
}
})
const styles = {

    housePropertyHdr1: {
        fontSize: 27,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: 'Nunito',
        color: '#36403B',
    },
    checkField: {
        display: 'flex',
        aligItems: 'center',
        marginLeft: 'auto',
        marginRight: '7rem',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    mainCard: {
        display: 'flex',
        background: '#FFF',
        margin: 10,
        padding: 10,
        position: 'relative' as const
    },
    input: {
        border: ' 0.5px solid #C3CAD9',
        borderRadius: 8,
        height: 45
    },
    headerBox1: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column' as const,
        padding: '40px 25px',
    },
    heading11: {
        color: '#36403B',
        fontStyle: 'normal',
        fontSize: 18,
        fontFamily: 'Nunito',
        fontWeight: 700
    },
    ruppeslogo: {
        padding: '1em 1.7em 1em 1em',
        background: "#ddfef7",
        borderRadius: "8px 0px 0px 8px",
        height: '42px',
        alignItems: "center",
        display: "flex",
    },
    textfiled: {

        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        "& input": {
            border: "1px solid #grey",
            padding: "11px 0px 11px 12px",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: "0px 8px 8px 0px",
                borderLeft: 'none',
            },
        },
    },
    amount: {
        display: "flex",
    },

    rupeessstyles: {
        fontSize: "14px",
        color: "rgba(54,64,59,0.9)",
        fontFamily: "Nunito",
        fontWeight: 400,
    },
    radioGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '20rem',
        marginLeft: '5rem'
    },
    labelHeading1: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontSize: 18,
        fontWeight: 600
    },
    descField: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },
    subLine: {
        fontSize: 16,
        fontFamily: 'Nunito',
        color: 'rgba(54, 64, 59, 0.75)',
        fontWeight: 400,
        fontStyle: 'normal',
        width : '80%'
    },
    expandImg: {
        background: '#DAF0E5',
        position: 'absolute' as const,
        right: '2%',
        top: '40%',
        padding: '10 20',
        borderRadius: 10
    },

    closeBtn1: {
        position: "absolute" as const,
        color: "#337152",
        top: 20,
        right: 15
    },
    heading22: {
        fontStyle: 'normal',
        fontSize: 24,
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#36403B',
    },
    textDiv: {
        flexDirection: 'column' as const,
        width: '100%',
        justifyContent: 'center',
        paddingLeft: 10,
        display: 'flex',
    },
    dialogTitleText: {
        fontSize: 27,
        color: '#36403B',
        fontFamily: 'Nunito',
        fontWeight: 700,
        fontStyle: 'normal',
    },
    dialogTitle1: {
        position: 'relative' as const,
        display: "inline-block",
    },
}


export default withStyles(styles)(OtherIncomes);
// Customizable Area End