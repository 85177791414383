import React from "react";
// Customizable Area Start
import SideMenuBarController, { Props } from "./SideMenuBarController.web";
import { styled } from "@material-ui/styles";
import {
  Container,
  Grid,
  Box,
  Drawer,
  CssBaseline,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Modal,
  Button,
  TextField
} from "@material-ui/core";

//@ts-ignore
import { ReactComponent as PocketTax } from "../assets/PocketTax.svg";
//@ts-ignore
import { ReactComponent as Candlestick } from "../assets/candlestick_chart.svg";
//@ts-ignore
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
//@ts-ignore
import { ReactComponent as Storefront } from "../assets/storefront.svg";
//@ts-ignore
import { ReactComponent as ContactEmergency } from "../assets/contact_emergency.svg";
//@ts-ignore
import { ReactComponent as CorporateFare } from "../assets/corporate_fare.svg";
//@ts-ignore
import { ReactComponent as Home } from "../assets/home.svg";
//@ts-ignore
import { ReactComponent as Work } from "../assets/work.svg";
//@ts-ignore
import { ReactComponent as Graph } from "../assets/graph.svg";
//@ts-ignore
import { ReactComponent as Documentupload } from "../assets/documentupload.svg";
//@ts-ignore
import { ReactComponent as TaxSavingTipsImg } from "../assets/taxSavingTips.svg";
import UploadDocuments from "../../documentstorage/src/UploadDocuments.web";
import Dashboard from "../../dashboard/src/Dashboard.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import Salary from "../../multipageforms2/src/SalaryNavigation.web";
import HouseProperty from "../../multipageforms2/src/HouseProperty.web";
import Deductions from "../../multipageforms2/src/Deductions.web";
import OtherIncomes from "../../multipageforms2/src/OtherIncomes.web";
import BusinessIncome from "../../multipageforms2/src/BusinessIncome.web";
import StaticLandBuilding from "../../multipageforms2/src/StaticLandBuilding.web";
import ShareSecurityBondsWeb from "../../multipageforms2/src/ShareSecurityBonds.web";
import GeneralInformation from "../../multipageforms2/src/GeneralInformation.web";
import BusinessProfessionalIncome from "../../multipageforms2/src/BusinessProfessionalIncome.web";
import IncomeForBusiness from "../../multipageforms2/src/IncomeForBusiness.web";
import CapitalGainLangingPage from "../../multipageforms2/src/CapitalGainLangingPage.web";
import ListedShares from "../../multipageforms2/src/ListedShares.web";
import OtherCapitalAssets from "../../multipageforms2/src/OtherCapitalAssets.web";
import CryptoVirtualDigitalAssets from "../../multipageforms2/src/CryptoVirtualDigitalAssets.web"
import CapitalGainBond from "../../multipageforms2/src/CapitalGainBond.web";
import SmallBusiness from "../../multipageforms2/src/SmallBusiness.web";
import LandBuilding from "../../multipageforms2/src/LandBuilding.web";
import ResidentialStatus from "../../assessmenttest/src/ResidentialStatus.web";
import TaxSavingTips from "../../cfsuggestionontaxsaving5/src/TaxSavingTips.web";

import { pockettaxprice } from "../../dashboard/src/assets";
import { CustomTextArea } from "../../../components/src/CustomTextfield.web";


// Customizable Area End

export default class SideMenuBar extends SideMenuBarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  returnelement = () => {
    const { classes }: any = this.props;
    switch (this.state.activeTab) {
      case "Dashboard":
        return (
          <Dashboard navigation={this.props.navigation} id={this.props.id} />
        );
      case "General Information":
        return <GeneralInformation
          navigation={this.props.navigation}
          id={this.props.id}
        />;
      case "Salary":
        return (
          <Salary
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes}
            step={0}
          />
        );
      case "House Property":
        return (
          <HouseProperty
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes} allStates={undefined} />
        );
      case "Business Income":
        return (
          <BusinessIncome
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes}
            open={false}
            handleopenfuturesOrIntraday={() => { }}
            handleSendData={() => ""}
            changeTab={this.changeTab}
          />
        );
      case "Businessincome/SmallBusiness":
        return (
          <SmallBusiness
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes}
            changeTab={this.changeTab} open={false}
            handleopenfuturesOrIntraday={() => { }}
            handleSendData={() => { }} />
        );
        case "Business Income/Income From Personal":
        return (
          <BusinessProfessionalIncome navigation={undefined} id={""} 
          changeTab={this.changeTab}
          />
        );
        case "Business Income/Income From Business":
        return (
          <IncomeForBusiness navigation={undefined} id={""} 
          changeTab={this.changeTab}
          />
        );
      case "Capital Gains":
        return (
          <CapitalGainLangingPage
            navigation={this.props.navigation}
            id={this.props.id}
            needHelp={this.state.needHelp}
            children={this.props.children}
            changeTab={this.changeTab}
            handleCloseNeedHelp={this.handleCloseNeedHelp}
          />
        );
      case "Capitalgains/ListedShares":
        return (
          <ListedShares
            navigation={this.props.navigation}
            id={this.props.id}
            children={this.props.children}
            changeTab={this.changeTab}
          />
        );
      case "Capitalgains/Bonds":
        return (
          <CapitalGainBond
            navigation={this.props.navigation}
            id={this.props.id}
            children={this.props.children}
            classes={classes}
            changeTab={this.changeTab}
          />
        );
      case "Capitalgains/OtherAssets":
        return (
          <OtherCapitalAssets
            navigation={this.props.navigation}
            id={this.props.id}
            children={this.props.children}
            changeTab={this.changeTab}
          />
        );
        case "Capitalgains/CryptoAssets":
          return (
            <CryptoVirtualDigitalAssets
              navigation={this.props.navigation}
              children={this.props.children}
              changeTab={this.changeTab}
            />
          );
      case "Capitalgains/LandOrBuilding":
        return (
          <LandBuilding
            navigation={undefined}
            id={""}
            classes={classes}
            changeTab={this.changeTab}
          />
        );
      case "Income From Other Sources":
        return (
          <OtherIncomes
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes}
            addLandFn={undefined}
          />
        );
      case "Documents":
        return (
          <UploadDocuments
            navigation={this.props.navigation}
            id={this.props.id}
          />
        );
      case "Analytical":
        return (<BusinessProfessionalIncome navigation={undefined} id={""} />);
      case "Tax Saving Tips":
        return(<TaxSavingTips navigation={this.props.navigation} id={""}/> )
          ;
      case "Deduction":
        return (
          <Deductions
            navigation={this.props.navigation}
            id={this.props.id}
            classes={classes}
          />
        );
        case "Residential Status":
          return (
            <ResidentialStatus
              navigation={this.props.navigation}
              id={this.props.id}
            />
          );
      case "Need help?":
        return <h1>Need help?</h1>;
    }
  };

  handleReturnErrorMessage = (message: string) => {
    return <>{message && <Box className="error_message">{message}</Box>}</>;
  };

  renderDrawer = () => {
    let getInitial = (name:string) => {
      return name ? name.charAt(0).toUpperCase() : '';
    };
    let firstName = this.state.name.split(" ")[0];
firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    return (
      <>
        <Hidden xsDown>
          <Box className="logoBox" style={{ margin: "2em 3em 0em 3em" }}>
            <PocketTax
              className="pocketLogo"
              style={{ margin: "auto", cursor: "pointer" }}
              onClick={this.navigateToLandingPage}
              data-test-id="image_logo"
            />
          </Box>
        </Hidden>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ height: "90%", paddingLeft: "10%" }}
          onMouseEnter={e => {
            e.currentTarget.style.overflow = "auto";
          }}
          onMouseLeave={e => {
            e.currentTarget.style.overflow = "hidden";
          }}
        >
          <List>
            {[
              { icon: <DashboardIcon />, name: "Dashboard" },
              { icon: <ContactEmergency />, name: "General Information" },
              { icon: <Work />, name: "Salary" },
              { icon: <Home />, name: "House Property" },
              { icon: <CorporateFare />, name: "Business Income" },
              { icon: <Candlestick />, name: "Capital Gains" },
              { icon: <Storefront />, name: "Income From Other Sources" },
              { icon: <Documentupload />, name: "Documents" },
              { icon: <Graph />, name: "Analytical" },
              { icon: <TaxSavingTipsImg />, name: "Tax Saving Tips" },
              { icon: <TaxSavingTipsImg />, name: "Deduction" },
              { icon: <TaxSavingTipsImg />, name: "Residential Status" }
            ].map(text => (
              <ListItem
                button
                key={text.name}
                className={
                  this.handlesetActiveTab(text)
                }
                data-test-id={text.name}
                onClick={() => this.changeTab(text.name)}
              >
                <ListItemIcon>{text.icon}</ListItemIcon>
                <Hidden xsDown>
                  <ListItemText primary={text.name} />
                </Hidden>
              </ListItem>
            ))}
          </List>
            <Hidden xsDown>
              <Box component="div" sx={{ width: "100%", height: "100%" }}>
                <Box
                data-test-id="helpmodal"
                  style={{
                    padding: "12px 0",
                    margin: "20px 0",
                    gap: "10px",
                    color: "white",
                    display:"flex",
                    width: "100%"
                  }}
                  onClick={this.handleopen}
                >
                  <HelpOutlineRoundedIcon />
                  <span style={{cursor:"pointer"}}>Need help?</span>
                </Box>
              </Box>
            </Hidden>
          {this.state.profileLogoutProfile && (
              <Box
                component="div"
                sx={{ width: "85%", height: "100px", marginBottom: "10px" }}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  display: "flex"
                }}
              >
                <div>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8em",
                      margin: "1em  0.3em 0 0",
                      cursor: "pointer"
                    }}
                    onClick={this.profilepage}
                  >
                    <PersonIcon />
                    <CustomeTypography style={{ marginLeft: "1em" }}>
                      My profile
                    </CustomeTypography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8em",
                      margin: "1em  0.3em 0 0",
                      cursor: "pointer"
                    }}
                    onClick={this.logoutpage}
                  >
                    <ExitToAppIcon />
                    <CustomeTypography style={{ marginLeft: "1em" }}>
                      Logout
                    </CustomeTypography>
                  </Box>
                </div>
              </Box>
          )}
          <div style={{ textAlign: "center", margin: "0 3em 1em 0em" , height:"1px", background:"rgba(189, 189, 189, 0.37)"}}>
            
          </div>
          <CustomeBox>
            
          {this.state.userData?.profile_picture ? (
              <img
                src={this.state.userData.profile_picture}
                alt="Profile"
                style={{
                  width: "48px",
                  height: "48px",
                  fill: "#A6E4C5",
                  borderRadius: "2em",
                  background: "#A6E4C5",
                  objectFit: 'cover',
                }}
              />
            ) : ( 
              <span className="sidebarspan">{getInitial(this.state.userData?.first_name)}</span>
            )}
            <Box>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  marginRight:"35px"
                }}
              >
        {this.state.name == ""
                  ? "Hi User"
                  : firstName}
              </Typography>
            </Box>
           
            <svg onClick={this.handleprofilelogout} fill="rgba(189, 189, 189, 0.37)" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              width="30px" height="30px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" >
              <g>
                <path d="M78.466,35.559L50.15,63.633L22.078,35.317c-0.777-0.785-2.044-0.789-2.828-0.012s-0.789,2.044-0.012,2.827L48.432,67.58
		c0.365,0.368,0.835,0.563,1.312,0.589c0.139,0.008,0.278-0.001,0.415-0.021c0.054,0.008,0.106,0.021,0.16,0.022
		c0.544,0.029,1.099-0.162,1.515-0.576l29.447-29.196c0.785-0.777,0.79-2.043,0.012-2.828S79.249,34.781,78.466,35.559z"/>
              </g>
            </svg>
          </CustomeBox>
        </Box>
      </>
    );
  };



  rendermodal = (temp:boolean=false) => {
    const { formData, errors, helpmodalstate } = this.state;
  
    if (helpmodalstate == "0" && !temp) {
      return (
        <Grid className="parentContainer">
          <Grid className="parentContainerheader">
            <span  style={{ position: 'absolute', right: '20px', top: '14px' }}>
              <svg data-test-id="button5" onClick={() => this.handleclose()} className="headersvg" fill="white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24">
                <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
              </svg>
            </span>
            <Typography className="headertextHeadingcolor"><HelpOutlineRoundedIcon className="headericon" /> Need Help </Typography>
          </Grid>
          <Grid item xs={12} md={5} className="imagewrapper">
            <img src={pockettaxprice} className="imageprice" />
          </Grid>
          <Grid item xs={12} md={6} className="imagepaper" style={{ marginTop: '40px' }}>
            <Box className="payment-button">
              <Button className="verify-now" variant="contained" data-test-id="button" onClick={this.handleincresestate}>Make an Inquiry</Button>
              <Button className="verify-later" variant="contained" >Knowledge base</Button>
            </Box>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid className="parentContainer2">
          <Grid className="parentContainerheader2">
            <span style={{ position: 'absolute', right: '20px', top: '14px' }}>
              <svg
                onClick={() => this.handleclose()}
                data-test-id="button6"
                className="headersvg"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
              </svg>
            </span>
            <Typography className="headertextHeadingcolor2">Make an enquiry</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item lg={6} sm={6} md={6} className="needhelpform" style={{ marginTop: "0px" }}>
              <Typography className="font_style-1 marginBottom_10 ">Name</Typography>
              <TextField
                className="form_textfield"
                data-testid="name"
                variant="outlined"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} className="needhelpform">
              <Typography className="font_style-1 marginBottom_10">Email</Typography>
              <TextField
                className="form_textfield"
                data-testid="email"
                variant="outlined"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={this.handleChange}
                error={!!errors.email}
                helperText={errors.email && this.handleReturnErrorMessage(errors.email)}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} className="needhelpform">
              <Typography className="font_style-1 marginBottom_10 querydiv">Query</Typography>
              
              <CustomTextArea
              data-testid="query"
                name="query"
                value={formData.query}
                onChange={this.handleChange}
                variant="outlined"
                className="form_textfield_query"
                placeholder="Write Something.."
                fullWidth
                multiline
                minRows={5}
              />
            </Grid>
            <Box className="payment-button">
              <Button
                className="verify-now2"
                variant="contained"
                data-test-id="button-loading"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    } 
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper disableGutters maxWidth={false}>
        <Grid container style={{ height: "100%" }}>
          <Grid item md={3} lg={2} xl={2} sm={2} xs={2}>
            <SideBar component="section">
              <CssBaseline />
              <Box component="nav" aria-label="mailbox folders">
                <Drawer variant="permanent" open>
                  {this.renderDrawer()}
                </Drawer>
              </Box>
            </SideBar>
          </Grid>
          <CustomeGrid
            item
            md={9}
            lg={10}
            xl={10}
            sm={8}
            xs={10}
          >
            {this.returnelement()}
          </CustomeGrid>
          <Modal open={this.state.helpmodal} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", border: "none" }}>
            <HelpModalBox>
              {this.rendermodal()}
            </HelpModalBox>
          </Modal>
        </Grid>
      </MainWrapper>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const MainWrapper = styled(Container)({
  width: "100%",
  fontFamily:"Nunito",
  "& .MuiTypography-body1":{
  fontFamily:"Nunito",
  }
});

const CustomeGrid = styled(Grid)({
  paddingLeft: "2.5em",
  "@media(max-width:767px)": {
    paddingLeft: "0",
  }
})
const CustomeTypography = styled(Typography)({
  display: "flex",
  "@media(max-width:767px)": {
    display: "none",
  }
})

const CustomeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "inherit",
  marginRight: "32px",
  color: "white",
  marginBottom: "2em",
  "@media(max-width:767px)": {
    flexDirection: 'column',
    marginRight: "0",
    marginBottom: "0",
  }
})
const SideBar = styled(Box)({
  "& .MuiDrawer-paper": {
    borderRight: "none",
    position: "unset",
    backgroundColor: "#347054",
    height: "auto",
    marginRight: "-2.5em",
    "@media(max-width:767px)": {
      marginRight: "0"
    }
  },
  "& .content": {
    flexGrow: 1
  },
  "& .MuiListItemIcon-root": {
    minWidth: "40px",
    justifyContent: "center"
  },
  "& .activeTab": {
    backgroundColor:"rgb(244, 246, 245)",
    borderRadius: "15px 0 0 15px",
    padding: "12px 5px",
    margin: "20px 0",
    " & .MuiTypography-body1" :{
      fontWeight:700,
    },
    "&::after": {

      content: '""',
      fontWeight:700,
      position: "absolute",
      top: "-40px",
      width: "40px",
      height: "40px",
      right: 0,
      borderBottomRightRadius: "40%",
      boxShadow: "0 20px 0 0 rgb(244, 246, 245)",
      "@media(max-width:600px)": {
        width: "20px"
      }
    },
    "&::before": {
      
      content: '""',
      position: "absolute",
      bottom: "-40px",
      width: "40px",
      height: "40px",
      right: 0,
      transform: "rotate(180deg)",
      borderBottomLeftRadius: "40%",
      boxShadow: "0 20px 0 0 rgb(244, 246, 245)",
      "@media(max-width:600px)": {
        width: "20px"
      }
    },
    "& svg": {
      fill: "black"
    }
  },
  "& .MuiListItemText-primary": {
    fontSize: "14px"
  },
  "& .listItem": {
    padding: "12px 0",
    margin: "20px 0",
    gap:"10px",
    color: "white",
    "& svg": {
      fill: "#fff"
    },
    width: "100%"
  },
  "& .logoBox ": {
    "@media(max-width: 768px)": {
      margin: '0 auto !important'
    },
    "& .pocketLogo": {
      "@media(max-width: 768px)": {
        maxWidth: '120px',
        margin: '0 auto !important'
      },
    }
  },
  "& .sidebarspan": {
    height: "50px",
    width: "50px",
    alignContent: "space-between",
    background: "#A6E4C5",
    color: "green",
    borderRadius: "50%",
    fontSize: "28px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    border: "1px solid white",
    alignItems:"center"
  }
});
const HelpModalBox = styled(Grid)({
  "& .parentContainer": {
    padding: '30px',
    paddingTop: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    height: "350px",
    width: "400px",
    flexDirection: "column",
    borderColor: "#c6c6c6",
    "& .MuiInputBase-input": {
      width: "300px",
      height: "5px",
      borderColor: "none"
    },
    "& .MuiGrid-grid-md-6": {
      maxWidth: "100%"
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "18px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      height: "48px",
      '& fieldset': {
        borderColor: '#c6c6c6',
      },
      '&:hover fieldset': {
        borderColor: '#c6c6c6',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c6c6c6',
      }
    }
  },
  "& .parentContainer2": {
    "& .MuiInputBase-input": {
      width: "300px",
      height: "5px",
      borderColor: "none"
    },
    width: "400px",
    padding: '30px',
    paddingTop: "0px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    height: "450px",
    display: "flex",
    flexDirection: "column",
    borderColor: "#c6c6c6",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      height: "48px",
      '& fieldset': {
        borderColor: '#c6c6c6',
      },
      '&:hover fieldset': {
        borderColor: '#c6c6c6',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c6c6c6',
      }
    },
    "& .MuiGrid-grid-md-6": {
      maxWidth: "100%"
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "18px",
    },
   
  },
  "& .needhelpform": {
    marginTop: "25px",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .marginBottom_10": {
    marginBottom: "5px"
  },
  "& .form_textfield_query":{
"& .MuiOutlinedInput-root":{
  height:"110px !important" 
}
  },
  "& .parentContainerheader": {
    background: "#337152",
    height: "250px",
    width: "400px",
    borderRadius: "10px 10px 0px 0px",
    alignContent: "center",
    padding: "10px",
    position: "relative"
  },
  "& .parentContainerheader2": {
    width: "400px",
    position: "relative",
    borderRadius: "10px 10px 0px 0px",
    alignContent: "center",
    height: "150px",
    padding: "10px",
    background: "white",
  },
  "& .headertextHeadingcolor": {
    color: "white",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    gap: "5px",
    alignItems: "center",
  },
  "& .headertextHeadingcolor2": {
    display: "flex",
    fontFamily: "Nunito",
    fontSize: "18px",
    textAlign: "left",
    justifyContent: "left",
    color: "black",
    alignItems: "left",
    gap: "5px",
    fontWeight: 500,
    marginLeft: "25px"
  },
  "& .headersvg": {
    position: "relative"
  },
  "& .imagewrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },
  "& .imageprice": {
    width: "200px",
    height: "200px",
  },
  "& .payment-button": {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "baseline",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
  },
  "& .verify-now": {
    width: "200px",
    background: "#337152",
    color: "#FFFFFF",
    "&:hover": {
      background: "#337152",
    },
    marginTop: "20px",
  },
  "& .verify-now2": {
    width: "100px",
    background: "#337152",
    color: "#FFFFFF",
    "&:hover": {
      background: "#337152",
    },
    marginTop: "20px",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
  },
  "& .verify-later": {
    width: "200px",
    background: "#FDDD8D",
    color: "black",
    "&:hover": {
      background: "#FDDD8D",
    },
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
  },
})

// Customizable Area End
