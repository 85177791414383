import {
    styled,
    Switch
  } from "@material-ui/core";

 export const ResimeSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "&.MuiSwitch-root": {
        width: "72px",
        height: "40px",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "white !important"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#347054",
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        opacity: '1 !important',
        backgroundColor: "#347054",
        '&::before': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
            left: 12,
        },
        '&::after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 20,
        height: 20,
        margin: 1
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(32px)"
    }
}));