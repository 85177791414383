import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Divider
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Link } from "react-router-dom";
import Toster from "../../../components/src/Toster";
import CustumCkeckBox from "../../../components/src/CustomCheckBox.web";
import ForgetPassword from "../../forgot-password/src/ForgetPassword.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./AccountLoginController.web";
import Google from "../../../components/src/GoogleLogin";
import { BrandLogo } from "./assets";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  communInputField = (name: string, label: string, placeholder: string, type: string) => {
    return (
      <Box component="div" className="fieldContainer">
        <Typography component="span" variant="caption" className="label">{label}</Typography>
        <br />
        <TextField
          data-test-id={`${name}-id`}
          className="inputbase"
          name={name}
          style={{
            boxSizing: "border-box",
            margin: 0,
          }}
          placeholder={placeholder}
          fullWidth
          value={this.state.formData[name]}
          margin="normal"
          type={type}
          variant="outlined"
          inputProps={{
            style: {
              height: "0.5rem",
              border: "1px solid white",
              color: "#fff",
            }
          }}
          onChange={(e: { target: { name: string, value: string } }) => this.handleChange(e, type)}
        />
        <Typography variant="body2" component="div" className="errors">
          {type === "email" ? this.state.emailError : this.state.passwordError}
        </Typography>
      </Box>
    );
  };

  renderCheckbox = () => {
    return (
      <>
        <CustumCkeckBox label='Keep me logged in' onChangeValue={this.handleCheckbox} isChecked={this.state.isChecked} color={"white"}  />
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <BackgroundWrapper disableGutters maxWidth={false} className="fullWidth">
        <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
        <ForgetPassword id="" navigation="" open={this.state.forgetOpen} handleClose={this.handleForgetClose} />
        <Grid container className="fullWidth">
          <Grid item lg={3} sm={1} xl={3} md={3}></Grid>
          <Grid item lg={4} sm={10} xl={4} md={5} xs={11} className="form">
            <Box component="div" sx={{ margin: "auto", width: "75%" }}>
              <Box component="div" display="flex" flexDirection="column">
                <Typography component="h1" variant="h4" className="pocketLogo"><img src={BrandLogo} onClick={this.navigateToDashboard} data-test-id="image" alt="pocket tax" style={{cursor:"pointer",width:"150px"}}/></Typography>
                <Typography component="h5" variant="h3" className="titleText">Welcome back!</Typography>
                <Typography variant="subtitle1" className="titlebody">Log in with your data that you entered during your registration.</Typography>
              </Box>
              <Box component="div">
                {this.communInputField("email", "Email", "Your email", "email")}
                {this.communInputField("password", "Password", "Your Password", "password")}
                {this.renderCheckbox()}
                <Button variant="contained"
                  className="loginBtn"
                  onClick={this.handleSubmit}
                  data-test-id="submit-id">Log In</Button>
                <br />
                <Typography data-test-id="forget-password-id" align="center" variant="body2" className="forgetPass" onClick={this.handleForgotPassword}>
                  Forgot Password?
                </Typography>
                <Box component="div" display="flex" justifyContent="center" alignItems="center" style={{ margin: '10px 0px' }}>
                  <Divider className="dividerLine" />
                  <Typography variant="body2" component='span' className="dividerText" onClick={this.handleForgotPassword}>
                    Or
                  </Typography>
                  <Divider className="dividerLine" />
                </Box>
                <Box component="div" display="flex" justifyContent="center" alignItems="center" className="google">
                  <Google handleGoogleLogin={this.handleGoogleLogin} />
                </Box>
                <Typography align="center" className="signUp">
                  Don't have an account? {" "}
                  <Link to="/Register" style={{ color: '#2ABC74' , textDecoration:"none"}}>
                    Sign Up
                  </Link>{" "}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={5} sm={1} xl={5} md={4} ></Grid>
        </Grid>
      </BackgroundWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const BackgroundWrapper = styled(Container)({
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline":{
        border: '1px solid white',
        borderRadius:"0"
      }
    }
  },
  fontFamily:"Nunito",
  backgroundImage: `url(${require("../assets/Tree.png")}), url(${require("../assets/TableGuy.png")}),url(${require("../assets/Background.png")})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "120px 200px, 270px 400px,100% 100%",
  overflowY: "hidden",
  backgroundPosition: "200px 90%, 75% 90%, center",
  "@media(max-width:1280px)": {
    backgroundPosition: "200px 90%, 90% 90%, center",
  },
  "@media(max-width:959px)": {
    backgroundImage: `url(${require("../assets/Background.png")})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  },
  "& .MuiOutlinedInput-input": {
    "@media(max-width:599px)": {
      padding: "14px"
    },
  },
  "& .pocketLogo": {
    margin: "25px 0 20px 0",
    cursor:"pointer",
    textAlign: "center",
    "@media(max-width:599px)": {
      margin: "20px 0",
    },
  },
  "& .form": {
    backgroundColor: "#1b222e", margin: "auto 0", borderRadius: "15px", maxHeight: "fit-content", height: "98%", overflowY: 'auto',scrollbarWidth:"thin",
    scrollbarColor:"#2b664b #f1f1f1",
    "@media(max-width:599px)": {
      margin: "auto"
    },
  },
  "& .fullWidth": {
    width: "100%",
    height: "100%"
  },
  "& .fieldContainer": {
    margin: "15px 0",
    "@media(max-width:599px)": {
      margin: "18px 0",
    },
  },
  "& .white": {
    color: "#fff",
  },
  "& .titleText": {
    color: "#fff",
    fontSize: "2.4rem",
    fontFamily:"Nunito",
    "@media(max-width:599px)": {
      fontSize: "2rem",
      fontFamily:"Nunito",
    },
  },
  "& .titlebody": {
    color: "#acafb3", margin: "15px 0 0 0", fontSize: "1.0rem",fontFamily:"Nunito",fontWeight:400,
    "@media(max-width:599px)": {
      margin: "15px 0 0 0", fontSize: "0.80rem",fontFamily:"Nunito",
    },
  },
  "& .loginBtn": {
    backgroundColor: '#2B664B',
    color: "#fff",
    width: "100%",
    margin: '10px 0px'
  },
  "& .inputbase": {
    paddingTop:"10px",
    paddingBottom:"2px",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      "& .MuiOutlinedInput-root": {
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline":{
            border: '1px solid white',
          }
        }
      }
    }
  },
  "& .label": {
    color: "white", fontSize: "1rem", margin: "5px 0",fontWeight:400,padding:"3px 0px",
    "@media(max-width:599px)": {
      fontSize: "0.80rem",fontFamily:"Nunito",
    },
  },
  "& .forgetPass": {
    color: '#2ABC74', cursor: 'pointer', margin: '10px 0px'
  },
  "& .dividerLine": {
    width: "30%", backgroundColor: "#acafb3"
  },
  "& .dividerText": {
    color: '#acafb3', margin: "5px 20px"
  },
  "& .google": {
    width: "80%", margin: "auto", height: "2rem", cursor: 'pointer'
  },
  "& .signUp": {
    color: '#cfd1d4', fontSize: "12px",fontFamily:"Nunito",
    paddingBottom:"10px",
    marginTop: "13px",
    "@media(max-width:599px)": {
      padding: '8px 0px 20px 0px',
      fontFamily:"Nunito",
    },
  },
  "& .errors": {
    color: "red",
    fontSize: "0.75rem",fontFamily:"Nunito",
    marginTop: "0.2rem"
  },
});
    // Customizable Area End
