import React from "react";

// Customizable Area Start
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          style:{
            maxHeight:"70%"
          }
        }}
        disableScrollLock={true}
      >
        <DialogTitle id="alert-dialog-title">
          {"Terms & Conditions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p dangerouslySetInnerHTML={{__html:this.props.description}}/>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End