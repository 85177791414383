import React from "react";
// Customizable Area Start
import { Container, Box, withStyles } from "@material-ui/core";
import BackgroundHeaderWeb from "../../../components/src/BackgroundHeader.web";
import TransactionITR from "../../../components/src/TransactionITR.web";
import TransactionITRHeader from "../../../components/src/TransactionITRHeader.web";
import styles from './styles.web'
import ITRHistoryController, {
  Props,
  configJSON
} from "./ITRHistoryController.web";

export class ITRHistory extends ITRHistoryController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes }= this.props;
    const arr = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <Container maxWidth={false} className={classes.defaltValues}>
        <BackgroundHeaderWeb
          title={configJSON.itrHistory}
          data-test-id="navigateToUserProfile"
          onClick={this.backtoUserprofile}
        />
        <TransactionITRHeader data={true} />
        <Box className={classes.dropDownMain}>
          <TransactionITR
            arr={arr}
            className={classes.parentContainer1}
            data={true}
          />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(ITRHistory);
// Customizable Area End
