import React from 'react';
// Customizable Area Start
import ReactPlayer from 'react-player';
import Dialog from '@material-ui/core/Dialog';


interface Props {
    video: string;
    open: boolean;
    handleCloseVideo: () => void;
}
interface ss{

}

export default class VideoPlayer extends React.Component<Props, ss> {
    constructor(props: Props) {
        super(props);
      }
   
    render() {
        const { video, open, handleCloseVideo } = this.props;
    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseVideo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    elevation: 0,
                    style: {
                        maxHeight: "70%"
                    }
                }}
                disableScrollLock={true}
            >
                <ReactPlayer className="audio_wrapper"
                    width="100%" height="100%" playing={true}
                    config={{
                        file: {
                            attributes: {
                                autoPlay: true,
                                muted: false,
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                    controls={true}
                    url={video} />
            </Dialog>

        </>
    );
}
};

// Customizable Area End
