import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from "@material-ui/core";

interface MyProps {
    dateState: string,
    onClearDate: (value: string) => void
}

const webStyles = {
    dateCrossIcon: {
        display: "flex",
        alignItems: "center",
        height: "42px",
        padding: "1em 2em 1em 1em",
        cursor: 'pointer',
    },
}

const RemoveSelectedDate = ({ dateState, onClearDate }: MyProps) => {

    return (
        <Box style={webStyles.dateCrossIcon}>
            <CloseIcon onClick={() => onClearDate(dateState)} />
        </Box>
    );
};

export default RemoveSelectedDate;
