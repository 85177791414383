import React from 'react'
import {
    Box,
    styled,
    Typography
} from "@material-ui/core";
import { CustomTextfield } from './CustomTextfield.web';
const deleteIcon = require("./delete.png")
const DescriptionAmount = (Props: any) => {
    const { data, handleDeleteCostImprovement, handleSetCostImprovement } = Props
    return (
        <BoxWrapper>
            <Box className='DA_container'>
                <Typography className='font_style-1'>Description</Typography>
                <CustomTextfield
                    data-testid="description_amount"
                    variant="outlined"
                    placeholder="Enter"
                    name="description"
                    value={data.description.value}
                    onChange={(e) => handleSetCostImprovement(e, data.id)}
                />
                {data.description.error && <Box className="error_message">{data.description.error}</Box>}
            </Box>
            <Box className='DA_container'>
                <Typography className='font_style-1'>Amount</Typography>
                <Box className="textfield_box">
                    <Box className="ruppee">
                        <span>₹</span>
                    </Box>
                    <CustomTextfield
                        data-testid="test_GridTextField"
                        variant="outlined"
                        placeholder="00"
                        name="amount"
                        value={data.amount.value}
                        onChange={(e) => handleSetCostImprovement(e, data.id)}
                    />
                </Box>
                {data.amount.error && <Box className="error_message">{data.amount.error}</Box>}
            </Box>
            <Box>
                <img src={deleteIcon} className='deleteIcon' onClick={() => handleDeleteCostImprovement(data.id, data.objID)} />
            </Box>
        </BoxWrapper>
    )
}

export default DescriptionAmount

const BoxWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    gap: "30px",
    "& .font_style-1": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        paddingRight: "10px",
        marginBottom: "15px"
    },
    "& .textfield_box": {
        display: "flex",
        position: "relative"
    },
    "& .ruppee": {
        width: "54px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#DEF5EA",
        zIndex: 1,
        marginRight: "-6px",
        flexShrink: 0
    },
    "& .DA_container": {
        width: "280px"
    },
    "& .deleteIcon": {
        marginTop: "50px",
        cursor: "pointer"
    },
}));