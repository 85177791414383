import React from "react";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  styled,
  Switch,
  TextField,
  Typography,
  IconButton,
  FormGroup,
  Snackbar
} from "@material-ui/core";
import { AddCircle } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DatePicker from "react-multi-date-picker";
import { info, QuestionMark, Rightarrow, DeleteIcon, LeftArrow, calanderIcon, downArrow } from "./assets"
import MuiAlert from '@material-ui/lab/Alert';

const ResimeSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "&.MuiSwitch-root": {
    width: "72px",
    height: "40px",
  },
  "& .MuiSwitch-colorSecondary.Mui-checked": {
    color: "white !important"
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#347054",
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: '1 !important',
    backgroundColor: "#347054",
    '&::before': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      left: 12,
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    margin: 1
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(32px)"
  }
}));
const StepperContainer = styled(Box)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "D1D1D1"
  },
  "& .MuiStepIcon-root": {
    color: "white"
  },
  "& .MuiStepIcon-text": {
    fill: "black",
    fontSize: "0.57rem"
  },
  "& .MuiSvgIcon-root": {
    fontSize: "3.3rem",
    color: ""
  },
  "& .MuiStepConnector-alternativeLabel": {
    top: "25px"
  },
  "& .MuiStepper-alternativeLabel": {
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: "#F4F6F5",
    paddingLeft: "50px"
  },
  "& .MuiStepConnector-lineHorizontal": {
    borderTopStyle: "dashed",
    borderWidth: "2px",
    zIndex: 1
  },
  "& .MuiStepLabel-iconContainer": {
    zIndex: 2
  },
  "& .MuiTypography-root": {
    color: "black !important"
  },
  '& .MuiStepIcon-completed': {
    color: 'blue',
  },

})
const CustomTextfield = styled(TextField)({
  borderColor: "#c6c6c6",
  "& .MuiOutlinedInput-input": {
    fontSize: "18px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",
    height: "48px",
    '& fieldset': {
      borderColor: '#c6c6c6',
    },
    '&:hover fieldset': {
      borderColor: '#c6c6c6',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c6c6c6',
    }
  }
})
const CustomGrid = styled(Grid)({
  width: "95%",
  marginBottom: "35px",
  "& .MuiGrid-grid-md-4": {
    maxWidth: "31.4%"
  }
})
const PhoneInputWrapper = styled(Box)({
  "& .form-control": {
    width: "100%",
    height: "48px",
    paddingLeft: "15px",
    color: "#36403B",
    fontFamily: " Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .selected-flag": {
    display: "none"
  },
  "& .flag-dropdown ": {
    border: "none"
  }
})
const CustomCalander = styled(Box)({
  "& .rmdp-day.rmdp-selected >span": {
    backgroundColor: "#337152 !important",
  },
  "& .rmdp-day >span:hover": {
    backgroundColor: "#DEF5EA6B !important",
    color: "black !important"
  },
  "& .rmdp-day >span": {
    borderRadius: "20%",
  },
  "& .rmdp-day": {
    height: "40px",
    width: "30.5px !important"
  },
  "& .rmdp-today >span": {
    backgroundColor: "transparent",
    color: "black"
  },
  "& .rmdp-input--1": {
    border: "1px solid",
    borderColor: '#c6c6c6',
    maxWidth: "234px",
    borderRadius: "7px"
  },
  "& .rmdp-input": {
    backgroundColor: "transparent",
    position: "relative",
    zIndex: 1000,
    height: "48px",
    border: "none",
    paddingLeft: "70px",
    fontSize: "18px",
    width: "100%"
  },
  "& .rmdp-input::placeholder": {
    color: "rgba(54, 64, 59, 0.50)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .calander_icon": {
    position: "absolute",
    backgroundColor: "#DEF5EA",
    height: "100%",
    width: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px 0px 0px 7px"
  },
  "& .rmdp-week-day": {
    backgroundColor: "#DEF5EA6B !important",
    color: "black !important"
  },
  "& .rmdp-arrow": {
    borderColor: "black !important"
  },
  "& .rmdp-header-values": {
    fontSize: "13.5px"
  }
})
const CustomAutocomplete = styled(Autocomplete)({
  position: "relative",
  "& .MuiAutocomplete-popupIndicator>span": {
    visibility: "hidden"
  },
  "& .MuiIconButton-root": {
    position: "relative",
    zIndex: 1000,
  }
})

import Multipageforms2Controller, {
  Props,
} from "./Multipageforms2Controller";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";

export default class GeneralInformation extends Multipageforms2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const steps = ['Permanent Information', 'Identification & Contact details', 'Your Address', "Residential Status", "Bank Details", "Additional Information"];
    const {
      activeState,
      permanentInformation,
      permanentInformationError,
      identificationContactDetailsError,
      yourAddressError,
      primaryBankError
    } = this.state
    const handleReturnErrorMessage = (message: any) => {
      return (
        <>
          {
            message &&
            <Box className="error_message">{message}</Box>
          }
        </>
      )
    }
    
    return (
      <>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={this.state.toast.open}
        onClose={this.handleTostClose}
        autoHideDuration={2000}
      >
        <MuiAlert >
          {this.state.toast.message}
        </MuiAlert>
      </Snackbar>
        <DashboardPanselector navigation={undefined} id={""} data-test-id="pancard" />
      <MainWrapper maxWidth={false}>
        <Box className="body">
          <StepperContainer className="stepper">
            <Box data-testid="DecreaseActiveState" className="left_arrow" onClick={this.handleDecreaseActiveState}>
              <img src={LeftArrow} />
            </Box>
            <Stepper nonLinear activeStep={activeState} alternativeLabel>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel className={(index < this.state.activeState) ? "steplabel" as any : null} {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </StepperContainer>
          <Box className="form">
            <Card className="form_card">
              {this.state.activeState === 0 &&
                <>
                  <Typography className="form_heading" variant="h5" >
                    Permanent Information
                  </Typography>
                  <Typography className="form_heading-1">Please provide all info as per your government identity documents &#40;PAN,Aadhaar,etc.&#41;</Typography>
                  <Grid container className="grid_container">
                    <Grid item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1" style={{ marginTop: "45px" }}>Name <span className="star">*</span></Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <Grid className="marginBottom_15" container spacing={2}>
                        <Grid item lg={6} sm={6} md={6}>
                          <Typography className="font_style-1 marginBottom_10"  >First Name</Typography>
                          <CustomTextfield
                            className="form_textfield"
                            data-testid="first_name"
                            variant="outlined"
                            placeholder="Enter"
                            name="first_name"
                            value={permanentInformation.first_name.value}
                            onChange={this.handleGetPermanentInformation}
                          />
                          {handleReturnErrorMessage(permanentInformationError.first_name)}
                        </Grid>
                        <Grid item lg={6} sm={6} md={6}>
                          <Typography className="font_style-1 marginBottom_10" >Middle Name</Typography>
                          <CustomTextfield
                            className="form_textfield"
                            data-testid="middle_name"
                            variant="outlined"
                            placeholder="Enter"
                            name="middle_name"
                            value={permanentInformation.middle_name.value}
                            onChange={this.handleGetPermanentInformation}
                          />
                          {handleReturnErrorMessage(permanentInformationError.middle_name)}
                        </Grid>
                      </Grid>
                      <Grid className="marginBottom_15" >
                        <Typography className="font_style-1 marginBottom_10" >Last Name</Typography>
                        <CustomTextfield
                          className="form_textfield"
                          data-testid="last_name"
                          variant="outlined"
                          placeholder="Enter"
                          name="last_name"
                          value={permanentInformation.last_name.value}
                          onChange={this.handleGetPermanentInformation}
                        />
                        {handleReturnErrorMessage(permanentInformationError.last_name)}
                      </Grid>
                      <Box style={{ display: "flex" }}>
                        <span style={{ marginRight: "10px" }}>
                          <img src={QuestionMark} />
                        </span>
                        <Typography className="font_style-2">Name should be as per the PAN; 5th character of the PAN no. is the first letter of last name</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1">Date of Birth <span className="star">*</span></Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomCalander style={{ position: "relative" }}>
                        <Box className="calander_icon">
                          <img src={calanderIcon} />
                        </Box>
                        <Box className="rmdp-input--1">
                        <DatePicker
                          editable={false}
                          placeholder="DD/MM/YYYY"
                          data-testid="date_of_birth"
                          format="DD/MM/YYYY"
                          weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                          name="date_of_birth"
                          value={this.state.permanentInformation.date_of_birth.value}
                          onChange={(date) => this.handleGetDateOfBirth(date)}
                          required={true}
                          maxDate={new Date()}
                        />
                        </Box>
                      </CustomCalander>
                      {handleReturnErrorMessage(permanentInformationError.date_of_birth)}

                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1">Father's Name <span className="star">*</span></Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        className="form_textfield"
                        data-testid="father_name"
                        variant="outlined"
                        placeholder="Enter"
                        name="father_name"
                        value={permanentInformation.father_name.value}
                        onChange={this.handleGetPermanentInformation}
                      />
                      {handleReturnErrorMessage(permanentInformationError.father_name)}

                    </Grid>
                  </Grid>
                <Grid container className="grid_container">
                  <Grid className="align_flex" item lg={4} sm={4} md={4}>
                    <Typography className="font_style-1">Gender <span className="star">*</span></Typography>
                  </Grid>
                  <Grid item lg={8} sm={8} md={8}>
                    
                    <FormGroup data-testid="gender" className="checkbox-group" onChange={this.handleGetGender}>
                      <FormControlLabel style={{width:"106px"}}
                        control={
                          <Checkbox
                            name="male"
                            className="checkbox"
                            checked={permanentInformation.gender.male}
                          />
                        }
                        label="Male"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="female"
                            className="checkbox"
                            checked={permanentInformation.gender.female}
                          />
                        }
                        label="Female"
                      />

                    </FormGroup>
                    {handleReturnErrorMessage(permanentInformationError.gender)}
                  </Grid>
                </Grid>
                <Grid container className="grid_container">
                  <Grid className="align_flex" item lg={4} sm={4} md={4}>
                    <Typography className="font_style-1">Marital Status <span className="star">*</span></Typography>
                  </Grid>
                  <Grid item lg={8} sm={8} md={8}>
                    <FormGroup data-testid="marital_status" className="checkbox-group" onChange={this.handleGetMaritalStatus}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="married"
                            className="checkbox"
                            checked={permanentInformation.marital_status.married}
                          />
                        }
                        label="Married"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="unmarried"
                            className="checkbox"
                            checked={permanentInformation.marital_status.unmarried}
                          />
                        }
                        label="Unmarried"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="prefernottodisclose"
                            className="checkbox"
                            checked={permanentInformation.marital_status.prefernottodisclose}
                          />
                        }
                        label="Prefer not to disclose"
                      />
                    </FormGroup>
                    {handleReturnErrorMessage(permanentInformationError.marital_status)}
                  </Grid>
                </Grid>
                </>
              }
              {
                this.state.activeState === 1 &&
                <>
                  <Box style={{ marginBottom: "50px" }}>
                    <Typography className="form_heading" variant="h5">
                      Identification & Contact details
                    </Typography>
                    <Typography className="form_heading-1">To e-file your returns, please provide your Aadhaar, PAN and contact details.</Typography>
                    <CustomGrid container>
                      <Grid item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1" style={{ marginTop: "45px", paddingRight: "10px" }}>To e-file your returns, please provide your Aadhaar, PAN and contact details.</Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <Grid className="marginBottom_15" container spacing={5}>
                          <Grid style={{ position: "relative" }} item lg={6} sm={6} md={6}>
                            <Typography className="font_style-1 marginBottom_10"  >Aadhaar Number</Typography>
                            <CustomTextfield
                              className="form_textfield"
                              data-testid="adhar_number"
                              variant="outlined"
                              placeholder="Enter"
                              name="adhar_number"
                              value={this.state.identificationContactDetails.adhar_number.value}
                              onChange={this.handleGetIdentificationAndContactDetails}
                            />
                            {handleReturnErrorMessage(identificationContactDetailsError.adhar_number)}
                            <Typography className="or_text">Or</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} >
                            <Typography className="font_style-1 marginBottom_10" >Aadhaar Enrollment No.</Typography>
                            <CustomTextfield
                              className="form_textfield"
                              variant="outlined"
                              data-testid="adhar_enroll_number"
                              placeholder="Enter"
                              name="adhar_enroll_number"
                              value={this.state.identificationContactDetails.adhar_enroll_number.value}
                              onChange={this.handleGetIdentificationAndContactDetails}
                            />
                            {handleReturnErrorMessage(identificationContactDetailsError.adhar_enroll_number)}
                          </Grid>
                        </Grid>
                        <Box style={{ display: "flex" }}>
                          <span style={{ marginRight: "10px" }}>
                            <img src={QuestionMark} />
                          </span>
                          <Typography className="font_style-2" style={{ color: "#868A88", fontSize: "15px", }}>Don’t remember your Aadhaar number or Enrollment number? <span className="search">Search it here</span></Typography>
                        </Box>
                      </Grid>
                    </CustomGrid>
                    <Grid container className="grid_container">
                      <Grid className="align_flex" item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1">PAN Card Number <span className="star">*</span></Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <CustomTextfield
                          className="form_textfield"
                          data-testid="pan_card_number"
                          variant="outlined"
                          placeholder="Enter"
                          name="pan_card_number"
                          value={this.state.identificationContactDetails.pan_card_number.value}

                          onChange={this.handleGetIdentificationAndContactDetails}
                        />
                        {handleReturnErrorMessage(identificationContactDetailsError.pan_card_number)}

                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography className="form_heading" variant="h5">
                      Additional Information &#40;Optional&#41;
                    </Typography>
                    <Typography className="form_heading-1">Leave empty if you don’t have additional information</Typography>
                    <Grid container className="grid_container">
                      <Grid className="align_flex" item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1">Secondary mobile number</Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <PhoneInputWrapper>
                          <PhoneInput
                            data-testid="secondary_mobile_number"
                            country={'in'}
                            placeholder=""
                            value={this.state.identificationContactDetails.secondary_mobile_number.value}
                            onChange={phone => this.handleGetSecondaryPhoneNumber(phone)}
                          />
                        </PhoneInputWrapper>
                      </Grid>
                    </Grid>
                    <Grid container className="grid_container">
                      <Grid className="align_flex" item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1">Secondary email address</Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <CustomTextfield
                          className="form_textfield"
                          data-testid="secondary_email"
                          type="email"
                          variant="outlined"
                          placeholder="Enter"
                          name="secondary_email"
                          value={this.state.identificationContactDetails.secondary_email.value}

                          onChange={this.handleGetIdentificationAndContactDetails}
                        />
                        {handleReturnErrorMessage(identificationContactDetailsError.secondary_email)}
                      </Grid>
                    </Grid>
                    <Grid container className="grid_container">
                      <Grid className="align_flex" item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1">Landline number</Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <CustomTextfield
                          className="form_textfield"
                          data-testid="landline_number"
                          variant="outlined"
                          placeholder="Enter"
                          name="landline_number"
                          value={this.state.identificationContactDetails.landline_number.value}

                          onChange={this.handleGetIdentificationAndContactDetails}
                        />
                        {handleReturnErrorMessage(identificationContactDetailsError.landline_number)}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              }
              {
                this.state.activeState === 2 &&
                <Box>
                  <Typography className="form_heading" variant="h5">
                    Your Address
                  </Typography>
                  <Typography className="form_heading-1">You can provide either your current address or permanent address of residence.</Typography>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1" style={{ color: "rgb(54 64 59 / 90%)" }}>Flat / Door No <span className="star">*</span></Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        className="form_textfield"
                        data-testid="flat_number"
                        variant="outlined"
                        placeholder="Enter"
                        name="flat_number"
                        value={this.state.yourAddress.flat_number.value}

                        onChange={this.hendleGetAddress}
                      />
                      {handleReturnErrorMessage(yourAddressError.flat_number)}
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1">Premise Name</Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        className="form_textfield"
                        data-testid="premise_name"
                        variant="outlined"
                        placeholder="Enter"
                        name="premise_name"
                        value={this.state.yourAddress.premise_name.value}

                        onChange={this.hendleGetAddress}
                      />
                      {handleReturnErrorMessage(yourAddressError.premise_name)}
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1"> Road / Street</Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        className="form_textfield"
                        data-testid="street_name"
                        variant="outlined"
                        placeholder="Enter"
                        name="street_name"
                        value={this.state.yourAddress.street_name.value}

                        onChange={this.hendleGetAddress}
                      />
                      {handleReturnErrorMessage(yourAddressError.street_name)}
                    </Grid>
                  </Grid>
                    <Grid container className="grid_container">
                      <Grid className="align_flex" item lg={4} sm={4} md={4}>
                        <Typography className="font_style-1" style={{ color: "rgb(54 64 59 / 90%)" }}>
                          Area Locality <span className="star">*</span>
                        </Typography>
                      </Grid>
                      <Grid item lg={8} sm={8} md={8}>
                        <TextField
                          className="form_textfield"
                          data-testid="locality"
                          variant="outlined"
                          placeholder="Enter"
                          name="locality"
                          value={this.state.yourAddress.locality.value}
                          onChange={this.hendleGetAddress}
                        />
                      {handleReturnErrorMessage(yourAddressError.locality)}
                      </Grid>
                    </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1" style={{ color: "rgb(54 64 59 / 90%)" }}>Pincode <span className="star">*</span></Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        className="form_textfield"
                        data-testid="pin_code"
                        variant="outlined"
                        placeholder="Enter"
                        name="pin_code"
                        value={this.state.yourAddress.pin_code.value}
                        onChange={this.hendleGetAddress}
                      />
                      {handleReturnErrorMessage(yourAddressError.pin_code)}
                    </Grid>
                  </Grid>
                </Box>
              }
              {
                this.state.activeState === 3 &&
                <Box>
                  <Typography className="form_heading" variant="h5">
                    Residential Status
                  </Typography>
                  <Typography className="form_heading-1">Please declare if you are an NRI, RNOR or Resident.This may vary from an individual’s citizenship.</Typography>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1">Your residential status</Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <CustomTextfield
                        disabled
                        className="form_textfield_status"
                        data-testid="residentialstatus"
                        variant="outlined"
                        placeholder="Enter"
                        name="residentialstatus"
                        value=  {this.state.residentialStatus.value ? this.state.residentialStatus.value : "No Residential Status Found!"}
                      />
                      </Grid>
                  </Grid>

                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <Box display="flex" style={{ cursor: "pointer" }} onClick={this.handlenavigate}>
                        <Typography className="text_underline" >Change Residential Status</Typography>
                        <img src={Rightarrow} className="rightarrow" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
              {
                this.state.activeState === 4 &&
                <Box>
                  <Typography className="form_heading" variant="h5">
                    Bank Details
                  </Typography>
                  <Typography className="form_heading-1">It is mandatory to provide primary bank account details to e-file returns</Typography>
                  <Typography className="text_underline" style={{ marginBottom: "40px" }}>How to find Bank account details</Typography>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={4} sm={4} md={4}>
                      <Typography className="font_style-1">Primary bank </Typography>
                    </Grid>
                    <Grid item lg={8} sm={8} md={8}>
                      <Grid className="marginBottom_15" container spacing={2}>
                        <Grid item lg={6} sm={6} md={6}>
                          <Typography className="font_style-1 marginBottom_10"  >Account number</Typography>
                          <CustomTextfield
                            className="form_textfield"
                            data-testid="primary_account_number"
                            variant="outlined"
                            placeholder="Enter"
                            name="primary_account_number"
                            value={this.state.primaryBank.primary_account_number.value}
                            onChange={this.handleGetPrimaryBankDetail}
                          />
                          {handleReturnErrorMessage(primaryBankError.primary_account_number)}
                        </Grid>
                        <Grid item lg={6} sm={6} md={6}>
                          <Box className="ifsc_code">
                            <Typography className="font_style-1 marginBottom_10" >IFSC code</Typography>
                            <InfoOutlinedIcon className="InfoOutlined_icon" style={{ marginTop: "1px", marginLeft: "5px" }} />
                          </Box>
                          <CustomTextfield
                            className="form_textfield"
                            data-testid="primary_ifsc_code"
                            variant="outlined"
                            placeholder="Enter"
                            name="primary_ifsc_code"
                            value={this.state.primaryBank.primary_ifsc_code.value}
                            onChange={this.handleGetPrimaryBankDetail}
                          />
                          {handleReturnErrorMessage(primaryBankError.primary_ifsc_code)}
                        </Grid>
                      </Grid>
                      <Grid className="marginBottom_15" container spacing={2}>
                        <Grid item lg={6} sm={6} md={6}>
                          <Typography className="font_style-1 marginBottom_10"  >Bank name</Typography>
                          <CustomTextfield
                            className="form_textfield"
                            data-testid="primary_bank_name"
                            variant="outlined"
                            placeholder="Enter"
                            name="primary_bank_name"
                            value={this.state.primaryBank.primary_bank_name.value}
                            onChange={this.handleGetPrimaryBankDetail}
                          />
                          {handleReturnErrorMessage(primaryBankError.primary_bank_name)}
                        </Grid>
                        <Grid item lg={6} sm={6} md={6} style={{ position: "relative", width: '100%' }}>
                          <Typography className="font_style-1 marginBottom_10" >Type</Typography>
                          <CustomAutocomplete
                            data-testid="primary_bank_type"
                            renderInput={(params: any) => <CustomTextfield
                              {...params}
                              variant="outlined"
                              fullWidth
                              placeholder="Select"
                              name="primary_bank_type"
                              value={this.state.primaryBank.primary_bank_type.value}
                            />}
                            defaultValue={this.state.primaryBank.primary_bank_type.value}
                            options={["1", "2", "3"]}
                            onChange={(e: any, value: any) => this.handleSetPrimaryBankType(e, value)}
                            fullWidth
                          />
                          <img className="downArrow_img" src={downArrow} />
                        </Grid>
                      </Grid>
                      <Box style={{ display: "flex" }}>
                        <span style={{ marginRight: "10px" }}>
                          <img src={QuestionMark} />
                        </span>
                        <Typography className="font_style-2" style={{ color: "#868A88", fontSize: "15px", }}>Your primary bank account is where you will receive your refunds, if eligible.</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  {this.state.secondaryBank.map((data, index) => {
                    return (
                      <Grid container className="grid_container" key={data.id}>
                        <Grid className="align_flex" item lg={4} sm={4} md={4}>
                          <Typography className="font_style-1">{index < 1 && "Secondary banks"}</Typography>
                        </Grid>
                        <Grid item lg={8} sm={8} md={8} key={data.id}>
                          <Grid className="marginBottom_15" container spacing={2}>
                            <Grid item lg={6} sm={6} md={6}>
                              <Typography className="font_style-1 marginBottom_10"  >Account number</Typography>
                              <CustomTextfield className="form_textfield"
                                data-testid="secondary_account_number"
                                variant="outlined"
                                placeholder="Enter"
                                name="secondary_account_number"
                                value={data.secondary_account_number.value}
                                onChange={(e: { target: { value: string, name: string } }) => this.handleGetSecondaryBank(e, data.id)}
                              />
                              {handleReturnErrorMessage(data.secondary_account_number.error)}
                            </Grid>
                            <Grid style={{ position: "relative" }} item lg={6} sm={6} md={6}>
                              <Box className="ifsc_code">
                                <Typography className="font_style-1 marginBottom_10" >IFSC code</Typography>
                                <InfoOutlinedIcon className="InfoOutlined_icon" style={{ marginTop: "1px", marginLeft: "5px" }} />
                              </Box>
                              <CustomTextfield className="form_textfield"
                                data-testid="secondary_ifsc_code"
                                variant="outlined"
                                placeholder="Enter"
                                name="secondary_ifsc_code"
                                value={data.secondary_ifsc_code.value}
                                onChange={(e: { target: { value: string, name: string } }) => this.handleGetSecondaryBank(e, data.id)}
                              />
                              {handleReturnErrorMessage(data.secondary_ifsc_code.error)}
                              <IconButton className="delete_icon">
                                <img src={DeleteIcon} onClick={() => this.handleDeleteSecondaryBank(data.id)} />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid className="marginBottom_15" container spacing={2}>
                            <Grid item lg={6} sm={6} md={6}>
                              <Typography className="font_style-1 marginBottom_10"  >Bank name</Typography>
                              <CustomTextfield className="form_textfield"
                                data-testid="secondary_bank_name"
                                variant="outlined"
                                placeholder="Enter"
                                name="secondary_bank_name"
                                value={data.secondary_bank_name.value}
                                onChange={(e: { target: { value: string, name: string } }) => this.handleGetSecondaryBank(e, data.id)}
                              />
                              {handleReturnErrorMessage(data.secondary_bank_name.error)}
                            </Grid>
                            
                            <Grid item lg={6} sm={6} md={6} style={{ position: "relative" }}>
                              <Typography className="font_style-1 marginBottom_10" >Type</Typography>
                              <CustomAutocomplete
                                data-testid="secondary_bank_type"
                                renderInput={(params: any) => <CustomTextfield {...params}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Select"
                                  name="secondary_bank_type"
                                  value={data.secondary_bank_type.value}
                                />}
                                options={["1", "2", "3"]}
                                fullWidth
                                defaultValue={data.secondary_bank_type.value}
                                onChange={(e: any, value: any) => this.handleSetSecondaryBankType(e, value, data.id)}
                              />
                              <img className="downArrow_img" src={downArrow} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                  }

                  <Box className="add_item" onClick={this.handleAddSecondaryBank}>
                    <IconButton style={{ padding: "7px" }} size="medium" >
                      <AddCircle className="add_icon" />
                    </IconButton>
                    <Typography>Add More items</Typography>
                  </Box>
                </Box>
              }
              {
                this.state.activeState === 5 &&
                <Box>
                  <Typography className="form_heading" variant="h5">
                    Additional Information
                  </Typography>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                      <Typography className="font_style-1">Are you a director in a company?</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} md={6}>
                      <CustomDropDown
                        data-testid="deduction_caimed_year"
                        name="0"
                        list={["Yes", "No"]}
                        value={this.state.additionalInformation?.[0][0]}
                        handleGetValue={this.handleGetAdditionalInformation}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                      <Typography className="font_style-1">Do you hold unlisted equity shares at any time during the year?</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} md={6}>
                      <CustomDropDown
                        data-testid="deduction_caimed_year"
                        name="1"
                        list={["Yes", "No"]}
                        value={this.state.additionalInformation?.[1][0]}
                        handleGetValue={this.handleGetAdditionalInformation}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                      <Typography className="font_style-1">Do you have ESOP tax which has been deferred?</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} md={6}>
                      <CustomDropDown
                        data-testid="deduction_caimed_year"
                        name="2"
                        list={["Yes", "No"]}
                        value={this.state.additionalInformation?.[2][0]}
                        handleGetValue={this.handleGetAdditionalInformation}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                      <Typography className="font_style-1">Do you have any foreign assets/foreign source income/foreign signatory authority</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} md={6}>
                      <CustomDropDown
                        data-testid="deduction_caimed_year"
                        name="3"
                        list={["Yes", "No"]}
                        value={this.state.additionalInformation?.[3][0]}
                        handleGetValue={this.handleGetAdditionalInformation}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="grid_container">
                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                      <Typography className="font_style-1">Are you claiming relief from international taxation?</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} md={6}>
                      <CustomDropDown
                        data-testid="deduction_caimed_year"
                        name="4"
                        list={["Yes", "No"]}
                        value={this.state.additionalInformation?.[4][0]}
                        handleGetValue={this.handleGetAdditionalInformation}
                      />
                    </Grid>
                  </Grid>
                </Box>
              }
            </Card>
              <Box className="btn_group">
                <Button data-testid="IncreaseActiveState" variant="contained" className="btn" onClick={()=>this.handleIncreaseActiveState(false)}>
                  {this.state.activeState === 5 ? "Submit" : "Next"}
                </Button>
                <Button variant="contained" data-testid="SaveandDraft" className="btn" onClick={() => this.handleIncreaseActiveState(true)}>
                  Save the Draft
                </Button>
              </Box>
          </Box>
        </Box>
      </MainWrapper>
      </>
    );
  }
}

const MainWrapper = styled(Container)({
  width: "100%",

  "& .align_flex": {
    display: "flex",
    alignItems: "center"
  },
  "& .marginBottom_15": {
    marginBottom: "15px"
  },
  "& .marginBottom_10": {
    marginBottom: "10px"
  },
  "& .grid_container": {
    width: "90%",
    marginBottom: "35px" ,
  },
  "& .header": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
    "@media(max-width: 768px)": {
      flexDirection: 'column'
    }
  },
  "& .MuiFormGroup-root": {
    display: "flex" ,
    flexWrap: "wrap",
    flexDirection:"row"
  },
  "& .box-primary-1": {
    backgroundColor: "#F4F6F5",
    display: "flex",
    width: "400px",
    height: "56px",
    justifyContent: "space-between",
    marginRight: "20px",
    padding: "0px 10px",
    borderRadius: "10px",
    alignItems: "center"
  },
  "& .box-primary-2": {
    backgroundColor: "#F4F6F5",
    width: "350px",
    padding: "15px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px"
  },
  "& .user_name": {
    display: "flex",
    gap: "5px",
    alignItems: "center"
  },
  "& .regime": {
    display: "flex",
    width: "100%",
    justifyContent: "right",
    alignItems: "center",
  },
  "& .form": {
    backgroundColor: "#F4F6F5",
    padding: "30px",
    "@media(max-width: 400px)": {
      padding: "0px"
    }
  },
  "& .form_card": {
    padding: '35px',
    borderRadius: "20px",
    "@media(max-width: 768px)": {
      padding: '15px'
    }
  },
  "& .form_heading": {
    marginBottom: "20px",
    color: "#36403B",
    fontFamily: "Nunito",
    fontSize: "27px",
    fontStyle: 'normal',
    fontWeight: 700
  },
  "& .form_heading-1": {
    marginBottom: "30px",
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .form_textfield": {
    width: "100%"
  },

  "& .form_textfield_status": {
    width: "100%",
    "& .MuiInputBase-input.Mui-disabled":{
color:"black"
    },
    "& .PrivateNotchedOutline-root-91":{
      border:"none",
      color:"black",
      fontFamily: "Nunito",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
    }
  },
  
  "& .or_text": {
    position: "absolute",
    right: "-10px",
    top: "70px",
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    "@media(max-width: 600px)": {
      position: 'initial',
      textAlign: 'center',
      marginTop: '10px'
    }
  },
  "& .checkbox": {
    color: "#337152",
    "& .MuiSvgIcon-root": {
      fontSize: "24px",
    },
  },
  "& .MuiFormControlLabel-label": {
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  "& .font_style-1": {
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    paddingRight: "10px"
  },
  "& .InfoOutlined_icon": {
    color: "rgba(54, 64, 59, 0.70)"
  },
  "& .btn": {
    width: "211px",
    height: "46px",
    backgroundColor: "#337152",
    color: "white",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
    "&:first-child": {
      marginRight: "30px"
    }
  },
  "& .font_style-2": {
    color: "rgba(54, 64, 59, 0.50)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400
  },
  "& .btn_group": {
    marginTop: "50px",
    marginBottom: "50px",
    "@media(max-width: 768px)": {
      "& .btn": {
        width: '100%',
        marginTop: '10px'
      }
    }
  },
  "& .star": {
    color: "#C46F60",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .search": {
    color: "#36403B",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    textDecorationLine: "underline",
    cursor: "pointer"
  },
  "& .text_underline": {
    color: "#36403B",
    fontSize: "20px",
    fontStyle: "normal",
    textDecorationLine: "underline",
    fontFamily: "Nunito",
    fontWeight: 600,
    marginRight: "10px"
  },
  "& .rightarrow": {
    width: "32px",
    height: "32px"
  },
  "& .MuiAutocomplete-input:first-child": {
    padding: "6.5px 4px"
  },
  "& .add_item": {
    display: "flex",
    alignItems: "center",
    width: 'max-content'
  },
  "& .add_icon": {
    width: "24px",
    height: "24px",
    color: '#337152'
  },
  "& .delete_icon": {
    position: "absolute",
    padding: "7px",
    left: "101%",
  },
  "& .ifsc_code": {
    display: "flex",
  },
  "& .stepper": {
    position: "relative"
  },
 "& .checkbox-group" :  {
    display: "flex",
    gap: "16px" ,
    flexWrap:"wrap",
    alignItems: "center",
  },
   
  "& .checkbox-group .checkbox" : {
    marginRight: '0 8px 0 0 !important', 
  },

  
  "& .left_arrow": {
    position: "absolute",
    left: "30px",
    top: "35px",
    cursor: "pointer"
  },
  "& .steplabel>span>svg": {
    color: "#337152 !important"
  },
  "& .MuiStepLabel-label": {
    color: "#000000bf !important",
    fontFamily: "Nunito",
    fontSize: "18px",
  },
  "& .steplabel>span>span": {
    color: "#337152  !important",
    fontFamily: "Nunito",
    fontSize: "18px",
  },
  "& .steplabel>span>svg>text": {
    fill: "#ffffff !important"
  },
  "& .resime_container": {
    position: "relative"
  },
  "& .resime--new": {
    position: "absolute",
    top: "11px",
    right: "11px",
    fontSize: "13.5px",
    color: "#ffffff",
    zIndex: 1,
    fontFamily: "Nunito",
    fontWeight: 600,
  },
  "& .resime--old": {
    position: "absolute",
    top: "11px",
    left: "15px",
    fontSize: "13.5px",
    color: "#ffffff",
    zIndex: 1,
    fontFamily: "Nunito",
    fontWeight: 600,
  },
  "& .downArrow_img": {
    position: "absolute",
    bottom: "27px",
    right: "23px",
    zIndex: 1
  },
  "& .error_message": {
    color: "red",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "5px",
    marginBottom: "-10px",
    marginLeft: "5px",
  },
  "& .MuiStepper-horizontal": {
    "@media(max-width: 768px)": {
      flexDirection: 'column',
      "& .MuiStepConnector-alternativeLabel": {
        display: 'none'
      },
      "& .MuiStep-alternativeLabel": {
        width: '100%'
      }
    }
  },
  "& .headerWrapper": {
    "@media(max-width: 768px)": {
      flexDirection: 'column',
      gap: '10px',
      "& .box": {
          flexDirection: 'column',
          width: '100%'
      },
    }
  },
});
