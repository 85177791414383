import { BlockComponent } from "../../../framework/src/BlockComponent";
//@ts-ignore
import validator from "validator";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  formData: {
    [keys: string]: string;
  };
  errors: {
    [keys: string]: string;
  };
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
  };
  otpOpen: boolean;
  isChecked: boolean;
  termsOpen: boolean;
  privacyOpen: boolean;
  termsDescription: string;
  privacyDescription: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  googleRegister: string = "";
  otpsendId: string = "";
  privacyPolicyId: string = "";
  termsConditionId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      // Customizable Area Start
      formData: {
        password: "",
        confirmPassword: "",
        email: "",
      },
      errors: {
        password: "",
        confirmPassword: "",
        name: "",
        email: "",
      },
      toast: {
        type: "success",
        open: false,
        message: ""
      },
      otpOpen: false,
      isChecked: false,
      termsOpen: false,
      privacyOpen: false,
      termsDescription: "",
      privacyDescription: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse1 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      this.handleApiResponse(apiRequestCallId1, responseJson1, errorReponse1);
    }
  }

  // Customizable Area Start
  navigateFUnctiontoDashboard = (status: string) => {
    localStorage.setItem("Pan_card_status", status)
    this.props.navigation.navigate(localStorage.getItem("Pan_card_status") == "false" ? "FileIncomeTax" : "");
  }
  handleApiResponse = (
    apiRequestCallId: string,
    responseJson: { errors: { message: string } | string[], meta: { token: string, pan_card_status: string }, data: { attributes: { description: string } }[] },
    errorReponse: object
  ) => {
    const err = responseJson.errors && (!Array.isArray(responseJson.errors) ? responseJson.errors?.message : responseJson.errors[0]);
    if (apiRequestCallId === this.otpsendId) {
      if (!err && responseJson.meta.token) {
        this.setState({
          toast: {
            open: true,
            message: "OTP send successfully",
            type: "success"
          }
        });
        // eslint-disable-next-line no-undef
        sessionStorage.setItem("token", responseJson.meta.token);
        this.setState({
          otpOpen: true
        })
      } else {
        //Check Error Response
        this.setState({
          toast: { open: true, message: err, type: "error" }
        });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (apiRequestCallId === this.googleRegister) {
      if (!err && responseJson.meta.token) {
        localStorage.setItem("authToken", responseJson.meta.token)
        this.navigateFUnctiontoDashboard(responseJson.meta.pan_card_status)
      } else {
        this.setState({
          toast: { open: true, message: err, type: "error" }
        });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (apiRequestCallId === this.privacyPolicyId || this.termsConditionId) {
      this.handleApiResponseTerms(err, responseJson?.data[0].attributes.description, apiRequestCallId)
    }
  };

  handleApiResponseTerms = (err: unknown, responseJson: string, apiRequestCallId: string) => {
    if (!err && responseJson) {
      apiRequestCallId === this.privacyPolicyId ? this.setState({ privacyDescription: responseJson }) : this.setState({ termsDescription: responseJson })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.privacyPolicyId = sendAPIRequest(configJSON.privacy, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    this.termsConditionId = sendAPIRequest(configJSON.terms, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  handleChange = (e: { target: { name: string, value: string } }) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
      errors: { ...this.state.errors, [e.target.name]: "" }
    });
  };

  handleTostClose = () => {
    this.setState({
      toast: {
        ...this.state.toast,
        open: false
      }
    })
  }

  handleOtpClose = () => {
    this.setState({
      otpOpen: false
    })
  }

  handleGoogleLogin = (response?: string) => {
    const body = {
      data: {
        type: "social_account",
        attributes: {
          social_auth_id: response,
        }
      }
    }
    this.googleRegister = sendAPIRequest(configJSON.googleRegister, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }
  handleEmailValidation = () => {
    if (!this.state.formData.email) {
      return "Please enter email id"
    } else if (!validator.isEmail(this.state.formData.email)) {
      return "Email is invalid"
    } else {
      return ""
    }
  }
  handlePasswordValidation = () => {
    if (!this.state.formData.password) {
      return "Please enter password"
    } else if (!validator.isStrongPassword(this.state.formData.password, [
      {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      }
    ])) {
      return "Password shoud be atleast 8 character and must include number,symbols and uppercase"
    } else {
      return ""
    }
  }
  handleConfirmPasswordValidation = () => {
    if (!this.state.formData.confirmPassword) {
      return "Please enter confirm password"
    } else if (!validator.equals(
      this.state.formData.password,
      this.state.formData.confirmPassword
    )) {
      return "Password and confirm password not matched"
    } else {
      return ""
    }
  }
  handleSubmit = () => {
    let error = {
      password: this.handlePasswordValidation(),
      email: this.handleEmailValidation(),
      confirmPassword: this.handleConfirmPasswordValidation()
    };
    this.setState({ errors: { ...error } });
    let temp = [];
    for (const [key, value] of Object.entries(error)) {
      key && temp.push(value === "");
    }
    if (!this.state.isChecked) return this.setState({ toast: { open: true, message: "Selects Terms And Privacy Policy", type: "warning" } })
    if (!temp.includes(false)) {
      const body = {
        data: {
          attributes: {
            email: this.state.formData.email
          }
        }
      };
      this.otpsendId = sendAPIRequest(configJSON.sendOTPPath, {
        method: "POST",
        body,
        headers: { "Content-Type": "application/json", token: "" }
      });
      // eslint-disable-next-line no-undef
      sessionStorage.setItem("signUpData", JSON.stringify(this.state.formData));
    }
  };

  handleTerms = () => {
    this.setState({ termsOpen: !this.state.termsOpen });
  };

  handlePrivacy = () => {
    this.setState({ privacyOpen: !this.state.privacyOpen });
  };

  handleCheckbox = (value: boolean) => {
    this.setState({ isChecked: value });
  };
  navigatesignupToDashboard = () => {
    this.props.navigation.history.push("")
  }
  // Customizable Area End
}
