import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { StaticHouseProperty } from "./StaticHouseProperty.web";


// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  allStates: any;
  oldprops?: any;
  instance?: StaticHouseProperty
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addPropertyBtn: boolean;
  expandSelfProperty: boolean;
  expandRentalProperty: boolean;
  housePropertyIncome: boolean;
  activeStep: number;
  houseEdit: boolean;
  rentalEdit: boolean;
  propertyUpdate: boolean;
  selfHouseID: any;
  rentalPropertyID: any;
  allSelfProperties: any;
  allRentalProperties: any;
  openPaperIndex: null | string;
  openRentalPaperIndex:null | string;
  openSelfPaperIndex:null | string;
  propertyId: undefined | string | number
  currentPropertyId:string
  isLoading:boolean
  isLoadingProperty:boolean;
  deleteStatus:boolean
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
};
  savedSuccess:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HousePropertyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSelfsID: string = '';
  getAllRentalsID: string = '';
  delSelfProperty: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      activeStep: 1,
      addPropertyBtn: false,
      expandRentalProperty: false,
      expandSelfProperty: false,
      housePropertyIncome: false,
      houseEdit: false,
      rentalEdit: false,
      propertyUpdate: false,
      selfHouseID: null,
      rentalPropertyID: null,
      allSelfProperties: [],
      allRentalProperties: [],
      openPaperIndex: null,
      openSelfPaperIndex:null,
      openRentalPaperIndex:null,
      propertyId: undefined,
      currentPropertyId:"",
      isLoading:true,
      isLoadingProperty:true,
      deleteStatus:false,
      toast: {
        type: "success",
        open: false,
        message: "",
      },
      savedSuccess:false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }



  async componentDidMount() {
    // Customizable Area Start
    this.getAllSelfProperties();
    this.getAllRentalProperties();
    // Customizable Area End
  }

  // Customizable Area Start


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAllSelfsID) {
        this.handleGetSelfId(responseJson)

      }
      if (apiRequestCallId === this.getAllRentalsID) {
        this.handleGetRental(responseJson)
      }
      if (this.delSelfProperty === apiRequestCallId) {
        this.handleDeleteProperySelf(responseJson)
      }

    }

    // Customizable Area End
  }
  handleGetSelfId=(responseJson:any)=>{
    if(responseJson&&responseJson.data){
      this.setState({ allSelfProperties: responseJson?.data ,isLoading:false,isLoadingProperty:false,housePropertyIncome:true,})
    }else{
      this.setState({isLoading:false,isLoadingProperty:false,allSelfProperties:[]})
    }
  }
  handleGetRental=(responseJson:any)=>{
    if(responseJson&&responseJson.data){
      this.setState({ allRentalProperties: responseJson?.data ,isLoadingProperty:false,isLoading:false,housePropertyIncome:true})
    }else{
      this.setState({isLoading:false,isLoadingProperty:false, allRentalProperties:[]})
    }
  }

  handleDeleteProperySelf = (apiRes: any) => {
    if (apiRes && apiRes.message) {
      this.setState({deleteStatus:true})
      this.getAllSelfProperties();
      this.getAllRentalProperties()
    }
  }
  handleSnackbarDelete = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.clickaway) {
      return;
    }
  
    this.setState({ deleteStatus: false })
  };
  getSignleHouseProperty = (id: any) => {
    this.setState({ houseEdit: true, propertyUpdate: true,currentPropertyId:id,openSelfPaperIndex:null })
  }
  getSignleRentalProperty=(id: any) => {
    this.setState({ rentalEdit: true, propertyUpdate: true,currentPropertyId:id ,openRentalPaperIndex:null})
  }
  housePropertyNavTemp = () => {
    this.setState({ addPropertyBtn: false, housePropertyIncome: true })
  }

  handleAccordian = () => {
    this.setState({ expandSelfProperty: !this.state.expandSelfProperty })
  }
  handleAccordianRentalProperty = () => {
    this.setState({ expandRentalProperty: !this.state.expandRentalProperty })
  }
  handleAddProperty = () => {
    this.setState({ addPropertyBtn: !this.state.addPropertyBtn })
  }
  handleOpenHouseEdit = () => {
    if(this.state.allSelfProperties.length >= 2){
      this.setState({
        toast: { open: true, message:"You can able to add only 2 self house property", type: "error" },
      })
    }
    else{
      this.setState({ houseEdit: true, propertyUpdate: false })
    }
  }
  handleOpenRentalEdit = () => {
    this.setState({ rentalEdit: true, propertyUpdate: false })
  }
  handleOpenPaper = (index: string | null, type: string) => {
    if (type === "rental") {
      this.setState((prevState) => ({
        openRentalPaperIndex: prevState.openRentalPaperIndex === index ? null : index
      }));
    } else {
      this.setState((prevState) => ({
        openSelfPaperIndex: prevState.openSelfPaperIndex === index ? null : index
      }));
    }
  };
  
  homePage=(savedSuccess:boolean=false)=>{
    if(savedSuccess){
      this.setState(
        {
          savedSuccess:true
        }
      )
    }
    this.setState({houseEdit:false,addPropertyBtn:false,currentPropertyId:""})
    this.getAllSelfProperties();
    this.getAllRentalProperties();
  }
  homePageRental=()=>{
    this.setState({rentalEdit:false,addPropertyBtn:false,currentPropertyId:""})
    this.getAllSelfProperties();
    this.getAllRentalProperties();
  }
  getAllSelfProperties = async() => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSelfsID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listSelfPropertiesEndPoint}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllRentalProperties = async() => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllRentalsID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listRentalPropertiesEndPoint}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleDeleteProperty = async (id: string | number, type: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.delSelfProperty = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteHouseProperty}${id}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  handleClickOutside=()=>{
    this.setState({openPaperIndex:null})
  }
  handleTostClose = () => {
    this.setState({
      toast: {
          open: false,
          message: this.state.toast.message,
          type: this.state.toast.type,
      },
      });
 
}
handleSuccessSnackClose=()=>{
  this.setState({
    savedSuccess:false
  })
}
  // Customizable Area End
}
