import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Toster from "../../../components/src/Toster";
// Customizable Area End

import AssessmenttestController, {
  Props,
} from "./AssessmenttestController";
import MainHeader from "../../../components/src/MainHeader.web";

const GreenRadio = withStyles({
    root: {
      '&$checked': {
        color: ' green[600]',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

export default class ResidentialStatus extends AssessmenttestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
         <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
        <Box >
          
          <div style={webStyle.mainDiv as React.CSSProperties}>

            <h1 style={{ color: '#347152' }}>Residential Status Calculator</h1>

           {this.state.residentialQuestion.map((question,index)=>(
            <React.Fragment key={index}>
              {!this.checkIsResidentOrNot() ?
              <div style={webStyle.bodyDiv as React.CSSProperties}>
                <div style={webStyle.bodychildDiv as React.CSSProperties}>
                  <h3>{question.name}</h3>
                   <div>
                       <FormControl component="fieldset" >
                         <RadioGroup row
                           data-test-id='radioGroup'
                           onChange={this.handleRadioChange}
                         >
                           {this.state.residentialQuestion.some(question => question.id === 76 || question.id === 105) ? (
                             <>
                               <FormControlLabel
                                 checked={this.state.isResident === "1"}
                                 data-test-id="radio2"
                                 value="1"
                                 control={<GreenRadio />}
                                 label="1"
                               />
                               <FormControlLabel
                                 checked={this.state.isResident === "0"}
                                 data-test-id="radio1"
                                 value="0"
                                 control={<GreenRadio />}
                                 label="2"
                               />
                             </>
                           ) : (
                             <>
                               <FormControlLabel
                                 checked={this.state.isResident === "yes"}
                                 data-test-id="radio1"
                                 value="yes"
                                 control={<GreenRadio />}
                                 label="Yes"
                               />
                               <FormControlLabel
                                 checked={this.state.isResident === "no"}
                                 data-test-id="radio2"
                                 value="no"
                                 control={<GreenRadio />}
                                 label="No"
                               />
                             </>
                           )}
                         </RadioGroup>
                       </FormControl>
                  </div>
                </div>
              </div> :
               <div style={webStyle.bodyDivForRes as React.CSSProperties}>
               <div style={webStyle.bodychildDivForRes as React.CSSProperties}>
                 <h3>{question.name}</h3>
               </div>
             </div>
              }
              <JourneyWrapper>
              <div style={webStyle.footer} >
                <Button data-test-id="prev_question" className="getstarted-btn" style={{ background: "#ffffff", color: "#347152" }} onClick={()=>this.handlePreviousQuestion(question)}>Previous</Button>&nbsp;&nbsp;&nbsp;
                <Button data-test-id="next_question" className="getstarted-btn" style={{ background: "#ffffff", color: "#347152" }} onClick={()=>this.handleNextQuestion(question,this.state.AssesmentDone ? "submit" : "")}>{this.state.AssesmentDone ? "Submit" : "Next"}</Button>
              </div>
              </JourneyWrapper>
            </React.Fragment>
           ))}
          </div>
        </Box>

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    header:{ 
        height: '100px', 
        backgroundColor: '#347152' 
    },
    arrowMain:{ 
        marginLeft: '130px', 
        height: '70px', 
        display: 'flex', 
        justifyContent: "flex-start", 
        alignItems: 'center'
    },
    backText:{
        cursor:'pointer', 
        color: '#347152' 
    },
    mainDiv:{
         width: '100%', 
         marginLeft: '130px', 
         height: '70px', 
         display: 'flex', 
         justifyContent: "flex-start", 
         flexDirection: 'column' 
    },
    bodyDiv:{
        borderRadius: '4px', 
        border: '1px solid #347152',
        padding: '40px 50px',
        width: '80%', 
        marginTop: '10px' ,
        display:"flex",
        justifyContent:"flex-start"
    },
    bodychildDiv:{
      width: "500px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
  bodyDivForRes:{
    borderRadius: '4px', 
    border: '1px solid #347152',
    width: '80%', 
    padding: '40px 50px',
    marginTop: '10px' ,
    textAlign:"center"
  },
  bodychildDivForRes:{
    width: "100%",
  },
    footer:{ 
        display: 'flex',
        justifyContent: 'flex-start',
        width:"80%", 
        alignItems: 'center', 
        marginTop: '30px' 
    },
    prevButton:{
         height: '50px',
        width: '170px', 
        color: 'white', 
        backgroundColor: '#347152', 
        fontSize: '16px', 
        margin: '10px',
        cursor:"pointer"
    },
    nextButton:{
        height: '50px', 
        width: '170px', 
        color: '#347152',
        border: '2px solid #347152', 
        fontSize: '16px', 
        margin: '10px',
        cursor:"pointer"
      }
}

const JourneyWrapper = styled(Box)({
 "& .MuiButton-label":{
width:"120px",
padding:"4px"
 },
  "& .getstarted-btn": {
    cursor:"pointer",
    border:"1px solid #347152",
    paddingRight:"10px",
    paddingLeft:"10px",
    "&:hover": {
      color: "#f3fef8 !important",
      backgroundColor: '#347152  !important'
    },
  },
 
});
// Customizable Area End
