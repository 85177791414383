import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id?: { id: string, type: string }
    // Customizable Area Start
    onClick?: () => void
    changeTab?: any,
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    documentUpload: any;
    openManualForm: boolean;
    opendataofSale: boolean,
    opendateofPurchase: boolean,
    dataofSale: Date | null;
    dateofPurchase: Date | null;
    assetName: string;
    totalSalePrice: string;
    totalPurchasePrice: string;
    incomeType: string,
    gains: string;
    error: any;
    fileError: string;
    open: boolean;
    docData: any;
    manualDocData: [];
    dataPresent: boolean;
    isLoading: boolean;
    isFormEnable: boolean;
    cryptoId: string;
    isSaleDateOpen: boolean
    isPurchaseDateOpen: boolean
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    toastMessage: string,
    toastType: "success" | "info" | "warning" | "error";
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CryptoVirtualDigitalAssetsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    cryptoFilescallID: string = ""
    cryptoDetailsGet: string = ""
    cryptoDetailsDel: string = ""
    cryptoManualPostAPi: string = ""
    cryptoDetailsmanualGet: string = ""
    cryptoDeleteApiCallId: string = ""
    cryptoDetailsGetById: string = ""
    cryptoStatmentVirtual: string = ""
    getSampleCSVID:string=""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.state = {
            documentUpload: false,
            openManualForm: false,
            opendataofSale: false,
            opendateofPurchase: false,
            dataofSale: null,
            dateofPurchase: null,
            assetName: "",
            totalSalePrice: "",
            totalPurchasePrice: "",
            incomeType: "",
            gains: "",
            docData: {},
            manualDocData: [],
            fileError: "",
            open: false,
            error: {},
            dataPresent: false,
            isLoading: false,
            isFormEnable: false,
            cryptoId: '',
            isSaleDateOpen: false,
            isPurchaseDateOpen: false,
            toast: {
                type: "success",
                open: false,
                message: "",
            },
            toastMessage: "",
            toastType: "success",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (this.cryptoDetailsGet === responseData) {
            this.handlesaveDoc(successMessage);
        }
        if (this.cryptoFilescallID === responseData) {
            this.cryptoDocGet()
        }

        if (this.cryptoDetailsDel === responseData) {
            this.cryptoDocGet()
        }
        if (this.cryptoManualPostAPi === responseData) {
            this.manualDocGet();

            this.setState({
                isFormEnable: false,
                cryptoId: "",
                assetName: "",
                totalSalePrice: "",
                totalPurchasePrice: "",
                incomeType: "",
                gains: "",
                toast: { open: true, message: this.state.cryptoId ? "Data updated successfully!" : "Data submitted successfully!", type: "success" },
                toastMessage: this.state.cryptoId ? "Data updated successfully!" : "Data submitted successfully!",
                toastType: "success",
            });

        }
        if (this.cryptoDetailsmanualGet === responseData) {
            this.handlesavemanualdata(successMessage)
        }

        if(this.cryptoDeleteApiCallId === responseData){
            this.manualDocGet();
        }
        if (this.cryptoDetailsGetById === responseData) {
            this.autofillData(successMessage)
        }
        if (this.getSampleCSVID === responseData){
           const { data } = successMessage;
                if (data && data[0] && data[0].url) {
                    const url = data[0].url;
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', data[0].file_name || 'download');
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                }
        }

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        if (this.props.id?.id) {
            this.virtualDocGetById()
        }
        this.cryptoDocGet()
        this.manualDocGet()
        // Customizable Area End
    }

    // Customizable Area Start
    autofillData = (apiResponse: {
        data: {
            attributes: {
                vda_asset_name?: string;
                income_type?: string;
                total_sale_price?: number | string;
            }
        }
    }) => {
        if (apiResponse && apiResponse?.data) {
            let {
                vda_asset_name,
                income_type, total_sale_price } = apiResponse.data?.attributes
            this.setState({
                assetName: vda_asset_name ?? "",
                totalSalePrice: total_sale_price ? total_sale_price.toString() : "",
                totalPurchasePrice: "",
                incomeType: income_type ?? "",
                gains: ""
            })
        }
    }
    handlesavemanualdata = (apiResponse: any) => {
        if (apiResponse) {
            if (apiResponse.data) {
                this.setState({ dataPresent: true, isLoading: false, manualDocData: apiResponse.data, })
            } else {
                this.setState({ isLoading: false, dataPresent: false })
            }
        }
    }
    handlesaveDoc = (apiResponse: { data: [], metadata: {} }) => {
        if (apiResponse) {
            if (apiResponse.data && apiResponse.metadata) {
                this.setState({ dataPresent: true, isLoading: false, docData: apiResponse, })
            }
            else {
                this.setState({ isLoading: false, dataPresent: false, docData: [] })
            }
        }
    }
    handleChangeSale = (newValue: Date) => {
        this.setState({ dataofSale: newValue });
    };
    handleChangePurchase = (newValue: Date) => {
        this.setState({ dateofPurchase: newValue });
    };
    handleOnchangeCryptoAssets = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    handleOnchangeCryptoOnlyNumber = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value)) {
            return
        }else{
            this.setState((prevData) => ({
                ...prevData,
                [name]: value
            }))
        }
        
    }

    handleFocusCryptoAssets = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurCryptoAssets = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: String(value).trim() === ""
            }
        }));
    }
    handleModalOpen = () => {
        this.setState((prev) => ({ documentUpload: !prev.documentUpload }))
    }
    handleManulform = () => {
        this.setState((prev) => ({ openManualForm: !prev.openManualForm }))
    }
    handleBrowseClick = () => {
        const fileInput = document.getElementById("fileInput");
        fileInput?.click();
    }
    handlefiles = (fileExtension: string, file: FileList) => {
        if (fileExtension === "csv" || fileExtension === "xlsx") {
            this.setState({ fileError: "" })
            this.cryptoFileinputPost(file)
        } else {
            this.setState({ fileError: configJSON.errormessageforfile })
        }
    }
    handleFileChange = (event: any) => {
        const file = event.target.files[0];
        const fileExtension = file.name?.split('.')[1].toLowerCase();
        this.handlefiles(fileExtension, file)
    }
    handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file: File | FileList | any = event.dataTransfer.files[0];
        const fileExtension = file.name.split('.')[1].toLowerCase();
        this.handlefiles(fileExtension, file);
    }
    cryptoFileinputPost = async (file: any) => {
        const headers = {
            "token": await getStorageData(configJSON.authToken)
        };
        let formData = new FormData();
        formData.append("statement", file);

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoFilescallID = getpasswordfrom.messageId;

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cryptoendPoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }
    cryptoDocGet = async () => {
        this.setState({ isLoading: true, dataPresent: false })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoDetailsGet = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAlldocs
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handledelete = async (id: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoDetailsDel = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAlldocs + "/" + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    cryptoManualPost = async () => {
        let method = this.state.cryptoId ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod
        const {
            assetName,
            totalSalePrice,
            totalPurchasePrice,
            incomeType,
            gains,
        } = this.state;
        const digital: {
            capital_gain_asset_statement: {
                name: string;
                dop: string;
                dos: string;
                total_sale_price: string;
                income_type: string;
                income_from_vda_transfer: string;
                total_purchase_price: string;
            };
        } = {
            capital_gain_asset_statement: {
                name: assetName,
                dop: "2022-01-01",
                dos: "2022-01-02",
                total_sale_price: totalSalePrice,
                income_type: incomeType,
                income_from_vda_transfer: gains,
                total_purchase_price: totalPurchasePrice
            }

        };
        const manual = {
            capital_gain_digital_asset: {
                date_of_sale: moment(this.state.dataofSale).format('YYYY-MM-DD'),
                date_of_purchase: moment(this.state.dateofPurchase).format('YYYY-MM-DD'),
                gains: gains,
                vda_asset_name: assetName,
                total_sale_price: totalSalePrice,
                total_purchase_price: totalPurchasePrice,
                income_type: incomeType
            }

        };

        let httpBody = (this.props?.id?.type == configJSON.finalEndPoint) ? digital : manual
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoManualPostAPi = getpasswordfrom.messageId;

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.cryptoId ? `${configJSON.manualGetDataEndPoint}/${this.state.cryptoId}` : configJSON.manualGetDataEndPoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }
    handlepostmanual = () => {
        if(!this.state.dataofSale || !this.state.dateofPurchase){
            this.setState({
                toast: {
                    open: true,
                    message: 'Dates are mandatory!',
                    type: 'warning',
                },
                toastMessage: "Dates are mandatory!",
                toastType: "warning",
            })
            return;
        }else if((this.state.dataofSale && this.state.dateofPurchase) && ( new Date(this.state.dataofSale) < new Date(this.state.dateofPurchase))){
            this.setState({ toast: { open: true, message: 'Sale date cannot be earlier than the purchase date!', type: 'warning', }, toastMessage: "Sale date cannot be earlier than the purchase date!", toastType: "warning", })
            return;
        }
        this.cryptoManualPost()
    }
    manualDocGet = async () => {
        this.setState({ isLoading: true, dataPresent: false })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoDetailsmanualGet = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.manualdocgetapi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    deleteManualDoc = async (crptId: string) => {
        
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cryptoDeleteApiCallId = reqMsg.messageId;

        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.manualdocgetapi}/${crptId}`
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    virtualDocGetById = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cryptoDetailsGetById = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cryptoendPoint + "/" + this.props.id?.id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSampleCSV = async() =>{
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSampleCSVID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.samplecsvendpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    checkValueEmpty = (value: number | string) => {
        return value || '-'
    }

    checkIsDateNull = (value: string | null) => {
        return value ? moment(value).format('DD/MM/YYYY') :  '-'
    }

    addMore = () => {
        this.setState({
            dataofSale : null,
            cryptoId: "",
            dateofPurchase : null,
            assetName : '',
            totalSalePrice : '',
            totalPurchasePrice : '',
            incomeType : '',
            gains : '',
            isFormEnable: true,
        })

    }
    

    editManualDoc = (data: any) => {
        this.setFormData(data)
        this.setState({
            isFormEnable: true,
        });
    }

    setFormData = (data: any) => {
        let attributes = data && data.attributes;
        this.setState({
            cryptoId: data.id,
            dataofSale : attributes && attributes.date_of_sale,
            dateofPurchase : attributes && attributes.date_of_purchase,
            assetName : attributes && attributes.vda_asset_name,
            totalSalePrice : attributes && attributes.total_sale_price,
            totalPurchasePrice : attributes && attributes.total_purchase_price,
            incomeType : attributes && attributes.income_type,
            gains : attributes && attributes.gains,
        })
    }

    backToPrevPage = (data: any) => {
        if(this.state.isFormEnable){
            this.setState({ isFormEnable: false, cryptoId: ""});
        }else {
            this.props.onClick && this.props.onClick()
        }
    }

    // isSaleDateOpen: false,
    // isPurchaseDateOpen: false,

    openCloseDatePicker = (name: any, value: any) => {
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    handleTostClose = () => {
        this.setState({
            toast: {
                open: false,
                message: this.state.toastMessage,
                type: this.state.toastType,
            },
        });
    }
    // Customizable Area End
}
