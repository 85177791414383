import React from "react";
// Customizable Area Start
import { Container, Box, withStyles } from "@material-ui/core";
import BackgroundHeaderWeb from "../../../components/src/BackgroundHeader.web";
import TransactionITR from "../../../components/src/TransactionITR.web";
import TransactionITRHeader from "../../../components/src/TransactionITRHeader.web";
import styles from './styles.web'

import TransactionHistoryController, {
  Props,
  configJSON
} from "./TransactionHistoryController.web";

export class TransactionHistory extends TransactionHistoryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const arr = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <Container maxWidth={false} className={classes.defaltValues}>
        <BackgroundHeaderWeb
          title={configJSON.transactionHistory}
          data-test-id="navigateToUserProfile"
          onClick={this.backtoUserprofilefromTtansaction}
        />
        <TransactionITRHeader data={false} />
        <Box className={classes.dropDownMain}>
          <TransactionITR
            arr={arr}
            className={classes.parentContainer1}
            data={false}
          />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(TransactionHistory);
// Customizable Area End
