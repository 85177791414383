import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  otpOpen: boolean;
  handleOtpClose: () => void;
  email?: string
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: number;
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
  };
  resendDisable: boolean;
  error: string;
  isVerified: boolean;
  isLogin: boolean;
  second: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpConfirmationId: string = "";
  submitSignUp: string = "";
  resendOtp: string = "";
  interval: number | ReturnType<typeof setInterval> = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      otp: 0,
      toast: {
        type: "success",
        open: false,
        message: "OTP send successfully"
      },
      resendDisable: true,
      error: "",
      isVerified: false,
      isLogin: false,
      second: 60,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId2 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse2 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId2 && responseJson2) {
      this.handleApiResponse(apiRequestCallId2, responseJson2, errorReponse2);
    }
  }

  // Customizable Area Start
  handleOtpChange = (e: number) => {
    this.setState({
      otp: e,
      error:""
    });
  };

  handleOtpClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return;
    this.setState({ second: 60, otp: 0 })
    this.props.handleOtpClose();
  }

  handleApiResponse = (
    apiRequestCallId: string,
    responseJson: { errors: { message: string } | string[], meta: { token: string } },
    errorReponse: object
  ) => {
    const errr = responseJson.errors && (!Array.isArray(responseJson.errors) ? responseJson.errors?.message : responseJson.errors[0]);
    if (apiRequestCallId === this.otpConfirmationId) {
      if (!errr && responseJson.meta.token) {
        sessionStorage.removeItem("token");
        this.setState({ isVerified: true })
        this.handleSignupApi();
      } else {
        this.setState({ error: errr })
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (apiRequestCallId === this.submitSignUp) {
      if (!errr) {
        this.setState({ isLogin: true })
      } else {
        this.setState({
          toast: {
            open: true,
            message: "Something wrong heppen!",
            type: "error"
          }
        })
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (apiRequestCallId === this.resendOtp) {
      if (!errr&&responseJson.meta.token) {
        this.setState({ isLogin: false })
        sessionStorage.setItem("token", responseJson.meta.token)
      }
    }
  };

  handleSubmit = () => {
    this.handleOtpApi();
  };

  goToLogin = () => {
    this.props.navigation.history.push({
      pathname: '/login',
      state: { message: "Signup Successful" }
    })
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (nextProps.otpOpen && !this.props.otpOpen) {
      this.setState({
        second: 60
      })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.interval = setInterval(() => {
      if (this.state.second > 0) {
        this.setState({ second: this.state.second - 1 });
      }
    }, 1000);
  }

  componentWillUnmount(): Promise<void> {
    return new Promise((resolve) => {
      clearInterval(this.interval)
      resolve()
    })
  }

  handleResendOtp = () => {
    this.handleResendApi();
    this.setState({ second: 59 })
  };

  handleTostClose = () => {
    this.setState({
      toast: {
        open: false,
        message: "OTP send successfully",
        type: "success"
      }
    })
  }

  handleOtpApi = () => {
    if (this.state.otp) {
      const body = {
        data: {
          attributes: {
            pin: this.state.otp
          }
        }
      };
      this.otpConfirmationId = sendAPIRequest(configJSON.verifyOtp, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token")
        }
      });
    }
  };

  handleSignupApi = () => {
    const formData = JSON.parse(
      sessionStorage.getItem("signUpData") || ""
    );
    const body = {
      data: {
        type: "email_account",
        attributes: {
          full_name: formData.name,
          email: formData.email,
          password: formData.password,
          phone_number: formData.phone,
          activated: true
        }
      }
    };
    this.submitSignUp = sendAPIRequest(configJSON.signUp, {
      method: "POST",
      body,
      headers: { "Content-Type": "application/json", token: "" }
    });
  };

  handleResendApi = () => {
    const body = {
      data: {
        attributes: {
          email: JSON.parse(sessionStorage.getItem("signUpData") || "")
            .email
        }
      }
    };

    this.resendOtp = sendAPIRequest(configJSON.sendOTPPath, {
      method: "POST",
      body,
      headers: { "Content-Type": "application/json", token: "" }
    });
  };
  // Customizable Area End
}
