import React from 'react'

import { CustomAutocomplete } from "./StyledMuiComponent";
import { CustomTextfield } from "./CustomTextfield.web";

import {
    Box,
    styled,
    Container
} from "@material-ui/core";
const downArrow = require("./downArrow.png")

const CustomDropDown = (props: any) => {
    const { 
        value,
        handleGetValue,
        name,
        list = []
    } = props;
    const renderOption = (option: any, { selected }: any) => {
        return (
            <div style={{ color: selected ? '#000000e0' : '#000000a3' }} >
                {option}
            </div >
        );
    };
    return (
        <MainWrapper>
            <Box className="LB_autocomplete_box">
                <CustomAutocomplete
                    value={value}
                    renderInput={(params: any) => <CustomTextfield
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select"
                    />}
                    onChange={(e: any, value: any) => {
                        handleGetValue(value, name)
                    }}
                    options={list}
                    fullWidth
                    renderOption={renderOption}
                />
                <img className="LB_dropdown_img" src={downArrow} />
            </Box>
        </MainWrapper>
    )
}

export default CustomDropDown

const MainWrapper = styled(Container)({
    width: "100%",
    "& .LB_autocomplete_box": {
        position: "relative"
    },
    "& .LB_dropdown_img": {
        position: "absolute",
        right: "15px",
        bottom: "20px",
        zIndex: 1
    },
});

