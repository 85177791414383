import * as React from 'react';
import { AppBar, Box, Container, Button, Tooltip, MenuItem, Toolbar, IconButton, Menu, Typography, ClickAwayListener, Grow, Paper, Popper, MenuList } from "@material-ui/core";
//@ts-ignore
import { ReactComponent as PocketTax } from "./PocketTax.svg";
//@ts-ignore
import { ReactComponent as GreenPocketTax } from "./GreenPockettax.svg";
import { styled } from "@material-ui/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const pages = [{ name: 'About us', path: "" }, { name: 'Testimonials', path: "Testimonials" }, { name: 'Knowledge base', path: "Knowladgebase" }, { name: 'Make enquiry', path: "" }];
const pages1 = [{ name: 'About us', path: "" }, { name: 'Testimonials', path: "Testimonials" }, { name: 'Knowledge base', path: "Knowladgebase" }, { name: 'Make enquiry', path: "" }, { name: "ITR history", path: "ITRHistory" }];

interface Props {
    handleLogin: () => void;
    handleLogout: () => void;
    navigation: any;
    type: string;
    handleHeaderRoute: (path: string) => void;
    handlemodalopen: () => void;
   handledashboard: () => void;
   handledashprofile: () => void;
   handleToLandingPage: () => void;




}

function MainHeader(props: Props) {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [isLoggedin, setIsLoggedin] = React.useState<boolean>(false);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleToLandingPage=()=>{
        props.handleToLandingPage()
     }

    React.useEffect(() => {
        if (localStorage.getItem('authToken')) {
            setIsLoggedin(true)
        }
        else {
            setIsLoggedin(false)
        }
    }, [])

    return (
        <Wrapper position={props.type === "knowladge" ? "static" : "absolute"} style={{ boxShadow: "none", background: "transparent" }}>
            <Container maxWidth="xl" className="headerContainer">
                <Toolbar disableGutters>
                    <Box sx={{ width: { xl: "190px", lg: "170px" } }}>
                        {props.type === "knowladge" ?
                            <GreenPocketTax  className="greenimagestyle" onClick={handleToLandingPage}/>
                            : <PocketTax style={{ width: "100%", height: "100%",cursor:"pointer" }} onClick={handleToLandingPage} />}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            { (localStorage.getItem("authToken")?pages1:pages).map((page) => (
                                
                                <MenuItem key={page.name} onClick={page.name=="Make enquiry"?props.handlemodalopen:() => props.handleHeaderRoute(page.path)}>
                                    <Typography align="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent="center">
                        {(localStorage.getItem("authToken")?pages1:pages).map((page) => (
                            <Button
                                key={page.name}
                                className="headerMenu"
                                onClick={page.name=="Make enquiry"?props.handlemodalopen:() => props.handleHeaderRoute(page.path)}
                                style={{ margin: 2, color: props.type === "knowladge" ? "black" : "#fff", display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    {!isLoggedin ? <Box sx={{ display: { sm: "flex", xs: "flex", md: "flex" }, width: "150px" }} justifyContent="end">
                        <Button variant="contained" className='login-btn' style={{ width: "75%", fontWeight: 600, color: props.type === "knowladge" ? "#fff" : "#2b674a", background: props.type === "knowladge" ? "#2b674a" : "#fff" }} onClick={props.handleLogin}>Login</Button>
                    </Box> :
                        <MenuListDrawer {...props} />}

                    <Box sx={{ flexGrow: 0, display: { lg: "none", md: "none", xl: "none" } }}>
                        <Tooltip title="Open settings">
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color={props.type === "knowladge" ? "default" : "inherit"}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled(AppBar)({
    "& .headerContainer": {
        padding: "1% 5%",
        "@media(min-width: 1400px)": {
            padding: "1.5% 5%"
        }
    },
    "& .greenimagestyle":{
        maxWidth: "100%", height: "100%", width:'100%',cursor:"pointer"
    },
    "& .headerMenu": {
        "&:hover": {
            color: 'rgb(43, 103, 74) !important',
            backgroundColor: '#e0e0e0 !important'
        },
    },
    "& .login-btn": {
        "&:hover": {
            color:"#ffffff !important",
            backgroundColor: 'rgba(45, 216, 129, 1) !important'
        },
    }
});

export default MainHeader;



export function MenuListDrawer(props: Props) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
            },
            paper: {
                marginRight: theme.spacing(2),
            },
            profilesection:{
                width:'100%',
                [theme.breakpoints.down('sm')]: {
                    width:'unset',
                  },
                 
            },
            fontprofileSize:{
                [theme.breakpoints.down('xs')]: {
                    fontSize:'10px',
                  },
            }
        }),
    );
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleLogout = () => {
        setOpen(false);
        localStorage.clear();
        props.handleLogout()
    };
    const handleProfile=()=>{
        props.handledashprofile()
    }
    const handleDashboard=()=>{
        props.handledashboard()
     }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);
    let storedUsername:any = localStorage.getItem("username")=="null" ? "":localStorage.getItem("username")
    return (
      

        <div className={classes.root}>
            <Box sx={{ display: { sm: "flex", xs: "flex", md: "flex" }, width: "150px" }}  className={classes.profilesection}justifyContent="end">

                <Button variant="contained"
                    ref={anchorRef}
                    aria-haspopup="true"
                    endIcon={<ExpandMoreIcon />}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    style={{  color: props.type === "knowladge" ? "#fff" : "#2b674a", fontWeight: 600, background: props.type === "knowladge" ? "#2b674a" : "#fff" }} onClick={handleToggle}><span className={classes.fontprofileSize}>{(storedUsername) ? "Hi" + " "+ storedUsername.split(" ")[0] : "Hi User"}</span></Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                        <MenuItem onClick={handleDashboard}>Dashboard</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </div>
    );
}
