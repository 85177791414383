// Customizable Area Start
import React from "react";
import StaticSalaryWeb from "./StaticSalary.web";
import CompanyDetailsWeb from "./CompanyDetails.web";
import StaticSalaryController, {
    Props,
} from "./StaticSalaryController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    // Customizable Area Start
    withStyles,
    Box
    // Customizable Area End
} from "@material-ui/core";
export class SalaryNavigation extends StaticSalaryController {
    constructor(props: Props) {
        super(props);
    }
    renderLoaderOrContent() {
      const { formLoading, formsPresent } = this.state;
      const { navigation, classes } = this.props;
      if (formLoading) {
        return (
          <Box className={classes.loaderContainer}>
            <CircularProgress size="4em" />
          </Box>
        );
      } else {
        return formsPresent ? (
          <CompanyDetailsWeb navigation={navigation} id={""} step={0} />
        ) : (
          <StaticSalaryWeb navigation={navigation} id={""} step={0} />
        );
      }
    }
    
    
    render() {
        return (
          this.renderLoaderOrContent()
        );
    }
}


const styles = () => ({
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
  });
export default withStyles(styles)(SalaryNavigation);

// Customizable Area End
