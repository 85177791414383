import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles, Snackbar, Slide,
  Button,
  Input,
  Typography, Grid,
  TextField
}
  from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import OtherIncomesController, { Props, } from "./OtherIncomesController.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deleteRed } from "./assets";

export class OtherRent extends OtherIncomesController {
  constructor(props: Props) {
    super(props);
  }


  render() {

    const { classes } = this.props

    return (
      <>
        <Box style={{ padding: '10px 20px' }}>
          <Snackbar TransitionComponent={Slide} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} open={this.state.snack ? this.state.snack : this.state.snackError} autoHideDuration={4000} onClose={this.handleMessageClose} >
            <Alert severity={this.state.snack ? "success" : "error"}>
              {this.state.snack ? 'Saved Succesfully' : 'Something went wrong'}
            </Alert>
          </Snackbar>

          {this.state.rent.map((item: any, index: any) => (
            <div key={index}>
              <Grid spacing={2} container>
                <Grid sm={5} item>
                  <Typography className={classes.descField1}>Description</Typography>
                        <TextField
                            className={classes.textfiledWrapper}
                            name="descitption"
                            variant="outlined"
                            placeholder="Enter"
                            type="string"
                            value={item.description} 
                            onChange={(e: any) => this.handleOtherRentChangeDesc(index, e.target.value)}
                            data-test-id={`description${index}`}
                            FormHelperTextProps={{
                                style: { margin: '0' },
                            }}
                        />
                </Grid>
                <Grid sm={5} item>
                  <Typography   className={classes.descField1}>Amount</Typography>
                  <Box className={classes.amount}>
                     <Box className={classes.ruppeslogo}>
                          <Typography className={classes.rupeessstyles}>₹</Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          placeholder='00'
                          className={classes.textfiledWrapper1}
                          type="string"
                          onChange={(e: any) => this.handleOtherRentChangeAmt(index, e.target.value)}
                          data-test-id={`amount${index}`}
                          value={String(item.amount) ? Number(item.amount) : ""}
                        />
                  </Box>
                </Grid>
                <Grid sm={2} item style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }}>
                  <img src={deleteRed} data-test-id={`deleteRent${index}`} onClick={() => this.deleteFieldRent(index)} style={{ cursor: 'pointer', marginTop: '1.7rem', paddingRight: '2rem' }} />
                </Grid>
              </Grid>
             
              <Typography style={{fontSize:"12px",color:"red",textAlign:"center",marginTop:"12px"}}> {item.error}</Typography>
            </div>
          ))}
       { this.state?.rent.length == 0 &&  <Typography style={{fontSize:"12px",color:"red",textAlign:"center",marginTop:"12px"}}>{this.getRentErrorMessageByName("rent")}</Typography>}
          <div data-test-id='addField' onClick={() => this.addField()} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '1rem 0 2rem 0' }}>
            <AddCircleIcon style={{ color: '#337152', margin: '0 5px 0 -10px' }} />
            <Typography className={classes.addMore}>Add More Items</Typography>
          </div>
          <Typography className={classes.subHeading}>{'Deduction (depreciation, insurance, machinery loan interest etc.)'}</Typography>
          {this.state.deduction.map((item: any, index: any) => (
            <div key={index}>
              <Grid spacing={2} container>
                <Grid sm={5} item>
                  <Typography className={classes.descField1}>Description</Typography>
                   <TextField
                        className={classes.textfiledWrapper}
                        variant="outlined"
                        placeholder="Enter"
                        name="descitption"
                        value={item.description} 
                        onChange={(e: any) => this.handleDeductionDesc(index, e.target.value)}
                        type="string"
                        data-test-id={`dedDesc${index}`}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                    />
                </Grid>
                <Grid sm={5} item>
                  <Typography className={classes.descField1}>Amount</Typography>
                  <Box className={classes.amount}>
                        <Box className={classes.ruppeslogo}>
                          <Typography className={classes.rupeessstyles}>₹</Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          className={classes.textfiledWrapper1}
                          type="string"
                          placeholder='00'
                          value={String(item.amount) ? Number(item.amount) : ""}
                          data-test-id={`dedAmt${index}`}
                          onChange={(e: any) => this.handleDeductionAmt(index, e.target.value)}
                        />
                  </Box>
                </Grid>
                <Grid sm={2} item style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }}>
                  <img src={deleteRed} data-test-id={`deleteDed${index}`} onClick={() => this.deleteFieldDeduction(index)} style={{ cursor: 'pointer', marginTop: '1.7rem', paddingRight: '2rem' }} />
                </Grid>
              </Grid>
              <Typography style={{fontSize:"12px",color:"red",textAlign:"center",marginTop:"12px"}}> {item.error}</Typography>        
            </div>
          ))}
          {this.state?.deduction.length == 0 && <Typography style={{fontSize:"12px",color:"red",textAlign:"center",marginTop:"12px"}}>{this.getRentErrorMessageByName("deduction")}</Typography>}
          <div data-test-id='addDeduction' onClick={() => this.addDeduction()} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '1rem 0 2rem 0' }}>
            <AddCircleIcon style={{ color: '#337152', margin: '0 5px 0 -10px' }} />
            <Typography className={classes.addMore}>Add More Items</Typography>
          </div>
          <Button onClick={() => this.saveOtherRent()} data-test-id='saveBtn'
            style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
          >Save</Button>

        </Box>
      </>
    )
  }
}

const styles = {

  descField1: {
    color: 'rgba(54, 64, 59, 0.70)',
    fontSize: 18,
    fontFamily: 'Nunito',
    fontWeight: 600,
    fontStyle: 'normal',
  },
  addMore: {
    fontFamily: 'Nunito',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
  },
  subHeading: {
    fontFamily: 'Nunito',
    color: '#242B28',
    fontStyle: 'normal',
    fontSize: 20,
    margin: '1rem 0rem',
    fontWeight: 600,
  },
  amount: {
    display: "flex",
  },
  input: {
    borderRadius: 8,
    border: ' 0.5px solid #C3CAD9',
  },

  ruppeslogo: {
    background: "#ddfef7",
    height: '42px',
    padding: '1em 2em 1em 1em',
    display: "flex",
    borderRadius: "8px 0px 0px 8px",
    alignItems: "center"
  },
  rupeessstyles: {
    fontSize: "14px",
    color: "rgba(54,64,59,0.9)",
    fontFamily: "Nunito",
    fontWeight: 400,
},
textfiledWrapper1: {

  '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
          borderColor: '#c3cad9',
      },
  },
  "& input": {
      padding: "11px 0px 11px 12px",

      border: "1px solid #grey"
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderLeft: 'none',
          borderRadius: "0px 8px 8px 0px",
      },
  },

},
textfiledWrapper: {

  '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
          borderColor: '#c3cad9',
      },
  },
  "& input": {
      padding: "11px 0px 11px 12px",

      border: "1px solid #grey"
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderRadius: "8px 8px 8px 8px",
      },
  },

},

}


export default withStyles(styles)(OtherRent);
// Customizable Area End