import React from "react";

import {
    Box,
    // Customizable Area Start
    
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


// Customizable Area End

import AssessmenttestController, {
    Props,
} from "./AssessmenttestController";
import MainHeader from "../../../components/src/MainHeader.web";



export default class HRACalculator extends AssessmenttestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div style={{ height: '100px',backgroundColor: '#347152',marginBottom:'50px' }}>
                    <MainHeader handleLogout={this.handleLogout} handlemodalopen={this.handlemodalopen} data-aos='zoom-in' navigation={undefined}  handleHeaderRoute={this.handleHeaderRoute} handledashboard={this.navigateToDashBoard} handledashprofile={this.handledashprofile} handleToLandingPage={this.handleToLandingPageTo}  handleLogin={this.handleLoginBtn} type={""}/>
                </div>

                <Box>
                    <Box style={{  display: 'flex', justifyContent: 'center',width: '100%', flexDirection: 'column', alignItems: 'center' }} >

                        <div style={{marginBottom: '5px', width: '85%',  }}>
                            <div style={{ height: '20px',display: 'flex', alignItems: 'center',  }} onClick={this.handlenavigation}>
                                <ArrowBackIosIcon style={{ color: "#347152" }} />
                                <h4 style={{fontWeight: 'normal', color: "#347152",  }}>Back To Home</h4>
                            </div>
                            <h1 style={{ color: "Black" }}>HRA Exemption Calculator</h1>
                        </div>


                        <div style={{ marginBottom: '10px', border: '1px solid #d3d3d3', padding: '2px 35px', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', }}>

                            <h3>Basic Salary</h3>
                            <div>
                                <input style={webStyle.inputBox}

                                    name="HRA_basicSalary"
                                    type="text"
                                    onChange={this.handleHRA_Change2}
                                    value={this.state.HRA_basicSalary}
                                    placeholder="Enter"
                                ></input>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center', padding: '2px 35px', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', marginBottom: '10px' }}>

                            <h3>DA forming part of salary</h3>
                            <div>
                                <input style={webStyle.inputBox}

                                    name="HRA_DAFormingPartOfSalary"
                                    type="text"
                                    onChange={this.handleHRA_Change2}
                                    placeholder="Enter"
                                    value={this.state.HRA_DAFormingPartOfSalary}
                                ></input>
                            </div>
                        </div>
                        <div style={{ borderRadius: '4px', padding: '2px 35px', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>

                            <h3>Commission (as % of turnover achieved by the employee)</h3>
                            <div>
                                <input style={webStyle.inputBox}
                                    name="HRA_CommissionTurnoverAchieved"
                                    type="text"
                                    onChange={this.handleHRA_Change2}
                                    placeholder="Enter"
                                    value={this.state.HRA_CommissionTurnoverAchieved}
                                ></input>
                            </div>
                        </div>
                        <div style={{ padding: '2px 35px', alignItems: 'center', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', marginBottom: '10px' }}>

                            <h3>HRA Recieved</h3>
                            <div>
                                <input style={webStyle.inputBox}
                                    name="HRA_HraRecieved"
                                    type="text"
                                    onChange={this.handleHRA_Change2}
                                    placeholder="Enter"
                                    value={this.state.HRA_HraRecieved}
                                ></input>
                            </div>
                        </div>
                        <div style={{ padding: '2px 35px', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', marginBottom: '10px', alignItems: 'center', }}>

                            <h3>Rent Paid</h3>
                            <div>
                                <input style={webStyle.inputBox}
                                    name="HRA_RentPaid"
                                    type="text"
                                    onChange={this.handleHRA_Change2}
                                    placeholder="Enter"
                                    value={this.state.HRA_RentPaid}
                                ></input>
                            </div>
                        </div>

                        <div style={{ padding: '2px 35px', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', alignItems: 'center', borderRadius: '4px', marginBottom: '10px', justifyContent: 'space-between', }}>

                            <h3>Tick if residing in metro city.</h3>
                            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', width: '180px', height: '33px', }}>
                                <input style={{ height: '20px', width: '20px' }}
                                
                                    type="checkbox"
                                    checked={this.state.HRA_residingInMetro}
                                    onChange={this.handleHRA_CheckboxChange}
                                ></input>
                                <label style={{ marginLeft: '5px', fontSize: '12px', }}>(Tick if Yes)</label>
                            </div>
                        </div>

                        <div style={{ padding: '2px 35px', alignItems: 'center', border: '1px solid #d3d3d3', width: '80%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', }}>

                            <h3>Exempted House Rent Allowance</h3>
                            <div>

                                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px' }}
                                
                                    name="HRA_ExemptedHouseRent"
                                    value={this.state.HRA_ExemptedHouseRent}
                                    onChange={this.handleHRA_Change2}
                                    disabled
                                ></input>
                            </div>
                        </div>
                        <div style={{ width: '80%', padding: '2px 35px', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

                            <h3>Taxable House Rent Allowance</h3>
                            <div>

                                <input style={{ backgroundColor: '#d8d8d8', width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', paddingRight: '10px' }}
                                    name="HRA_TaxableHouseRent"
                                    value={this.state.HRA_TaxableHouseRent}
                                    onChange={this.handleHRA_Change2}
                                    disabled
                                ></input>
                            </div>
                        </div>

                        <div style={{ width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px', marginTop: '50px', }} >
                            <button style={{ height: '45px', width: '170px', color: 'white', backgroundColor: '#347152', fontSize: '16px', margin: '10px', fontWeight: "bold", borderRadius: '4px', fontFamily: 'sans-serif' }}>Calculate</button>

                            <button style={{
                                backgroundColor: 'white',
                                height: '45px', width: '170px', color: '#347152',
                                border: '2px solid #347152', fontSize: '16px', margin: '10px',
                                fontWeight: 'bold', borderRadius: '4px', fontFamily: 'sans-serif',cursor:'pointer',
                            }} onClick={this.handleReset}>Reset</button>
                        </div>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    inputBox : {
        paddingLeft: '10px', 
        width: '180px', 
        height: '33px', 
        border: '1px solid grey', 
        borderRadius: '4px', 
        fontSize: '14px',
    }
}








// Customizable Area End
