import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { creditSalary } from "./assets"
import AddIcon from '@material-ui/icons/Add';

// Customizable Area End

import StaticSalaryController, {
    Props,
    configJSON,
} from "./StaticSalaryController.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import SalaryDetailsEdit from "./SalaryDetailsEdit.web";

export class StaticSalary extends StaticSalaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        return (
            // Customizable Area Start
            <>

                <DashboardPanselector navigation={this.props.navigation} id={""} />
                {this.state.formNumber !== 3 && (
                <Container maxWidth={false} className={classes.mainBackground}>
                    <Box className={classes.cardWrapper} >
                        <img src={creditSalary} className={classes.creditSalaryImage} />
                        <Box className={classes.textContentWrapper} style={{ textAlign: "center" }}>
                            <Typography variant="h5" className={classes.headingText}>{configJSON.dummyText}</Typography>
                        </Box>
                        <Box className={classes.textContentWrapperSub} style={{ textAlign: "center" }} >
                            <Typography variant="subtitle2" className={classes.headingTextSub}>{configJSON.dummySub}</Typography>
                        </Box>
                        <Box className={classes.buttonWrappper} style={{ textAlign: "center" }}>
                            <Button className={classes.form16Button} onClick={this.handleNavigateToDoc} data-test-id="navigateToUpload">{configJSON.UploadForm16}</Button>
                            <Button className={classes.employerButton} onClick={this.handleNavigateToForm2}>  <AddIcon /> {configJSON.Addemployer}</Button>
                        </Box>
                    </Box>
                </Container>)}

                {this.state.formNumber == 3 &&
                    <SalaryDetailsEdit data-test-id="salary_tabmanual" navigation={this.state.formsPresent} id={this.state.activeId} classes={undefined} activeStep={undefined} steps={undefined} accordianSteps={undefined} step={0} />
                }
            </>
            // Customizable Area End
        );
    }
}


// Customizable Area Start
const styles = {
    creditSalaryImage: {
        maxWidth: "308.3px",
        width: "100%",
        height: "auto"


    },
    cardWrapper: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center",


    },
    mainBackground: {
        borderRadius: "16px",
        background: "#F4F6F5",
        height: "auto",
        margin: '2em',
        "@media(max-width:767px)": {
            margin: '0',
        },
    },
    textContentWrapper: {
        marginTop: '2em',

    },
    textContentWrapperSub: {
        marginTop: '2em',
        width: '400px',
        "@media(max-width:664px)": {
            width: 'unset',
        },
    },
    headingText: {
        color: "#36403B",
        fontSize: "29px",
        fontWeight: 500,
        fontFamily: 'Nunito'
    },
    headingTextSub: {
        color: "#36403B",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: 'Nunito'
    },
    form16Button: {
        borderRadius: "8px",
        background: "#337152",
        color: "#fff",
        width: "170px",
        fontWeight: 500,
        fontFamily: 'Nunito',
        padding: '0.5em 0 0.5em 0',
        '&:hover': {
            background: "#337152",
        },
    },
    employerButton: {
        borderRadius: "8px",
        background: "#FFF5DC",
        color: "#C7AA61",
        width: "170px",
        fontWeight: 500,
        fontFamily: 'Nunito',
        padding: '0.5em 0 0.5em 0',
        margin: "0 0 0 2em",
        '&:hover': {
            background: "#FFF5DC",
        },
        " & .MuiButton-label": {
            justifyContent: "space-evenly"
        }, "@media(max-width:512px)": {
            margin: "0",
        },


    },
    buttonWrappper: {
        display: "flex",
        flexDirection: "row" as const,
        margin: "4em 0 5em 0",

        "@media(max-width:512px)": {
            flexDirection: "column",
            alignItems: "center",
            gap: "20px"
        },
    },

};

export default withStyles(styles)(StaticSalary);
// Customizable Area End

