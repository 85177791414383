import React from "react";
// Customizable Area Start
import {
  Box,Snackbar, Slide,
  withStyles,
  Button, Radio, FormControl, RadioGroup, FormControlLabel,
  Input,
  Typography, Grid, TextField,
}
  from "@material-ui/core";
  import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OtherIncomesController, { Props, configJSON } from "./OtherIncomesController.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deleteRed, editIcon, } from "./assets";

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: ' green[600]',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


export class OtherExemptIncomes extends OtherIncomesController {
  constructor(props: Props) {
    super(props);
  }

  commonGrid(state: any, title: any, value: any, subtitle?: any,) {
    const { classes } = this.props
    return (
      <Grid spacing={2} container style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Grid sm={6} item >
          <Typography className={classes.descField}>{title}</Typography>
          <Typography className={classes.descField}>{subtitle}</Typography>

        </Grid>
        <Grid sm={6} item>
          <Box className={classes.amount}>
            <Box className={classes.ruppeslogo}>
              <Typography className={classes.rupeessstyles}>₹</Typography>
            </Box>
            <Box id="main">
            <TextField
              className={classes.textfiled}
              variant="outlined"
              placeholder='00'
              type="string"
              value={value}
              onChange={(e: any) => this.handleExemptChange(state, e.target.value)}
              data-test-id={`commonInput-${state}`}
            />
            <Typography style={{fontSize:"12px",color:"red"}}>{this.getErrorMessageByName(state)}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  addLandGrid(state: any, title: any, value: any, subtitle?: any,) {
    const { classes } = this.props
    return (
      <Grid spacing={2} container style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Grid sm={6} item >
          <Typography className={classes.descField}>{title}</Typography>
          <Typography className={classes.descField}>{subtitle}</Typography>

        </Grid>
        <Grid sm={6} item>
        <Box id="main">
          <TextField  variant="outlined" className={classes.textfiledWrapper}  style={{ height: '2.5rem' }} data-test-id={`commonInput-${state}`}
            value={value} onChange={(e: any) =>  this.handleLandsChange(state, e.target.value)} fullWidth placeholder="Enter" />
           <Typography style={{fontSize:"12px",color:"red",marginTop:"6px"}}>{this.getLandsErrorMessageByName(state)}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  generateDropdownOptions(currentIndex: number) {
    const selectedDescriptions = this.state.otherExemptIncomes.map((item: any, index: number) => index !== currentIndex && item.description).filter(Boolean);

    return this.state.anyOtherExpIncDropDown.filter(
        (item: { description: string }) => !selectedDescriptions.includes(item.description)
    );
}

  render() {

    const { classes } = this.props
    return (
      <>

        {
          !this.state.addLandExe ?
            <Box style={{ padding: 10 }}>

              <div className={classes.dialogTitle1} >
                <Typography className={classes.dialogTitleText}>Exempt Income</Typography>

                <CloseIcon
                  data-test-id='exeClose'
                  className={classes.closeBtn1}
                  onClick={() => this.props.addLandFn()}
                />
              </div>

              {this.commonGrid('exemptInt', 'Exempt Interest', this.state.exemptInt, '(from PPF and Sukanya Samriddhi)')}
              {this.commonGrid('exemptAgriInc', 'Agricultural Income', this.state.exemptAgriInc,)}
              {this.commonGrid('exemptAgriExpenses', 'Agricultural Expenses', this.state.exemptAgriExpenses,)}


              <Box className={classes.greenBox}>
                <Typography className={classes.greenText}>{configJSON.landGreenText} </Typography>
              </Box>

              {this.state.landDetails.map((item: any, index: any) => (
                <Grid container spacing={2} style={{ border: '1px solid #ccc', borderRadius: 8, margin: '10px -4px' }}>
                  <Grid sm={10} style={{ padding: 15 }}>
                    <Typography >{item.name_of_district} </Typography>

                  </Grid>
                  <Grid sm={1}> <Button data-test-id="editLand" style={{ padding: 10 }} onClick={() => this.editLandDetails(index)} ><img src={editIcon} />   </Button></Grid>
                  <Grid sm={1}>
                    <Button style={{ padding: 10 }} data-test-id="delete_land" onClick={() => this.deleteLandDetails(index)} > <img src={deleteRed} />
                    </Button>
                  </Grid>
                </Grid>
              ))}


              <Button data-test-id='addLandBtn' onClick={() => this.setState({ addLandExe: true,landId:null,name_of_district:"", pin_code:"",checkbox:"",irrigation:"" })}
                style={{
                  borderRadius: 8, background: '#FFF5DC',
                  color: '#C7AA61', marginTop: '1rem',
                  fontFamily: 'Nunito', width: '30%',
                  fontSize: 18,
                  fontWeight: 600,
                }}>+ Add Land</Button>
            <Typography style={{fontSize:"12px",color:"red"}}>{this.getErrorMessageByName("landDetails")}</Typography>

              <Typography className={classes.subHeading}>Any Other Exempt Income</Typography>
              <Box>
              {this.state.otherExemptIncomes.map((item: any, index: any) => (
                <div key={index}>
                  <Grid spacing={2} container>
                    <Grid sm={5} item>
                      <Typography className={classes.descField}>Description</Typography>
                            <select
                              className={classes.otherIncomeDropDown}
                              data-test-id={`description${index}`}
                              value={item.description}
                              onChange={(e: any) => this.handleOtherExemptChangeDesc(index, e.target.value)}
                            >
                              <option value="">Select</option>
                              {this.generateDropdownOptions(index).map((option: { description: string, code: string }) => (
                                <option key={option.description} value={option.description}>
                                  {option.code} - {option.description}
                                </option>
                              ))}
                            </select>
                          <Typography style={{ fontSize: "12px", color: "red" }}>{this.state.errorGroup.otherExamptIncome[index]}</Typography>
                    </Grid>
                    <Grid sm={5} item>
                      <Typography className={classes.descField}>Amount</Typography>
                        <Box className={classes.amount}>
                            <Box className={classes.ruppeslogo}>
                              <Typography className={classes.rupeessstyles}>₹</Typography>
                            </Box>
                            <TextField
                              className={classes.textfiled}
                              variant="outlined"
                              placeholder='00'
                              type="string"
                              value={this.otherExemptIncomesInputValidation(item.amount)}
                              onChange={(e: any) => this.handleOtherExemptChangeAmt(index, e.target.value)}
                              data-test-id={`amount${index}`}
                            />
                          </Box>
                          <Typography style={{ fontSize: "12px", color: "red" }}>{this.state.errorGroup.otherExamptIncome2[index]}</Typography>
                    </Grid>
                    <Grid sm={2} item>
                      <img src={deleteRed} data-test-id={`deleteRent${index}`} onClick={() => this.deleteOtherExempts(index, item.id)} style={{ cursor: 'pointer', marginTop: '1.7rem', paddingRight: '2rem' }} />
                    </Grid>
                  </Grid>
                </div>
              ))}
              </Box>

              <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', margin: '1rem 0 2rem 0', cursor: 'pointer' }} onClick={this.addExemptIncomes} data-test-id='addMore'>
                <AddCircleIcon style={{ color: '#337152', marginRight: '0 5px 0 -10px' }} />
                  <Typography className={classes.addMore}>Add More Items</Typography>
              </div>
              <Button data-test-id='saveBtn' onClick={this.saveOtherExempt}
                style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
              >Save</Button>
              <Snackbar
                TransitionComponent={Slide}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={this.state.snack}
                autoHideDuration={2000}
                onClose={(event,reason)=>{
                  this.handleMessageClose(event,reason);
                  }} 
                data-test-id="ExemptSnack"
              >
                <Alert severity={this.state.errorType}>
                  {this.state.snackMessage}
                </Alert>
              </Snackbar>

            </Box > : 
            <Box
              style={{
                backgroundColor: "#fff", justifyContent: 'center', width: '40rem',
                border: "1px solid #fff", borderRadius: "10px",
                padding: 10
              }}

            >
              <div className={classes.dialogTitle1} >

                <Typography className={classes.dialogTitleText}>
                    <Box id="conatinerBox" className={classes.conditionalheading}>
                        <ArrowBackIosIcon data-test-id="backIcon"  onClick={() => this.setState({ addLandExe: false, })}  style={{color: "#337152",}} fontSize="medium"  /><Typography style={{fontSize:"20px",fontWeight:600}}  className={classes.donationdheading}> Add Land</Typography>
                    </Box>
                 </Typography>
                <CloseIcon
                 fontSize="medium" 
                  className={classes.landcloseBtn}
                  data-test-id="closeIcon"
                  onClick={() => this.setState({ addLandExe: false, })}
                />
              </div>
              <div style={{ padding: 10 }}
              >

                {this.addLandGrid('name_of_district', 'Name of District', this.state.name_of_district)}
                {this.addLandGrid('pin_code', 'Pincode', this.state.pin_code)}

                <Grid spacing={2} container style={{ padding: 10, marginBottom: 10, alignItems: 'center' }}>
                  <Grid sm={3}>
                    <Typography className={classes.descField} >Ownership</Typography>
                  </Grid>
                  <FormControl component="fieldset" >
                    <RadioGroup className={classes.radioGroup} row
                      data-test-id='radioGroup' onChange={(val: any) => {
                        this.setState({ checkbox: val.target.value },()=>{
                          this.getaddLandsValidation()
                      });
                      }} >
                      <FormControlLabel  data-test-id='radio1'  checked={this.state.checkbox === "land1_checkbox"} className={classes.labelHeading1} value="land1_checkbox" control={<GreenRadio  />} label="Owned" />
                      <FormControlLabel data-test-id='radio2' checked={this.state.checkbox === "land2_checkbox"} className={classes.labelHeading1} value="land2_checkbox" control={<GreenRadio />} label="Leased" />
                    </RadioGroup>

                  </FormControl>
                  <Typography style={{fontSize:"12px",color:"red"}}>{this.getLandsErrorMessageByName("checkbox")}</Typography>
                </Grid>
                <Grid spacing={2} container style={{ padding: 10, marginBottom: 10, alignItems: 'center' }}>
                  <Grid sm={3}>
                    <Typography className={classes.descField} style={{ width: '30%' }}>Irrigation</Typography>
                  </Grid>
                  <FormControl component="fieldset" >
                    <RadioGroup  data-test-id='radioGroup1' className={classes.radioGroup} row
                      onChange={(val: any) => {
                        this.setState({ irrigation: val.target.value },()=>{
                          this.getaddLandsValidation()
                      });
                      }} >
                      <FormControlLabel className={classes.labelHeading1} checked={this.state.irrigation === "land1_checkbox"} value="land1_checkbox" control={<GreenRadio />} label="Irrigated" />
                      <FormControlLabel className={classes.labelHeading1} checked={this.state.irrigation === "land2_checkbox"} value="land2_checkbox" control={<GreenRadio />} label="Non Irrigated" />
                    </RadioGroup>
                  </FormControl>
                  <Typography style={{fontSize:"12px",color:"red"}}>{this.getLandsErrorMessageByName("irrigation")}</Typography>
                </Grid>
                <Button data-test-id='saveBtnLand' onClick={() => this.saveLandDetails(this.state.name_of_district, this.state.pin_code, this.state.checkbox, this.state.irrigation)}
                  style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
                >Save</Button>
              </div>

              <Snackbar TransitionComponent={Slide} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }} open={this.state.snack ? this.state.snack : this.state.snackError} autoHideDuration={4000} onClose={this.handleMessageClose} >
                <Alert severity={this.state.snack ? "success" : "error"}>
                  {this.state.snack ? 'Saved Succesfully' : 'Something went wrong'}
                </Alert>
              </Snackbar>
            </Box>
        }
      </>
    )
  }
}

const styles = {
  dialogTitleText: {
    fontSize: 27,
    color: '#36403B',
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20rem',
    marginLeft: '5rem'
  },
  labelHeading1: {
    color: 'rgba(54, 64, 59, 0.70)',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontSize: 18,
    fontWeight: 600
  },
  closeBtn1: {
    position: "absolute" as const,
    color: "#337152",
    top: 3,
    right: 6,
    cursor: "pointer",
  },
  landcloseBtn: {
    position: "absolute" as const,
    color: "#337152",
    right: 18,
    cursor: "pointer",
  },
  addMore: {
    color: '#000',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600
  },
  input: {
    border: ' 0.5px solid #C3CAD9',
    borderRadius: 8
  },
  greenText: {
    color: '#337152',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500
  },
  ruppeslogo: {
    padding: '1em 1.7em 1em 1em',
    background: "#ddfef7",
    borderRadius: "8px 0px 0px 8px",
    height: '42px',
    alignItems: "center",
    display: "flex",
  },
  dialogTitle1: {
    position: 'relative' as const,
    display: "flex",
    paddingLeft: 15,
    paddingBottom:12
  },
  textfiled: {

    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: '#c3cad9',
      },
    },
    "& input": {
      border: "1px solid #grey",
      padding: "11px 0px 11px 12px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "0px 8px 8px 0px",
        borderLeft: 'none',
      },
    },
  },
  textfiledWrapper: {

    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: '#c3cad9',
      },
    },
    "& input": {
      border: "1px solid #grey",
      padding: "11px 0px 11px 12px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "8px",
      },
    },

},
  otherIncomeDropDown: {
    width: '100%',
    height: '42px',
    border: "1px solid grey",
    background: 'transparent',
    outline: 'none',
    borderRadius: '8px',
    overflow: 'hidden', 
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap' as 'nowrap',
  },
  amount: {
    display: "flex",
  },
  rupeessstyles: {
    fontSize: "14px",
    color: "rgba(54,64,59,0.9)",
    fontFamily: "Nunito",
    fontWeight: 400,
  },


  descField: {
    color: 'rgba(54, 64, 59, 0.70)',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600
  },
  greenBox: {
    borderRadius: 10,
    border: '1px solid #337152',
    background: '#E5F7EE',
    padding: 10
  },
  subHeading: {
    color: '#242B28',
    fontFamily: 'Nunito',
    fontSize: 20,
    fontStyle: 'normal',
    margin: '1rem 0rem',
    fontWeight: 600
  },
  conditionalheading: {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    gap: '4px',
},
}


export default withStyles(styles)(OtherExemptIncomes);
// Customizable Area End