import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

interface Response {
  /** This field is the returned ID token */
  credential?: string;
  clientId?: string;
  /** This field sets how the credential is selected */
  select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'brn_add_session' | 'btn_confirm_add_session';
}
interface Props {
  handleGoogleLogin: (res?: string) => void
}

const GoogleLoginComponent = (props: Props) => {

  function responseGoogle(response: Response) {
    props.handleGoogleLogin(response.credential)
  }
  return (
    <div className="">
      <div className="">
        <GoogleOAuthProvider
          clientId={"692315649443-u90phq6knstrltj68isvtkb2kevi702a.apps.googleusercontent.com"}
        >
          <GoogleLogin
            text='continue_with'
            onSuccess={responseGoogle}
            size='large'
            auto_select={false}
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  )
}

export default GoogleLoginComponent