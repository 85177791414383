import React from "react";
// Customizable Area Start
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// Customizable Area End

import TermsConditionsController, { Props } from "./TermsConditionsController";

export default class PrivacyPolicy extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <Dialog
                onClose={this.props.handleClose}
                open={this.props.open}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                disableScrollLock={true}
                PaperProps={{
                    style: {
                        maxHeight: "70%"
                    },
                    elevation: 0,
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {'Privacy Policy'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText  id="alert-dialog-description">
                        <p dangerouslySetInnerHTML={{__html:this.props.description}}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End