import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from 'uuid';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface EmptyJsonObject {
  [key: string]: string | null;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeState: number;
  Regime: boolean;
  submitteddata:any;
  toast :{
    open:boolean;
    message:string
  };
  permanentInformation: {
    first_name: { value: string, },
    middle_name: { value: string, },
    last_name: { value: string, },
    date_of_birth: { value: string, },
    father_name: { value: string, },
    gender: { male: boolean, female: boolean, },
    marital_status: { married: boolean, unmarried: boolean, prefernottodisclose: boolean, },
  }
  identificationContactDetails: {
    adhar_number: { value: string },
    adhar_enroll_number: { value: string },
    pan_card_number: { value: string },
    secondary_mobile_number: { value: string },
    secondary_email: { value: string },
    landline_number: { value: string },
  }
  yourAddress: {
    flat_number: { value: string, error: string | null },
    premise_name: { value: string, error: string | null },
    street_name: { value: string, error: string | null },
    locality: { value: string, error: string | null },
    pin_code: { value: string, error: string | null },
  }
  residentialStatus: { value: string }
  primaryBank: {
    primary_account_number: { value: string },
    primary_ifsc_code: { value: string },
    primary_bank_name: { value: string },
    primary_bank_type: { value: string },
  };
  secondaryBank: {
    id: string,
    secondary_account_number: { value: string, error: string | null, name: string },
    secondary_ifsc_code: { value: string, error: string | null, name: string },
    secondary_bank_name: { value: string, error: string | null, name: string },
    secondary_bank_type: { value: string, error: string | null, name: string }
  }[];
  permanentInformationError: EmptyJsonObject;
  identificationContactDetailsError: EmptyJsonObject;
  yourAddressError: EmptyJsonObject
  primaryBankError: EmptyJsonObject
  additionalInformation: any
  residentialError: string | null;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Multipageforms2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveGeneralInformationID: string = '';
  submitGeneralInformationID:string ="";
  getDraftDataID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeState: 0,
      Regime: false,
      submitteddata:{},
      toast: {
        open:false,
        message:""
      },
      permanentInformation: {
        first_name: { value: "" },
        middle_name: { value: "" },
        last_name: { value: "" },
        date_of_birth: { value: "" },
        father_name: { value: "" },
        gender: { male: false, female: false },
        marital_status: { married: false, unmarried: false, prefernottodisclose: false },
      },
      identificationContactDetails: {
        adhar_number: { value: "" },
        adhar_enroll_number: { value: "" },
        pan_card_number: { value: "" },
        secondary_mobile_number: { value: "" },
        secondary_email: { value: "" },
        landline_number: { value: "" },
      },
      yourAddress: {
        flat_number: { value: "", error: null },
        premise_name: { value: "", error: null },
        street_name: { value: "", error: null },
        locality: { value: "", error: null },
        pin_code: { value: "", error: null },
      },
      residentialStatus: { value: "" },
      primaryBank: {
        primary_account_number: { value: "" },
        primary_ifsc_code: { value: "" },
        primary_bank_name: { value: "" },
        primary_bank_type: { value: "" },
      },
      secondaryBank: [{
        id: uuidv4(),
        secondary_account_number: { value: "", error: null, name: "secondary_account_number" },
        secondary_ifsc_code: { value: "", error: null, name: "secondary_ifsc_code" },
        secondary_bank_name: { value: "", error: null, name: "secondary_bank_name" },
        secondary_bank_type: { value: "", error: null, name: "secondary_bank_type" },
      }],
      permanentInformationError: {},
      identificationContactDetailsError: {},
      yourAddressError: {},
      primaryBankError: {},
      additionalInformation:  Array(5).fill([""]),
      residentialError: ""
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.saveGeneralInformationID === apiRequestCallId) {
        this.setState({toast:{open:true,message:"Data Save Successfully"}})
        setStorageData("Draft_data", JSON.stringify(responseJson));
        this.getDraftData()
      }

      if (this.submitGeneralInformationID === apiRequestCallId){
        this.setState({activeState:0,toast:{open:true,message:"Data Submitted Successfully"}})
      }

      if (this.getDraftDataID === apiRequestCallId) {
        this.handleDraftResponse(responseJson)
      };
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
   this.getDraftData()
    // Customizable Area End
}

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  handleIncreaseActiveState = async(onlyDraft:boolean=false) => {
      if (this.state.activeState === 0) {
        this.handleValidatePermanentInformation(onlyDraft)
        this.costumStepperData("submitted")
      } else if (this.state.activeState === 1) {
        this.handleValidateIdentificationContact(onlyDraft)
        this.costumStepperData("submitted")
      } else if (this.state.activeState === 2) {
        this.handleValidateAddress(onlyDraft)
        this.costumStepperData("submitted")
      } else if (this.state.activeState === 3) {
        this.handleSaveandDraft("submitted")
        this.setState({
          activeState: this.state.activeState + 1
        })
        this.costumStepperData("submitted")

      } else if (this.state.activeState === 4) {
        this.handleValidatePrimaryBank(onlyDraft)
        this.costumStepperData("submitted")
      }
      else if (this.state.activeState === 5){
        this.handleSubmit("submitted")
      }
  }

  handleValidatePermanentInformation = (onlyDraft:boolean=false) => {
    const newErrors: EmptyJsonObject = {}
    let isValid = true;
    if (this.state.permanentInformationError.first_name || !this.state.permanentInformation.first_name.value.trim()) {
      newErrors.first_name = "Required!";
      isValid = false
    } 

    
    
    if (this.state.permanentInformationError.last_name || !this.state.permanentInformation.last_name.value.trim()) {
      newErrors.last_name = "Required!";
      isValid = false
    } 
    
    if (this.state.permanentInformationError.father_name || !this.state.permanentInformation.father_name.value.trim()) {
      newErrors.father_name = "Required!";
      isValid = false
    } 

    if (this.state.permanentInformation.date_of_birth.value === "" ||  this.state.permanentInformationError.date_of_birth != undefined) {
      newErrors.date_of_birth = "Required!"
      isValid = false
    }
    this.handleValidateGender(newErrors)
    this.handleValidateMaritalStatus(newErrors)
    if (Object.keys(newErrors).length > 0) {
      this.setState({
        permanentInformationError: newErrors
      })
    } else {
      this.handleSaveandDraft("submitted")
      this.setState({
        activeState: onlyDraft ? this.state.activeState: this.state.activeState + 1,
        permanentInformationError: {}
      })
    }
    return isValid

  }
  handleValidateGender = (newErrors: EmptyJsonObject) => {
    const genderData = this.state.permanentInformation.gender
    if (genderData["female"] === false && genderData["male"] === false) {
      newErrors.gender = "Required!"
    }
  }
  handleValidateMaritalStatus = (newErrors: EmptyJsonObject) => {
    const maritalStatusData = this.state.permanentInformation.marital_status
    if (maritalStatusData["married"] === false && maritalStatusData["unmarried"] === false && maritalStatusData["prefernottodisclose"] === false) {
      newErrors.marital_status = "Required!"
    }
  }
  handleDecreaseActiveState = () => {
    if (this.state.activeState > 0)
      this.setState({
        activeState: this.state.activeState - 1
      })
  }
  handleSetRegime = (e: any) => {
    this.setState({
      Regime: e.target.checked
    })
  }
  handleGetPermanentInformation = (e: { target: { value: string, name: string } }) => {
    const { value, name } = e.target;
    const trimmedValue = value.trim();
  
    if (name === "middle_name" || name === "father_name" || name === "first_name" || name === "last_name") {
      if (trimmedValue === "" || !/^[a-zA-Z\s]+$/.test(trimmedValue)) {
        this.setState((prevState) => ({
          permanentInformation: {
            ...prevState.permanentInformation,
            [name]: { value }
          },
          permanentInformationError: {
            ...prevState.permanentInformationError,
            [name]: "Invalid!"
          }
        }));
      } else {
        this.setState((prevState) => ({
          permanentInformation: {
            ...prevState.permanentInformation,
            [name]: { value }
          },
          permanentInformationError: {
            ...prevState.permanentInformationError,
            [name]: ""
          }
        }));
      }
    } else if (value === "") {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          [name]: { value }
        },
        permanentInformationError: {
          ...prevState.permanentInformationError,
          [name]: "Required!"
        }
      }));
    } else if (!/^[a-zA-Z]+$/.test(value)) {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          [name]: { value }
        },
        permanentInformationError: {
          ...prevState.permanentInformationError,
          [name]: "Invalid!"
        }
      }));
    } else {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          [name]: { value }
        },
        permanentInformationError: {
          ...prevState.permanentInformationError,
          [name]: null
        }
      }));
    }
  }

  handleGetDateOfBirth = (date: any) => {
    const formError = { ...this.state.permanentInformationError };
    const selectedDate = new Date(date); 
    const today = new Date();
    
    let age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
    const dayDiff = today.getDate() - selectedDate.getDate();
  
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    if (age < 18) {
      formError.date_of_birth = "Age must be 18 years or older.";
    } else {
      delete formError.date_of_birth;
    }
  
    let finaldate =  moment(selectedDate).format("DD/MM/YYYY").toString()
    this.setState((prevState) => ({
      permanentInformation: {
        ...prevState.permanentInformation,
        date_of_birth: { value: finaldate.toString(), error: formError.date_of_birth }
      },
      permanentInformationError: formError
    }));
  }
  
  handleGetGender = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.target as HTMLInputElement
    const formError = this.state.permanentInformationError
    if (name === "male") {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          gender: { male: true, female: false, error: null }
        }
      }))
    } else {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          gender: { male: false, female: true, error: null }
        }
      }))
    }
    delete formError.gender
    this.setState({
      permanentInformationError: formError
    })

  }
  handleGetMaritalStatus = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.target as HTMLInputElement
    const formError = this.state.permanentInformationError
    if (name === "married") {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          marital_status: { married: true, unmarried: false, prefernottodisclose: false, error: null }
        }
      }))
    } else if (name === "unmarried") {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          marital_status: { married: false, unmarried: true, prefernottodisclose: false, error: null }
        }
      }))
    } else if (name === "prefernottodisclose") {
      this.setState((prevState) => ({
        permanentInformation: {
          ...prevState.permanentInformation,
          marital_status: { married: false, unmarried: false, prefernottodisclose: true, error: null }
        }
      }))
    }
    delete formError.marital_status
    this.setState({
      permanentInformationError: formError
    })

  }

  handleGetIdentificationAndContactDetails = (e: { target: { value: string, name: string } }) => {
    const { name, value } = e.target

    this.setState((prevState) => ({
      identificationContactDetails: {
        ...prevState.identificationContactDetails,
        [name]: { value: value }
      }
    }))
    if (name === "adhar_number") {
      this.validateAdharNumber(value, name)
    } else if (name === "adhar_enroll_number") {
      this.validateAdharEnrollNumber(value, name)
    } else if (name === "pan_card_number") {
      this.validatePanCardNumber(value, name)
    } else if (name === "secondary_email") {
      this.validateSecondaryEmail(value, name)
    } else if (name === "landline_number") {
      this.validateLandlineNumber(value, name)
    } else if (name === "secondary_mobile_number") {
      this.handleGetSecondaryPhoneNumberCustomTextfield(value, name)
    }
  }

  aadharValidation = (value:string) =>{
    let error = null || "";
  
    if (value !== "") {
      if (/\D/.test(value)) {
        error = "Aadhar Number should only contain numbers.";
      } else if (value.length > 12) {
        error = "Aadhar Number must not exceed 12 digits.";
      } else if (value.length !== 12) {
        error = "Aadhar Number must be exactly 12 digits.";
      } else if (/^[01]/.test(value)) {
        error = "Aadhar Number should not start with 0 or 1.";
      } else if (/^(\d)\1*$/.test(value)) {
        error = "Aadhar Number should not consist of a single digit repeated.";
      } 
    }
    return error
  }
  validateAdharNumber = (value: string, name: string) => {
    const error = this.aadharValidation(value);
  
    this.setState((prevState) => ({
      identificationContactDetailsError: {
        ...prevState.identificationContactDetailsError,
        [name]: error
      }
    }));
  }

  aadharEnrollValidation = (value: string) => {
    let error = null || "";

    if (value !== "") {
       if (/\D/.test(value)) {
        error = "Aadhar Enrollment Number should only contain numbers.";
      } else if (value.length > 14) {
        error = "Aadhar Enrollment Number must not exceed 14 digits.";
      } else if (value.length !== 14) {
        error = "Aadhar Enrollment Number must be exactly 14 digits.";
      } else if (/^[01]/.test(value)) {
        error = "Aadhar Enrollment Number should not start with 0 or 1.";
      } else if (/^(\d)\1*$/.test(value)) {
        error = "Aadhar Enrollment Number should not consist of a single digit repeated.";
      } else if (!/^\d{4}\d{5}\d{5}$/.test(value)) {
        error = "Aadhar Enrollment Number should be in the format XXXX XXXXX XXXXX.";
      }
    }
    return error
  }
  validateAdharEnrollNumber = (value: string, name: string) => {
    
    const error = this.aadharEnrollValidation(value)
    this.setState((prevState) => ({
      identificationContactDetailsError: {
        ...prevState.identificationContactDetailsError,
        [name]: error
      }
    }));

  }

  validatePanCardNumber = (value: string, name: string) => {
    let error = null || "";

  if (value !== "") {
    const panRegex = /[A-Z]{5}\d{4}[A-Z]/;
    if (!panRegex.test(value)) {
      error = "Invalid PAN Card Number";
    } else if (value.length !== 10) {
      error = "PAN Card Number must be exactly 10 characters";
    }
  } else {
    error = "PAN Card Number is required";
  
  }

  this.setState((prevState) => ({
    identificationContactDetailsError: {
      ...prevState.identificationContactDetailsError,
      [name]: error
    }
  }));
  }
  
  validateSecondaryEmail = (value: string, name: string) => {
    const trimmedValue = value.trim();
  
    if (trimmedValue === "") {
      this.setState((prevState) => ({
        identificationContactDetailsError: {
          ...prevState.identificationContactDetailsError,
          [name]: "Invalid Email!"
        }
      }));
    } else {
      this.setState((prevState) => ({
        identificationContactDetailsError: {
          ...prevState.identificationContactDetailsError,
          [name]: null
        }
      }));
    }
  }
  
  validateLandlineNumber = (value: string, name: string) => {
    const trimmedValue = value.trim();
    const isOnlyDigits = /^\d+$/.test(trimmedValue)
  
    if (trimmedValue === "" || !isOnlyDigits) {
      this.setState((prevState) => ({
        identificationContactDetailsError: {
          ...prevState.identificationContactDetailsError,
          [name]: "Invalid Landline Number!"
        }
      }));
    } else {
      this.setState((prevState) => ({
        identificationContactDetailsError: {
          ...prevState.identificationContactDetailsError,
          [name]: null
        }
      }));
    }

  }
  handleGetSecondaryPhoneNumberCustomTextfield = (value: string, name: string) => {
    this.setState((prevState) => ({
      identificationContactDetails: {
        ...prevState.identificationContactDetails,
        secondary_mobile_number: { value: value }
      }
    }))
  }
  handleGetSecondaryPhoneNumber = (phone: any) => {
    this.setState((prevState) => ({
      identificationContactDetails: {
        ...prevState.identificationContactDetails,
        secondary_mobile_number: { value: phone }
      }
    }))
  }
  handleValidateIdentificationContact = (onlyDraft:boolean=false) => {
    const newErrors: EmptyJsonObject = {}
    let isValid = true;
    if(!this.handleValidateAadharCard(newErrors)) {
      isValid = false
    }
    if(!this.handleValidatePanCard(newErrors)) {
      isValid = false
    }
    if (!/^\d{8,12}$/.test(this.state.identificationContactDetails.landline_number.value) && this.state.identificationContactDetails.landline_number.value !== "") {
      newErrors.landline_number = "Please Enter a valid Number (Number should contains 10 digits only)"
      isValid = false
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.identificationContactDetails.secondary_email.value) && this.state.identificationContactDetails.secondary_email.value !== "") {
      newErrors.secondary_email = "Invalid Email!"
      isValid = false
    }
    if (Object.keys(newErrors).length > 0) {
      this.setState({
        identificationContactDetailsError: newErrors
      })
    } else {
      this.handleSaveandDraft("submitted")
      this.setState({
        activeState: onlyDraft ? this.state.activeState: this.state.activeState + 1,
        identificationContactDetailsError: {}
      })

    }
    return isValid
  }
  handleValidateAadharCard = (newErrors: EmptyJsonObject) => {
    let isValid = true;
    const error = this.aadharValidation(this.state.identificationContactDetails.adhar_number.value)
    const enrollError = this.aadharEnrollValidation(this.state.identificationContactDetails.adhar_enroll_number.value)
    if (!/^\d{4}\d{4}\d{4}$/.test(this.state.identificationContactDetails.adhar_number.value) && this.state.identificationContactDetails.adhar_number.value !== "") {
      newErrors.adhar_number = 'Invalid aadhar number';
      isValid = false
    }
    if(error!=""){
      newErrors.adhar_number = error;
      isValid = false
    }
    if (this.state.identificationContactDetails.adhar_enroll_number.value.length !== 14 && this.state.identificationContactDetails.adhar_enroll_number.value !== "") {
      newErrors.adhar_enroll_number = 'Invalid aadhar enrollment number';
      isValid = false
    }
    if(enrollError!=""){
      newErrors.adhar_enroll_number = enrollError;
      isValid = false
    }
    return isValid;
  }
  handleValidatePanCard = (newError: EmptyJsonObject) => {
    let isValid = true;
    if (this.state.identificationContactDetails.pan_card_number.value === "") {
      newError.pan_card_number = "Required!"
      isValid = false;
    } else if (!/^[A-Z]{5}\d{4}[A-Z]/.test(this.state.identificationContactDetails.pan_card_number.value)) {
      newError.pan_card_number = "Invalid PAN Card Number!"
      isValid = false;
    } else if(this.state.identificationContactDetails.pan_card_number.value.length !== 10){
      newError.pan_card_number = "PAN Card Number must be exactly 10 characters";
      isValid = false
    }
     return isValid
  }
 
  hendleGetAddress = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      yourAddress: {
        ...prevState.yourAddress,
        [name]: { value }
      }
    }));

   
    switch (name) {
      case "locality":
        if (value.length !== 0 && value.trim() === "") {
          this.setState((prevState) => ({
            yourAddressError: {
              ...prevState.yourAddressError,
              [name]: "Only spaces are not allowed!"
            }
          }));
        } else if (value.length !== 0 && !/^[A-Za-z0-9\s]+$/.test(value)) {
          this.setState((prevState) => ({
            yourAddressError: {
              ...prevState.yourAddressError,
              [name]: "Invalid input! Only alphabetic characters are allowed."
            }
          }));
        } else {
          this.setState((prevState) => ({
            yourAddressError: {
              ...prevState.yourAddressError,
              [name]: null
            }
          }));
        }
        break;
  
      case "pin_code":
        if (value.trim() === "") {
          this.setState((prevStatepin) => ({
            yourAddressError: {
              ...prevStatepin.yourAddressError,
              [name]: "Required!"
            }
          }));
        } else if (!/^[1-9]\d{2}\s?\d{3}$/.test(value)) {
          this.setState((prevStatepin) => ({
            yourAddressError: {
              ...prevStatepin.yourAddressError,
              [name]: "Invalid Pincode!"
            }
          }));
        } else {
          this.setState((prevStatepin) => ({
            yourAddressError: {
              ...prevStatepin.yourAddressError,
              [name]: null
            }
          }));
        }
        break;
  
      case "flat_number":
        if (value.trim() === "") {
          this.setState((prevStateflat) => ({
            yourAddressError: {
              ...prevStateflat.yourAddressError,
              [name]: "Required!"
            }
          }));
        } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
          this.setState((prevStateflat) => ({
            yourAddressError: {
              ...prevStateflat.yourAddressError,
              [name]: "Invalid input! Only alphanumeric characters are allowed."
            }
          }));
        } else {
          this.setState((prevStateflat) => ({
            yourAddressError: {
              ...prevStateflat.yourAddressError,
              [name]: null
            }
          }));
        }
        break;
    }
  };

  handleValidateAddress = (onlyDraft:boolean=false) => {
    const newErrors: EmptyJsonObject = {}
    let isValid = true;
    if (this.state.yourAddress.flat_number.value === "" ) {
      newErrors.flat_number = "Required!"
      isValid = false;
    } else if (!/^[A-Za-z0-9\s]+$/.test(this.state.yourAddress.flat_number.value)) {
      newErrors.flat_number = "Invalid!"
      isValid = false;
    }
    else if (this.state.yourAddressError.flat_number != null) {
      isValid = false;
      newErrors.flat_number = "Required!"
    }
  
    if (
      this.state.yourAddress.locality.value.trim() === "" || this.state.yourAddressError.locality?.length != null
    ) {
      newErrors.locality = "Required!";
      isValid = false;
    }
    else if (!/^[A-Za-z0-9\s]+$/.test(this.state.yourAddress.locality.value)) {
      newErrors.locality = "Invalid!"
      isValid = false;
    }
    if (this.state.yourAddress.pin_code.value === "") {
      newErrors.pin_code = "Required!"
      isValid = false;
    } else if (!/^[1-9]\d{2}\s?\d{3}$/.test(this.state.yourAddress.pin_code.value)) {
      newErrors.pin_code = "Invalid Pincode!"
      isValid = false;
    }
    if (Object.keys(newErrors).length > 0) {
      this.setState({
        yourAddressError: newErrors
      })
    } else {
      this.handleSaveandDraft("submitted")
      this.setState({
        activeState: onlyDraft ? this.state.activeState: this.state.activeState + 1,
        yourAddressError: {}
      })
    }
    return isValid;
  }
  
  handlePrimaryBankNumber = (value: any, name: any) => {
    if (!/^\d+(\.\d+)?$/.test(value) && value !== "") {
      this.setState((prevState) => ({
        primaryBankError: {
          ...prevState.primaryBankError,
          [name]: "Invalid Account Number!"
        }
      }))
    } else {
      this.setState((prevState) => ({
        primaryBankError: {
          ...prevState.primaryBankError,
          [name]: null
        }
      }))
    }
  }
  handlePrimaryIFSCCode = (name: any, value: any) => {
    if (value !== "") {
      if (!/^[A-Za-z]{4}[A-Za-z0-9]{7}$/.test(value)) {
        this.setState((prevState) => ({
          primaryBankError: {
            ...prevState.primaryBankError,
            [name]: "Invalid IFSC code!"
          }
        }))
      } else {
        this.setState((prevState) => ({
          primaryBankError: {
            ...prevState.primaryBankError,
            [name]: null
          }
        }))
      }
    }
  }
  handleGetPrimaryBankDetail = (e: { target: { value: string, name: string } }) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      primaryBank: {
        ...prevState.primaryBank,
        [name]: { value }
      }
    }))
    if (name === "primary_account_number") {
      this.handlePrimaryBankNumber(value, name)
    } else if (name === "primary_ifsc_code") {
      this.handlePrimaryIFSCCode(name, value)
    }
    if (name === "primary_bank_name") {
      if (value !== "") {
        if (!/^[a-zA-Z\s]+$/.test(value) && value !== "") {
          this.setState((prevState) => ({
            primaryBankError: {
              ...prevState.primaryBankError,
              [name]: "Invalid Bank Name!"
            }
          }))
        } else {
          this.setState((prevState) => ({
            primaryBankError: {
              ...prevState.primaryBankError,
              [name]: null
            }
          }))
        }
      } else {
        this.setState((prevState) => ({
          primaryBankError: {
            ...prevState.primaryBankError,
            [name]: null
          }
        }))
      }
    }
  }
  handleSetPrimaryBankType = (e: { target: { value: string, name: string } }, value: any) => {
    this.setState((prevState) => ({
      primaryBank: {
        ...prevState.primaryBank,
        primary_bank_type: { value }
      }
    }))
  }
  handleValidatePrimaryBank = (onlyDraft:boolean=false) => {
    const newErrors: EmptyJsonObject = {}
    let isValid = true;
    if (!/^\d+(\.\d+)?$/.test(this.state.primaryBank.primary_account_number.value) && this.state.primaryBank.primary_account_number.value !== "") {
      newErrors.primary_account_number = "Invalid Account Number!"
      isValid = false;
    }
    if (!/^[A-Za-z]{4}[A-Za-z0-9]{7}$/.test(this.state.primaryBank.primary_ifsc_code.value) && this.state.primaryBank.primary_ifsc_code.value !== "") {
      newErrors.primary_ifsc_code = "Invalid IFSC Code!"
      isValid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(this.state.primaryBank.primary_bank_name.value) && this.state.primaryBank.primary_bank_name.value !== "") {
      newErrors.primary_bank_name = "Invalid Bank Name!"
      isValid = false;
    }
    
    const secondaryBankValidate = this.handleValidateSecondaryBank()
    if (Object.keys(newErrors).length > 0 || !secondaryBankValidate) {
      this.setState({
        primaryBankError: newErrors
      })
    } else {
      this.handleSaveandDraft("submitted")
      this.setState({
        activeState: onlyDraft ? this.state.activeState: this.state.activeState + 1,
        primaryBankError: {}
      })
    }
    return isValid;
  }
  handleAddSecondaryBank = () => {
    this.setState((prevState) => ({
      secondaryBank: [
        ...prevState.secondaryBank,
        {
          id: uuidv4(),
          secondary_account_number: { value: "", error: null, name: "secondary_account_number" },
          secondary_ifsc_code: { value: "", error: null, name: "secondary_ifsc_code" },
          secondary_bank_name: { value: "", error: null, name: "secondary_bank_name" },
          secondary_bank_type: { value: "", error: null, name: "secondary_bank_type" },
        }
      ]
    }))
  }
  handleDeleteSecondaryBank = async (id: any) => {
    const filterArray = this.state.secondaryBank.filter((data: any) => data.id !== id)
    this.setState({
      secondaryBank: filterArray
    })
  }
  handleValidateSecondaryBank = () => {
    const obj = this.state.secondaryBank.map((data) => {
      const obj = data
      if (!/^\d+(\.\d+)?$/.test(data.secondary_account_number.value) && data.secondary_account_number.value !== "") {
        obj.secondary_account_number.error = "Invalid Account Number!"
      }
      return obj
    })
    this.setState({
      secondaryBank: obj
    })
    for (const iterator of obj) {
      for (const key in iterator) {
        if (iterator["secondary_account_number"].error !== null || iterator["secondary_ifsc_code"].error !== null || iterator["secondary_bank_name"].error !== null) {
          return false
        }
        break
      }
    }
    return true
  }
  handleSecondaryAccountNumber(name: any,data: any,value: any){
    if (!/^\d+(\.\d+)?$/.test(value) && value !== "") {
      return { ...data, [name]: { value, error: "Invalid Account Number!", name: name } }
    } else {
      return { ...data, [name]: { value, error: null, name: name } }
    }
  }
  handleSecondaryIfscCode(name: any,data: any,value: any){
    if (value !== "") {
      if (!/^[A-Za-z]{4}[A-Za-z0-9]{7}$/.test(value)) {
        return { ...data, [name]: { value, error: "Invalid IFSC code!", name: name } }
      } else {
        return { ...data, [name]: { value, error: null, name: name } }
      }
    } else {
      return { ...data, [name]: { value, error: null, name: name } }
    }
  }
  handleSecondaryBankname(name: any,data: any,value: any){
    if (!/^[a-zA-Z\s]+$/.test(value) && value !== "") {
      return { ...data, [name]: { value, error: "Invalid Bank name!", name: name } }
    } else {
      return { ...data, [name]: { value, error: null, name: name } }
    }
  }
  validateSecondaryDetail = (name: string, data: any, value: any) => {
    if (name === "secondary_account_number") {
      return this.handleSecondaryAccountNumber(name,data,value)
    } else if (name === "secondary_ifsc_code") {
      return this.handleSecondaryIfscCode(name,data,value)
    } else if (name === "secondary_bank_name") {
      return this.handleSecondaryBankname(name,data,value)
    }
    else {
      return { ...data, [name]: { value, error: null, name: name } }
    }
  }
  handleGetSecondaryBank = (e: { target: { value: string, name: string } }, id: string) => {
    const { name, value } = e.target
    const updataObject = this.state.secondaryBank.map((data) => {
      if (data.id === id) {
        return this.validateSecondaryDetail(name, data, value)
      } else {
        return data
      }
    })
    this.setState({
      secondaryBank: updataObject
    })
  }
  handleSetSecondaryBankType = (e: { target: { value: string, name: string } }, value: string, id: string) => {
    const updataObject = this.state.secondaryBank.map((data) => {
      if (data.id === id) {
        return { ...data, ["secondary_bank_type"]: { value, error: null, name: "secondary_bank_type" } }
      } else {
        return data
      }
    })
    this.setState({
      secondaryBank: updataObject
    })
  }
  handleGetAdditionalInformation = (value:any, name:any) => {
    let additionalInformation = this.state?.additionalInformation ? [...this.state.additionalInformation] : Array(5).fill([""]);
    additionalInformation[name] = value ? [value] : [""];
    this.setState({
      additionalInformation: additionalInformation
    });
  }

  getmeritialstatus = () =>{
    if(this.state.permanentInformation.marital_status.married){
      return "married"
    }
    else if(this.state.permanentInformation.marital_status.prefernottodisclose){
      return "prefernottodisclose"
    }
    else{
      return "unmarried"
    }
  }
  costumStepperData = async(name:string) => {
   let answers = this.state?.additionalInformation?.map((item:any) => item[0])
    let accumulatedData = {
        draft_status: name,
        first_name: this.state.permanentInformation.first_name.value,
        middle_name: this.state.permanentInformation.middle_name.value,
        last_name: this.state.permanentInformation.last_name.value,
        date_of_birth: this.state.permanentInformation.date_of_birth.value,
        father_name: this.state.permanentInformation.father_name.value,
        gender: this.state.permanentInformation.gender.male ? "male" : "female",
        marital_status: this.getmeritialstatus(),
        adhar_number: this.state.identificationContactDetails.adhar_number.value,
        adhar_enroll_number: this.state.identificationContactDetails.adhar_enroll_number.value,
        pan_card_number: this.state.identificationContactDetails.pan_card_number.value,
        secondary_mobile_number: this.state.identificationContactDetails.secondary_mobile_number.value,
        secondary_email: this.state.identificationContactDetails.secondary_email.value,
        landline_number: this.state.identificationContactDetails.landline_number.value,
        flat_number: this.state.yourAddress.flat_number.value,
        premise_name: this.state.yourAddress.premise_name.value,
        street_name: this.state.yourAddress.street_name.value,
        locality: this.state.yourAddress.locality.value,
        pin_code: this.state.yourAddress.pin_code.value,
        residental_status: this.state.residentialStatus.value,
        account_details: [
            {
                account_number: this.state.primaryBank.primary_account_number.value,
                bank_name: this.state.primaryBank.primary_bank_name.value,
                ifsc_code: this.state.primaryBank.primary_ifsc_code.value,
                bank_type: this.state.primaryBank.primary_bank_type.value,
            },
            ...this.state.secondaryBank.map((data) => ({
                account_number: data.secondary_account_number.value,
                bank_name: data.secondary_bank_name.value,
                ifsc_code: data.secondary_ifsc_code.value,
                bank_type: data.secondary_bank_type.value,
            }))
        ],
        answers:answers 
    };
    return accumulatedData;
};
  
  handleSaveandDraft = async (name:string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    let data = await this.costumStepperData(name)
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.saveGeneralInformationID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.saveDraftEndpoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({general_information : data})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleSubmit = async (name:string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    let data = await this.costumStepperData(name)
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.submitGeneralInformationID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.saveDraftEndpoint)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({general_information : data})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getDraftData = async()=>{
    const headers = {
      "token": await getStorageData(configJSON.authToken)
  };

  const getpasswordfrom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDraftDataID  = getpasswordfrom.messageId

  getpasswordfrom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDraftEndpoint
  );

  getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );

  getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType

  );
  runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
}

handleTostClose = () => {
this.setState({toast:{open:false,message:""}})
}

 extractAttributes = (responseJson:any) => {
  return responseJson?.data[0]?.attributes || {};
};

 formatGender = (gender:any) => ({
  male: gender === 'male',
  female: gender === 'female'
});

 formatMaritalStatus = (status:any) => ({
  married: status === 'married',
  unmarried: status === 'unmarried',
  prefernottodisclose: status === 'prefernottodisclose'
});

 formatDate = (date:any) => date ? moment(date,"DD/MM/YYYY").format("DD/MM/YYYY") : "";

 extractPrimaryBank = (primaryBank:any) => ({
  primary_account_number: { value: primaryBank[0]?.account_number || "" },
  primary_ifsc_code: { value: primaryBank[0]?.ifsc_code || "" },
  primary_bank_name: { value: primaryBank[0]?.bank_name || "" },
  primary_bank_type: { value: primaryBank[0]?.bank_type || "" },
});

 extractSecondaryBank = (secondaryBank: any[]) => {
  const secondaryBanks = secondaryBank
    .map((bank: any) => ({
      id: bank.id.toString(),
      secondary_account_number: { value: bank.account_number || "", error: null, name: "" },
      secondary_ifsc_code: { value: bank.ifsc_code || "", error: null, name: "" },
      secondary_bank_name: { value: bank.bank_name || "", error: null, name: "" },
      secondary_bank_type: { value: bank.bank_type || "", error: null, name: "" }
    }))
    .filter((bank: any) => 
      bank.secondary_account_number.value &&
      bank.secondary_ifsc_code.value &&
      bank.secondary_bank_name.value &&
      bank.secondary_bank_type.value
    );

  return secondaryBanks;
};

extractIdentificationContactDetails = (data:any) => (
  {
    adhar_number: { value: String(data?.adhar_number || "") },
    adhar_enroll_number: { value: String(data?.adhar_enroll_number || "") },
    pan_card_number: { value: String(data?.pan_card_number || "") },
    secondary_mobile_number: { value: String(data?.secondary_mobile_number || "") },
    secondary_email: { value: data?.secondary_email || "" },
    landline_number: { value: String(data?.landline_number || "") },
  }
);

 extractYourAddress = (data:any) => ({
  flat_number: { value: data.flat_number || "", error: null },
  premise_name: { value: data.premise_name || "", error: null },
  street_name: { value: data.street_name || "", error: null },
  locality: { value: data.locality || "", error: null },
  pin_code: { value: data.pin_code || "", error: null },
});

 handleGetStatus = (name: string) => {
  if (name === "non_resident") return "Non Resident";
  if (name === "not_ordinarily_resident") return "Not Ordinarily Resident";
  if (name === "resident") return "Resident";
};

  handleDraftResponse = async (responseJson: any) => {
    let draftdata = await getStorageData("Draft_data")
    let activeStep = await getStorageData("residentStep")
    removeStorageData("residentStep")
    const data = await responseJson?.data[0].attributes.draft_status =="submitted" ? this.extractAttributes(responseJson) : this.extractAttributes(JSON.parse(draftdata));

    const answers = ["Yes", "No", "Yes", "No", "Yes"];

    const formattedAnswers = await responseJson.data[0].attributes.draft_status =="save as draft" ? answers.map((answer, index) => ({
      0: answer,
      answer: [answer]
    })): data.question_answers.map((questionAnswer:any, index:string) => ({
      0: questionAnswer.answer , 
      question: questionAnswer.question,
      answer:[ questionAnswer.answer]
    }));

    this.setState({
      permanentInformation: {
        first_name: { value: data.first_name || "" },
        middle_name: { value: data.middle_name || "" },
        last_name: { value: data.last_name || "" },
        date_of_birth: { value: this.formatDate(data.date_of_birth) },
        father_name: { value: data.father_name || "" },
        gender: this.formatGender(data.gender),
        marital_status: this.formatMaritalStatus(data.marital_status),
      },
      identificationContactDetails: this.extractIdentificationContactDetails(data),
      yourAddress: this.extractYourAddress(data),
      residentialStatus: { value: this.handleGetStatus(responseJson.data[0].attributes.residental_status) || "" },
      primaryBank: this.extractPrimaryBank(data?.account_details || {}),
      secondaryBank: data?.account_details?.length > 1 ? this.extractSecondaryBank(data.account_details.slice(1)) : [],
      additionalInformation: formattedAnswers,
      activeState: activeStep ?Number(activeStep) : this.state.activeState
    });
  }

  handlenavigate = () => {
    setStorageData("redirectToGI","1")
    this.props.navigation.navigate(
      "Menu",
      { navigationBarTitleText: "Residential Status" }
    );
  }

  // Customizable Area Startis.state
  // Customizable Area End
}
