import React from "react";
// Customizable Area Start
import {
    withStyles,
    Box
}
    from "@material-ui/core";


import HousePropertyController, {
    Props,
} from "./HousePropertyController.web";
import StaticHouseProperty from "./StaticHouseProperty.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import  HousePropertyIncome  from "./HousePropertyIncome.web";


export class HouseProperty extends HousePropertyController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <HousePropertyIncome navigation={this.props.navigation} id={""} allStates={undefined} />
            </>
        );
    }
}

const styles = {
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "1em 0 0 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      },
}

export default withStyles(styles)(HouseProperty);
// Customizable Area End
