import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    withStyles,
    Typography,
    Button,
    Grid,
    Theme
} from "@material-ui/core";
import FileIncomeTaxController, {
    Props,
    configJSON
} from "./FileIncomeTaxController.web";
import { pockettaxprice, pockettaxreglogo, rupee } from "./assets"

export class FileIncomeTax extends FileIncomeTaxController {
    constructor(props: Props) {
        super(props);
    }
    renderLogo = () => {
        const { classes } = this.props;
        return (
            <Box>
                <img src={pockettaxreglogo} alt={configJSON.logoAlt} className={classes.logoimage} />
            </Box>
        )
    }
    renderTaxReturn = () => {
        const { classes } = this.props;
        return (
            <Grid container className={classes.parentContainer} >
                <Grid item xs={12} md={5} className={classes.imagewrapper} >

                    <img src={pockettaxprice} alt={configJSON.logoAlt} className={classes.imageprice} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.imagepaper}>
                    <div>
                    <Typography className={classes.textHeadingcolor} style={{paddingTop:"40px",paddingBottom:"20px"}}>File Income Tax Return yourself in <br/> 3 simple steps</Typography>
                    </div>
                    <Box className={classes.content}>
                        <Typography className={classes.priceAt}>{configJSON.priceAt}</Typography>
                        <img src={rupee} alt={configJSON.logoAlt} className={classes.rupee} />
                        <Typography className={classes.price}>900</Typography>
                    </Box>

                    <Box className={classes.button}>
                        <Button className={classes.startFiling} variant="contained" onClick={this.paymentData} data-test-id="go_back">{configJSON.startFiling}</Button>
                    </Box>
                    <Typography className={classes.payNotNow}>{configJSON.payNotNow}</Typography>
                    <Typography className={classes.payLater}>{configJSON.payLater}</Typography>
                </Grid>

            </Grid>

        )

    }

    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth={false} className={classes.defaltvalues}>
                {this.renderLogo()}
                {this.renderTaxReturn()}
            </Container>
        );
    }
}
export const styles = (theme: Theme) => ({
    defaltvalues:{
        overflow:"hidden",
        [theme.breakpoints.down('xs')]: {
            overflow:"auto",
        },
        "@media(max-width:959px)":{
            overflow:"auto"
        }
 
     
    },
    logoimage: {
        width: "15.738vw",
        height: "2.986vw",
        padding: '1.5em 0 0 1em',
    },
    parentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%'
    },
    imageprice: {
        width: "443px",
        height: "432.195px",
        [theme.breakpoints.down('xs')]: {
            width: "243px",
            height: "232px",
        }
    },
    imagepaper: {
        minWidth: "41.181vw",
        background: "#e6edea",
        minHeight: "32.583vw",
        borderRadius: "8px",
        padding: "0 2.5em 2.5em 2.5em",
        [theme.breakpoints.down('xs')]: {
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            flexDirection:"column"
        }

    },
    imagewrapper: {
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "flex-end",
        alignItems: "end",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            justifyContent: "center",
        }
    },
    textHeadingcolor: {
        color: "#252525",
        fontFamily: "Helvetica",
        fontSize: "26px",
        fontWeight: 700,
        // padding: '2em 0 1em 0'
        
    },
    priceAt: {
        color: "#252525",
        fontFamily: "Helvetica",
        fontSize: "24px",
        fontWeight: 400
    },
    price: {
        color: "#2B664B",
        fontFamily: "Helvetica",
        fontSize: "36px",
        fontWeight: 700
    },
    startFiling: {
        width: "19.444vw",
        background: "#337152",
        color: "#FFFFFF",
        '&:hover': {
            background: "#337152",
        }
    },
    payNotNow: {
        color: "#337152",
        fontSize: "18px",
        fontWeight: 700
    },
    button: {
        margin: '2em 0 3em 0'
    },
    content: {
        display: "flex",
        alignItems: "center",
        margin: "0 0 2.5em 0"
    },
    rupee: {
        width: "1.924vw",
        height: "2.361vw",
        margin: " 0 0.3em 0 1em"
    },
    payLater: {
        marginTop: '0.5em'
    }
})


export default withStyles(styles)(FileIncomeTax);

// Customizable Area End
