import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Dialog,
    IconButton,

} from "@material-ui/core";
import { withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { configJSON } from "../../blocks/multipageforms2/src/StaticSalaryController.web";
import { walletmoney, wallet, walletminus, dollarsquare, checked } from "../../blocks/multipageforms2/src/assets"

interface MyComponentProps {
    open: boolean;
    closeIcon: () => void;
    handleUpdateData:any;
    onChildChange: any;
}


export class DialogSalaryList extends React.Component<MyComponentProps> {

    handleUpdate = (step: number) => {
        this.props.onChildChange(step);
        this.props.handleUpdateData();
      }

    render() {

        const { classes }: any = this.props;
        const { open, closeIcon ,handleUpdateData, onChildChange} = this.props;
        return (
            <Dialog open={open} className={classes.drawer}>

                <Box className={classes.backgroundpriceList}>
                    <Box className={classes.modelHeadingWrapper}>
                        <Typography className={classes.deductionsHeading}>Alter the details</Typography>
                        <CloseIcon onClick={closeIcon} style={{cursor:"pointer"}} />
                    </Box>
                </Box>

                <Box className={classes.backgroundpriceListProduct}>                    
                    <Typography className={classes.deductions} onClick={() => this.handleUpdate(1)}><img src={walletmoney} className={classes.logo} style={{ marginInline: '20px' }} />{configJSON.editGross}</Typography>
                </Box>
                <hr className={classes.hrcolor} />
                <Box className={classes.backgroundpriceListProduct}>
                    <Typography className={classes.deductions}onClick={() => this.handleUpdate(1)}><img src={wallet} className={classes.logo} style={{ marginInline: '20px' }} />{configJSON.editallowances}</Typography>
                </Box>
                <hr className={classes.hrcolor} />
                <Box className={classes.backgroundpriceListProduct}>
                    <Typography className={classes.deductions }onClick={() => this.handleUpdate(1)}><img src={walletminus} className={classes.logo} style={{ marginInline: '20px' }} />{configJSON.editdeductions}</Typography>
                </Box>
                <hr className={classes.hrcolor} />
                <Box className={classes.backgroundpriceListProduct} >
                    <Typography className={classes.deductions} onClick={() => this.handleUpdate(0)}> <img src={checked} className={classes.logo} style={{ marginInline: '20px' }} />{configJSON.allfields}</Typography>
                </Box>

            </Dialog>
        )
    }

}
const styles = {
    modelHeadingWrapper: {
        display: "flex",
        justifyContent: "space-between",
        background: "#DEF5EA",
        padding: '1em'
    },
    backgroundpriceList: {
        maxWidth: "100%",
        width: "620px"
    },
    drawer: {
        width:'100%',
        " & .MuiDialog-paperScrollPaper": {
            borderRadius: '1em'
        }
    },
    backgroundpriceListProduct: {
        borderRadius: "26px",
        background: "#FFF",
        cursor:"pointer"
    },
    deductions: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Nunito",
        fontSize: "15px",
        fontWeight: 400,
        padding: '1.2em'
    },
    deductionsHeading: {
        fontFamily: "Nunito",
        fontSize: "18px",
        fontWeight: 500,
        marginLeft: "0.8em"
    },
    logo: {
        width: "30.27px",
        height: "28px",
    },

    hrcolor: {
        strokeWidth: "0.5px",
        stroke: "#C5C5C5",
        margin: '0 3em 0 2em',
        color: "lightgrey"
    }
}




export default withStyles(styles)(DialogSalaryList);

// Customizable Area End