import React from "react";
import DocumentController, { Props } from "./DocumentController.web";
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import OTPInput from "../../../components/src/OTPInput.web";
import { BackArrow, Shield } from "./assets";
import Toster from "../../../components/src/Toster";

export default class PanDocumentBlock extends DocumentController {
    constructor(props: Props) {
        super(props);
    }

    otpField = () => {
        return (
            <>
                <Typography variant="body2" align="left" style={style.error}>
                    {this.state.panOtpError}</Typography>
                <OTPInput
                    otp={this.state.panOtp ? `${this.state.panOtp}` : ""}
                    handleOtpChange={this.handlePanOtpChange}
                    separator=""
                    blocks={6}
                />
            </>
        );
    };

    successBody = () => {
        return (
            <Box component="div" display="flex" alignItems="center" flexDirection="column" sx={{ marginTop: "20px" }}>
                <img
                    style={style.shield}
                    src={Shield}
                    alt="shield"
                />
                <DialogTitle id="alert-dialog-title" style={{ ...style.modelTitle, textAlign: "center", ...style.white }}>
                    Verifed
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" align="center" style={{ ...style.subtitle1, margin: "0 25px 20px 25px" }}>Your PAN has been verified successfully</Typography>
                    {this.state.isOtpVerified && <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
                        <Button data-test-id="close-id" onClick={this.closePanModel} style={{ ...style.submitBtn,backgroundColor: "#2b664b" }} variant="contained">
                            Done
                        </Button>
                    </DialogActions>}
                </DialogContent>
            </Box>
        )
    }

    defaultBody = () => {
        return (
            <>
                <DialogTitle id="alert-dialog-title" style={{ ...style.modelTitle, textAlign: "center", ...style.white }}>
                Verify Your Pan Number
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" align="left" style={{ ...style.modelSubtitle, ...style.white }}>Verification code sent to you on registerd mobile number 32245454</Typography>
                    <Box component="div" display="flex" justifyContent="center" flexDirection="column" >
                        {this.otpField()}
                    </Box>
                    <Box component="div" display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "95%", margin: "7px auto 12px auto" }}>
                        <Typography align="right" variant="body2" style={style.timer}>
                            {this.state.second > 0 && `Resend OTP after: 00:${this.state.second.toString().padStart(2, '0')}`}
                        </Typography>
                        <Typography align="right" data-test-id="resend-id" variant="button" style={{ ...style.resendBtn, textTransform: "none" }} onClick={this.handlePanResendOtp}>{this.state.second == 0 && "Resend code"}</Typography>
                    </Box>
                    <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
                        <Button data-test-id="verify-id" onClick={this.handleVerifyPanOtp} style={{ ...style.submitBtn, backgroundColor:this.state.panOtp.length<6?"grey": "#2b664b", }} variant="contained" disabled={this.state.panOtp.length<6}>
                            Verify
                        </Button>
                    </DialogActions>
                </DialogContent> </>
        )
    }
    otpDialog = () => {
        return (
            <Dialog
                open={this.state.panOtpOpen}
                onClose={this.closePanModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    elevation: 0,
                    style: {
                        backgroundColor: "#1b222e", border: "1px solid #fff", borderRadius: "10px"
                    }
                }}
                disableScrollLock={true}
            >
                {this.state.isOtpVerified ? this.successBody() : this.defaultBody()}
            </Dialog>
        )
    }

    communInputField = (name: string, label: string, placeholder: string, type: string) => {
        return (
            <Box component="div" sx={{
                margin: "15px 0"
            }}>
                <TextField
                    data-test-id={`${name}-id`}
                    name={name}
                    style={{
                        boxSizing: "border-box",
                        margin: 0,
                    }}
                    placeholder={placeholder}
                    fullWidth
                    value={this.state.panDetails[name]}
                    margin="normal"
                    type={type}
                    variant="outlined"
                    inputProps={{
                        style: {
                            height: "0.5rem",
                            border: "1px solid white",
                            color: "#fff"
                        }
                    }}
                    onChange={(e: { target: { name: string, value: string } }) => this.handlePanChange(e)}
                />
                <Typography variant="body2" component="div" style={style.errors}>
                    {this.state.panDetailsError[name]}
                </Typography>
            </Box>
        );
    };

    render() {
        return (
            <Wrapper>
                <Container disableGutters maxWidth={false} style={{ ...style.fullWidth, display: "flex" }}>
                <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
                    {this.otpDialog()}
                    <Box component="div" className="container">
                        <Box component="div" sx={{ margin: { xs: "0 0 0 15px", lg: "0 0 0 -100px" } }}>
                            <img data-test-id="back-arrow-id" src={BackArrow} alt="back-arrow" onClick={this.handlePanBackarrow} />
                        </Box>
                        <Box component="div" className="form" sx={{ right: { xs: 0, sm: "100px",md:"50px" } }}>
                            <Box component="div" display="flex" flexDirection="column">
                                <Typography component="h1" variant="h4" style={{ ...style.title, ...style.white }}>Welcome to Pockettax!</Typography>
                                <Typography variant="subtitle1" style={{ ...style.titlebody, ...style.white }}>Lets setup your profile</Typography>
                                <Typography component="h2" variant="h5" style={{ ...style.subTitle, ...style.white }}>Add PAN card</Typography>
                            </Box>
                            <Box component="div">
                                {this.communInputField("name", "Name", "Name", "text")}
                                {this.communInputField("panNo", "panNo", "Pan card number", "text")}
                                <Button variant="contained"
                                    style={{ ...style.loginBtn }}
                                    onClick={this.handlePanDetailsSubmit}
                                    data-test-id="submit-id">Get OTP</Button>
                                <Typography align="right" style={style.otpBrif}>
                                    You will receive an SMS with OTP to verify your details
                                </Typography>
                            </Box>
                        </Box>
                        <Box component="div" className="bottom-text">
                            <Typography variant="subtitle1" align="center">You own your data, so your data does not move without your permission.</Typography>
                        </Box>
                    </Box>
                </Container>
            </Wrapper>
        )
    }
}

const Wrapper = styled("div")({
    "& .MuiOutlinedInput-root": {
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline":{
            border: '1px solid white',
            borderRadius:"0"
          }
        }
      },
    "& .container": {
        backgroundImage: `url(${require("../assets/panBackground.png")})`,
        backgroundSize: "contain",
        backgroundRepeat:"no-repeat",
        width: "110%",
        height: "90%",
        margin: "auto",
        position: "relative",

        "@media(min-width:1200px)": {
            width: "67%",
        },
        "@media(min-width:1400px)": {
            width: "55%",
            height: "75%",
        },
        "@media(max-width:620px)": {
            width: "100%",
        }
    },
    "& .form": {
        backgroundColor: "#1b222e", margin: "auto 0 0 0", borderRadius: "15px", padding: "0 40px", position: "absolute", bottom: "20px", opacity: "0.9",
        width:"50%",
        "@media(min-width:1400px)": {
            width: "45%",
        },
        "@media(min-width:1550px)": {
            width: "40%",
        }
    },
    "& .bottom-text": {
        position: "absolute",
        bottom: "-1rem",
        width: "100%",
        "@media(max-width:620px)": {
            bottom: "-2rem",
        }
    }
})

const style = {
    white: {
        color: "#fff",
    },
    fullWidth: {
        width: "100%",
        height: "100%"
    },
    modelSubtitle: {
        maxWidth: "435px",
        marginLeft: "5px"
    },
    modelTitle: {
        margin: "2px 0 0 0"
    },
    titlebody: {
        color: "#acafb3", margin: "5px 0 0 0", fontSize: "1.0rem"
    },
    title: {
        margin: "2rem 0 0 0"
    },
    loginBtn: {
        backgroundColor: "#2b664b",
        color: "#fff",
        width: "100%",
        margin: '10px 0px'
    },
    otpBrif: {
        color: '#cfd1d4', fontSize: "12px", padding: '5px 0px 20px 0px'
    },
    subTitle: {
        margin: "10px 0 0 0"
    },
    submitBtn: {
        width: "85%", marginBottom: "5px", color: "#fff"
    },
    errors: {
        color: "red",
        fontSize: "0.75rem",
        marginTop: "0.2rem"
    },
    resendBtn: { color: "#7292cd", textDecoration: "underLine", cursor: "pointer" },
    subtitle1: { color: "#fff" },
    timer: { color: "#fff" },
    backBtn: { marginTop: "5px", color: "#fff" },
    error: { margin: "0 0 10px 15px", color: "red" },
    shield: { width: "75px", height: "75px" },
};