import React from "react";
// Customizable Area Start

import { Typography, Box, TextField, Container, AccordionSummary, AccordionDetails, Accordion, Tooltip } from "@material-ui/core";
import { styled, withStyles } from "@material-ui/styles";
import { CloudComputing, DocumentCopy, ExpandArrow, Question, TrashCan } from "./assets";
import { Props, configJSON } from "./DocumentController.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppOutlinedIconIcon from '@material-ui/icons/GetAppOutlined';

import UploadDocumentsController from "./UploadDocumentsController.web";

const LightTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#eeffea",
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 12,
        maxWidth: 250,
        textAlign: "center",
        border: "0.5px solid black"
    },
}))(Tooltip);
// Customizable Area End

export default class UploadDocuments extends UploadDocumentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    comunDrageField = (label: string, accept: string, name: string, textHeader: string) => {
        return (
            <>
                <Box component="div" className='record-box' data-test-id={`${name}`}
                    onDrop={(e) => {

                        this.handleOnImportFile(e.dataTransfer.files, name);
                        e.preventDefault();
                    }}
                    onDragOver={e => e.preventDefault()}
                >
                    <Box component="div" display="flex" alignItems="center" flexDirection="column" data-test-id={`click${name}`}
                        className='browse-file-btn'
                        onClick={() => { this.clickInput(name) }}>
                        <TextField type='file' id={name} name={name} value="" className='input-hide'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleOnImportFile(e.target?.files!, name);
                                e.preventDefault();
                            }}
                            inputProps={{
                                accept: accept,
                                multiple: !(name == configJSON.Form26ASData ||
                                    name == configJSON.TaxpayerInformationSummaryData || name == configJSON.AnnualInforamtionStatementData)
                            }}
                            data-test-id={`upload${name}`}
                        />
                        <img src={CloudComputing} alt="cloud" style={{ width: "50px" }} />
                        <Typography variant="body1" align="center" className="dragText">{configJSON.DragDrop} &nbsp;<Typography variant="body1" className="browseText">Browse </Typography></Typography>
                        <Typography variant="body2" > {configJSON.supportedFormatJSON + textHeader}</Typography>

                    </Box>
                </Box>
                <Box style={{ margin: "1em 0em 0.5em 3em" }}>
                    {(label == configJSON.CapitalHeading) && <Typography variant="body2" > {configJSON.zerodha}</Typography>}
                </Box>
            </>
        )
    }

    renderIcon = (name: string, status: any) => {
        if (status >= 1) {
            if (name === configJSON.Form16Heading || name === configJSON.ReturnedFiledHeading || name === configJSON.CapitalHeading) {
                return <img className="expanderIcon" src={DocumentCopy} alt="documentcopy" style={{ marginInline: "20px" }} />;
            } else if (name === configJSON.Form26ASHeading || name === configJSON.TaxpayerInformationSummaryHeading || name === configJSON.AnnualInforamtionStatementHeading) {
                return <CheckCircleIcon style={{ color: "green", marginInline: "20px" }} />;
            }
        }
        return null;
    };
    renderErrror = () => {
        if (this.state.form26AS && this.state.form26AS.data && this.state.tis && this.state.tis.data && this.state.ais && this.state.ais.data) {
            const reminder1 = this.state.form26AS.data[0].reminder;
            const reminder2 = this.state.tis.data[0].reminder;
            const reminder3 = this.state.ais.data[0].reminder;
            return (reminder1 || reminder2 || reminder3)
        } else {
            return false
        }
    }
    comunAccordian = (name: string, id: string, filename: any, formTitledata: { info: string, link: string }, type: string, errormessage: string, textHeader: string) => {
        let level: any
        if (filename?.data && filename?.data[0] !== undefined) level = filename?.data && filename?.data.length == 0 ? "" : filename?.data.length - 1;
        let status = filename?.data?.length
        return (

            <Accordion expanded={this.state.expanded === id} onChange={this.handleChange(id)} className="accordian-div" data-test-id={`acc${id}`}>

                <AccordionSummary
                    className="boxStyle"
                    expandIcon={<img src={ExpandArrow} alt="expand" style={{ width: "15px" }} />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{ flexDirection: "row-reverse", backgroundColor: this.state.expanded === id ? "#fff1ca" : "#ffffff", background: this.state.expanded === id ? "boxStyle" : "" }}
                >


                    <Typography className="heading" variant="subtitle1" data-test-id={`header${id}`}>{name} {status <= 0 && ""}
                        {this.renderIcon(name, status)}
                    </Typography>


                    <Typography className="secondaryHeading" variant="subtitle2" >
                        {filename?.data && filename?.data[level]?.created_at}

                        <LightTooltip interactive data-test-id={`headers${id}`} title={<div>{formTitledata.info} <a href={formTitledata.link} target="_blank">{formTitledata.link}</a></div>}><img className="expanderIcon" src={Question} alt="question" style={{ marginInline: "20px" }} /></LightTooltip>
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <InputWrapper>
                        <Box component="div" sx={{ width: { xs: "100%", sm: "80%" }, margin: "10px auto" }}>
                            {this.state.formstatus ? <CircularProgress /> :
                                filename && filename?.data?.map((item: any) => {
                                    return (

                                        <Box style={{ display: "flex", flexDirection: "column", position: 'relative' }} key={item?.file_name + Math.floor(Math.random() * 200) + 1}>
                                            <Box style={{ display: "flex", justifyContent: "space-between", marginTop: '0.6rem' }}>
                                                <Typography variant="body1" key={item?.file_name + Math.floor(Math.random() * 200) + 1}>{item?.file_name}</Typography>
                                                <img className="TrashCanimg" src={TrashCan} alt="trash" style={{ width: "20px", cursor: "pointer" }} onClick={() => { this.deleteFile(id, item.attachment_id, item.id) }} data-test-id={`delfile${item.id}${id}`} />
                                                <GetAppOutlinedIconIcon className="download-btn" style={{ cursor: 'pointer' }} onClick={() => this.downloadFile(item.url, item.file_name)} data-test-id={`downldfile${item.id}${id}`} />
                                            </Box>
                                        </Box>
                                    )
                                })}

                            {
                                !this.state.formstatus && filename?.message &&

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="body1" style={{ fontSize: '12px' }}> Please select file</Typography>
                                    <img src={TrashCan} className="TrashCanimg" alt="trash" style={{ width: "20px", cursor: "pointer" }} />
                                </Box>


                            }
                            {
                                errormessage != "" &&

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="body1" style={{ color: errormessage == "" ? "" : "red", fontSize: '12px' }}>{errormessage}</Typography>
                                </Box>


                            }

                        </Box>
                        {this.comunDrageField(name, type, id, textHeader)}
                    </InputWrapper>
                </AccordionDetails>
            </Accordion>

        )
    }
    // Customizable Area End

    render() {


        // Customizable Area Start
        return (
            <Wrapper disableGutters>
                {this.renderErrror()}
                <Box component="div" sx={{ width: { xs: "90%", sm: "80%" }, height: "80%", margin: "auto", padding: "25px 0", boxSizing: "border-box", borderRadius: "15px" }} style={{ backgroundColor: "#f3f1ff", overflowY: "auto" }}>
                    <Typography variant="h5" className="accordian-div" style={{ color: "#3f6e59" }}>{configJSON.UploadMentionedDocuments}</Typography>
                    {(this.renderErrror()) && <Typography className="error-message">{configJSON.docErr}</Typography>}
                    {this.comunAccordian(configJSON.Form16Heading, configJSON.Form16Data, this.state.form16, this.state.form16title, ".pdf", this.state.form16validationError, "PDF")}
                    {this.comunAccordian(configJSON.Form26ASHeading, configJSON.Form26ASData, this.state.form26AS, this.state.form26title, ".pdf, .txt", this.state.form26validationError, "PDF/TXT")}
                    {this.comunAccordian(configJSON.TaxpayerInformationSummaryHeading, configJSON.TaxpayerInformationSummaryData, this.state.tis, this.state.tistitle, ".pdf", this.state.tisvalidationError, "PDF")}
                    {this.comunAccordian(configJSON.ReturnedFiledHeading, configJSON.ReturnedFiledData, this.state.returnFiledInPastYears, this.state.returnedFiledtitle, ".json, .txt", this.state.returnedFiledvalidationError, 'JSON/TXT')}
                    {this.comunAccordian(configJSON.AnnualInforamtionStatementHeading, configJSON.AnnualInforamtionStatementData, this.state.ais, this.state.aistitle, ".pdf", this.state.aisvalidationError, "PDF")}
                    {this.comunAccordian(configJSON.CapitalHeading, configJSON.CapitalData, this.state.capitalGainsComputationSheet, this.state.capitalGainstitle, ".csv, .xlsx", this.state.capitalValidationError, "CSV/XLXS")}
                </Box>
            </Wrapper>
        )
        // Customizable Area End

    }
}
// Customizable Area Start

const InputWrapper = styled(Box)({
    width: "100%",
    "& .record-box": {
        maxWidth: "50%",
        margin: "auto",
        border: "2px dashed #2b664b",
        boxSizing: "border-box",
        minWidth: "215px",
        borderRadius: "12px"
    },
    "& .browse-file-btn": {
        cursor: "pointer",
        padding: "10%",
    },
    "& .input-hide ": {
        display: "none"
    },
    "& .browseText": {
        fontWeight: 500,
        color: "#337152",
        textDecoration: "underline"
    },
    "& .dragText": {
        display: "flex",
        alignItems: "center",
        margin: "15px 0 10px 0",
        fontWeight: 500
    }
})

const Wrapper = styled(Container)({
    height: "100%",
    width: "100%",
    display: "flex",
    "& .heading": {
        fontSize: "15px",
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        fontFamily: "Helvetica-Bold, sans-serif",
        "@media(max-width:600px)": {
            flexBasis: '33.33%',
        }
    },
    "& .MuiAccordion-root": {
        boxShadow: "none"
    },
    "& .MuiAccordionSummary-root": {
        minHeight: "55px"
    },
    "& .MuiAccordion-root:before": {
        height: 0,
    },
    "& .accordian-div": {
        margin: "40px 40px 0 40px",
        borderRadius: "15px",
        overflow: "hidden",
        "@media(max-width:600px)": {
            margin: "40px 10px",
        }
    },
    " & .error-message": {
        margin: "0 40px 40px 40px",
        color: "red",
        fontSize: "12px",
        "@media(max-width:600px)": {
            margin: "40px 10px",
        }

    },
    "& .secondaryHeading": {
        fontWeight: 400,
        display: "flex",
        alignItems: "center"
    },
    "& .MuiIconButton-edgeEnd": {
        margin: 0,
    },
    "& .MuiAccordionSummary-expandIcon": {
        transform: "rotate(-90deg)",
    },
    "& .Mui-expanded": {
        transform: "rotate(0deg)",
    },
    "& .MuiAccordionSummary-content": {
        justifyContent: "space-between",
    },
    "& .expanderIcon": {
        width: "22px"
    },
    " & .boxStyle": {
        '&:hover': {
            backgroundColor: '#fff1ca',
        },

    },
    " & .TrashCanimg":{
        "@media (max-width:600px)" :{
           marginRight:"30px"
        }  
    },
        " & .download-btn": {
            position: 'absolute',
            top: 10,
            right: -33,
            opacity: 0.4,
            "@media (max-width:600px)" :{
                right: 0 
            }
        }
})
// Customizable Area End
