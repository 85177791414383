export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const creditSalary = require("../assets/creditSalary.png")
export const walletmoney = require("../assets/walletmoney.png")
export const wallet = require("../assets/wallet.png")
export const walletminus = require("../assets/walletminus.png")
export const dollarsquare = require("../assets/dollarsquare.png")
export const rupee = require("../assets/rupee-sign.png")
export const checked = require("../assets/checked.png")
export const selfHouse = require('../assets/selfHouse.png')
export const rentalHouse = require('../assets/rentalHouse.png')
export const deleteRed = require('../assets/deleteRed.png')
export const interest = require('../assets/Interest.png')
export const rent = require('../assets/Rent.png')
export const dividend = require('../assets/Dividend.png')
export const otherIncome = require('../assets/OtherIncome.png')
export const exemptIncome = require('../assets/ExemptIncome.png')
export const staticHouse = require('../assets/staticHouse.png')
export const editIcon = require('../assets/editIcon.png')
export const availableStat = require('../assets/availableStat.png')
export const deetsFromITPortal = require('../assets/deetsFromITPortal.png')
export const manualEntry = require('../assets/manualEntry.png')

export const calenderIcon= require('../assets/calenderImg.png')
export const info= require('../assets/info.png')
export const QuestionMark= require('../assets/QuestionMark.png')
export const Rightarrow= require('../assets/rightarrow.png')
export const DeleteIcon= require('../assets/DeleteIcon.svg')
export const LeftArrow= require('../assets/LeftIcon.svg')
export const calanderIcon= require('../assets/calanderIcon.png')
export const capital1= require('../assets/capital1.png')
export const capital2= require('../assets/capital2.png')
export const capital3= require('../assets/capital3.png')
export const capital4= require('../assets/capital4.png')
export const SharesSecurities1= require('../assets/SharesSecurities1.png')
export const SharesSecurities2= require('../assets/SharesSecurities2.png')
export const listedshare1 = require('../assets/listedshare1.png')
export const listedshare2 = require('../assets/listedshare2.png')
export const listedshare3 = require('../assets/listedshare3.png')
export const upload = require('../assets/upload.png')
export const Question = require('../assets/Question.png')
export const success = require('../assets/success.png')
export const downArrow= require('../assets/downArrow.png')
export const OtherAssets1 = require('../assets/OtherAssets1.png')
export const OtherAssets2 = require('../assets/OtherAssets2.png')
export const OtherAssets3 = require('../assets/OtherAssets3.png')
export const OtherAssets4 = require('../assets/OtherAssets4.png')
export const infoColorIcon = require('../assets/infoColorIcon.png')
export const tooltip = require('../assets/tooltip.png')
export const search = require('../assets/search.png')
export const edit = require('../assets/edit.png')
export const DeleteRedIcon = require('../assets/DeleteRedIcon.png')
///businessincome assets///
export const incomefromprofession= require('../assets/incomeforproffesions.png')
export const incomefrombusiness= require('../assets/incomeforbusiness.png')
export const incomefromfutures= require('../assets/incomefromfutures.png')
export const incomefrompatnership= require('../assets/incomefrompatnership.png')
export const verysmallbusiness= require('../assets/verysmallbusiness.png')
export const regularbusiness= require('../assets/regularbusinessincome.png')
export const futuresandoptions= require('../assets/futureandoptions.png')
export const intraday= require('../assets/intraday.png')
export const incomePartnershipFirm= require('../assets/income_partnership_firm.png')
export const otherBusiness= require('../assets/other_buiseness.png')
export const plyingTruck= require('../assets/plying_truck.png')
///businessincome assets///
export const deleteHouseProperty=require('../assets/deleteIcon.png')