import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core';
import {
  configJSON,
} from "../../blocks/user-profile-basic/src/TransactionHistoryController.web";

interface MyComponentProps {
  arr?: any;
  className:any;
  data?: boolean;

}

export class TransactionITR extends React.Component<MyComponentProps> {

  render() {
    
    const { classes }: any = this.props;
    const { arr, className, data } = this.props;
    return (
      <>
        {arr && arr.map(() => {
          return (
            <Box className={className}>
              <Box className={classes.boxSizes} style={{ width: !data ? "204px" : "148px", backgroundColor: "#FFFAED" }} >
                <Typography align="center" >{!data ? configJSON.transactionId : configJSON.serial}</Typography>

              </Box>
              <Box className={classes.boxSizes} style={{ width: !data ? "204px" : "172px", backgroundColor: "#FAFFFC" }}>
                <Typography align="center" >{!data ? configJSON.transactionDate : configJSON.date}</Typography>

              </Box>
              <Box className={classes.boxSizes} style={{ width: !data ? "254px" : "204px", backgroundColor: "#FFFAED" }}>
                <Typography align="center" >{!data ? configJSON.transactionpanDetails : configJSON.panDetails}</Typography>

              </Box>
              <Box className={classes.boxSizes} style={{ width: !data ? "204px" : "208px", backgroundColor: "#FAFFFC" }}>
                <Typography align="center" >{!data ? configJSON.assessmentYear : configJSON.taxPayer}</Typography>

              </Box>
              <Box className={classes.boxSizes} style={{ width: !data ? "254px" : "254px", backgroundColor: "#FFFAED" }}>
                <Typography align="center" >{!data ? configJSON.Amount : configJSON.statusofData}</Typography>
              </Box>
              {data ?
                <Box className={classes.boxSizes} style={{ width: "208px", backgroundColor: "#FAFFFC" }}>
                  <Typography align="center" >{configJSON.takeAction}</Typography>

                </Box> : null
              }
            </Box>
          )
        })}
      </>
    )
  }

}
const styles = {
  defaltValues: {
    padding: 0,
    margin: 0,

  },
  parentContainer: {
    display: "flex",
    margin: '0 4em 0 4em',
    justifyContent: "center"

  },
  parentContainer1: {
    display: "flex",
    margin: '0 3em 0 4em',
    justifyContent: "center",
    marginTop: '0.8em'

  },
  boxSizes: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    height: " 72px"
  },
  dropDownMain: {
    width: '100%',
    height: '310px',
    overflowX: 'hidden' as 'hidden',
    overflowY: 'scroll' as 'scroll',

  },
};
export default withStyles(styles)(TransactionITR);

// Customizable Area End