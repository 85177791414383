import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Theme,
    TextField,
    FormControl,
    Select,
    Snackbar,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CustomAutocomplete } from "../../../components/src/StyledMuiComponent";
import { CustomTextfield } from "../../../components/src/CustomTextfield.web";
import { downArrow } from "./assets";
// Customizable Area End

import RegularBusinessIncomeController, {
    Props,
    configJSON,
} from "./RegularBusinessIncomeController.web";

export class RegularBusinessIncome extends RegularBusinessIncomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    rendercommonComponetSelectInputRegularIncome = (stylesprop: string, name: string, value: string, onChange: ((event: React.ChangeEvent<{
        name: string;
        value: unknown;
    }>, child: React.ReactNode) => void)|any, error: boolean | undefined) => {
        const { classes } = this.props;

        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        placeholder="select"
                        variant="outlined"
                        name={name}
                        value={value}
                        data-test-id={name}
                        onChange={onChange}
                        onFocus={this.handleFocusRegularIncome.bind(this, name)}
                        error={error}
                    >
                        <MenuItem 
                        className={classes.menuinitialStyle}
                        value="choose"> <span className={classes.menuinitialStyleColor} >{configJSON.SelectInitial}</span></MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                   {error && <Box className={classes.errorBox}>
                    <Typography variant="caption" className={classes.errorText}>
                        required field
                    </Typography>
                    </Box>}
                </FormControl >
            </Box>

        )
    }
    renderStandardInputforRegularIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <TextField
                variant="outlined"
                placeholder={configJSON.emptyPlaceholder}
                className={classes.selectdropDown}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                onFocus={this.handleFocusRegularIncome.bind(this, name)}
                data-test-id={name}
                helperText={error && configJSON.requriedfield}
                FormHelperTextProps={{
                    style: { margin: '-12px 0 0 0' },
                }}
                error={error}

            />
        )
    }
    renderCostumTextfiledforRegularIncome = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <Box className={classes.amountWrapper}>
                <Box className={classes.ruppeslogoWrapper}>
                    <Typography className={classes.rupeessstyles}>₹</Typography>
                </Box>
                <TextField
                    variant="outlined"
                    placeholder={configJSON.passplaceHolder}
                    className={classes.textfiledWrapper}
                    name={name}
                    type="number"
                    value={value}
                    onChange={onChange}
                    onFocus={this.handleFocusRegularIncome.bind(this, name)}
                    data-test-id={name}
                    helperText={error && configJSON.requriedfield}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}
                    error={error}
                />
            </Box>
        )
    }

    renderCommonTextFieldforRegularIncome = (commonAttributes:
        {
            amountActive: boolean,
            selectactive: boolean,
            heading: string,
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            businessCode?:boolean
        }) => {
         const {textInputValues, amountValues } = commonAttributes
        const { classes } = this.props;
        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiledforRegularIncome(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        } else if (commonAttributes.selectactive) {
            rendertxt = this.rendercommonComponetSelectInputRegularIncome(classes.selectdropDown, amountValues.name, amountValues.value, amountValues.onChange, amountValues.error)
        }
        else if(commonAttributes.businessCode){
            rendertxt = <Box className={classes.autoCompleteBox}>
                <CustomAutocomplete
                    renderInput={(params: any) => <CustomTextfield
                        {...params}
                        placeholder="Select"
                        fullWidth
                        name={amountValues.name}
                        variant="outlined"
                    />}
                    onChange={(event,value)=>this.handleOnChangeCode(value as string)}
                    options={this.state.businessCodeList}
                    value={amountValues.value}
                    fullWidth
                    data-test-id={amountValues.name}
                />
                <img className={classes.downArrow} src={downArrow} />
            </Box>
            
        }
        else {
            rendertxt = this.renderStandardInputforRegularIncome(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)
        }


        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }
    rendercommonComponetDeductionsforRegularIncome = () => {
        const { nameofBusiness,
            businessCode,
            netProfitPl,
            Depreciation,
            DetailedPL,
            taxDepreciation, error } = this.state;
            const { classes } = this.props;

        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.natureofBussiness}</Typography>
                </Box>
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.nameofBusiness,
                    amountActive: false,
                    selectactive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "nameofBusiness", value: nameofBusiness, type: "text", onChange: this.handleOnchangeRegularChar, error: error["nameofBusiness"] },
                })}
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.BusinessCode,
                    amountActive: false,
                    selectactive: false,
                    businessCode: true,
                    amountValues: { name: "businessCode", value: businessCode, type: "text", onChange: this.handleOnchangeBusiness, error: error["businessCode"] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}

                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.PL}</Typography>
                </Box>
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.NetProfitasperPL,
                    amountActive: false,
                    selectactive: false,
                    amountValues: { name: "", value: "", type: "number", onChange: undefined, error: false },
                    textInputValues: { name: "netProfitPl", value: netProfitPl, type: "text", onChange: this.handleOnchangeNumber, error: error["netProfitPl"] },
                })}
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.Depreciationasperthebooks,
                    amountActive: false,
                    selectactive: false,
                    amountValues: { name: "", value: "", type: "number", onChange: undefined, error: false },
                    textInputValues: { name: "Depreciation", value: Depreciation, type: "text", onChange: this.handleOnchangeNumber, error: error["Depreciation"] },
                })}
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.GiveLink,
                    amountActive: false,
                    selectactive: false,
                    amountValues: { name: "", value: "", type: "number", onChange: undefined, error: false },
                    textInputValues: { name: "DetailedPL", value: DetailedPL, type: "text", onChange: this.handleOnchangeRegularIncome, error: error["DetailedPL"] },
                })}
                {this.renderCommonTextFieldforRegularIncome({
                    heading: configJSON.TaxDepreciation,
                    amountActive: false,
                    selectactive: false,
                    amountValues: { name: "", value: "", type: "number", onChange: undefined, error: false },
                    textInputValues: { name: "taxDepreciation", value: taxDepreciation, type: "text", onChange: this.handleOnchangeRegularIncome, error: error["taxDepreciation"] },
                })}

            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <>
                <Container className={classes.mainWrapper} maxWidth={false}>
                    <Snackbar 
                        data-test-id="snackbar"
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                        open={this.state.status} 
                        autoHideDuration={3000}
                        onClose={this.handleOnClose}>
                        <Alert severity={this.state.snackbarType}>
                            {this.state.snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Box className={classes.conditionalheading}>
                        <ArrowBackIosIcon fontSize="large"  className={classes.arrowColor}onClick={this.props.onClick}/><Typography data-test-id="initial-render" className={classes.donationdeading}>{configJSON.RegularBusinessIncomeHeading}</Typography>
                    </Box>
                    <Box className={classes.backgroundWrapper}>
                        {this.rendercommonComponetDeductionsforRegularIncome()}
                    </Box>
                    <Box >
                        <Button className={classes.buttonComponent} onClick={this.handleSubmitUpdateRegularIncome} data-test-id="next" >{configJSON.submitData}</Button>
                    </Box>
                </Container>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

export const styles = (theme: Theme) => ({
    arrowColor:{
color:"green"
    },
    menuinitialStyleColor:{
        color: "#ABABB0"
    },
    menuinitialStyle:{
        display: "none",
         color: '#ABABB0'
    },
    helpertext: {
        display: "flex",
        aliginItems: "center",
        gap: "5px",
        marginTop: '0.9em',
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 350,
        fontFamily: "Nunito",
    },
    headingwrapeer: {
        marginLeft: '2em',
        marginTop:'1em'
    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },
    },
    citystate: {
        display: "flex",
        gap: '20px',
        width: "100%",
        maxWidth: "500",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    textAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 8em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    errorBox: {
        marginTop: '-10px'
    },
    errorText: {
        color: '#f44336'
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    inputlableStyle: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
    },

    underhovertext: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    boxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },
    subheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        },
        '& .MuiSelect-select:focus':{
            backgroundColor: 'unset !important' 
        }


    },
    textfiledWrapper: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    ruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },

    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },
    labelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    amountWrapper: {
        display: "flex"
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },
    autoCompleteBox: {
        width:"500px",
        position: "relative" as "relative",
    },
    downArrow: {
        position: "absolute" as "absolute",
        right: "15px",
        zIndex: 1,
        bottom: "20px",
    },
})
export default withStyles(styles)(RegularBusinessIncome);
// Customizable Area End