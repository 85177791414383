import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


type CustomCheckBoxProps = {
  isChecked: boolean;
  onChangeValue: (value: boolean) => void;
  label: string;
  color?: string;
  testID?: string;
};
const GreenCheckbox = withStyles({
  root: {
    color: "#acafb3",
    '&$checked': {
      color: "#2b664b",
    },
    "& .MuiSvgIcon-root":{
      fontSize:"30px",
      position:"relative",
      top:"-2px",
      right:"0px"
    }
  },
  checked: {},
})(Checkbox);

type CustomCheckBoxState = {
  isChecked: boolean;
};

export default class CustomCheckBox extends Component<CustomCheckBoxProps, CustomCheckBoxState> {
  static propTypes = {
    testID: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    color: PropTypes.string,
  };

  constructor(props: CustomCheckBoxProps) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked,
    };
  }

  handleOnChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    this.setState({ isChecked });
    this.props.onChangeValue(isChecked);
  };

  render() {
    const { isChecked } = this.state;
    const { label, color } = this.props;

    return (
      <FormGroup row>
        <FormControlLabel
          style={{ marginRight: 0, color }}
          control={<GreenCheckbox checked={isChecked} onChange={this.handleOnChangeValue} id="check-id" />}
          label={label}
        />
      </FormGroup>
    );
  }
}
