import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import {WithStyles} from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getValue:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FileIncomeTaxController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  paymentData=()=>{
    this.props.navigation.navigate(configJSON.PanDocumentPage)
  }

  // Customizable Area End
}
