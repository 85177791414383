


import React, { useState } from 'react'
import {
    Box,
    styled,
} from "@material-ui/core";
import DatePicker from "react-multi-date-picker";

const Calander = styled(Box)({
    "& .rmdp-day.rmdp-selected >span": {
        backgroundColor: "#337152 !important",
    },
    "& .rmdp-day >span:hover": {
        backgroundColor: "#DEF5EA6B !important",
        color: "black !important"
    },
    "& .rmdp-day >span": {
        borderRadius: "20%",
    },
    "& .rmdp-day": {
        height: "40px",
        width: "30.5px !important"
    },
    "& .rmdp-today >span": {
        backgroundColor: "transparent",
        color: "black"
    },
    "& .rmdp-input--1": {
        border: "1px solid",
        borderColor: '#c6c6c6',
        maxWidth: "290px",
        borderRadius: "7px"
    },
    "& .rmdp-input": {
        backgroundColor: "transparent",
        position: "relative",
        zIndex: 99,
        height: "44px",
        border: "none",
        paddingLeft: "70px",
        fontSize: "18px",
        width: "100%"
    },
    "& .rmdp-input::placeholder": {
        color: "rgba(54, 64, 59, 0.50)",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .calander_icon": {
        position: "absolute",
        backgroundColor: "#DEF5EA",
        height: "48px",
        width: "54px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "7px 0px 0px 7px"
    },
    "& .rmdp-week-day": {
        backgroundColor: "#DEF5EA6B !important",
        color: "black !important"
    },
    "& .rmdp-arrow": {
        borderColor: "black !important"
    },
    "& .rmdp-header-values": {
        fontSize: "13.5px"
    },
    "& .rmdp-wrapper": {
        maxWidth: "290px"
    }
})
const CustomCalander = (props: any) => {
    const { calanderIcon,
        handleGetDate,
        name,
        value = null,
        maxDate = undefined
    } = props;
    return (
        <Calander style={{ position: "relative" }}>
            <Box className="calander_icon">
                <img src={calanderIcon} />
            </Box>
            <Box className="rmdp-input--1">
                <DatePicker
                    editable={false}
                    placeholder="DD/MM/YYYY"
                    name={name}
                    format="DD/MM/YYYY"
                    weekDays={["S", "M", "T", "W", "T", "F", "S", "S"]}
                    onChange={(e) => handleGetDate(new Date(e as any), name)}
                    maxDate={maxDate}
                    value={value}
                />
            </Box>
        </Calander>
    )
}

export default CustomCalander


