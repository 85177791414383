import React from "react";

import {
  Box,
  Button,
  Card,
  Container,
  styled,
  Typography,
  Dialog,
  Grid,
  IconButton
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Close } from "@material-ui/icons"
import {
  info,
  capital1,
  capital2,
  capital3,
  capital4,
  SharesSecurities1,
  SharesSecurities2,
  Question,
  upload,
  success,
  tooltip
} from "./assets"
import { CustomTextfield, CustomTextArea } from "../../../components/src/CustomTextfield.web";
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "24px",
    height: "571px",
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "881.297px",
    width: "881.297px"
  },
  "& .capital_dialogue_title": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#DEF5EA",
    height: "79.271px",
    padding: " 0px 40px",
    justifyContent: "space-between"
  },
  "& .capital_dialogue_title--text": {
    color: "#36403B",
    fontFamily: "Nunito",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .capital_dialogue_title--icon": {
    transform: "scale(1.2)",
    color: "#337152"
  },
  "& .capital_dialogue_desc": {
    padding: "30px"
  },
  "& .capital_dialogue_desc--box": {
    width: "100%",
    borderRadius: "16px",
    border: "1px solid #E0E0E0",
    backgroundColor: "#F3F5F4",
    height: "380px",
    padding: "35px 35px",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #337152"
    },
  },
  "& .capital_dialogue--image": {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "308px",
    height: "219px",
    borderRadius: "16px",
    marginBottom: "20px"

  },
  "& .capital_dialogue--typo": {
    color: "#36403B",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
  }
})
const NeedHelpDialog = styled(Dialog)({

  "& .NH_dialogue_title": {
    display: "flex",
    alignItems: "center",
    padding: "0px 30px",
    justifyContent: "space-between"
  },
  "& .NH_dialogue_title--text": {
    color: "#36403B",
    fontFamily: "Nunito",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 700
  },
  "& .NH_dialogue_title--icon": {
    color: "#337152",
    transform: "scale(1.2)",
  },
  "& .MuiDialog-paperWidthSm": {
    width: "592px",
    maxWidth: "592px",
    "&>div": {
      overflowY: "scroll",
      height: "800px",
    }
  },
  "& .MuiDialog-paper": {
    padding: "30px 0px",
    height: "571px",
    borderRadius: "24px",
    "& .NH_typo": {
      color: "rgba(54, 64, 59, 0.50)",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400
    },
    "& .NH_question--img": {
      width: "24px",
      height: "24px",
      marginRight: "10px"
    },
    "& .NH_question": {
      display: "flex",
      marginTop: "30px",
      marginBottom: "30px"
    },
    "& .NH_body": {
      padding: "0px 30px",
    },
    "& .NH_text-1": {
      color: "rgba(54, 64, 59, 0.70)",
      fontFamily: "Nunito",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600
    },
    "& .NH_form_fields": {
      marginBottom: "20px",
      "& >p": {
        marginBottom: "10px"
      }
    },
    "& .NH_upload-box": {
      width: "100%",
      height: "152px",
      borderRadius: "8px",
      border: "0.5px solid #C3CAD9",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&>img": {
        width: "44px",
        height: "36px",
        marginTop: "40px",
        marginBottom: "10px"
      },
      "&>p": {
        fontSize: "1.13rem",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginBottom: "5px",
        "&>span": {
          textDecoration: "underline",
          color: "#2B664B",
          cursor: "pointer"
        }
      }
    },
    "& .NH_button": {
      width: "198px",
      height: "46px",
      color: "#FFF",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "-0.529px",
      backgroundColor: "#337152",
      borderRadius: "10px"
    }
  },
})
const SuccessDialog = styled(Dialog)({
  "& .capital_success_icon": {
    width: "60px",
    margin: "20px auto"
  },
  "& .MuiDialog-paperWidthSm": {
    width: "592px",
    maxWidth: "592px",
    display: "flex",
    alignItems: "center",
    "&>div": {
      height: "800px",
    }
  },
  "& .S_text-1": {
    color: "rgba(54, 64, 59, 0.70)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: "30px"
  },
  "& .MuiDialog-paper": {
    borderRadius: "24px"
  },
  "& .S_dialogue_title--text": {
    color: "#337152",
    fontFamily: "Nunito",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "20px"
  },
  "& .S_button": {
    width: "198px",
    height: "46px",
    color: "#FFF",
    backgroundColor: "#337152",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "-0.529px",
    fontFamily: "Nunito",
    fontSize: "16px",
    borderRadius: "10px",
    marginBottom: "25px"
  }
})
import CapitalGainLangingPageController, {
  Props,
} from "./CapitalGainLangingPageController.web";
import Header from "../../../components/src/Header.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";

export default class CapitalGainLangingPage extends CapitalGainLangingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { SaleAndSecurityPopup, submit } = this.state;
    const { needHelp, handleCloseNeedHelp } = this.props;

    return (
      <MainWrapper maxWidth={false}>
        <Box className="body">
        <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
          <Box className="capital_form">
            <Typography className="capital_form_heading" variant="h5">
              Add Capital Gains Income
            </Typography>
            <CustomDialog open={SaleAndSecurityPopup} className="capital_dialogue">
              <Box className="capital_dialogue_title">
                <Typography className="capital_dialogue_title--text">
                  Select Sale of Shares & Securities
                </Typography>
                <IconButton onClick={this.handleSaleAndSecurityPopup}>
                  <Close className="capital_dialogue_title--icon" />
                </IconButton>
              </Box>
              <Grid container>
                <Grid item className="capital_dialogue_desc" lg={6} md={6} sm={6}>
                  <Box data-testid="navigate_to--ListedShares" className="capital_dialogue_desc--box" onClick={() => this.props.changeTab("Capitalgains/ListedShares")}>
                    <Box className="capital_dialogue--image">
                      <img src={SharesSecurities2} />
                    </Box>
                    <Box>
                      <Typography className="capital_dialogue--typo">Listed Shares, Equity Mutual Funds or Debt Mutual Funds</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item className="capital_dialogue_desc" lg={6} md={6} sm={6}>
                  <Box data-testid="navigate_to--ListedShares1" className="capital_dialogue_desc--box" onClick={() => this.props.changeTab("Capitalgains/Bonds")}>
                    <Box className="capital_dialogue--image">
                      <img src={SharesSecurities1} />
                    </Box>
                    <Box>
                      <Typography className="capital_dialogue--typo">Bonds, Debentures, Unlisted Shares and any other listed securities other than as stated above</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CustomDialog>
            <Card className="capital_form_card" onClick={this.handleSaleAndSecurityPopup}>
              <Box className="capital_image_box">
                <img className="capital_img" src={capital1} />
              </Box>
              <Box className="capital_detail">
                <Typography className="capital_detail--title">Sale of Shares & Securities</Typography>
                <Typography className="capital_detail--description">Capital Gains on Sale of Domestic/Foreign Shares, Mutual Funds, ETFs, Bonds, Debentures etc.</Typography>
              </Box>
            </Card>
            <Card data-testid="navigate_to--ListedShares2" className="capital_form_card" onClick={() => this.props.changeTab("Capitalgains/LandOrBuilding")}>
              <Box className="capital_image_box">
                <img className="capital_img" src={capital2} />
              </Box>
              <Box className="capital_detail">
                <Typography className="capital_detail--title">Sale of Land or Building</Typography>
                <Typography className="capital_detail--description">Capital Gains on sale of plots of land, buildings, residential or commercial properties</Typography>
              </Box>
            </Card>
            <Card className="capital_form_card">
              <Box className="capital_image_box">
                <img className="capital_img" src={capital3} />
              </Box>
              <Box data-testid="navigate_to--ListedShares3" className="capital_detail" onClick={() => this.props.changeTab("Capitalgains/CryptoAssets")}>
                <Typography className="capital_detail--title">Crypto/Virtual Digital Assets</Typography>
                <Typography className="capital_detail--description">Capital Gains on sale of Virtual Digital Assets such as all types of crypto assets, including NFTs, tokens, and cryptocurrencies</Typography>
              </Box>
            </Card>
            <Card className="capital_form_card" onClick={() => this.props.changeTab("Capitalgains/OtherAssets")}>
              <Box className="capital_image_box">
                <img src={capital4} />
              </Box>
              <Box className="capital_detail">
                <Typography className="capital_detail--title" style={{ maxWidth: "445px" }}>Others &#40;Reversal of 54 exemptions, any other assets
                  such as jewellery, paintings etc.&#41;<span className="tooltip"><img src={tooltip} /></span></Typography>
                <Typography className="capital_detail--description">Gains on sale of capital assets such as Unit Linked Insurance Policy, Personal effects such as jewellery, gold or silver.</Typography>
              </Box>
            </Card>
            <NeedHelpDialog open={needHelp as boolean}>
              <Box>
                <Box className="NH_dialogue_title">
                  <Typography className="NH_dialogue_title--text">
                    Make an enquiry
                  </Typography>
                  <IconButton data-testid="close_need_help" onClick={() => handleCloseNeedHelp()}>
                    <Close className="NH_dialogue_title--icon" />
                  </IconButton>
                </Box>
                <Box className="NH_body">
                  <Box className="NH_question">
                    <img className="NH_question--img" src={Question} />
                    <Typography className="NH_typo">
                      If you want us to process your capital
                      gains, you can send all the details to
                      us and we will process the information
                      as per the documents received. However,
                      since this is an additional consulting service
                      being provided for free, this might take time depending
                      on the documents pending with us.
                    </Typography>
                  </Box>
                  <Box className="NH_form_fields">
                    <Typography className="NH_text-1">Name</Typography>
                    <CustomTextfield variant="outlined" fullWidth placeholder="Enter" />
                  </Box>
                  <Box className="NH_form_fields">
                    <Typography className="NH_text-1">Email</Typography>
                    <CustomTextfield variant="outlined" fullWidth placeholder="Enter" />
                  </Box>
                  <Box className="NH_form_fields">
                    <Typography className="NH_text-1">Query</Typography>
                    <CustomTextArea
                      variant="outlined"
                      placeholder="Write Something.."
                      fullWidth
                      multiline
                      minRows={5}
                    />
                  </Box>
                  <Box className="NH_form_fields">
                    <Typography className="NH_text-1">Select document to upload</Typography>
                    <Autocomplete
                      id="combo-box-demo"
                      renderInput={(params: any) => <CustomTextfield {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select"
                      />}
                      options={["1", "2", "3"]}
                      fullWidth
                    />
                  </Box>
                  <Box className="NH_form_fields">
                    <Typography className="NH_text-1">Select document to upload</Typography>
                    <Box className="NH_upload-box">
                      <img className="upload_icon" src={upload} />
                      <Typography>Drag & drop files or <span style={{ textDecoration: "underline", color: "#2B664B", cursor: "pointer" }}>Browse</span></Typography>
                    </Box>
                  </Box>
                  <Button data-testid="submit_help" className="NH_button" onClick={() => this.handleSubmitNeedHelpForm()}>Submit</Button>
                </Box>
              </Box>
            </NeedHelpDialog>
            <SuccessDialog open={submit}>
              <img className="capital_success_icon" src={success} />
              <Typography className="S_dialogue_title--text">Submit Successfully</Typography>
              <Typography className="S_text-1">We have received your query, we will get backto your in 3-7 days</Typography>
              <Button data-testid="continue_test" className="S_button" onClick={() => this.setState({ submit: false })}>Continue</Button>
            </SuccessDialog>
          </Box>
        </Box>
      </MainWrapper>
    );
  }
}

const MainWrapper = styled(Container)({
  width: "100%",
  "& .capital_box-primary-1": {
    backgroundColor: "#F4F6F5",
    display: "flex",
    padding: "0px 10px",
    borderRadius: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "400px",
    height: "56px",
    marginRight: "20px",
    "@media(max-width: 768px)": {
      width: '100%',
      maxWidth: 'unset'
  }
  },
  "& .capital_header": {
    margin: "20px 0px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .capital_regime": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  "& .capital_box-primary-2": {
    backgroundColor: "#F4F6F5",
    width: "350px",
    alignItems: "center",
    borderRadius: "10px",
    display: "flex",
    padding: "20px",
    height: "56px",
    "@media(max-width: 768px)": {
      width: '100%',
      maxWidth: 'unset'
    }
  },
  "& .capital_form": {
    backgroundColor: "#F4F6F5",
    padding: "30px",
    borderRadius: "16px",
    "@media(max-width: 768px)": {
     padding: '15px'
    }
  },
  "& .capital_user_name": {
    display: "flex",
    gap: "5px",
    alignItems: "center"
  },
  "& .capital_form_card": {
    padding: '35px',
    borderRadius: "20px",
    display: "flex",
    height: "172px",
    alignItems: "center",
    marginBottom: "20px",
    boxShadow: "none",
    width: "95%",
    cursor: "pointer !important",
    "&:hover": {
      border: "2px solid #337152"
    },
    "@media(max-width: 768px)": {
      flexDirection: 'column',
      height: 'auto',
      width: '100%',
      padding: '15px'
    }
  },
  "& .capital_btn": {
    width: "211px",
    height: "46px",
    backgroundColor: "#337152",
    color: "white",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
    "&:first-child": {
      marginRight: "30px"
    },
  },
  "& .capital_btn_group": {
    marginTop: "50px",
    marginBottom: "50px"
  },
  "& .capital_resime_container": {
    position: "relative"
  },
  "& .capital_resime--new": {
    position: "absolute",
    top: "11px",
    right: "11px",
    fontSize: "13.5px",
    color: "#ffffff",
    zIndex: 1,
    fontFamily: "Nunito",
    fontWeight: 600,
  },
  "& .capital_resime--old": {
    position: "absolute",
    top: "11px",
    left: "15px",
    fontSize: "13.5px",
    color: "#ffffff",
    zIndex: 1,
    fontFamily: "Nunito",
    fontWeight: 600,
  },
  "& .capital_image_box": {
    width: "236px",
    height: "144px",
    backgroundColor: "#F4F6F5",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    padding: "0 30px",
    "@media(max-width: 768px)": {
      marginRight: 0,
    }
  },
  "& .capital_detail": {
    minHeight: "100%",
  },
  "& .capital_detail--title": {
    color: "#36403B",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "10px",
    marginTop: "-10px",
    "@media(max-width: 768px)": {
      marginTop: '20px'
    }
  },
  "& .capital_detail--description": {
    color: "rgba(54, 64, 59, 0.75)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    maxWidth: "524px"
  },
  "& .capital_img": {
    marginBottom: "5px"
  },
  "& .capital_form_heading": {
    margin: "20px 0px 40px 0px"
  },
  "& .capital_entry": {
    marginLeft: "50px"
  },
  "& .tooltip": {
    position: "absolute",
    transform: "translate(30%,20%)"
  }
});
