import React from 'react'

import {
    Box,
    Typography,
    styled,
    Grid,
} from "@material-ui/core";
import { CustomTextfield } from './CustomTextfield.web';
import { tooltip } from '../../blocks/multipageforms2/src/assets';
import classNames from 'classnames';

const ReturnGrid = (props: any) => {
    const {
        title,
        value,
        name,
        handleSetValue,
        errormessage,
        readOnly,
        startIcon = false,
        showTooltip = [false, ""],
        bold = false,
        required = false
    } = props;
    const btnClass = classNames({
        'font_style-1': true,
        "font_bold": bold,
        "font_gray--dark": required
    });
    return (
        <MainWrapper>
            <Grid container className="grid_container">
                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                    <Typography className={btnClass}>{title} {startIcon && <span className="star">*</span>}</Typography>
                </Grid>
                <Grid item lg={7} sm={12} md={7}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={12} className="error_container">
                            <Box className="textfield_box">
                                <Box className="ruppee">
                                    <span>₹</span>
                                </Box>
                                <CustomTextfield
                                    data-testid="test_GridTextField"
                                    variant="outlined" placeholder="00"
                                    value={value}
                                    inputProps={{ readOnly }}
                                    name={name}
                                    onChange={(e) => handleSetValue(e)}
                                />
                            </Box>
                            {errormessage && <Box className="error_message">{errormessage}</Box>}
                        </Grid>
                        <Grid item lg={6} md={12}></Grid>
                        {showTooltip[0] &&
                            <Box className="tooltip_container mt-10">
                                <Box className='tooltip_icon--box'>
                                    <img src={tooltip} className="tooltip_icon" alt="tooltip" />
                                </Box>
                                <Typography className="tooltip_text">{showTooltip[1]}</Typography>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </MainWrapper>
    )
}

export default ReturnGrid

const MainWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down('sm')]: {
        "& .align_flex": {
            marginBottom: "15px"
        },
        "& .grid_container": {
            width: "100%",
            marginBottom: "20px"
        },
        "& .rmdp-input--1": {
            maxWidth: "290px"
        }
    },
    "& .grid_container": {
        width: "90%",
        marginBottom: "35px"
    },
    "& .align_flex": {
        display: "flex",
        alignItems: "center",
        marginBottom: "0px"
    },
    "& .font_style-1": {
        color: "#9EA3A1",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .font_bold": {
        color: "rgb(54, 64, 59) !important"
    },
    "& .error_container": {
        display: "flex",
        maxWidth: "260px",
        position: "relative",
        flexDirection: "column"
    },
    "& .textfield_box": {
        display: "flex",
        position: "relative"
    },
    "& .ruppee": {
        width: "54px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#DEF5EA",
        zIndex: 1,
        marginRight: "-6px",
        flexShrink: 0
    },
    "& .error_message": {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    },
    "& .star": {
        color: "#C46F60",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .tooltip_icon": {
        marginRight: "12px",
        width: "20px"
    },
    "& .tooltip_container": {
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
    },
    "& .tooltip_text": {
        color: "#D1D1D1",
        fontSize: "18px",
        fontStyle: "normal",
        fontFamily: "Nunito",
        fontWeight: 600
    },
    "& .tooltip_icon--box": {
        height: "100%",
        marginTop: "6px"
    },
    "& .font_gray--dark": {
        color: "#737975 !important",
    }
}));