// Customizable Area Start

const styles = {
  defaltValues: {
    padding: 0,
    margin: 0
  },
  parentContainer: {
    display: "flex",
    margin: "0 4em 0 4em",
    justifyContent: "center"
  },
  parentContainer1: {
    display: "flex",
    margin: "0 3em 0 4em",
    justifyContent: "center",
    marginTop: "0.8em"
  },
  boxSizes: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: " 72px"
  },
  dropDownMain: {
    width: "100%",
    height: "310px",
    overflowX: "hidden" as const,
    overflowY: "scroll" as const
  }
};
export default styles;
    // Customizable Area End
