import React from "react";
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import OTPInputAuthController, { Props } from "./OTPInputAuthController.web";
import OTPInput from "../../../components/src/OTPInput.web";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { Shield } from "./assets";
// Customizable Area Start
// Customizable Area End
export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  otpField = () => {
    return (
      <>
        <Typography variant="body2" align="left" style={style.error}>{this.state.error}</Typography>
        <OTPInput
          otp={this.state.otp ? `${this.state.otp}` : ""}
          handleOtpChange={this.handleOtpChange}
          separator=""
          blocks={6}
        />
      </>
    );
  };

  successBody = () => {
    return (
      <Box component="div" display="flex" alignItems="center" flexDirection="column" sx={{ marginTop: "20px" }}>
        <img
          style={style.shield}
          src={Shield}
          alt="shield"
        />
        <DialogTitle id="alert-dialog-title" style={{ ...style.title, textAlign: "center" }}>
          Verifed
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" align="center" style={{ ...style.subtitle1, margin: "0 25px" }}>Your account has been verified successfully</Typography>
          {this.state.isLogin && <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
            <Button data-test-id="submit-id" onClick={this.goToLogin} style={{ ...style.submitBtn }} variant="contained">
              Login
            </Button>
          </DialogActions>}
        </DialogContent>
      </Box>
    )
  }
  defaultBody = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title" style={{ ...style.title, textAlign: "center" }}>
          Verify Your Email address
        </DialogTitle>
        <DialogContent >
          <Typography variant="subtitle1" align="center" style={style.subtitle1}>Verification code sent to you on {this.props.email} </Typography>
          <Box component="div" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            {this.otpField()}
          </Box>
          <Box component="div" display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "95%", margin: "7px auto 12px auto" }}>
            <Typography align="right" variant="body2" style={style.timer}>
              {this.state.second > 0 && `Resend OTP after: 00:${this.state.second.toString().padStart(2, '0')}`}
            </Typography>
            <Typography align="right" variant="button" style={{ ...style.resendBtn, textTransform: "none" }} onClick={this.handleResendOtp}>{this.state.second == 0 && "Resend code"}</Typography>
          </Box>
          <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
            <Button data-test-id="submit-id" onClick={this.handleSubmit} style={{ ...style.submitBtn }} variant="contained">
              Verify
            </Button>
            <Button data-test-id="back-arrow-id" startIcon={<ArrowBackOutlinedIcon style={{ color: "grey" }} />} onClick={() => this.handleOtpClose({}, "escapeKeyDown")} style={{ ...style.backBtn, textTransform: "none" }}>Change email</Button>
          </DialogActions>
        </DialogContent> </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Dialog
        open={this.props.otpOpen}
        onClose={this.handleOtpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "#1b222e", border: "1px solid #fff", borderRadius: "10px"
          }
        }}
        disableScrollLock={true}
      >
        {this.state.isVerified ? this.successBody() : this.defaultBody()}
      </Dialog>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const style = {
  fullWidth: {
    width: "100%",
    height: "100%"
  },
  background: {
    width: "100%",
    height: "100%",
    background: "#8baef2"
  },
  submitBtn: {
    backgroundColor: "#2b664b", width: "85%", marginBottom: "5px", color: "#fff"
  },
  resendBtn: {
    color: "#7292cd", textDecoration: "underLine", cursor: "pointer"
  },
  timer: { color: "#fff" },
  title: {
    color: "#fff"
  },
  subtitle1: { color: "#fff", marginBottom: "1.1rem" },
  backBtn: {
    marginTop: "5px", color: "#fff"
  },
  shield: { width: "75px", height: "75px" },
  error: { margin: "0 0 10px 15px", color: "red" },
  backArrow: { width: "20px", marginRight: "5px" }
};
// Customizable Area End