import { IBlock } from "../../../framework/src/IBlock";

import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { downloadFile } from "../../../components/src/DownloadFile.web";



export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    documents: {
        [key: string]: string;
    },
    expanded: string;
    form16: any;
    form26AS: any;
    tis: any;
    returnFiledInPastYears: any;
    ais: any;
    capitalGainsComputationSheet: any;
    form16title: {
        info: string;
        link: string
    };
    form26title: {
        info: string;
        link: string
    };
    tistitle: {
        info: string;
        link: string
    };
    returnedFiledtitle: {
        info: string;
        link: string
    };
    aistitle: {
        info: string;
        link: string
    };
    capitalGainstitle: {
        info: string;
        link: string
    };
    form16validationError: string;
    form26validationError: string;
    tisvalidationError: string;
    returnedFiledvalidationError: string;
    aisvalidationError: string;
    capitalValidationError: string;
    formstatus: boolean;
    form16call: boolean;
    form26call: boolean;
    formAiscall: boolean;
    formTiscall: boolean;
    formReturncall: boolean;
    formCapitalcall: boolean;

    messageIdCounter: number;
    filesUploadData: any

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UploadDocumentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    ///fromstitlecallid Start///
    form16DesLink: string = ""
    form26DesLink: string = ""
    formTisDesLink: string = ""
    formReturnDesLink: string = ""
    formAisDesLink: string = ""
    formCapitalDesLink: string = ""


    ///fromstitlecallid end///

    //upoading Start//
    uploadAllForm16Data: string = ""
    uploadAllForm26Data: string = ""
    uploadAllFormTisData: string = ""
    uploadAllFormReturnedData: string = ""
    uploadAllFormAisData: string = ""
    uploadAllFormCapitalData: string = ""
    //upoading End//

    //recieving Start//
    uploadAllForm16DataGet = ""
    uploadAllForm26DataGet: string = ""
    uploadAllFormTisGet: string = ""
    uploadAllFormReturnedGet: string = ""
    uploadAllFormAisGet: string = ""
    uploadAllFormCapitalGet: string = ""
    //recieving End//

    //deleting Start//
    uploadAllForm16Del: string = ""
    uploadAllForm26Del: string = ""
    uploadAllFormTisDel: string = ""
    uploadAllFormReturnedDel: string = ""
    uploadAllFormAisDel: string = ""
    uploadAllFormCapitalDel: string = ""
    //deleting End//


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            // Customizable Area Start
            documents: {},
            expanded: "",
            form16: [],
            form26AS: [],
            tis: [],
            returnFiledInPastYears: [],
            ais: [],
            capitalGainsComputationSheet: [],
            form16title: {
                info: "",
                link: ""
            },
            form26title: {
                info: "",
                link: ""
            },
            tistitle: {
                info: "",
                link: ""
            },
            returnedFiledtitle: {
                info: "",
                link: ""
            },
            aistitle: {
                info: "",
                link: ""
            },
            capitalGainstitle: {
                info: "",
                link: ""
            },
            form16validationError: "",
            form26validationError: "",
            tisvalidationError: "",
            returnedFiledvalidationError: "",
            aisvalidationError: "",
            capitalValidationError: "",
            formstatus: false,
            form16call: false,
            form26call: false,
            formAiscall: false,
            formTiscall: false,
            messageIdCounter: 1,
            formReturncall: false,
            formCapitalcall: false,
            filesUploadData: ""

            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        const dataKey = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        const handleApiResponse = (apiName: string, stateKey: string) => {
            if (apiResponse) {
                this.handleGetAllForms(apiName, stateKey);
                this.setState({ formstatus: false });
            }
        };
        switch (dataKey) {
            case this.uploadAllForm16Data:
                handleApiResponse("fetch_all_form16", "form16");
                break;
            case this.uploadAllForm26Data:
                handleApiResponse("fetch_all_26as", "form26as");
                break;
            case this.uploadAllFormTisData:
                handleApiResponse("fetch_all_tis", "tis_form");
                break;
            case this.uploadAllFormAisData:
                handleApiResponse("fetch_all_ais", "form_ais");
                break;
            case this.uploadAllFormCapitalData:
                handleApiResponse("fetch_all_capital_gain", "capital_gain");
                break;
            case this.uploadAllFormReturnedData:
                handleApiResponse("fetch_all_previous_year_itr", "previous_year_itr");
                break;
            case this.uploadAllForm16DataGet:
                this.setState({ form16: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllForm26DataGet:
                this.setState({ form26AS: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllFormTisGet:
                this.setState({ tis: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllFormAisGet:
                this.setState({ ais: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllFormReturnedGet:
                this.setState({ returnFiledInPastYears: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllFormCapitalGet:
                this.setState({ capitalGainsComputationSheet: apiResponse });
                this.setState({ formstatus: false });
                break;
            case this.uploadAllForm16Del:
                handleApiResponse("fetch_all_form16", "form16");
                this.setState({ form16: apiResponse });
                break;
            case this.uploadAllForm26Del:
                handleApiResponse("fetch_all_26as", "form26as");
                this.setState({ form26AS: apiResponse });
                break;
            case this.uploadAllFormTisDel:
                handleApiResponse("fetch_all_tis", "tis_form");
                this.setState({ tis: apiResponse });
                break;
            case this.uploadAllFormAisDel:
                handleApiResponse("fetch_all_ais", "form_ais");
                this.setState({ ais: apiResponse });
                break;
            case this.uploadAllFormReturnedDel:
                handleApiResponse("fetch_all_previous_year_itr", "previous_year_itr");
                this.setState({ returnFiledInPastYears: apiResponse });
                break;
            case this.uploadAllFormCapitalDel:
                handleApiResponse("fetch_all_capital_gain", "capital_gain");
                this.setState({ capitalGainsComputationSheet: apiResponse });
                break;
            case this.form16DesLink:

                this.setState({ form16title: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })

                break;
            case this.form26DesLink:
                this.setState({ form26title: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })

                break;


            case this.formTisDesLink:
                this.setState({ tistitle: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })

                break;


            case this.formReturnDesLink:
                this.setState({ returnedFiledtitle: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })

                break;


            case this.formAisDesLink:
                this.setState({ aistitle: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })

                break;


            case this.formCapitalDesLink:
                this.setState({ capitalGainstitle: { info: apiResponse?.data?.attributes?.description, link: apiResponse?.data?.attributes?.link } })



                break;
            default:
                // Handle default case or do nothing if not needed
                break;
        }


        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start

        const fileTypes = ["form16", "26AS", "AIS", "previous_year_itr", "TIS", "capital_gains"];

        fileTypes.forEach(fileType => {
            this.filesHoverMessages(fileType);
        });

        if (!this.state.form16call) this.handleGetAllForms("fetch_all_form16", "form16");
        if (!this.state.form26call) this.handleGetAllForms("fetch_all_26as", "form26as");
        if (!this.state.formAiscall) this.handleGetAllForms("fetch_all_ais", "form_ais");
        if (!this.state.formTiscall) this.handleGetAllForms("fetch_all_tis", "tis_form");
        if (!this.state.formReturncall) this.handleGetAllForms("fetch_all_previous_year_itr", "previous_year_itr");
        if (!this.state.formCapitalcall) this.handleGetAllForms("fetch_all_capital_gain", "capital_gain");

        // Customizable Area End
    }

    handleUploadAllForms = (file: any, endpoint: string, formName: string) => {
        this.setState({ formstatus: true })
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const allFiles = [...file];
        let formData = new FormData();

        for (const element of allFiles) {
            formData.append(endpoint, element);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (formName == "form16") this.uploadAllForm16Data = requestMessage.messageId;
        if (formName == "form26as") this.uploadAllForm26Data = requestMessage.messageId;
        if (formName == "form_ais") this.uploadAllFormAisData = requestMessage.messageId;
        if (formName == "tis_form") this.uploadAllFormTisData = requestMessage.messageId;
        if (formName == "previous_year_itr") this.uploadAllFormReturnedData = requestMessage.messageId;
        if (formName == "capital_gain") this.uploadAllFormCapitalData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.form16pdfdata + "/" + formName
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleGetAllForms = (endpoint: string, formName: string) => {
        this.setState({ formstatus: true })
        const header = {
            "token": localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.setState(prevState => ({ messageIdCounter: prevState.messageIdCounter + 1 }));
        if (formName == "form16") {
            this.uploadAllForm16DataGet = requestMessage.messageId;
            this.setState({ form16call: true })

        }
        if (formName == "form26as") {
            this.setState({ form26call: true })
            this.uploadAllForm26DataGet = requestMessage.messageId;
        }
        if (formName == "form_ais") {
            this.setState({ formAiscall: true })
            this.uploadAllFormAisGet = requestMessage.messageId;


        }
        if (formName == "tis_form") {
            this.setState({ formTiscall: true })
            this.uploadAllFormTisGet = requestMessage.messageId;
        }
        if (formName == "previous_year_itr") {
            this.setState({ formReturncall: true })
            this.uploadAllFormReturnedGet = requestMessage.messageId;


        }
        if (formName == "capital_gain") {
            this.setState({ formCapitalcall: true })
            this.uploadAllFormCapitalGet = requestMessage.messageId;
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.form16pdfdata + "/" + endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleDeleteAllForms = (filename: string, file: any, deleteType: string, formName: any) => {
        this.setState({ formstatus: true })
        const header = {
            "token": localStorage.getItem("authToken")
        };
        let formData = new FormData();
        if (formName === "form16" || formName === "previous_year_itr" || formName === "capital_gain") {
            formData.append('file_id', file);
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (formName === "form16") this.uploadAllForm16Del = requestMessage.messageId;
        if (formName === "form26as") this.uploadAllForm26Del = requestMessage.messageId;
        if (formName === "form_ais") this.uploadAllFormAisDel = requestMessage.messageId;
        if (formName === "tis_form") this.uploadAllFormTisDel = requestMessage.messageId;
        if (formName === "previous_year_itr") this.uploadAllFormReturnedDel = requestMessage.messageId;
        if (formName === "capital_gain") this.uploadAllFormCapitalDel = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.form16pdfdata + "/" + filename + "/" + deleteType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        if (formName === "form16" || formName === "previous_year_itr" || formName === "capital_gain") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodTypeDel
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    filesHoverMessages = (type: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (type === "form16") this.form16DesLink = requestMessage.messageId;
        if (type === "26AS") this.form26DesLink = requestMessage.messageId;
        if (type === "AIS") this.formAisDesLink = requestMessage.messageId;
        if (type === "previous_year_itr") this.formReturnDesLink = requestMessage.messageId;
        if (type === "TIS") this.formTisDesLink = requestMessage.messageId;
        if (type === "capital_gains") this.formCapitalDesLink = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.attachmentGet + type
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleMultipleFiles = (name: string, extension: string | undefined, fileData: FileList) => {
        switch (name) {
            case configJSON.Form16Data:
                if (extension == "pdf") {
                    this.handleUploadAllForms([...fileData], "form16[]", "form16")
                    this.setState({ form16validationError: "" })
                } else this.setState({ form16validationError: configJSON.validtionMessage })
                break;
            case configJSON.ReturnedFiledData:
                if (extension === 'txt' || extension === 'json') {
                    this.handleUploadAllForms([...fileData], "previous_itr[]", "previous_year_itr")
                    this.setState({ returnedFiledvalidationError: "" })
                } else this.setState({ returnedFiledvalidationError: configJSON.validtionMessage })
                break;
            case configJSON.CapitalData:
                if (extension === 'csv' || extension === 'xlsx') {
                    this.handleUploadAllForms([...fileData], "capital_gain[]", "capital_gain")
                    this.setState({ capitalValidationError: "" })
                } else this.setState({ capitalValidationError: configJSON.validtionMessage })

                break;
        }
    }
    handleSingleFIles = (name: string, extension: string | undefined, fileData: FileList) => {
        switch (name) {

            case configJSON.TaxpayerInformationSummaryData:
                let taxform = () => {
                    this.handleUploadAllForms([...fileData], "form_tis", "tis_form")
                    this.setState({ tisvalidationError: "" })
                }
                let taxformerror = () => {
                    this.setState({ tisvalidationError: configJSON.validtionMessage })
                }
                extension == "pdf" ? taxform() : taxformerror()
                break;
            case configJSON.ReturnedFiledData:

                let returnfile = () => {

                    this.handleUploadAllForms([...fileData], "previous_itr[]", "previous_year_itr")
                    this.setState({ returnedFiledvalidationError: "" })
                }
                let returnfileerror = () => {
                    this.setState({ returnedFiledvalidationError: configJSON.validtionMessage })
                }

                extension === 'txt' || extension === 'json' ? returnfile() : returnfileerror()
                break;
            case configJSON.AnnualInforamtionStatementData:
                let annualinfo = () => {
                    this.handleUploadAllForms([...fileData], "form_ais", "form_ais")
                    this.setState({ aisvalidationError: "" })
                }
                let annualinfoerror = () => {
                    this.setState({ aisvalidationError: configJSON.validtionMessage })
                }
                extension === 'pdf' ? annualinfo() : annualinfoerror()

                break;
            case configJSON.CapitalData:
                if (extension === 'csv' || extension === 'xlsx') {
                    this.handleUploadAllForms([...fileData], "capital_gain[]", "capital_gain")
                    this.setState({ capitalValidationError: "" })
                } else this.setState({ capitalValidationError: configJSON.validtionMessage })

                break;
            default:
                this.validation(name, extension, fileData)
                break;
        }


    }
    validation = (name: string, extension: string | undefined, fileData: FileList) => {
        switch (name) {
            case configJSON.Form16Data:
                let handleform16 = () => {
                    this.handleUploadAllForms([...fileData], "form16[]", "form16")
                    this.setState({ form16validationError: "" })
                }
                let handleform16err = () => {
                    this.setState({ form16validationError: configJSON.validtionMessage })
                }
                extension == "pdf" ? handleform16() : handleform16err()
                break;
            case configJSON.Form26ASData:
                let form26as = () => {
                    this.handleUploadAllForms([...fileData], "form26as", "form26as")
                    this.setState({ form26validationError: "" })
                }
                let form26asError = () => {
                    this.setState({ form26validationError: configJSON.validtionMessage })
                }
                (extension == "pdf" || extension === 'txt') ? form26as() : form26asError()
                break;
        }
    }
    handleOnImportFile = (fileData: FileList, name: string) => {
        let extension = fileData[0]?.name?.split('.')?.pop();
        if (fileData.length > 1) {
            this.handleMultipleFiles(name, extension, fileData)

        }
        else if (extension !== 'csv') {
            this.handleSingleFIles(name, extension, fileData)
        }
        this.setState({ filesUploadData: fileData });
    }

    handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({ expanded: (isExpanded ? panel : "") })
    };

    clickInput = (name: string) => {
        const inputElement = document.getElementById(name);
        if (inputElement) {
            inputElement.click();
        }
    }
    deleteFile = (id: any, userid: any, pathId: string) => {
        switch (id) {
            case configJSON.Form16Data:
                this.handleDeleteAllForms(userid, pathId, "delete_attachment", "form16")
                break;
            case configJSON.Form26ASData:
                this.handleDeleteAllForms(userid, pathId, "delete_26as", "form26as")
                break;
            case configJSON.TaxpayerInformationSummaryData:
                this.handleDeleteAllForms(userid, pathId, "delete_tis", "tis_form")

                break;
            case configJSON.ReturnedFiledData:
                this.handleDeleteAllForms(userid, pathId, "delete_previous_year_itr", "previous_year_itr")
                break;
            case configJSON.AnnualInforamtionStatementData:
                this.handleDeleteAllForms(userid, pathId, "delete_ais", "form_ais")
                break;
            case configJSON.CapitalData:
                this.handleDeleteAllForms(userid, pathId, "delete_capital_gain", "capital_gain")
                break;
        }

    }
    downloadFile = (url: string, fileName: string) => {
        downloadFile(url, fileName)
    };
    // Customizable Area End
}
