import React from "react";
import OtpInput from 'react-otp-input';

interface MyPropsType {
    otp: string;
    handleOtpChange: (e: any) => void,
    separator: string,
    blocks: number
}

export default function OTPInput(props: MyPropsType) {
    return (
        <OtpInput
            value={props.otp}
            inputType='number'
            onChange={(e: any) => props.handleOtpChange(e)}
            numInputs={props.blocks}
            renderSeparator={<span>{props.separator}</span>}
            renderInput={(props: any) => <input {...props} />}
            inputStyle={style.inputStyle}
        />
    );
}

const style = {
    inputStyle: {
        width: '3.5rem',
        height: '3rem',
        borderRadius: '6px',
        border: '0px',
        margin: '0 0.575rem',
        background: '#f1f5ff',
        fontSize: '20px'
    }
}