import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

interface RegularBusinessIncome {
    "data": {
        "id": string,
        "type": string,
        "attributes": {
            "id": number,
            "business_name": string,
            "business_code": string,
            "net_profit_as_per_pl": string,
            "depreciation_as_per_the_book": string,
            "link_to_detailed_pl": string,
            "link_to_income_tax_depreciation_schedule": string,
            "pan_user_profile_id": number
        }
    },
    "errors": string
}

// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    onClick:()=>void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    nameofBusiness: string;
    businessCode: string,
    netProfitPl: string,
    Depreciation: string,
    DetailedPL:string,
    taxDepreciation:string,
    error: any;
    status: boolean
    authToken: string;
    snackbarMsg: string;
    regularIncomeId: string;
    snackbarType: "success" | "info" | "warning" | "error";
    businessCodeList: string[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RegularBusinessIncomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitUpdateRegularIncomeApiCallId: string = "";
    getRegularIncomeApiCallId: string = "";
    BusinessCodeID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            nameofBusiness: "",
            businessCode:"",
            netProfitPl: "",
            Depreciation: "",
            DetailedPL:"",
            taxDepreciation:"",
            error: {},
            authToken: "",
            snackbarMsg: configJSON.succesmessageSnackBar,
            regularIncomeId: "",
            snackbarType: "success",
            status: false,
            businessCodeList: []
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData(configJSON.authToken)
        this.setState({authToken: token})
        this.getBusinessCodeList()
        this.handleGetRegularIncome();
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(apiRequestCallId === this.getRegularIncomeApiCallId){
                this.receiveGetRegularIncome(responseJson);
            }
            if (apiRequestCallId === this.BusinessCodeID) {    
                this.setState({
                    businessCodeList: responseJson.business_codes
                })
            } 
            if(apiRequestCallId === this.submitUpdateRegularIncomeApiCallId){
                if(responseJson && responseJson.data){
                    this.setState({status: true, snackbarMsg: configJSON.succesmessageSnackBar, snackbarType: "success",})
                    setTimeout(() => {
                        this.props.onClick();
                    }, 1000);
                }else if(responseJson && responseJson.error){
                    this.setState({
                        status: true,
                        snackbarMsg: responseJson.error,
                        snackbarType: "error",
                    })
                }
            }
        }
    }

    receiveGetRegularIncome = (responseJson: RegularBusinessIncome) => {
        if (responseJson && responseJson.data) {
            let data = responseJson.data
            let attributes = data && data.attributes
            const { business_name, business_code, net_profit_as_per_pl, depreciation_as_per_the_book, link_to_detailed_pl, link_to_income_tax_depreciation_schedule } = attributes;

            this.setState({
                regularIncomeId: data.id,
                nameofBusiness: business_name,
                netProfitPl: this.checkIsZeroPresent(net_profit_as_per_pl),
                Depreciation: this.checkIsZeroPresent(depreciation_as_per_the_book),
                DetailedPL: link_to_detailed_pl,
                taxDepreciation: link_to_income_tax_depreciation_schedule,
            })

            if(business_code){
                this.setState({
                    businessCode: business_code,
                })
            }
        }
    }

    checkIsZeroPresent = (value: string) => {
        return value.endsWith('.0') ? value.slice(0, -2) : value;
    }

    handleOnchangeRegularIncome = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))
    }
    handleOnchangeRegularChar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        if (!/^[a-zA-Z0-9 ]*$/.test(value) || value.length > 25) {
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))
    }
    handleOnchangeBusiness = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleOnChangeCode = (value:any) => {
        this.setState({
            businessCode:value
        })
    }
    handleOnchangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusRegularIncome = (name: string) => {
        this.setState((prevData) => ({
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurRegularIncome = (event: { target: { name: string, value: string } }) => {
        const { name,value } = event.target;
     this.setState((prevData) => ({
                error: {
                    ...prevData.error,
                    [name]: String(value).trim() === "" 
                }
            }));
    }

    handleOnClose = () => {
        this.setState({ status: false })
    }
    
    handleSubmitUpdateRegularIncome = () => {
        const { 
            nameofBusiness,
            businessCode,
            netProfitPl,
            Depreciation,
            DetailedPL,
            taxDepreciation,
        } = this.state;

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken
        };

        const httpBody = {
            "regular_business_income": {
                business_name: nameofBusiness,
                business_code: businessCode,
                net_profit_as_per_pl: netProfitPl,
                depreciation_as_per_the_book: Depreciation,
                link_to_detailed_pl: DetailedPL,
                link_to_income_tax_depreciation_schedule: taxDepreciation,
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitUpdateRegularIncomeApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.regularIncomeId ? `${configJSON.regularIncomeEndPoint}/${this.state.regularIncomeId}` : configJSON.regularIncomeEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.regularIncomeId ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleGetRegularIncome = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRegularIncomeApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.regularIncomeEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBusinessCodeList = async () => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.BusinessCodeID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BusinessCodeEndPoint
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    // Customizable Area End
}
