import { Message } from "../../framework/src/Message";

import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";

export const downloadFile = (url: string, fileName: string) => {

    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })


}

