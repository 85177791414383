Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "documentstorage";
exports.labelBodyText = "documentstorage Body";
exports.FileIncomeTax="FileIncomeTax"

exports.btnExampleTitle = "CLICK ME";
exports.Form16Heading="Form 16"
exports.Form16Data="form16"

exports.Form26ASHeading="Form 26AS"
exports.Form26ASData="form26AS"

exports.TaxpayerInformationSummaryHeading="TIS-Taxpayer Information Summary"
exports.TaxpayerInformationSummaryData="TIS"

exports.ReturnedFiledHeading="Return filed in the past years"
exports.ReturnedFiledData="returnedFiled"

exports.AnnualInforamtionStatementHeading="AIS- Annual Inforamtion Statement"
exports.AnnualInforamtionStatementData="AIS"

exports.CapitalHeading="Capital gains computation sheet"
exports.CapitalData="capitalGains"

exports.UploadMentionedDocuments="Upload below mentioned documents"
exports.DragDrop="Drag & drop files or"
exports.supportedFormatJSON ="supported format - "
exports.thisFileName="this is file name"
exports.attachmentGet="bx_block_bulk_uploading/attachments/get_attachment_details?form_type="
exports.validtionMessage='The uploaded file format is unsupported. Please choose a different file to upload.'
exports.form16pdfdata="bx_block_bulk_uploading/attachments"
exports.validationApiMethodTypeDel = "DELETE";
exports.docErr="Note: Form 26AS and TIS/AIS were posted more than 7 days ago; if there have been any changes. Please re-upload"
exports.zerodha="Our support is currently limited to sheets from Zerodha and Grow. If you have sheets form other brokers, kindly utilize the provided template"
exports.panCardEndpoint="account_block/pan_profiles"
exports.authToken="authToken"


// Customizable Area End