import React from "react";
// Customizable Area End
import {
    Box,
    Typography,
    Button,
    Modal,
    TextField,
    Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/styles';
import LandingPageController, { configJSON } from "./LandingPageController.web";

export class MakeaQuery extends LandingPageController {

    render() {
        return (
            <ModalWrapper
                open={this.props.open}
            >
                <Box style={{ boxSizing: 'border-box', ...webStyle.modalStyle }} className="enquiry-modal">
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleSnackbarClose} >
                        <Alert severity="success">
                            {configJSON.succesMessage}
                        </Alert>
                    </Snackbar>
                    <Box style={{ display: 'flex', flexDirection: 'column', }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={webStyle.heading}> {configJSON.makeAnEnquiry}</Typography>
                            <CloseIcon style={{ cursor: 'pointer' }} data-test-id="btnclick" onClick={this.props.handleModelClose} />
                        </Box>

                        <Typography style={webStyle.subHeading}>{configJSON.makeAnEnquiryName}</Typography>
                        <TextField variant="outlined" placeholder={configJSON.enterYourName} type="text"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleOnChange}
                            data-test-id="name"
                            onFocus={this.handleFocus}
                            style={webStyle.textfield}>
                        </TextField>
                        <Box>
                            {
                                (this.state.errors.name)
                                    ? <Typography style={webStyle.errorStyle}>{this.state.errors.name}</Typography>
                                    : <Typography style={{ visibility: configJSON.makeAnEnquiryHidden }}>""</Typography>
                            }
                        </Box>
                        <Typography style={webStyle.subHeading}>{configJSON.makeAnEnquiryEmail}</Typography>
                        <TextField variant="outlined" placeholder={configJSON.enterYourEmail} type="email"
                            value={this.state.email}
                            name="email"
                            data-test-id="email"
                            onChange={this.handleOnChange}
                            onFocus={this.handleFocus}
                            style={webStyle.textfield}>
                        </TextField>
                        <Box>
                            {
                                (this.state.errors.email)
                                    ? <Typography style={webStyle.errorStyle}>{this.state.errors.email}</Typography>
                                    : <Typography style={{ visibility: configJSON.makeAnEnquiryHidden }}>""</Typography>
                            }
                        </Box>
                        <Typography style={webStyle.subHeading}>{configJSON.makeAnEnquiryQuery}</Typography>
                        <TextField variant="outlined" placeholder={configJSON.writeSomething} type="text" multiline rows={4}
                            value={this.state.query}
                            name="query"
                            data-test-id="query"

                            onFocus={this.handleFocus}
                            onChange={this.handleOnChange}
                            style={webStyle.textfield}>
                        </TextField>
                        <Box>
                            {
                                (this.state.errors.query)
                                    ? <Typography style={webStyle.errorStyle}>{this.state.errors.query}</Typography>
                                    : <Typography style={{ visibility: configJSON.makeAnEnquiryHidden }}>""</Typography>
                            }
                        </Box>
                        <Box style={{ marginTop: '1em' }}>
                            <Button style={{ textTransform: "none", ...webStyle.button }} data-test-id="validations" variant="contained" onClick={this.makeAnEnquirySubmit}>{configJSON.makeAnEnquirySubmit}</Button>
                        </Box>
                    </Box>
                </Box>
            </ModalWrapper>
        )
    }

}
const ModalWrapper = styled(Modal)({
    "& .enquiry-modal": {
        "@media(max-width:420px)": {
            width: '100% !important',
            height: '100% !important',
        },
        "@media(max-height:580px)": {
            height: '100% !important',
            overflow: 'scroll'
        }
    }
})

const webStyle = {
    modalStyle: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '36.319vw',
        borderRadius: '20px',
        padding: '2em',
        backgroundColor: '#ffffff',
    },
    textfield: {
        border: "1px solid #C3CAD9",
        borderRadius: "8px",
        width: '100%'
    },
    heading: {
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: "21px",
        marginBottom: '1em'
    },
    subHeading: {
        fontFamily: 'Nunito',
        fontWeight: 400,
        fontSize: "16px",
        marginTop: '0.5em',
        marginBottom: "0.2em"
    },
    button: {
        background: "#2DD881",
        borderRadius: "8px",
        width: '117px',
    },
    errorStyle: {
        paddingTop: '5px',
        color: 'red',
        fontFamily: 'Nunito',
        fontSize: ' 12px',
    },
}
export default MakeaQuery;
// Customizable Area End