import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";


// Customizable Area End

import TaxSavingTipsController, {
  Props,
} from "./TaxSavingTipsController.web";
import { ExpandArrow } from "../../documentstorage/src/assets";

export default class TaxSavingTips extends TaxSavingTipsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  comunAccordian = () => {
    return (<>
      <Grid className="new-accordian-header">
      <Typography className="new-accordian-header-text">Tax Saving Tips For Next Year</Typography>
      </Grid>
      
      {this.state.tips.map((tip: any, index: any) => (
      <Accordion
        key={index}
        expanded={this.state.expanded == index}
        onChange={this.handleChange(index)}
        className="new-accordian-div"
      >
        <AccordionSummary
          className="new-boxStyle"
          expandIcon={<img src={ExpandArrow} alt="expand" style={{ width: "15px" }} />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          style={{
            flexDirection: "row-reverse",
            backgroundColor: "white",
          }}
        >
          <Typography className="new-heading" variant="subtitle1">
            {tip.title}
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {tip.content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))}</>)
  };

   comunAccordian1 = () => {
    return (
      <>
        <Grid className="accordian-header">
          <Typography className="accordian-header-text">General Tax Savings Tips</Typography>
        </Grid>
        
        {this.state.tips.map((tip:any, index:any) => (
          <div key={index} className="accordian-div">
            <div className="boxStyle" style={{ flexDirection: "row-reverse", backgroundColor: "white" }}>
              <Typography className="heading" style={{padding:"15px"}} variant="subtitle1">
                {tip.title}
              </Typography>
            </div>
          </div>
        ))}
      </>
    );
  };
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Wrapper disableGutters>
        <Box component="div" sx={{ width: { xs: "90%", sm: "95%" }, height: "40%", marginLeft: "30px",marginTop: "30px", paddingLeft: "10px", boxSizing: "border-box", borderRadius: "15px" }} style={{ backgroundColor: "#F3F1FF", overflowY: "auto" }}>
        {this.comunAccordian1()}
        </Box>
      </Wrapper>
      <GeneralWrapper>
        <Box component="div" sx={{ width: { xs: "90%", sm: "100%" }, height: "50%", marginTop: "30px",paddingLeft: "10px", boxSizing: "border-box", borderRadius: "15px" }} style={{ backgroundColor: "rgb(255, 241, 202)", overflowY: "auto" }}>
        {this.comunAccordian()}
        </Box>
      </GeneralWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Container)({
  width: "95%",
  "& .MuiAccordionSummary-expandIcon": {
    transform: "rotate(-90deg)",
  },
  "& .accordian-div": {
    margin: "20px 20px 0 20px",
    borderRadius: "15px",
    overflow: "hidden",
    "@media(max-width:600px)": {
      margin: "40px 10px",
    },
  },
  "& .MuiAccordion-root": {
    boxShadow: "none",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "55px",
  },
  "& .accordian-header-text": {
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 600,
  },
  "& .MuiIconButton-edgeEnd": {
    margin: 0,
  },
  "& .expanderIcon": {
    width: "22px",
  },
  "& .MuiAccordion-root:before": {
    height: 0,
  },
  "& .secondaryHeading": {
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
  },
  "& .accordian-header": {
    padding: "25px",
    margin: "18px",
  },
  "& .Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .download-btn": {
    position: 'absolute',
    top: 10,
    right: -33,
    opacity: 0.4,
  },
  "& .heading": {
    fontSize: "15px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    fontFamily: "Nunito",
    "@media(max-width:600px)": {
      flexBasis: '33.33%',
    },
  },
  "& .MuiAccordionSummary-content": {
    justifyContent: "space-between",
  },
  "& .boxStyle": {
    '&:hover': {
      backgroundColor: '#fff1ca',
    },
  },
  " & .error-message": {
    margin: "0 40px 40px 40px",
    color: "red",
    fontSize: "12px",
    "@media(max-width:600px)": {
      margin: "40px 10px",
    },
  },
});

const GeneralWrapper = styled(Container)({
  height: "90%",
  width: "95%",
  "& .MuiAccordionSummary-expandIcon": {
    transform: "rotate(-90deg)",
  },
  "& .new-accordian-div": {
    margin: "20px 20px 0 20px",
    borderRadius: "15px",
    overflow: "hidden",
    "@media(max-width:600px)": {
      margin: "40px 10px",
    },
  },
  "& .MuiAccordion-root": {
    boxShadow: "none",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "55px",
  },
  
  "& .MuiIconButton-edgeEnd": {
    margin: 0,
  },
  
  "& .MuiAccordion-root:before": {
    height: 0,
  },
  
  "& .new-accordian-header": {
    padding: "25px",
    margin: "18px",
  },
  "& .Mui-expanded": {
    transform: "rotate(0deg)",
  },
 
  "& .new-heading": {
    fontSize: "15px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    fontFamily: "Nunito",
    "@media(max-width:600px)": {
      flexBasis: '33.33%',
    },
  },
  "& .MuiAccordionSummary-content": {
    justifyContent: "space-between",
  },
  "& .new-boxStyle": {
    '&:hover': {
      backgroundColor: '#fff1ca',
    },
  },
 
});
// Customizable Area End
