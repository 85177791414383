import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  withStyles,
  Button, Dialog, DialogTitle, DialogContent,
  Input,
  InputAdornment,
  Typography,
  Paper,
  Snackbar,
  ClickAwayListener,
  styled

}
  from "@material-ui/core";
import { startCase } from "lodash"
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DoneIcon from '@material-ui/icons/Done';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';


import HousePropertyController, {
  Props,
  configJSON,
} from "./HousePropertyController.web";
import { rentalHouse, selfHouse, deleteHouseProperty, editIcon, staticHouse } from "./assets";
import HousePropertyEdit from "./HousePropertyEdit.web";
import RentalPropertyEdit from "./RentalPropertyEdit.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Toster from "../../../components/src/Toster";


export class HousePropertyIncome extends HousePropertyController {
  
  constructor(props: Props) {
    super(props);
  }



  selfMain() {
    const { classes } = this.props
    return (
      <div>
        <Box className={`${classes.mainCard} customMainBox`} style={{ borderRadius: this.state.expandSelfProperty ? '16px 16px 0 0' : 16 }} >
          <div className={classes.cardStyle} >
            <img src={selfHouse} className={classes.imageStyles} />
          </div>
          <Box className={classes.textDiv}>
            <Typography className={classes.heading11}>{configJSON.selfOccHouseProperty} </Typography>
            <Typography className={classes.subLine} data-test-id='closeSelfProperty'> {configJSON.selfOccSubText} </Typography>
          </Box>
          <div className={classes.expandImg}>
            {
              this.state.expandSelfProperty ?
                <ExpandLessIcon onClick={this.handleAccordian} />
                :
                <ExpandMoreIcon data-test-id='expandSelfProperty1' onClick={this.handleAccordian} />
            }
          </div>
        </Box>
      </div>
    )
  }

  selfSub() {
    const { classes } = this.props
    return (
      <ClickAwayListener onClickAway={this.handleClickOutside} data-test-id="onclick_away">
        <Box className={classes.mainCard} style={{
          borderRadius: this.state.expandSelfProperty ? '0 0 16px 16px' : 16,
          display: this.state.expandSelfProperty ? 'block' : 'none'
        }} >
          <Typography className={classes.heading22}> {configJSON.housePropertyDetails} </Typography>
          <Box className={classes.inputParents}>
            {this.state.allSelfProperties?.map((item: any, index: number) => (
              <Box key={index} className={classes.parentWrapper}>
                {this.state.openSelfPaperIndex === item.id && (
                  <Paper className={classes.paperWrapper} elevation={10}>
                    <Box className={classes.boxWrapper} data-test-id={"propertyadd"+index} onClick={this.getSignleHouseProperty.bind(this, item.id)}>
                      <img src={editIcon} className={classes.imageWrapper} /> Edit
                    </Box>
                    <hr className={classes.hrWrapper} />
                    <Box className={classes.boxWrapperA} data-test-id={"propertydelete"+index} onClick={this.handleDeleteProperty.bind(this,item.id,"selfProperty")}>
                      <img src={deleteHouseProperty}  /> Delete
                    </Box>
                  </Paper>
                )}
                <Box className={classes.inputboxStyle}>
                  <Input
                    placeholder="Enter property"
                    disabled={true}
                    disableUnderline
                    value={startCase(item.type + ' ' + (index + 1).toString())}
                    data-test-id={'selfProperty' + index}
                    className={item.attributes.draft_status =="submitted"?classes.inputField1:classes.inputboxStyleDraft}
                    inputProps={{ className: `${classes.placeholderLine}` }}
                    endAdornment={
                      <InputAdornment className={classes.inpurAdornmentStyles} position="end">
                        {item.attributes.draft_status =="submitted"
                        && <Box className={classes.iconStyle}>
                        <DoneIcon className={classes.bgcolour} />
                      </Box>
                        }
                        
                      </InputAdornment>
                    } />
                  <Button
                    className={item.attributes.draft_status =="submitted"?classes.buttonStyles:classes.buttonStylesDraft}
                    data-test-id={'extendSelf' + index}
                    onClick={this.handleOpenPaper.bind(this, item.id,"")}
                  >
                    <ChevronRightIcon />
                  </Button>
                </Box>
              </Box>
            ))}

          </Box>
        </Box>
      </ClickAwayListener>
    )
  }
      
  renderHouseEdit = () => (
    <HousePropertyEdit 
      navigation={this.props.navigation} 
      id={this.state.currentPropertyId} 
      homePage={this.homePage}   
    />
  );

  renderRentalEdit = () => (
    <RentalPropertyEdit 
      navigation={this.props.navigation} 
      id={this.state.currentPropertyId} 
      homePageRental={this.homePageRental} 
    />
  );

  renderCurrentPage() {
    const { classes } = this.props;
    const styleAlign: React.CSSProperties = { textAlign: 'center' };

  
    let renderPropertySection = () => (
      <Box className={classes.mainCard} style={{
        borderRadius: this.state.expandRentalProperty ? '0 0 16px 16px' : 16,
        display: this.state.expandRentalProperty ? 'block' : 'none'
      }}>
        <Typography className={classes.heading22}>{configJSON.housePropertyDetails}</Typography>
        <Box className={classes.inputParents}>
          {this.state.allRentalProperties?.map((item: any, index: number) => (
            <div key={index} className={classes.parentWrapper}>
              {this.state.openRentalPaperIndex  === item.id && (
                <Paper className={classes.paperWrapper} elevation={10}>
                  <Box className={classes.boxWrapper} onClick={this.getSignleRentalProperty.bind(this, item.id)}>
                    <img src={editIcon} className={classes.imageWrapper} /> Edit
                  </Box>
                  <hr className={classes.hrWrapper} />
                  <Box className={classes.boxWrapperA} data-test-id={"propertydelete"+item.id} onClick={this.handleDeleteProperty.bind(this,item.id,"selfProperty")}>
                    <img src={deleteHouseProperty} /> Delete 
                  </Box>
                </Paper>
              )}
              <div className={classes.inputboxStyle}>
                <Input
                  data-test-id={'rentalProperty' + index}
                  placeholder="Enter property"
                  disableUnderline
                  disabled={true}
                  value={startCase(item.type)}
                  className={item.attributes.draft_status === "submitted" ? classes.inputField1 : classes.inputboxStyleDraft}
                  inputProps={{ className: `${classes.placeholderLine}` }}
                  endAdornment={
                    <InputAdornment position="end" className={classes.inpurAdornmentStyles}>
                      {item.attributes.draft_status === "submitted" &&  
                        <div className={classes.iconStyle}>
                          <DoneIcon className={classes.bgcolour} />
                        </div>
                      }
                    </InputAdornment>
                  }
                />
                <Button
                  data-test-id={'extendRental' + index}
                  className={item.attributes.draft_status === "submitted" ? classes.buttonStyles : classes.buttonStylesDraft}
                  onClick={this.handleOpenPaper.bind(this, item.id,"rental")}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          ))}
        </Box>
      </Box>
    );
     
    return (
      <MainWrapper className={classes.containerStyle}>
        <Box>
          <Box className={`${classes.headerBox} customHeaderBox`}>
            <Typography className={classes.housePropertyHdr} data-test-id="initial-render">{configJSON.housePropertyIncome}</Typography>
            <Button className={classes.form16Btn} data-test-id="addProperty" onClick={this.handleAddProperty}><AddIcon /> {configJSON.addHouseProperty}</Button>
          </Box>
          {this.selfMain()}
          {this.selfSub()}
          <Box className={`${classes.mainCard} customMainBox`}>
            <div className={classes.subCard}>
              <img src={rentalHouse} className={classes.imageStyles} />
            </div>
            <Box className={classes.textDiv}>
              <Typography className={classes.heading11}>{configJSON.rentalProperty}</Typography>
              <Typography className={classes.subLine}>{configJSON.rentalSubText}</Typography>
            </Box>
            <div className={classes.expandImg}>
              {this.state.expandRentalProperty ? <ExpandLessIcon data-test-id='closeRentalProperty' onClick={this.handleAccordianRentalProperty} /> : <ExpandMoreIcon data-test-id='expandRentalProperty' onClick={this.handleAccordianRentalProperty} />}
            </div>
          </Box>
          {renderPropertySection()}
          <Box className={classes.clickHereWarning} style={styleAlign}>
            <Typography className={classes.clickHereWarningText}>{configJSON.clickHereWarningText}</Typography>
          </Box>
        </Box>
        <Dialog
        id="currentpage_dilog"
        onClose={this.handleAddProperty}
        maxWidth='md'
        fullWidth
        open={this.state.addPropertyBtn}
        data-test-id='dialog1'
          PaperProps={{
            elevation: 0,
            style: {
              backgroundColor: "#fff",
              justifyContent: 'center',
              borderRadius: "10px",
              width: 'auto',
              border: "1px solid #fff",
            }
          }}
          disableScrollLock={true}
        >
          <Box className={classes.dialogTitle} id="currentpage_title">
            <div className={classes.titleBody}><h2 className={classes.title}>{configJSON.housePropertyModalTitle}</h2></div>
            <CloseIcon data-test-id='closeDialog1' className={classes.closeBtn2} onClick={this.handleAddProperty} />
          </Box>
          <DialogContent className={classes.dialogContentStyle}>
            <Box className={classes.dialogImgBox} data-test-id='handleOpenHouseEdit' onClick={this.handleOpenHouseEdit}>
              <div className={classes.subDailogContent}>
                <img src={selfHouse} />
              </div>
              <Typography className={classes.dialogText} id="currentpage_text" style={styleAlign}>{configJSON.selfOccHouseProperty}</Typography>
            </Box>
            <Box className={classes.dialogImgBox} data-test-id='rentalProperty1' onClick={this.handleOpenRentalEdit}>
              <div className={classes.subDailogContent}>
                <img src={rentalHouse} />
              </div>
              <Typography className={classes.dialogText} style={styleAlign}>{configJSON.rentalProperty}</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </MainWrapper>
    );
  }
  renderStaticPage = () => {
    const { classes }: any = this.props;
    const styleAlign: React.CSSProperties = { textAlign: 'center' };

    return (
        <>
            <DashboardPanselector navigation={undefined} id={""} data-test-id="pancard" />

        <Container maxWidth={false} className={classes.mainBackground2}>
                <Box className={classes.cardWrapper2} >
                    <img src={staticHouse} className={classes.creditSalaryImage} />
                    <Box className={classes.textContentWrapper2} style={{ textAlign: "center" }}>
                        <Typography variant="h5" className={classes.headingText2}>{configJSON.dummyText}</Typography>
                    </Box>
                    <Box className={classes.textContentWrapper2Sub} style={{ textAlign: "center" }} >
                        <Typography variant="subtitle2" className={classes.headingText2Sub}>{configJSON.dummySub}</Typography>
                    </Box>
                    <Box className={classes.buttonWrappper2} style={{ textAlign: "center" }}>
                        <Button className={classes.form16Button2} data-test-id="addProperty1" onClick={this.handleAddProperty} >
                            <AddIcon style={{ marginRight: -20 }} />
                            {configJSON.addHouseProperty}</Button>
                    </Box>
                    <Box className={classes.clickHereWarning2} style={{ textAlign: 'center' }} >
                        <Typography className={classes.clickHereWarningText}>
                            <span className={classes.clickHereUnderLine2}>Click here</span>
                            {configJSON.clickHereWarningText}</Typography>
                    </Box>
                </Box>
            </Container> 

        <Dialog
          data-test-id='dialog'
          fullWidth
          maxWidth='md'
          open={this.state.addPropertyBtn}
          onClose={this.handleAddProperty}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            style: {
              backgroundColor: "#fff",
              justifyContent: 'center',
              width: 'auto',
              border: "1px solid #fff",
              borderRadius: "10px",
            }
          }}
        >
          <Box className={classes.dialogTitle}>
            <div className={classes.titleBody}><h2 className={classes.title}>{configJSON.housePropertyModalTitle}</h2></div>
            <CloseIcon data-test-id='closeDialog' className={classes.closeBtn2} onClick={this.handleAddProperty} />
          </Box>
          <DialogContent className={classes.dialogContentStyle}>
            <Box className={classes.dialogImgBox} data-test-id='selfProperty' onClick={this.handleOpenHouseEdit}>
              <div className={classes.subDailogContent}>
                <img src={selfHouse} />
              </div>
              <Typography className={classes.dialogText} style={styleAlign}>{configJSON.selfOccHouseProperty}</Typography>
            </Box>
            <Box className={classes.dialogImgBox} data-test-id='rentalProperty' onClick={this.handleOpenRentalEdit}>
              <div className={classes.subDailogContent}>
                <img src={rentalHouse} />
              </div>
              <Typography className={classes.dialogText} style={styleAlign}>{configJSON.rentalProperty}</Typography>
            </Box>
          </DialogContent>
        </Dialog>
        </>
    );
}
  
  render() {
    const { classes } = this.props;
     
    if (this.state.isLoading ) {
      return (
          <Box className={classes.loaderContainerbox}>
              <CircularProgress size="4em" className={classes.loaderContainer} />
          </Box>
      );
    }

    if (this.state.houseEdit) {
      return this.renderHouseEdit();
    } 
    if (this.state.rentalEdit) {
      return this.renderRentalEdit();
    }
    return (
      <>
        <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
            <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.deleteStatus} autoHideDuration={3000} data-test-id="snackbar"onClose={this.handleSnackbarDelete} >
          <Alert severity="success">
            Data deleted  successfully
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.savedSuccess} autoHideDuration={3000} data-test-id="snackbar"onClose={this.handleSuccessSnackClose} >
          <Alert severity="success">
            Data saved  successfully
          </Alert>
        </Snackbar>
        {(this.state.allRentalProperties.length > 0 || this.state.allSelfProperties.length >0) ?  this.renderCurrentPage() : this.renderStaticPage()}
      </>
    );
  }
}

const styles = {
  loaderContainerbox:{
    position: "relative" as const,
    height:"100%"
  },
  loaderContainer: {
    position: "absolute" as const,
    top: "25%",
    left: "50%",
    transform: "translate(-50%)",
  },
  parentWrapper: {
    position: "relative" as const
  },
  hrWrapper: {
    border: "0.5px solid rgba(152,152,152,0.5)"
  },
  imageWrapper: {
    maxWidth: '32px',
    width: "100%"
  },
  boxWrapper: {
    display: "flex", gap: '10px', alignItems: "center", padding: '0em 3em 0 0.5em', color: "green", cursor: "pointer"
  },
  boxWrapperA: {
    display: "flex", gap: '10px', alignItems: "center", padding: '0em 3em 0 0.5em', color: "red", cursor: "pointer"
  },
  paperWrapper: {
    position: "absolute" as const, left: "80%", top: 20, maxWidth: "100%", padding: '1em 1em 1em 0.5em', zIndex: 100
  },
  subDailogContent: {
    padding: '20px 40px', borderRadius: 16,
    background: '#FFF'
  },
  dialogContentStyle: {
    display: 'flex', justifyContent: 'space-evenly', padding: '15px 25px',
    width: '51rem',
  },
  subCard: {
    borderRadius: 12,
    background: '#F4F6F5',
    padding: '0px 45px'
  },
  containerStyle: {
    borderRadius: 16, background: '#F4F6F5'
  },
  bgcolour: {
    color: "#5EA476"
  },
  iconStyle: {
    background: '#E3FAEB', borderRadius: '50%'
  },
  inpurAdornmentStyles: {
    marginRight: 20
  },
  buttonStyles: {
    borderRadius: '0px 10px 10px 0px',
    border: '1px solid #979797',
    width: '4%',
    marginLeft: '1%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonStylesDraft:{
    borderRadius: '0px 10px 10px 0px',
    border: '1px solid #979797',
    width: '4%',
    marginLeft: '1%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "#dadada"

  },
  inputboxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },

  imageStyles: {
    width: 160
  },
  cardStyle: {
    borderRadius: 12,
    background: '#F4F6F5',
    padding: '0px 45px'
  },
  dialogTitle: {
    position: 'relative' as const,
    background: '#DEF5EA',
    display: "flex", justifyContent: "space-between", alignItems: "center",
    paddingRight: '1em'
  },
  titleBody: {
    flex: '0 0 auto',
    margin: 0,
    padding: '0px 20px'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  dialogImgBox: {
    padding: 10,
    borderRadius: 16,
    border: '1px solid #E0E0E0',
    background: ' linear-gradient(0deg, #F3F5F4 0%, #F3F5F4 100%), #F3F5F4',
    '&:hover': {
      background: 'rgba(222, 245, 234, 0.50)',
      border: ' 1.5px solid #337152',
      color: 'rgb(51,113,82)',
      fontWeight: 700

    },

  },
  dialogText: {
    width: '99%',
    paddingTop: 20,
    fontFamily: 'Nunito',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center'
  },
  form16Btn: {
    padding: '0.5em 0 0.5em 0',
    '&:hover': {
      background: "#337152",
    },
    display: 'flex',
    justifyContent: 'space-evenly',
    borderRadius: "8px",
    background: "#337152",
    color: "#fff",
    width: "220px",
  },

  housePropertyHdr: {
    fontSize: 27,
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#36403B',
    fontFamily: 'Nunito',
  },
  headerBox: {
    display: 'flex',
    padding: '40px 25px',
    justifyContent: 'space-between',
  },
  mainCard: {
    display: 'flex',
    background: '#FFF',
    margin: 10,
    padding: 10,
    position: 'relative' as const

  },
  clickHereWarning: {
    background: 'rgba(243, 194, 190, 0.50)',
    padding: 20,
    borderRadius: 10,
    border: '2px solid #9A4945',
    display: 'flex',
    margin: '40px 1%'
  },
  clickHereWarning2: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        backgroundColor: 'rgba(243, 194, 190, 0.50)',
        width: '50%',
        borderRadius: 10,
        border: '2px solid #9A4945',
        padding: 10,
  },
  clickHereWarningText: {
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#9B4945',
    fontFamily: 'Nunito',
    fontSize: 20,
    textDecorationLine: 'underline',
  },
  heading11: {
    fontStyle: 'normal',
    color: '#36403B',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: 700
  },
  heading22: {
    fontSize: 24,
    fontStyle: 'normal',
    color: '#36403B',
    fontFamily: 'Nunito',
    fontWeight: 700,
  },
  subLine: {
    fontSize: 16,
    color: 'rgba(54, 64, 59, 0.75)',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    width: '90%',
    fontWeight: 400
  },
  textDiv: {
    flexDirection: 'column' as const,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 10
  },
  inputParents: {
    display: 'flex',
    padding: 10,
    flexDirection: 'column' as const,
    justifyContent: 'space-evenly',
    // height: '20%'
  },
  expandImg: {
    background: '#F4F6F5',
    position: 'absolute' as const,
    top: '40%',
    right: '2%',
    padding: 10,
    borderRadius: 10
  },
  placeholderLine: {
    paddingLeft: 10
  },
  inputField1: {
    border: '1px solid #979797',
    height: '3rem',
    borderRadius: '16px 0px 0px 16px',
    width: '95%'

  },
  inputboxStyleDraft: {
    border: '1px solid #979797',
    height: '3rem',
    borderRadius: '16px 0px 0px 16px',
    width: '95%',
    background: "#dadada"
  },
  creditSalaryImage: {
    maxWidth: "308.3px",
    width: "100%",
    height: "auto"


},
cardWrapper2: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",


},
mainBackground2: {
    borderRadius: "16px",
    background: "#F4F6F5",
    height: "auto",
    margin: '2em',
    paddingBottom: 100,
},
textContentWrapper2: {
    marginTop: '2em',

},
textContentWrapper2Sub: {
    marginTop: '2em',
    width: '400px',
    "@media(max-width:664px)": {
        width: 'unset',
    },
},
headingText2: {
    color: "#36403B",
    fontSize: "29px",
    fontWeight: 600
},
headingText2Sub: {
    color: "#36403B",
    fontSize: "16px",
    fontWeight: 400
},
closeBtn2: {
    color: "#337152",
    position: "absolute" as const,
    top: 20,
    right: 15
},

form16Button2: {
    padding: '0.5em 0 0.5em 0',
    borderRadius: "8px",
    background: "#337152",
    color: "#fff",
    width: "220px",
    '&:hover': {
        background: "#337152",
    },
    justifyContent: 'space-evenly',
    display: 'flex',
},

buttonWrappper2: {
    margin: "4em 0 5em 0",
    display: "flex",
    flexDirection: "row" as const,
    "@media(max-width:512px)": {
        flexDirection: "column" as const,
        alignItems: "center",
        gap: "20px"
    },
},




clickHereUnderLine2: {
    fontStyle: 'normal',
    color: '#9B4945',
    fontFamily: 'Nunito',
    fontSize: 20,
    fontWeight: 700,
    textDecorationLine: 'underline',
},


}

const MainWrapper = styled(Container)({
"& .customHeaderBox": {
  "@media(max-width: 768px)": {
    flexDirection: 'column'
  }
},
"& .customMainBox": {
  "@media(max-width: 768px)": {
    flexDirection: 'column'
  }
}
  
});
export default withStyles(styles)(HousePropertyIncome);
// Customizable Area End
