import { BlockComponent } from "../../../framework/src/BlockComponent";

//@ts-ignore
import validator from "validator";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formData: {
    [key: string]: string;
  };
  emailError: string;
  passwordError: string;
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
  };
  isChecked: boolean;
  forgetOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitLogin: string = "";
  googleLogin: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area Start
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      toast: {
        type: "success",
        open: false,
        message: "",
      },
      isChecked: false,
      emailError: "",
      passwordError: "",
      forgetOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.handleApiResponse(apiRequestCallId, responseJson, errorReponse)
    }
  }
  // Customizable Area Start
  navigateFUnction=(status:string)=>{
    localStorage.setItem("Pan_card_status", status)
    this.props.navigation.history.push(localStorage.getItem("Pan_card_status")=="false"?"FileIncomeTax":`/Menu/Dashboard`)
  }
  handleApiResponse = (
    apiRequestCallId: string,
    responseJson: { errors: string[] | { message: string } | "", meta: { token: string,pan_card_status: string } },
    errorReponse: object
  ) => {
    const error = responseJson.errors && (!Array.isArray(responseJson.errors) ? responseJson.errors.message : responseJson.errors[0]);
    if (apiRequestCallId === this.submitLogin) {
      if (!error && responseJson.meta.token) {
        this.setState({
          toast: { open: true, message: "Login Successful", type: "success" },
        });
        localStorage.setItem("authToken", responseJson.meta.token)
        this.navigateFUnction(responseJson.meta.pan_card_status)
      } else {
        //Check Error Response
        this.setState({
          toast: {
            open: true,
            message: error,
            type: "error",
          },
        });
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (apiRequestCallId === this.googleLogin) {
      if (!error && responseJson.meta.token) {
        localStorage.setItem("authToken", responseJson.meta.token)
        this.navigateFUnction(responseJson.meta.pan_card_status)
      } else {
        this.setState({
          toast: { open: true, message: error, type: "error" }
        });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  handleTostClose = () => {
    this.setState({
      toast: {
        open: false,
        message: "error.message",
        type: "error",
      },
    });
  }

  handleForgetClose = () => {
    this.setState({ forgetOpen: false })
  }

  handleEmailValidation = () => {
    if (!this.state.formData.email) {
      return "Please enter email id"
    } else if (!validator.isEmail(this.state.formData.email)){
      return "Email is invalid"
    } else {
      return ""
    }
  }

  handleSubmit = () => {
    let error = {
      password:
        this.state.formData.password.length < 8
          ? "Password shoud be atleast 8 character"
          : "",
      email: this.handleEmailValidation(),
    };
    this.setState({
      emailError: error.email,
      passwordError: error.password,
    });
    let temp = [];
    for (const [key, value] of Object.entries(error)) {
      key && temp.push(value === "");
    }
    if (!temp.includes(false)) {
      const body = {
        data: {
          attributes: {
            email: this.state.formData.email,
            password: this.state.formData.password,
          },
          type: "email_account",
        },
      };
      this.submitLogin = sendAPIRequest(configJSON.loginWeb, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  };

  handleCheckbox = (value: boolean) => {
    this.setState({
      isChecked: value,
    });
  };

  handleForgotPassword = () => {
    this.setState({
      forgetOpen: true
    })
  };

  handleGoogleLogin = (response?: string) => {
    const body = {
      data: {
        type: "social_account",
        attributes: {
          social_auth_id: response,
        }
      }
    }
    this.googleLogin = sendAPIRequest(configJSON.loginGoogle, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  handleChange = (e: { target: { value: string, name: string } }, type: string) => {
    type === "email"
      ? this.setState({ emailError: "" })
      : this.setState({ passwordError: "" });
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };
  navigateToDashboard=()=>{
    this.props.navigation.history.push("")
  }
  // Customizable Area End
}
